import {
  Button,
  InputGroup,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { t } from "i18next";

import {
  DescriptionList,
  DescriptionListItem,
  DescriptionListItemsInColumn,
} from "components/DescriptionList";
import { Modal } from "components/Dialog";
import { FileList } from "components/FileList";
import { FormatDateTime } from "components/FormatDateTime";

import { IInterpretation } from "modules/verification/application/types/IInterpretation";

interface IProps {
  id: string;
  payload: IInterpretation;
}

export const ShowInterpretationDetailsModal = ({ id, payload }: IProps) => {
  return (
    <Modal id={id}>
      {({ onClose }) => {
        return (
          <>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {t("Zapytanie")} <FormatDateTime date={payload.createdAt} />
              </ModalHeader>
              <ModalBody as={VStack} align="stretch" spacing={0}>
                <DescriptionList>
                  <DescriptionListItemsInColumn>
                    <DescriptionListItem
                      label={t("Imię i nazwisko")}
                      value={payload.details.name}
                    />
                  </DescriptionListItemsInColumn>
                  <DescriptionListItemsInColumn>
                    <DescriptionListItem
                      label={t("E-mail")}
                      value={payload.details.email}
                    />
                    <DescriptionListItem
                      label={t("Telefon")}
                      value={payload.details.phone || "-"}
                    />
                  </DescriptionListItemsInColumn>
                  <DescriptionListItemsInColumn>
                    <DescriptionListItem
                      label={t("Treść")}
                      value={payload.details.content}
                    />
                  </DescriptionListItemsInColumn>
                  <DescriptionListItemsInColumn>
                    <DescriptionListItem
                      label={t("Dołączone pliki")}
                      value={<FileList files={payload.attachments} />}
                    />
                  </DescriptionListItemsInColumn>
                  <DescriptionListItemsInColumn>
                    <DescriptionListItem
                      label={t("Id weryfikacji")}
                      value={payload.verification.verificationId}
                    />
                  </DescriptionListItemsInColumn>
                </DescriptionList>
              </ModalBody>
              <ModalFooter>
                <InputGroup gap="2" justifyContent="end">
                  <Button onClick={onClose}>{t("Zamknij")}</Button>
                </InputGroup>
              </ModalFooter>
            </ModalContent>
          </>
        );
      }}
    </Modal>
  );
};
