import { Button, GridItem, SimpleGrid, useToken } from "@chakra-ui/react";
import { t } from "i18next";

import { TariffTypes } from "modules/tariffs/application/types/ITariff";

interface IPricing {
  verifications: string;
  additionalVerifications: string;
  historyAccess: string;
  verificationCertification: string | null;
  apiAccess: string;
  fileCount: string;
  answerTime: string | null;
  consultations: string | null;
  ppdo: string | null;
  resources: string | null;
}

interface IProps {
  setTariffType: (type: TariffTypes) => void;
  selectedType: TariffTypes;
}

export const Pricing = ({ setTariffType, selectedType }: IProps) => {
  const tariffs = Object.keys(pricingData) as TariffTypes[];
  const fields: [keyof IPricing, string][] = [
    ["verifications", t("Liczba weryfikacji")],
    ["additionalVerifications", t("Dodatkowa liczba weryfikacji")],
    ["historyAccess", t("Dostęp do historii weryfikacji")],
    ["verificationCertification", t("Poświadczenie weryfikacji")],
    ["apiAccess", t("Dostęp do API")],
    ["fileCount", t("Liczba plików poddana jednoczesnej weryfikacji")],
    [
      "answerTime",
      t(
        "Czas udzielenia odpowiedzi dotyczącej interpretacji wyniku weryfikacji, konsultacje"
      ),
    ],
    ["consultations", t("Kanały konsultacji")],
    ["ppdo", t("Możliwość zawarcia umowy PPDO")],
    ["resources", t("Gwarantowane zasoby obliczeniowe")],
  ];

  const [purple] = useToken("colors", ["purple.500"]);

  return (
    <>
      <SimpleGrid
        templateColumns="repeat(5, 1fr)"
        columnGap={4}
        overflowX="auto"
        p={1}
      >
        <GridItem></GridItem>
        <HeadingItem
          isSelected={selectedType === "S"}
          heading="S"
          background="#95C11F"
        />
        <HeadingItem
          isSelected={selectedType === "M"}
          heading="M"
          background="#3AAB49"
        />
        <HeadingItem
          isSelected={selectedType === "L"}
          heading="L"
          background="#009939"
        />
        <HeadingItem
          isSelected={selectedType === "E"}
          heading="ENTERPRISE"
          background="#424B90"
        />

        {fields.map(([key, translation]) => {
          const first = key === "verifications";
          const last = key === "resources";
          return (
            <>
              <GridItem
                fontSize="sm"
                color="gray.600"
                border="1px solid gray"
                borderTop={first ? "1px solid" : "none"}
                borderColor="gray.200"
                alignContent="center"
                p={3}
                w="300px"
                borderTopRadius={first ? 12 : 0}
                borderBottomRadius={last ? 12 : 0}
              >
                {translation}
              </GridItem>
              {tariffs.map((tariff) => {
                const isSelected = selectedType === tariff;

                return (
                  <GridItem
                    boxShadow={
                      isSelected
                        ? `-2px 0px 0px 0px ${purple}, 2px 0px 0px 0px ${purple}`
                        : "none"
                    }
                    textAlign={"center"}
                    fontSize="sm"
                    color="gray.600"
                    border="1px solid gray"
                    borderTop={first ? "1px solid" : "none"}
                    borderBottom={last ? "none" : "1px solid"}
                    borderInline="1px solid"
                    borderColor="gray.200"
                    alignContent="center"
                    p={3}
                  >
                    {pricingData[tariff][key] || "-"}
                  </GridItem>
                );
              })}
            </>
          );
        })}

        <GridItem></GridItem>
        <SelectTariffItem
          selectedType={selectedType}
          setTariffType={setTariffType}
          tariffType="S"
        />
        <SelectTariffItem
          selectedType={selectedType}
          setTariffType={setTariffType}
          tariffType="M"
        />
        <SelectTariffItem
          selectedType={selectedType}
          setTariffType={setTariffType}
          tariffType="L"
        />
        <SelectTariffItem
          selectedType={selectedType}
          setTariffType={setTariffType}
          tariffType="E"
        />
      </SimpleGrid>
    </>
  );
};

interface IHeadingProps {
  isSelected: boolean;
  heading: string;
  background: string;
}

const HeadingItem = ({ heading, background, isSelected }: IHeadingProps) => {
  const [purple] = useToken("colors", ["purple.500"]);

  return (
    <GridItem
      boxShadow={
        isSelected
          ? `-2px -2px 0px 0px ${purple}, 2px -2px 0px 0px ${purple}`
          : "none"
      }
      textAlign="center"
      background={background}
      color="white"
      p={4}
      borderTopRadius={12}
      border="none"
    >
      {heading}
    </GridItem>
  );
};

interface IItemProps {
  tariffType: TariffTypes;
  setTariffType: (type: TariffTypes) => void;
  selectedType: TariffTypes;
}

const SelectTariffItem = ({
  tariffType,
  setTariffType,
  selectedType,
}: IItemProps) => {
  const isSelected = selectedType === tariffType;
  const [purple] = useToken("colors", ["purple.500"]);

  return (
    <GridItem
      p={4}
      borderBottomRadius={12}
      boxShadow={
        isSelected
          ? `-2px 2px 0px 0px ${purple}, 2px 2px 0px 0px ${purple}`
          : "none"
      }
      border="1px solid"
      borderTop="none"
      borderColor="gray.200"
      textAlign="center"
      onClick={() => {
        setTariffType(tariffType);
      }}
    >
      <Button
        variant={isSelected ? "solid" : "outline"}
        colorScheme="purple"
        size="sm"
      >
        {isSelected ? t("Wybrano") : t("Wybierz")}
      </Button>
    </GridItem>
  );
};

const pricingData: Record<TariffTypes, IPricing> = {
  S: {
    verifications: "Do 1 000 na rok",
    additionalVerifications: "1 000 na rok",
    historyAccess: "6 miesięcy od zakończenia umowy",
    verificationCertification: null,
    apiAccess: "Tak",
    fileCount: "Do 5",
    answerTime: null,
    consultations: null,
    ppdo: null,
    resources: null,
  },
  M: {
    verifications: "Do 5 000 na rok",
    additionalVerifications: "5 000 na rok",
    historyAccess: "1 rok od zakończenia umowy",
    verificationCertification: "Dostępne",
    apiAccess: "Tak",
    fileCount: "Do 10",
    answerTime: "Do 3 dni roboczych",
    consultations: "E-mail",
    ppdo: "Tak",
    resources: null,
  },
  L: {
    verifications: "Do 10 000 na rok",
    additionalVerifications: "10 000 na rok",
    historyAccess: "5 lat od zakończenia umowy",
    verificationCertification: "Dostępne",
    apiAccess: "Tak",
    fileCount: "Do 20",
    answerTime: "Do 1 dnia roboczego",
    consultations: "E-mail",
    ppdo: "Tak",
    resources: null,
  },
  E: {
    verifications: "Bez limitu",
    additionalVerifications: "Bez limitu",
    historyAccess: "Możliwość pobrania po zakończeniu umowy",
    verificationCertification: "Dostępne",
    apiAccess: "Tak",
    fileCount: "Bez limitu",
    answerTime: "Do 8 godzin roboczych",
    consultations: "E-mail, telefon",
    ppdo: "Tak",
    resources: "Tak",
  },
};
