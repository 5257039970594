import { useState } from "react";

import { IconButton } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";

import { RefreshIcon } from "components/icons/RefreshIcon";

export const RefreshButton = () => {
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();

  const refresh = async () => {
    setIsLoading(true);
    await queryClient.invalidateQueries({
      queryKey: ["notifications-process"],
    });
    setIsLoading(false);
  };

  return (
    <IconButton
      aria-label={t("Odśwież")}
      colorScheme="purple"
      variant="ghost"
      isLoading={isLoading}
      icon={<RefreshIcon />}
      onClick={refresh}
      position="absolute"
      top="4px"
      right="4px"
      size="sm"
    />
  );
};
