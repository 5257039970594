import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

import { Company } from "./useAddCompany";

export const useEditCompany = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["organizations", "company"],
    mutationFn: (body: Company) =>
      client.put(`organizations/${organizationId}/company`, { body }),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ["organizations"] });
    },
  });
};
