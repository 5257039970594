import { IconButton, Tooltip } from "@chakra-ui/react";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";
import { PowerIcon } from "components/icons/PowerIcon";

import { ToggleKeyConfirm } from "./ToggleKeyConfirm";

interface IProps {
  keyId: string;
  isActive: boolean;
  hasIframeUrl: boolean;
  organizationName: string;
}

export const ToggleKeyButton = ({
  isActive,
  hasIframeUrl,
  keyId,
  organizationName,
}: IProps) => {
  const id = `toggle-integration-key-${keyId}`;
  const onOpen = useOpenDialog(id);

  if (!isActive && !hasIframeUrl) {
    return null;
  }

  const label = isActive ? t("Dezaktywuj") : t("Aktywuj");

  return (
    <>
      <ToggleKeyConfirm
        id={id}
        keyId={keyId}
        isActive={isActive}
        organizationName={organizationName}
      />
      <Tooltip label={label} aria-label={label}>
        <IconButton
          variant="link"
          colorScheme="purple"
          size="sm"
          data-testid="toggle-integration-key"
          onClick={onOpen}
          aria-label={label}
          icon={<PowerIcon />}
        />
      </Tooltip>
    </>
  );
};
