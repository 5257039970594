import { useParams } from "react-router-dom";

import { IconButton, Tooltip } from "@chakra-ui/react";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";
import { HelpIcon } from "components/icons/HelpIcon";

import { useOrganizationByIdQuery } from "modules/organization/infrastructure/useOrganizationByIdQuery";

import { InterpretationRequestModal } from "../ResultInterpretationRequest/InterpretationRequestModal";

interface IProps {
  verificationId: string;
}

export const InterpretationRequestBtn = ({ verificationId }: IProps) => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const id = `verification-request-${verificationId}`;
  const onOpen = useOpenDialog(id);
  const { data, isLoading } = useOrganizationByIdQuery(organizationId!);

  if (isLoading) {
    return null;
  }

  if (data?.organization.tariffs.current?.type === "S") {
    return null;
  }

  return (
    <>
      <InterpretationRequestModal id={id} verificationId={verificationId} />
      <Tooltip label={t("Zadaj pytanie dotyczące wyniku weryfikacji")}>
        <IconButton
          variant="link"
          colorScheme="purple"
          size="sm"
          data-testid="open-interpretation-request-modal"
          onClick={async () => onOpen()}
          aria-label={t("Pomoc")}
          icon={<HelpIcon />}
        />
      </Tooltip>
    </>
  );
};
