import { Link, useLocation, useParams } from "react-router-dom";

import { Button } from "@chakra-ui/react";
import { t } from "i18next";

import { HistoryIcon } from "components/icons/HistoryIcon";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";

export const EventHistoryBtn = () => {
  const { userInfo } = ContextMachineContext.useSelector(
    (state) => state.context
  );
  const { organizationId } = useParams<{ organizationId: string }>();
  const { pathname } = useLocation();

  if (!organizationId) {
    return null;
  }

  if (userInfo?.currentlySelectedContext?.type === "context_user") {
    return null;
  }

  return (
    <Link
      to={organizationId + "/historia-aktywnosci"}
      style={{ width: "100%" }}
    >
      <Button
        w="100%"
        variant={{ base: "link", lg: "ghost" }}
        leftIcon={<HistoryIcon height="24px" width="24px" />}
        isActive={pathname === `/${organizationId}/historia-aktywnosci`}
      >
        {t("Historia aktywności")}
      </Button>
    </Link>
  );
};
