import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  VStack,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { t } from "i18next";

import { ITariff } from "modules/tariffs/application/types/ITariff";
import { ITariffsExpirations } from "modules/tariffs/application/types/ITariffsExpirations";

interface IProps {
  expirations: ITariffsExpirations[];
  nextTariff: ITariff | null;
}

export const ExpirationAlert = ({ expirations, nextTariff }: IProps) => {
  if (!expirations || expirations.length === 0) {
    return null;
  }

  if (nextTariff?.isActive) {
    return (
      <VStack mt={-6}>
        <NextTariffPreparedAlert nextTariff={nextTariff} />
      </VStack>
    );
  }

  return (
    <VStack mt={-6}>
      {expirations.find(({ type }) => type === "days_to_expire") && (
        <DayExpirationAlert />
      )}
      {expirations.find(({ type }) => type === "usage_limit_90_percent") && (
        <UsageLimitExpirationAlert />
      )}
    </VStack>
  );
};

const DayExpirationAlert = () => {
  return (
    <Alert status="warning" variant="subtle" rounded="md">
      <AlertIcon />
      <Box>
        <AlertTitle>{t("Czas trwania umowy dobiega końca")}</AlertTitle>
        <AlertDescription>
          {t("Więcej szczegółów znajdziesz w wiadomości e-mail.")}
        </AlertDescription>
      </Box>
    </Alert>
  );
};

const UsageLimitExpirationAlert = () => {
  return (
    <Alert status="warning" variant="subtle" rounded="md">
      <AlertIcon />
      <Box>
        <AlertTitle>{t("Kończy się liczba weryfikacji")}</AlertTitle>
        <AlertDescription>
          {t("Więcej szczegółów znajdziesz w wiadomości e-mail.")}
        </AlertDescription>
      </Box>
    </Alert>
  );
};

const NextTariffPreparedAlert = ({ nextTariff }: { nextTariff: ITariff }) => {
  return (
    <Alert status="info" variant="subtle" rounded="md">
      <AlertIcon />
      <Box>
        <AlertTitle>{t("Przedłużono umowę")}</AlertTitle>
        <AlertDescription>
          {t("Od dnia {{date}} rozpocznie się nowy pakiet {{tariffType}}", {
            tariffType: nextTariff.type,
            date: dayjs(nextTariff.validFrom).format("DD.MM.YYYY"),
          })}
        </AlertDescription>
      </Box>
    </Alert>
  );
};
