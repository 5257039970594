import { ComponentProps } from "react";
import { Control, useController } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { VStack } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { t } from "i18next";

import { FormControl } from "components/Form/FormControl";

interface IProps {
  control: Control;
  name: string;
  label: string;
  modules?: ComponentProps<typeof ReactQuill>["modules"];
  formats?: ComponentProps<typeof ReactQuill>["formats"];
}

const defaultModules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
  ],
};

const defaultFormats = [
  "bold",
  "italic",
  "underline",
  "list",
  "bullet",
  "link",
];

export const TextEditor = ({
  control,
  name,
  modules = defaultModules,
  formats = defaultFormats,
  label,
}: IProps) => {
  const {
    field: { value, onChange },
    formState: { errors, isDirty },
  } = useController({
    name: name,
    control,
    defaultValue: "",
    rules: {
      required: t("Pole jest wymagane."),
      validate: (value) => {
        if (value.replace(/<[^>]*>/g, "").length === 0 && isDirty) {
          return t("Pole jest wymagane.");
        }
      },
    },
  });

  return (
    <VStack align="stretch">
      <FormControl
        formLabel={label}
        isRequired
        formErrorMessage={errors[name]?.message as string}
      >
        <StyledEditor
          theme="snow"
          formats={formats}
          modules={modules}
          onChange={(value) => onChange(value)}
          value={value}
        />
      </FormControl>
    </VStack>
  );
};

const StyledEditor = styled(ReactQuill)`
  .ql-toolbar {
    border-color: inherit;
    border-top-right-radius: 0.375rem;
    border-top-left-radius: 0.375rem;
  }
  .ql-container {
    border-color: inherit;
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .ql-editor {
    min-height: 200px;
  }
`;
