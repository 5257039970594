import { Button } from "@chakra-ui/react";
import { t } from "i18next";

import { PlusIcon } from "components/icons/PlusIcon";

import { ITariff } from "modules/tariffs/application/types/ITariff";

import {
  PrepareTariffModal,
  useOpenPrepareTariffModal,
} from "./PrepareTariffModal";

interface IProps {
  tariff: ITariff;
}

export const PrepareTariffBtn = ({ tariff }: IProps) => {
  const onOpen = useOpenPrepareTariffModal();

  return (
    <>
      <PrepareTariffModal />
      <Button
        size="sm"
        leftIcon={<PlusIcon />}
        colorScheme="purple"
        onClick={() =>
          onOpen({
            personalDataClause: tariff.personalDataClause,
            type: tariff.type,
            validFrom: tariff.validFrom,
            validTo: tariff.validTo,
            withApiAccess: tariff.apiAccess,
          })
        }
      >
        {t("Przygotuj pakiet")}
      </Button>
    </>
  );
};
