import { useParams } from "react-router-dom";

import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  HStack,
  Td,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { t } from "i18next";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";

import { ActiveBadge } from "components/ActiveBadge";
import { FormatDateTime } from "components/FormatDateTime";
import { Table } from "components/Table";
import { TableEmptyState } from "components/Table/TableEmptyState";
import { TableLoadingState } from "components/Table/TableLoadingState";

import { IApiKey } from "modules/apiKey/application/types/IApiKey";
import { useApiKeysQuery } from "modules/apiKey/infrastructure/useApiKeysQuery";
import { useOrganizationByIdQuery } from "modules/organization/infrastructure/useOrganizationByIdQuery";

import { GenerateApiKeyButton } from "../GenerateApiKeyButton";
import { ApiDocsBtn } from "./ApiDocsBtn";
import { HiddenKey } from "./HiddenKey";
import { ToggleApiKeyButton } from "./ToggleApiKeyButton";

const columnHelper = createColumnHelper<IApiKey>();

const columns = [
  columnHelper.accessor("key", {
    header: () => t("Klucz"),
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal">
        <HiddenKey apiKey={info.getValue()} />
      </Td>
    ),
  }),
  columnHelper.accessor("active", {
    header: () => t("Status"),
    cell: (info) => (
      <Td whiteSpace="normal" data-testid="key-status">
        <ActiveBadge isActive={info.getValue()} />
      </Td>
    ),
  }),
  columnHelper.accessor("createdAt", {
    header: () => t("Data utworzenia"),
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal">
        <FormatDateTime date={info.getValue()} />
      </Td>
    ),
  }),
  columnHelper.accessor("id", {
    header: () => "",
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal" isNumeric>
        <ToggleApiKeyButton
          keyId={info.getValue()}
          isActive={info.row.original.active}
          apiKey={info.row.original.key}
        />
      </Td>
    ),
  }),
];

interface IProps {
  keys: IApiKey[];
  isLoading: boolean;
  hasApiAccess: boolean;
}

const ApiKeysTableConnected = ({ keys, isLoading, hasApiAccess }: IProps) => {
  if (isLoading) {
    return <TableLoadingState />;
  }

  if (!hasApiAccess && keys.length === 0) {
    return <TableEmptyState title={t("Niedostępne dla aktywnego pakietu")} />;
  }

  if (keys.length === 0) {
    return <TableEmptyState title={t("Lista kluczy jest pusta")} />;
  }

  const sortedRecords = reverse(
    sortBy(keys, function (data) {
      return new Date(data.createdAt);
    })
  );

  return <Table<IApiKey> data={sortedRecords} columns={columns} />;
};

export const ApiKeysTable = () => {
  const { organizationId } = useParams<{ organizationId: string }>();

  const { data, isLoading } = useApiKeysQuery(organizationId!);
  const { data: isOrgData, isLoading: isOrgLoading } = useOrganizationByIdQuery(
    organizationId!
  );

  return (
    <Card id="api-keys" variant="sawpe">
      <CardHeader>
        <VStack align="stretch">
          <HStack spacing="6" wrap="wrap">
            <Heading fontSize="lg"> {t("Klucze API")}</Heading>
            <Wrap spacing={3}>
              <WrapItem>
                <GenerateApiKeyButton
                  isActive={isOrgData?.organization?.status === "ACTIVE"}
                  hasApiAccess={
                    !!isOrgData?.organization?.tariffs.current?.apiAccess
                  }
                  hasActiveTariff={
                    !!isOrgData?.organization?.tariffs.current?.isActive
                  }
                />
              </WrapItem>
              <WrapItem>
                <ApiDocsBtn
                  isActive={isOrgData?.organization?.status === "ACTIVE"}
                  hasApiAccess={
                    !!isOrgData?.organization?.tariffs.current?.apiAccess
                  }
                  hasActiveTariff={
                    !!isOrgData?.organization?.tariffs.current?.isActive
                  }
                />
              </WrapItem>
            </Wrap>
          </HStack>
          <Text fontSize="sm" color="gray.600">
            {t(
              "Klucze API służą integracji z systemami zewnętrznymi. W tym celu wygeneruj klucz API i przekaż go integratorowi w celu wykonania połączenia pomiędzy systemem weryfikacjapodpisu.pl a systemem trzecim. W tym samym momencie możesz mieć aktywny tylko 1 klucz API."
            )}
          </Text>
        </VStack>
      </CardHeader>
      <CardBody>
        <ApiKeysTableConnected
          keys={data?.apiKeys}
          isLoading={isLoading || isOrgLoading}
          hasApiAccess={!!isOrgData?.organization?.tariffs.current?.apiAccess}
        />
      </CardBody>
    </Card>
  );
};
