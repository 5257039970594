import { useParams } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Skeleton,
} from "@chakra-ui/react";
import { t } from "i18next";

import {
  DescriptionList,
  DescriptionListItem,
  DescriptionListItemsInColumn,
} from "components/DescriptionList";
import { PencilIcon } from "components/icons/PencilIcon";

import { IOrganization } from "../application/types/IOrganization";
import { useOrganizationByIdQuery } from "../infrastructure/useOrganizationByIdQuery";
import {
  OrganizationModal,
  useOpenOrganizationalModal,
} from "./OrganizationModal";

interface IProps {
  isLoading: boolean;
  organization: IOrganization;
}

const Description = ({ isLoading, organization }: IProps) => {
  if (isLoading) {
    return (
      <>
        <Skeleton height="50px" />
        <Skeleton height="50px" />
        <Skeleton height="50px" />
        <Skeleton height="50px" />
        <Skeleton height="50px" />
      </>
    );
  }

  return (
    <DescriptionList>
      <DescriptionListItemsInColumn>
        <DescriptionListItem
          label={t("Nazwa")}
          value={`${organization.details.displayName}`}
        />
        <DescriptionListItem
          label={t("Kraj")}
          value={organization.details.address.countryCode}
        />
      </DescriptionListItemsInColumn>
      <DescriptionListItemsInColumn>
        <DescriptionListItem
          label={t("Kod pocztowy")}
          value={organization.details.address.postCode}
        />
        <DescriptionListItem
          label={t("Miejscowość")}
          value={organization.details.address.city}
        />
      </DescriptionListItemsInColumn>
      <DescriptionListItemsInColumn>
        <DescriptionListItem
          label={t("Ulica")}
          value={organization.details.address.street}
        />
        <DescriptionListItem
          label={t("Numer")}
          value={
            organization.details.address.flatNumber
              ? `${organization.details.address.houseNumber}/${organization.details.address.flatNumber}`
              : organization.details.address.houseNumber
          }
        />
      </DescriptionListItemsInColumn>
      <DescriptionListItemsInColumn>
        <DescriptionListItem
          label={t("E-mail")}
          value={organization.details.email}
        />
        <DescriptionListItem
          label={t("Telefon")}
          value={organization.details.phone}
        />
      </DescriptionListItemsInColumn>
    </DescriptionList>
  );
};

export const OrganizationDescription = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { data, isLoading } = useOrganizationByIdQuery(organizationId!);
  const onOpen = useOpenOrganizationalModal();

  return (
    <>
      <OrganizationModal isEdit />
      <Card id="organization-description" variant="sawpe">
        <CardHeader>
          <Heading fontSize="lg">{t("Dane podmiotu")}</Heading>
          <Button
            leftIcon={<PencilIcon />}
            variant="outline"
            colorScheme="purple"
            size="sm"
            data-testid="edit-organization-button"
            onClick={() => {
              const { address, email, name, phone } = data.organization.details;

              onOpen({
                address: address,
                email: email,
                name: name,
                phone: phone,
                surname: data.organization.details.surname,
                type: data.organization.details.surname ? "person" : "company",
              });
            }}
          >
            {t("Edytuj dane")}
          </Button>
        </CardHeader>
        <CardBody px={6} py={0}>
          <Description
            isLoading={isLoading}
            organization={data?.organization}
          />
        </CardBody>
      </Card>
    </>
  );
};
