import { IconButton, Tooltip } from "@chakra-ui/react";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";
import { DetailsIcon } from "components/icons/DetailsIcon";

import { InterpretationCount } from "modules/verification/application/getInterpretationCount";

import { ShowInterpretationCountModal } from "./ShowInterpretationCountModal";

export const ShowInterpretationCountBtn = (props: InterpretationCount) => {
  const id = `interpretation-count-modal-${props.organizationId}`;
  const onOpen = useOpenDialog(id);

  const label = t("Szczegóły");

  return (
    <>
      <ShowInterpretationCountModal id={id} payload={props} />
      <Tooltip label={label} aria-label={label}>
        <IconButton
          variant="link"
          colorScheme="purple"
          size="sm"
          data-testid="open-interpretation-count-modal"
          onClick={onOpen}
          aria-label={label}
          icon={<DetailsIcon />}
        />
      </Tooltip>
    </>
  );
};
