import { IAllTariffsExpirations } from "modules/tariffs/application/types/IAllTariffsExpirations";

export const getExpirationsStatistics = (
  tariffsExpirations: IAllTariffsExpirations[]
) => {
  const byDays = tariffsExpirations.filter(
    ({ expirationType }) => expirationType === "days_to_expire"
  );
  const byLimit = tariffsExpirations.filter(
    ({ expirationType }) => expirationType === "usage_limit_90_percent"
  );

  const latestByDays = byDays.reduce<Record<string, IAllTariffsExpirations>>(
    (acc, current) => {
      if (
        !acc[current.tariffId] ||
        acc[current.tariffId].daysToExpire > current.daysToExpire
      ) {
        acc[current.tariffId] = current;
      }
      return acc;
    },
    {}
  );

  const latestByDaysArray = Object.values(latestByDays);

  return {
    one_day: latestByDaysArray.filter(({ daysToExpire }) => daysToExpire <= 1),
    one_week: latestByDaysArray.filter(
      ({ daysToExpire }) => daysToExpire <= 7 && daysToExpire > 1
    ),
    one_month: latestByDaysArray.filter(
      ({ daysToExpire }) => daysToExpire <= 30 && daysToExpire > 7
    ),
    usage_limit: byLimit,
  };
};
