import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  Spacer,
  Td,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { t } from "i18next";

import { Table } from "components/Table";
import { TableEmptyState } from "components/Table/TableEmptyState";
import { TableLoadingState } from "components/Table/TableLoadingState";

import {
  getInterpretationCount,
  InterpretationCount,
} from "modules/verification/application/getInterpretationCount";
import { IInterpretation } from "modules/verification/application/types/IInterpretation";
import { useVerificationInterpretationsQuery } from "modules/verification/infrastructure/useVerificationInterpretationsQuery";

import { InterpretationsFilter } from "./InterpretationsFilter";
import { ShowInterpretationCountBtn } from "./ShowInterpretationCountBtn";

const columnHelper = createColumnHelper<InterpretationCount>();
const columns = [
  columnHelper.accessor("organizationName", {
    id: "organizationName",
    header: () => t("Podmiot"),
    cell: (info) => <Td whiteSpace="normal">{info.getValue()}</Td>,
  }),
  columnHelper.accessor("totalInterpretations", {
    id: "totalInterpretations",
    header: () => t("Wykonane zapytania"),
    enableGlobalFilter: false,
    enableSorting: true,
    cell: (info) => (
      <Td whiteSpace="normal" data-testid="total-requests">
        {info.getValue()}
      </Td>
    ),
  }),
  columnHelper.accessor("monthlyData", {
    id: "current-month",
    header: () => t("Bieżący miesiąc"),
    enableGlobalFilter: false,
    cell: (info) => {
      const thisMont = info
        .getValue()
        .find(
          (item) =>
            item.month === new Date().getMonth() + 1 &&
            item.year === new Date().getFullYear()
        );
      return <Td whiteSpace="normal">{thisMont?.interpretationsCount || 0}</Td>;
    },
  }),
  columnHelper.accessor("monthlyData", {
    id: "previous-month",
    header: () => t("Poprzedni miesiąc"),
    enableGlobalFilter: false,
    cell: (info) => {
      const prevMont = info
        .getValue()
        .find(
          (item) =>
            item.month === new Date().getMonth() &&
            item.year === new Date().getFullYear()
        );
      return <Td whiteSpace="normal">{prevMont?.interpretationsCount || 0}</Td>;
    },
  }),
  columnHelper.accessor("organizationId", {
    id: "interpretation",
    header: () => "",
    enableGlobalFilter: false,
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal" isNumeric>
        <ShowInterpretationCountBtn {...info.row.original} />
      </Td>
    ),
  }),
];

interface IProps {
  interpretations: IInterpretation[];
  isLoading: boolean;
}

const InterpretationRequestCountTableConnected = ({
  interpretations,
  isLoading,
}: IProps) => {
  if (isLoading || !interpretations) {
    return <TableLoadingState />;
  }

  if (interpretations?.length === 0) {
    return (
      <TableEmptyState
        title={t("Nie wykonano jeszcze żadnych zapytań o interpretację")}
      />
    );
  }

  const processedInterpretations = getInterpretationCount(interpretations);

  return (
    <Table<InterpretationCount>
      data={processedInterpretations}
      columns={columns}
      globalSearchParam="interpretationsSearch"
    />
  );
};

export const InterpretationRequestCountTable = () => {
  const { data, isLoading } = useVerificationInterpretationsQuery();
  return (
    <Card variant="sawpe">
      <CardHeader>
        <Heading fontSize="lg">
          {t("Wykonane zapytania o interpretację")}
        </Heading>
        <Spacer />
        <InterpretationsFilter />
      </CardHeader>
      <CardBody>
        <InterpretationRequestCountTableConnected
          interpretations={data?.interpretations}
          isLoading={isLoading}
        />
      </CardBody>
    </Card>
  );
};
