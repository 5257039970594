import { Link, useSearchParams } from "react-router-dom";

import { Button, Card, CardBody, Flex, Text, VStack } from "@chakra-ui/react";
import { mdiCheckCircleOutline } from "@mdi/js";
import { t } from "i18next";

import { Icon } from "components/icons/Icon";

export const LinkSendCard = () => {
  const [searchParams] = useSearchParams();

  const email = searchParams.get("email");

  return (
    <Card w={[330, 420]}>
      <CardBody p={8}>
        <VStack align="stretch" spacing="6">
          <Flex justifyContent="center">
            <Icon path={mdiCheckCircleOutline} size="33px" color="green.500" />
          </Flex>
          <Text>
            {t("Link aktywacyjny został wysłany pod e-mail:")}
            <Text fontWeight="500" color="gray.500">
              {email}
            </Text>
          </Text>
          <Text>
            {t(
              "Jeśli nie widzisz wiadomości, sprawdź folder spam lub skontaktuj się z nami."
            )}
          </Text>
          <Button as={Link} to="/login" variant="outline" colorScheme="purple">
            {t("Wróć na stronę główną")}
          </Button>
        </VStack>
      </CardBody>
    </Card>
  );
};
