import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

interface MutationVariables {
  isActive: boolean;
}

export const useAllowUserPasswordChange = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["allow-user-password-change"],
    mutationFn: (variables: MutationVariables) => {
      if (variables.isActive) {
        return client.put(
          `organizations/${organizationId}/disallowPasswordChange`
        );
      }
      return client.put(`organizations/${organizationId}/allowPasswordChange`);
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["organizations", organizationId, "details"],
      });
    },
  });
};
