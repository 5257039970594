import { ReactNode } from "react";

import {
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Heading,
  Tag,
  Text,
} from "@chakra-ui/react";

interface IProps {
  title: string;
  description?: ReactNode;
  actions?: ReactNode;
  children?: ReactNode;
  badges?: string[];
  onClick?: () => void;
}

export const StackedListItem = ({
  title,
  description,
  actions,
  children,
  badges,
  onClick,
}: IProps) => {
  return (
    <Card
      px={4}
      py={3}
      flexBasis="100%"
      gap={2}
      data-testid="stacked-list-item"
    >
      <CardHeader p={0}>
        <Heading
          size="md"
          isTruncated
          title={title}
          cursor={onClick ? "pointer" : undefined}
          onClick={onClick}
          _hover={{ textDecoration: onClick ? "underline" : undefined }}
        >
          {title}
        </Heading>
        {description && (
          <Text color="gray.500" fontWeight="500" fontSize="xs">
            {description}
          </Text>
        )}
      </CardHeader>
      {children && <CardBody p={0}>{children}</CardBody>}
      <CardFooter p={0} marginTop="auto" justifyContent="space-between">
        <Flex gap={2} alignItems="center">
          {badges && (
            <>
              {badges.map((badge) => (
                <Tag key={badge} size="sm" colorScheme="purple">
                  {badge}
                </Tag>
              ))}
            </>
          )}
        </Flex>
        <ButtonGroup size="sm" colorScheme="purple" variant="outline">
          {actions}
        </ButtonGroup>
      </CardFooter>
    </Card>
  );
};
