import { ReactNode } from "react";

import { Heading, VStack, Text, Box, ChakraProps } from "@chakra-ui/react";

import { EmptyStateIcon } from "components/icons/EmptyStateIcon";

interface Props extends Omit<ChakraProps, "size"> {
  title: ReactNode;
  description?: string | ReactNode;
  size?: "md" | "sm" | "xs";
  icon?: ReactNode;
}

export const EmptyState = ({
  title = "Wystąpił błąd",
  description,
  size = "md",
  icon = <EmptyStateIcon />,
  ...chakraProps
}: Props) => {
  const getSize = () => {
    switch (size) {
      case "md":
        return "200px";
      case "sm":
        return "150px";
      case "xs":
        return "110px";
    }
  };
  return (
    <VStack spacing={5} {...chakraProps}>
      <Box maxW={getSize()}>{icon}</Box>
      <VStack>
        {typeof title === "string" ? (
          <Heading size={size === "md" ? "lg" : "md"}>{title}</Heading>
        ) : (
          title
        )}
        {typeof description === "string" ? (
          <Text color={"gray.600"} fontSize={size}>
            {description}
          </Text>
        ) : (
          description
        )}
      </VStack>
    </VStack>
  );
};
