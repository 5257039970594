import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  GridItem,
  HStack,
  SimpleGrid,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";

import { AlertIcon } from "components/icons/AlertIcon";

import { getExpirationsStatistics } from "modules/dashboard/application/getExpirationsStatistics";
import {
  ExpiringTariffsModal,
  useOpenExpiringTariffsModal,
} from "modules/tariffs/ExpiringTariffsModal";
import { useAllTariffsExpirationsQuery } from "modules/tariffs/infrastructure/useAllTariffsExpirationsQuery";

export const ExpiringTariffsTile = () => {
  const { data, isLoading } = useAllTariffsExpirationsQuery();
  const onOpen = useOpenExpiringTariffsModal();

  if (isLoading) {
    return (
      <>
        <GridItem colSpan={2}>
          <Skeleton height="100%" />
        </GridItem>
      </>
    );
  }

  const expirationsStatistics = getExpirationsStatistics(
    data?.tariffsExpirations
  );

  return (
    <>
      <ExpiringTariffsModal />
      <GridItem colSpan={2}>
        <Card height="100%">
          <CardHeader
            fontSize="lg"
            fontWeight="semibold"
            roundedTop="md"
            color="white"
            bgColor="purple.500"
          >
            <HStack justifyContent="space-between">
              <Text>{t("Kończące się pakiety")}</Text>
              <AlertIcon />
            </HStack>
          </CardHeader>

          <CardBody>
            <SimpleGrid columns={{ base: 1, sm: 2 }} gap="4">
              {Object.entries(expirationsStatistics).map(
                ([type, expirations]) => {
                  const [icon, text] = getMeta(type);
                  const clickable = expirations.length > 0;

                  return (
                    <Flex
                      key={type}
                      flexDir="column"
                      alignItems="center"
                      py={2}
                      cursor={clickable ? "pointer" : "default"}
                      _hover={clickable ? { bgColor: "gray.100" } : {}}
                      rounded="md"
                      onClick={() => {
                        if (clickable) onOpen({ expirations, type });
                      }}
                    >
                      {icon}
                      <Text fontSize="lg" fontWeight="semibold">
                        {expirations.length}
                      </Text>
                      <Text fontSize="sm" color="gray.600">
                        {text}
                      </Text>
                    </Flex>
                  );
                }
              )}
            </SimpleGrid>
          </CardBody>
        </Card>
      </GridItem>
    </>
  );
};

const getMeta = (type: string) => {
  switch (type) {
    case "usage_limit":
      return [
        <AlertIcon size="24px" mb="2" color="purple.500" />,
        t("Kończący się limit"),
      ];
    case "one_day":
      return [<AlertIcon size="24px" mb="2" color="red.500" />, t("Dziś")];
    case "one_week":
      return [
        <AlertIcon size="24px" mb="2" color="orange.500" />,
        t("Najbliższe 7 dni"),
      ];
    case "one_month":
      return [
        <AlertIcon size="24px" mb="2" color="yellow.500" />,
        t("Najbliższy miesiąc"),
      ];
    default:
      return [
        <AlertIcon size="24px" mb="2" color="purple.500" />,
        t("Nieznane"),
      ];
  }
};
