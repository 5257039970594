import { IconButton, Tooltip } from "@chakra-ui/react";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";
import { KeyIcon } from "components/icons/KeyIcon";

import { EditUserPasswordModal } from "./EditUserPasswordModal";

interface IProps {
  contextId: string;
}

export const EditUserPasswordButton = ({ contextId }: IProps) => {
  const id = `edit-user-password-${contextId}`;

  const onOpen = useOpenDialog(id);

  const label = t("Zmień hasło");

  return (
    <>
      <EditUserPasswordModal id={id} contextId={contextId} />
      <Tooltip label={label} aria-label={label}>
        <IconButton
          variant="link"
          colorScheme="purple"
          size="sm"
          data-testid="edit-user-password"
          onClick={() => onOpen()}
          aria-label={label}
          icon={<KeyIcon />}
        />
      </Tooltip>
    </>
  );
};
