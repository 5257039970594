import { useForm } from "react-hook-form";

import { Input, VStack } from "@chakra-ui/react";
import { t } from "i18next";

import { toastWithError } from "components/ErrorHandling/toastWithError";
import { FormControl } from "components/Form/FormControl";

export interface IKeyFormData {
  name: string;
  iframeUrl: string;
}

interface IProps {
  onClose: () => void;
  onSubmit: (payload: IKeyFormData) => Promise<void>;
  defaultData?: IKeyFormData;
}

export const IntegrationKeyForm = ({
  onSubmit,
  onClose,
  defaultData,
}: IProps) => {
  const { handleSubmit, register } = useForm<IKeyFormData>({
    defaultValues: defaultData,
  });

  return (
    <form
      id="default-key-form"
      onSubmit={handleSubmit(async (data) => {
        try {
          await onSubmit(data);
          onClose();
        } catch (error) {
          toastWithError({
            error,
          });
        }
      })}
    >
      <VStack align="stretch" spacing={6}>
        <FormControl formLabel={t("Adres instancji")} isRequired>
          <Input {...register("name", { required: true })} />
        </FormControl>
        <FormControl formLabel={t("Iframe URL")} isRequired>
          <Input {...register("iframeUrl", { required: true })} />
        </FormControl>
      </VStack>
    </form>
  );
};
