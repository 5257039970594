import { QueryClient } from "@tanstack/react-query";
import { http, HttpResponse } from "msw";

import { client, withHost } from "utils/http";
import { useQuery } from "utils/useQuery";

import { IAllTariffsExpirations } from "../application/types/IAllTariffsExpirations";

const query = () => {
  return {
    queryKey: ["tariffs-expirations", "all"],
    queryFn: () =>
      client.get<{ tariffsExpirations: IAllTariffsExpirations[] }>(
        `tariffsExpirations`
      ),
  };
};

export const getAllTariffsExpirationsQuery = (queryClient: QueryClient) => {
  return queryClient.ensureQueryData(query());
};

export const useAllTariffsExpirationsQuery = () => {
  return useQuery(query());
};

export const getAllTariffsExpirationsMock = (
  tariffsExpirations: IAllTariffsExpirations[] = []
) => {
  return http.get(withHost("tariffsExpirations"), () => {
    return HttpResponse.json<{ tariffsExpirations: IAllTariffsExpirations[] }>({
      tariffsExpirations,
    });
  });
};
