import {
  HStack,
  IconButton,
  Text,
  useClipboard,
  useToast,
} from "@chakra-ui/react";
import { t } from "i18next";

import { CopyIcon } from "components/icons/CopyIcon";

interface IProps {
  apiKey: string;
}

export const HiddenKey = ({ apiKey }: IProps) => {
  const { onCopy } = useClipboard(apiKey);
  const toast = useToast();

  const firstFour = apiKey.substring(0, 4);
  const lastFour = apiKey.substring(apiKey.length - 4);
  const result = firstFour + "..." + lastFour;

  return (
    <HStack spacing={3}>
      <Text w="90px" data-testid="key">
        {result}
      </Text>
      <IconButton
        colorScheme="purple"
        aria-label={t("Kopiuj klucz")}
        icon={<CopyIcon h="14px" w="14px" />}
        size="sm"
        data-testid="copy-key"
        variant="outline"
        onClick={() => {
          toast({
            description: t("Skopiowano do schowka"),
            variant: "subtle",
          });
          onCopy();
        }}
      />
    </HStack>
  );
};
