import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

interface MutationVariables {
  title: string;
  content: string;
  publishedAt: Date | null;
}

export const useAddNewChange = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["add-new-change"],
    mutationFn: (variables: MutationVariables) => {
      return client.post(`changelog`, {
        body: { ...variables },
      });
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["changelog"],
      });
    },
  });
};
