import { useParams } from "react-router-dom";

import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  HStack,
  Skeleton,
  Stack,
  Text,
  VStack,
  Wrap,
} from "@chakra-ui/react";
import { t } from "i18next";

import { ActiveBadge } from "components/ActiveBadge";
import { FormatDate } from "components/FormatDate";
import { TableEmptyState } from "components/Table/TableEmptyState";
import { HourglassIcon } from "components/icons/HourglassIcon";

import { ITariff } from "modules/tariffs/application/types/ITariff";
import { useAllTariffsQuery } from "modules/tariffs/infrastructure/useAllTariffsQuery";

import { EditTariffButton } from "../common/EditTariffButton/EditTariffButton";
import { ToggleTariffButton } from "../common/ToggleTariffButton";
import { TrueFalseIcons } from "../common/TrueFalseIcons";

interface IProps {
  isLoading: boolean;
  tariff: ITariff | null;
}

const NextTariffConnected = ({ isLoading, tariff }: IProps) => {
  if (isLoading) {
    return (
      <Stack>
        <Skeleton height="78px" />
      </Stack>
    );
  }

  if (tariff === null) {
    return <TableEmptyState title={t("Brak pakietu")} />;
  }

  const isUnlimited = tariff.usageLimit.type === "UNLIMITED";

  return (
    <HStack spacing={4}>
      <HourglassIcon h="78px" w="78px" p={3} color="purple.500" />
      <VStack spacing="0" align="stretch" fontSize="sm">
        <HStack>
          <Text fontWeight="600" fontSize="lg">
            {t("Pakiet {{size}}", { size: tariff.type })}
          </Text>
          <ActiveBadge isActive={tariff.isActive} />
        </HStack>
        <Text textColor="gray.600">
          {t("Limit weryfikacji: {{limit}} ", {
            limit: isUnlimited
              ? "bez ograniczeń"
              : tariff.usageLimit.totalLimit,
          })}
        </Text>
        <HStack data-testid="api-access">
          <Text textColor="gray.600">{t("Dostęp do API")}</Text>
          <TrueFalseIcons isSelected={tariff.apiAccess} />
        </HStack>
        <HStack data-testid="ppdo">
          <Text textColor="gray.600">{t("PPDO")}</Text>
          <TrueFalseIcons isSelected={tariff.personalDataClause} />
        </HStack>
        <Text textColor="gray.600">
          {t("Ważny od")} <FormatDate date={tariff.validFrom} /> {t("do")}{" "}
          <FormatDate date={tariff.validTo} />
        </Text>
      </VStack>
    </HStack>
  );
};

export const NextTariff = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { data, isLoading } = useAllTariffsQuery(organizationId!);

  if (data?.tariffs.draft) {
    return null;
  }

  return (
    <Card id="next-tariff" variant="sawpe">
      <CardHeader>
        <Heading fontSize="lg">{t("Oczekujący pakiet")}</Heading>
        <Wrap spacing={3}>
          <EditTariffButton tariff={data?.tariffs.next} />
          <ToggleTariffButton tariff={data?.tariffs.next} />
        </Wrap>
      </CardHeader>
      <CardBody>
        <NextTariffConnected
          isLoading={isLoading}
          tariff={data?.tariffs.next}
        />
      </CardBody>
    </Card>
  );
};
