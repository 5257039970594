import { FilterFn, Row } from "@tanstack/react-table";

declare module "@tanstack/react-table" {
  interface FilterFns {
    global: FilterFn<unknown>;
  }
}

// https://tanstack.com/table/latest/docs/api/features/global-filtering#filter-meta
export const globalFilterFn = (
  row: Row<unknown>,
  columnId: string,
  value: string
) => {
  const rowValue = row.getValue(columnId) as string;

  return rowValue.includes(value);
};
