import { useMutation } from "@tanstack/react-query";

import { client } from "utils/http";

import { Person } from "modules/organization/infrastructure/useAddPerson";

import { BaseRegistration } from "../application/types/BaseRegistration";

export type PersonRegistration = Person & BaseRegistration;

export const useRegisterPerson = () => {
  return useMutation({
    mutationKey: ["auth", "register", "person"],
    mutationFn: (body: PersonRegistration) =>
      client.post("auth/register/person", { body }),
  });
};
