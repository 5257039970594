import { Text, VStack } from "@chakra-ui/react";
import { t } from "i18next";

import { AlertDialog } from "components/Dialog";
import { toastWithError } from "components/ErrorHandling/toastWithError";

import { useToggleTariffActivity } from "modules/tariffs/infrastructure/useToggleTariffActivity";

interface IConfirmProps {
  id: string;
  tariffId: string;
  isActive: boolean;
}

export const ToggleTariffConfirm = ({
  id,
  tariffId,
  isActive,
}: IConfirmProps) => {
  const { mutateAsync: toggle } = useToggleTariffActivity();

  return (
    <AlertDialog
      id={id}
      title={isActive ? t("Dezaktywacja pakietu") : t("Aktywacja pakietu")}
      body={
        <VStack align="stretch" spacing={4} fontSize="md">
          <Text>
            {t("Czy na pewno chcesz {{value}} pakiet?", {
              value: isActive ? t("dezaktywować") : t("aktywować"),
            })}
          </Text>
        </VStack>
      }
      colorScheme="purple"
      size="xl"
      confirmLabel={isActive ? t("Dezaktywuj") : t("Aktywuj")}
      onConfirm={async () => {
        try {
          await toggle({
            isActive,
            tariffId,
          });
        } catch (error) {
          toastWithError({
            error,
          });
        }
      }}
    />
  );
};
