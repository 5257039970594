import {
  Button,
  InputGroup,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { useIsMutating } from "@tanstack/react-query";
import { t } from "i18next";

import { Modal, useOpenDialog } from "components/Dialog";

import { NotificationProcess } from "modules/notifications/application/NotificationProcess";

import { CorrectionForm } from "./CorrectionForm";

const id = "correction-modal";

export const useOpenCorrectionModal = () => {
  return useOpenDialog<NotificationProcess>(id);
};

export const CorrectionModal = () => {
  const isMutating = useIsMutating({
    predicate: (mutation) =>
      !!(
        mutation.options.mutationKey?.includes("create-notification-process") ||
        mutation.options.mutationKey?.includes("complete-notification-process")
      ),
  });

  return (
    <Modal<NotificationProcess> id={id}>
      {({ payload, onClose }) => {
        if (!payload) {
          return null;
        }

        return (
          <>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{t("Korekta powiadomienia")}</ModalHeader>
              <ModalCloseButton />
              <ModalBody as={VStack} align="stretch" spacing={6}>
                <CorrectionForm
                  onClose={onClose}
                  defaultData={{
                    title: payload.subject,
                    content: payload.body,
                    tariffs: payload.selectedTariffs,
                    recipientsFrom: payload.id,
                  }}
                />
              </ModalBody>
              <ModalFooter>
                <InputGroup gap="2" justifyContent="end">
                  <Button onClick={onClose} isLoading={!!isMutating}>
                    {t("Anuluj")}
                  </Button>
                  <Button
                    colorScheme="purple"
                    type="submit"
                    form="correction-form"
                    isLoading={!!isMutating}
                  >
                    {t("Zaplanuj wysyłkę")}
                  </Button>
                </InputGroup>
              </ModalFooter>
            </ModalContent>
          </>
        );
      }}
    </Modal>
  );
};
