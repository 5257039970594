import { useState } from "react";
import { FieldErrors, useForm, useWatch } from "react-hook-form";
import { Link, useSearchParams } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  Checkbox,
  GridItem,
  Heading,
  HStack,
  Input,
  Select,
  SimpleGrid,
  VStack,
  FormControl as CFormControl,
  FormLabel,
  Link as CLink,
  Text,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { t } from "i18next";
import { HTTPError } from "ky";

import { maxLength } from "utils/form/maxLength";
import { minLength } from "utils/form/minLength";

import { toastWithError } from "components/ErrorHandling/toastWithError";
import { CountriesField } from "components/Form/CountriesField";
import { FormControl } from "components/Form/FormControl";
import { ArrowLeftIcon } from "components/icons/ArrowLeftIcon";
import { OpenNewIcon } from "components/icons/OpenNewIcon";

import {
  CompanyRegistration,
  useRegisterCompany,
} from "modules/auth/infrastructure/useRegisterCompany";
import {
  PersonRegistration,
  useRegisterPerson,
} from "modules/auth/infrastructure/useRegisterPerson";
import { TariffTypes } from "modules/tariffs/application/types/ITariff";

import { Footnotes } from "./Footnotes";
import { Pricing } from "./Pricing";
import { SectionHeading } from "./SectionHeading";

type FormValues = PersonRegistration | CompanyRegistration;
type AdditionalFields = {
  policyAcceptance: boolean;
  repeatEmail: string;
  contextManager: {
    repeatEmail: string;
  };
};

export const RegisterForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    getValues,
    setError,
    setValue,
  } = useForm<FormValues & AdditionalFields>({
    defaultValues: {
      tariff: {
        type: "M",
      },
      conclusionOfTheAgreement: "content_acceptance",
    },
  });

  const [formType, setFormType] = useState<"company" | "person">("company");
  const isCompany = formType === "company";

  const type = useWatch({ control, name: "tariff.type" });
  const conclusionOfTheAgreement = useWatch({
    control,
    name: "conclusionOfTheAgreement",
  });
  const isPPDOAvailable = ["M", "L", "E"].includes(type);

  const { mutateAsync: registerCompany } = useRegisterCompany();
  const { mutateAsync: registerPerson } = useRegisterPerson();

  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Card maxW="1140">
      <CardBody p={8}>
        <form
          id="register-form"
          noValidate
          onSubmit={handleSubmit(async (data) => {
            try {
              if (isCompany) {
                await registerCompany(data as CompanyRegistration);
              } else {
                await registerPerson(data as PersonRegistration);
              }

              setSearchParams(
                { ...searchParams, name: data.name },
                {
                  replace: true,
                }
              );
            } catch (error) {
              if (error instanceof HTTPError) {
                const errorJson = await error.response.json<{
                  message: string;
                }>();

                if (errorJson.message === "context.error.nonUniqueLogin") {
                  setError("contextManager.email", {
                    type: "manual",
                    message: t("Podany e-mail jest już zajęty"),
                  });
                  return;
                }
              }

              toastWithError({
                error,
              });
            }
          })}
        >
          <VStack align="stretch" spacing={6}>
            <SimpleGrid columns={6} rowGap={6} columnGap={4}>
              <GridItem colSpan={6}>
                <Link to="/" style={{ display: "block", width: "max-content" }}>
                  <HStack color="purple.500">
                    <ArrowLeftIcon width="14px" />
                    <Text fontSize="sm">{t("Wróć do logowania")}</Text>
                  </HStack>
                </Link>
                <Heading fontSize="3xl" textAlign="center" mt={0}>
                  {t("Formularz rejestracji")}
                </Heading>
              </GridItem>
              <GridItem colSpan={6}>
                <Text fontSize="md">
                  {t("Korzystanie z odpłatnych usług serwisu ")}
                  <CLink
                    href="https://weryfikacjapodpisu.pl/"
                    color="purple.500"
                    target="_blank"
                  >
                    weryfikacjapodpisu.pl
                  </CLink>
                  {t(
                    " wymaga stworzenia profilu. Profil można utworzyć dla siebie, bądź dla dowolnej organizacji, którą reprezentujesz. Każda organizacja w ramach platformy zarządzana jest przez osobę nazywaną „Administratorem”. Administrator może tworzyć konta „Użytkowników” wyłącznie dla własnej organizacji."
                  )}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Heading fontSize="md" mb={4}>
                  {t("Dane podmiotu/organizacji")}
                </Heading>
                <FormControl
                  formLabel={t("Typ podmiotu")}
                  formHelperText={t(
                    "Jeśli chcesz korzystać z usług wyłącznie przez siebie wybierz Osoba fizyczna, w pozostałych przypadkach wybierz Osoba niefizyczna."
                  )}
                  isRequired
                >
                  <Select
                    value={formType}
                    onChange={(e) =>
                      setFormType(e.target.value as typeof formType)
                    }
                  >
                    <option value="person">{t("Osoba fizyczna")}</option>
                    <option value="company">{t("Osoba niefizyczna")}</option>
                  </Select>
                </FormControl>
              </GridItem>
              {isCompany && (
                <GridItem colSpan={4}>
                  <FormControl
                    formLabel={t("Rodzaj podmiotu")}
                    formHelperText={t(
                      "W celach statystycznych oraz w celach ułatwiających przygotowanie umowy"
                    )}
                    isRequired
                  >
                    <Select
                      defaultValue="public_institution"
                      {...register("type", {
                        required: t("Pole jest wymagane."),
                      })}
                    >
                      <option value="company">{t("Firma")}</option>
                      <option value="public_institution">
                        {t("Instytucja publiczna")}
                      </option>
                      <option value="other">{t("Inne")}</option>
                    </Select>
                  </FormControl>
                </GridItem>
              )}
              <GridItem colSpan={3}>
                <FormControl
                  formLabel={
                    isCompany
                      ? t("Pełna nazwa podmiotu/organizacji")
                      : t("Imię")
                  }
                  isRequired
                  formErrorMessage={errors.name?.message}
                >
                  <Input
                    autoFocus
                    {...register("name", {
                      required: t("Pole jest wymagane."),
                      minLength: minLength(),
                      maxLength: maxLength(),
                    })}
                  />
                </FormControl>
              </GridItem>
              {!isCompany && (
                <GridItem colSpan={3}>
                  <FormControl
                    formLabel={t("Nazwisko")}
                    isRequired
                    formErrorMessage={
                      (errors as FieldErrors<PersonRegistration>).surname
                        ?.message
                    }
                  >
                    <Input
                      {...register("surname", {
                        required: t("Pole jest wymagane."),
                        minLength: minLength(),
                        maxLength: maxLength(),
                      })}
                    />
                  </FormControl>
                </GridItem>
              )}
              <GridItem colSpan={4}>
                <Heading fontSize="lg" mb={4}>
                  {t("Adres")}
                </Heading>
                <CountriesField register={register("address.countryCode")} />
              </GridItem>
              <GridItem colSpan={4}>
                <FormControl
                  formLabel={t("Ulica")}
                  formErrorMessage={errors.address?.street?.message}
                >
                  <Input
                    {...register("address.street", {
                      minLength: minLength(),
                      maxLength: maxLength(),
                    })}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl
                  formLabel={t("Nr domu")}
                  isRequired
                  formErrorMessage={errors.address?.houseNumber?.message}
                >
                  <Input
                    {...register("address.houseNumber", {
                      required: t("Pole jest wymagane."),
                      maxLength: maxLength(15),
                    })}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl formLabel={t("Nr lokalu")}>
                  <Input {...register("address.flatNumber")} />
                </FormControl>
              </GridItem>
              <GridItem colSpan={2} colStart={1}>
                <FormControl
                  formLabel={t("Kod pocztowy")}
                  isRequired
                  formErrorMessage={errors.address?.postCode?.message}
                >
                  <Input
                    {...register("address.postCode", {
                      required: t("Pole jest wymagane."),
                      minLength: minLength(5),
                      maxLength: maxLength(6),
                      validate: (value) => {
                        if (new RegExp(/^\d{2}-\d{3}$/).test(value) === false) {
                          return t("Kod pocztowy jest nie prawidłowy.");
                        }
                      },
                    })}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={4}>
                <FormControl
                  formLabel={t("Miejscowość")}
                  isRequired
                  formErrorMessage={errors.address?.city?.message}
                >
                  <Input
                    {...register("address.city", {
                      required: t("Pole jest wymagane."),
                      minLength: minLength(),
                      maxLength: maxLength(),
                    })}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={4}>
                <Heading fontSize="lg" mb={4}>
                  {t("Dane kontaktowe")}
                </Heading>
                <FormControl
                  formLabel={t("E-mail")}
                  isRequired
                  formErrorMessage={errors.email?.message}
                >
                  <Input
                    type="email"
                    {...register("email", {
                      setValueAs: (value) => value.trim(),
                      required: t("Pole jest wymagane."),
                      minLength: minLength(),
                      maxLength: maxLength(),
                      pattern: {
                        value: /[^@]+@[^.]+\..+/,
                        message: t("Niepoprawny format"),
                      },
                    })}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={4}>
                <FormControl
                  formLabel={t("Powtórz e-mail")}
                  isRequired
                  formErrorMessage={errors.repeatEmail?.message}
                >
                  <Input
                    type="email"
                    {...register("repeatEmail", {
                      setValueAs: (value) => value.trim(),
                      required: t("Pole jest wymagane."),
                      validate: (value) =>
                        value === getValues("email") ||
                        t("Adresy e-mail nie są identyczne."),
                    })}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={4}>
                <FormControl
                  formLabel={t("Telefon")}
                  formErrorMessage={errors.phone?.message}
                >
                  <Input
                    {...register("phone", {
                      minLength: minLength(6),
                      maxLength: maxLength(15),
                    })}
                  />
                </FormControl>
              </GridItem>
            </SimpleGrid>
            <SectionHeading
              title={t("Dane administratora")}
              description={t(
                "Podaj dane pierwszego administratora organizacji, dla której chcesz utworzyć profil. Po wypełnieniu formularza na wskazany tutaj adres email wyślemy potwierdzenie zgłoszenia, a po utworzeniu profil to właśnie administrator na podany tutaj adres e-mail otrzyma automatyczną wiadomość z linkiem pozwalającym samodzielnie utworzyć hasło logowania. Upewnij się, że wprowadzasz właściwy i prawidłowy adres e-mail."
              )}
              divided
            />
            <SimpleGrid columns={6} rowGap={6} columnGap={4}>
              <GridItem colSpan={3}>
                <FormControl
                  formLabel={t("Imię")}
                  isRequired
                  formErrorMessage={errors.contextManager?.name?.message}
                >
                  <Input
                    {...register("contextManager.name", {
                      required: t("Pole jest wymagane."),
                      minLength: minLength(),
                      maxLength: maxLength(),
                    })}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={3}>
                <FormControl
                  formLabel={t("Nazwisko")}
                  isRequired
                  formErrorMessage={errors.contextManager?.surname?.message}
                >
                  <Input
                    {...register("contextManager.surname", {
                      required: t("Pole jest wymagane."),
                      minLength: minLength(),
                      maxLength: maxLength(),
                    })}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={4}>
                <FormControl
                  formLabel={t("E-mail")}
                  formErrorMessage={errors.contextManager?.email?.message}
                  isRequired
                >
                  <Input
                    type="email"
                    {...register("contextManager.email", {
                      setValueAs: (value) => value.trim(),
                      required: t("Pole jest wymagane."),
                      minLength: minLength(),
                      maxLength: maxLength(),
                      pattern: {
                        value: /[^@]+@[^.]+\..+/,
                        message: t("Niepoprawny format"),
                      },
                    })}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={4}>
                <FormControl
                  formLabel={t("Powtórz e-mail")}
                  formErrorMessage={errors.contextManager?.repeatEmail?.message}
                  isRequired
                >
                  <Input
                    type="email"
                    {...register("contextManager.repeatEmail", {
                      setValueAs: (value) => value.trim(),
                      required: t("Pole jest wymagane."),
                      validate: (value) =>
                        value === getValues("contextManager.email") ||
                        t("Adresy e-mail nie są identyczne."),
                    })}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={4}>
                <FormControl
                  formLabel={t("Telefon")}
                  formErrorMessage={errors.contextManager?.phone?.message}
                >
                  <Input
                    {...register("contextManager.phone", {
                      minLength: minLength(6),
                      maxLength: maxLength(15),
                    })}
                  />
                </FormControl>
              </GridItem>
            </SimpleGrid>
            <SectionHeading
              title={t("Wybór pakietu")}
              description={t(
                "Nasze usługi świadczymy w ramach poniższych pakietów. Wskaż pakiet odpowiedni dla siebie. Traktuj to wskazanie jako swoją propozycję, a nie ostateczną decyzję. Przed aktywacją profilu niezależnie od formy zawarcia umowy na świadczenie usług, skontaktuje się telefoniczne z Tobą nasz pracownik i po konsultacji będzie mógł dokonać zmiany wybranego pakietu."
              )}
              divided
            />
            <SimpleGrid columns={6} rowGap={6} columnGap={4}>
              <GridItem colSpan={6}>
                <Pricing
                  selectedType={type}
                  setTariffType={(type: TariffTypes) =>
                    setValue("tariff.type", type)
                  }
                />
              </GridItem>
              <GridItem colSpan={6}>
                <SectionHeading
                  title={t("Dodatkowe opcje dla wybranego pakietu")}
                />
              </GridItem>
              <GridItem colSpan={6}>
                <FormControl
                  formLabel={t("Dostęp do API")}
                  as={HStack}
                  flexDir="row-reverse"
                  justifyContent="start"
                >
                  <Checkbox
                    colorScheme="purple"
                    {...register("tariff.withApiAccess")}
                    mb={2}
                  />
                </FormControl>
                <Text fontSize="sm" color="gray.600" pl={6}>
                  {t("Dostęp do API umożliwia dostęp do api.")}
                </Text>
              </GridItem>
              <GridItem colSpan={6}>
                <HStack w={"100%"} justifyContent="end">
                  <HStack
                    as={CLink}
                    href="https://weryfikacjapodpisu.pl/pakiety-uslug/"
                    color="purple.500"
                    target="_blank"
                  >
                    <OpenNewIcon />
                    <Text>{t("Szczegółowa specyfikacja pakietów")}</Text>
                  </HStack>
                </HStack>
              </GridItem>
            </SimpleGrid>
            <SectionHeading
              title={t("Zgody, żądania i akceptacje")}
              description={t(
                "Umowę o świadczenie usług możemy zawrzeć poprzez Twoją akceptację jej treści oraz potwierdzenie zapoznania się z pozostałymi dokumentami formalnymi albo poprzez zawarcie umowy w formie pisemnej (tradycyjnej bądź elektronicznej)."
              )}
              divided
            />
            <SimpleGrid columns={6} rowGap={6} columnGap={4}>
              <GridItem colSpan={6}>
                <FormControl
                  formLabel={t("Chcę zawrzeć umowę:")}
                  formErrorMessage={errors.conclusionOfTheAgreement?.message}
                  isRequired
                >
                  <Select
                    {...register("conclusionOfTheAgreement", {
                      required: t("Pole jest wymagane."),
                    })}
                  >
                    <option value="content_acceptance">
                      {t("Poprzez zapoznanie i akceptację treści umowy")}
                    </option>
                    <option value="conclude_agreement">
                      {t("W formie pisemnej (tradycyjnej lub elektronicznej)")}
                    </option>
                  </Select>
                </FormControl>
              </GridItem>
              {conclusionOfTheAgreement === "content_acceptance" && (
                <>
                  <GridItem colSpan={6}>
                    <iframe
                      src="src/assets/Umowa.pdf#toolbar=0"
                      width="100%"
                      height="500px"
                    />
                    <CFormControl
                      isRequired
                      isInvalid={!!errors.policyAcceptance}
                      as={HStack}
                      mt={4}
                    >
                      <Checkbox
                        colorScheme="purple"
                        {...register("policyAcceptance", {
                          required: true,
                        })}
                        mb={2}
                      />
                      <FormLabel>{t("Akceptuję warunki umowy")}</FormLabel>
                    </CFormControl>
                  </GridItem>
                  {isPPDOAvailable && (
                    <GridItem colSpan={6}>
                      <iframe
                        src="src/assets/PPDO.pdf#toolbar=0"
                        width="100%"
                        height="500px"
                      />
                      <CFormControl
                        isInvalid={!!errors.tariff?.personalDataClause}
                        as={HStack}
                        mt={4}
                      >
                        <Checkbox
                          colorScheme="purple"
                          {...register("tariff.personalDataClause")}
                          mb={2}
                        />
                        <FormLabel>{t("Akceptuję umowę PPDO")}</FormLabel>
                      </CFormControl>
                    </GridItem>
                  )}
                </>
              )}
              <GridItem colSpan={6}>
                <CFormControl
                  isRequired
                  isInvalid={!!errors.privacyPilicy}
                  as={HStack}
                >
                  <Checkbox
                    colorScheme="purple"
                    {...register("privacyPilicy", {
                      required: true,
                    })}
                    mb={2}
                  />
                  <FormLabel>
                    {t("Akceptuję ")}
                    <CLink
                      color="purple.500"
                      href="https://weryfikacjapodpisu.pl/polityka-swiadczenia-uslugi/#dokumenty-do-pobrania"
                      target="_blank"
                    >
                      {t("Politykę Weryfikacji")}
                    </CLink>
                  </FormLabel>
                </CFormControl>
              </GridItem>
              <GridItem colSpan={6}>
                <CFormControl
                  isRequired
                  isInvalid={!!errors.termsOfService}
                  as={HStack}
                >
                  <Checkbox
                    colorScheme="purple"
                    {...register("termsOfService", {
                      required: true,
                    })}
                    mb={2}
                  />
                  <FormLabel>
                    {t("Akceptuję ")}
                    <CLink
                      color="purple.500"
                      href="https://weryfikacjapodpisu.pl/polityka-swiadczenia-uslugi/#dokumenty-do-pobrania"
                      target="_blank"
                    >
                      {t("Regulamin")}
                    </CLink>
                  </FormLabel>
                </CFormControl>
              </GridItem>
              <GridItem colSpan={3}>
                <FormControl
                  formLabel={t("Data początkowa")}
                  isRequired
                  formErrorMessage={errors.tariff?.validFrom?.message}
                >
                  <Input
                    type="date"
                    {...register("tariff.validFrom", {
                      required: t("Pole jest wymagane."),
                    })}
                    min={dayjs().format("YYYY-MM-DD")}
                    onChange={(e) => {
                      setValue(
                        "tariff.validTo",
                        dayjs(e.target.value)
                          .add(1, "year")
                          .subtract(1, "day")
                          .format("YYYY-MM-DD")
                      );
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={3}>
                <FormControl formLabel={t("Data końcowa")} isDisabled>
                  <Input type="date" {...register("tariff.validTo")} />
                </FormControl>
              </GridItem>
              <GridItem colSpan={6} mt={-4}>
                <Text fontSize="sm" color="gray.600">
                  {t(
                    "Wskaż preferowany przez Ciebie termin rozpoczęcia działania usług. Umowy zawieramy na okres 1 roku."
                  )}
                </Text>
              </GridItem>
              {isPPDOAvailable &&
                conclusionOfTheAgreement === "conclude_agreement" && (
                  <>
                    <GridItem colSpan={6}>
                      <SectionHeading
                        title={t("Przetwarzanie danych osobowych")}
                        description={
                          <>
                            {t(
                              "Zdarza się, że weryfikowane dokumenty elektroniczne zawierają dane osobowe, a Ty reprezentujesz podmiot zobowiązany do przestrzegania przepisów RODO"
                            )}
                            <sup>1</sup>.{" "}
                            {t(
                              "Jeśli Twoja organizacja zobowiązana jest do powierzenia nam przetwarzania danych osobowych w formie odrębnej umowy zaznacz poniższą opcję."
                            )}
                          </>
                        }
                      />
                    </GridItem>
                    <GridItem colSpan={6}>
                      <FormControl
                        formLabel={t(
                          "Chcę zawrzeć umowę powierzenia przetwarzania danych osobowych"
                        )}
                        as={HStack}
                        flexDir="row-reverse"
                        justifyContent="start"
                      >
                        <Checkbox
                          colorScheme="purple"
                          {...register("tariff.personalDataClause")}
                          mb={2}
                        />
                      </FormControl>
                    </GridItem>
                  </>
                )}
            </SimpleGrid>
            <Button
              isLoading={isSubmitting}
              type="submit"
              colorScheme="purple"
              w="100%"
              maxW="700px"
              mt="4"
              alignSelf="center"
            >
              {t("Zarejestruj się")}
            </Button>
          </VStack>
        </form>
        <Footnotes />
      </CardBody>
    </Card>
  );
};
