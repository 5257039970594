import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Grid,
  Heading,
  HStack,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { t } from "i18next";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";

import { EmptyState } from "components/EmptyStates/EmptyState";
import { StackedList } from "components/StackedList";
import { StackedListItem } from "components/StackedList/StackedListItem";
import { CalendarIcon } from "components/icons/CalendarIcon";
import { NoDataIcon } from "components/icons/NoDataIcon";

import { Change } from "../application/types/Change";
import { useChangelogQuery } from "../infrastructure/useChangelogQuery";
import { DeleteChangeButon } from "./DeleteChangeButon";
import { EditChangeButon } from "./EditChangeButon";

interface IProps {
  isLoading: boolean;
  changes: Change[];
}

const ChangelogListConnected = ({ isLoading, changes }: IProps) => {
  if (isLoading) {
    return (
      <StackedList>
        <Skeleton h="140px" />
        <Skeleton h="140px" />
        <Skeleton h="140px" />
      </StackedList>
    );
  }

  if (changes.length === 0) {
    return (
      <Grid placeItems="center" h="100%" w="100%">
        <EmptyState
          icon={<NoDataIcon />}
          size="xs"
          title={<Text fontWeight="500">{t("Brak opublikowanych zmian")}</Text>}
        />
      </Grid>
    );
  }

  const sortedRecords = reverse(
    sortBy(changes, function (data) {
      return new Date(data.publishedAt!);
    })
  );

  return (
    <StackedList maxH="620px">
      {sortedRecords.map((change) => {
        return (
          <StackedListItem
            key={change.id}
            title={change.title}
            description={
              <HStack>
                <CalendarIcon size="16px" />
                <Text>
                  {t("Opublikowano: {{publishedAt}}", {
                    publishedAt: dayjs(change.publishedAt).format(
                      "DD.MM.YYYY HH:mm"
                    ),
                  })}
                </Text>
              </HStack>
            }
            actions={
              <>
                <EditChangeButon changeId={change.id} />
                <DeleteChangeButon changeId={change.id} />
              </>
            }
          >
            <Box
              dangerouslySetInnerHTML={{ __html: change.content }}
              sx={{
                ul: {
                  paddingLeft: "1.5rem",
                },
                ol: {
                  paddingLeft: "1.5rem",
                },
              }}
            />
          </StackedListItem>
        );
      })}
    </StackedList>
  );
};

export const ChangelogList = () => {
  const { data, isLoading } = useChangelogQuery();

  return (
    <Card variant="sawpe">
      <CardHeader>
        <Heading fontSize="lg">{t("Lista zmian")}</Heading>
      </CardHeader>
      <CardBody>
        <ChangelogListConnected
          isLoading={isLoading}
          changes={data?.changelog.filter(({ publishedAt }) => publishedAt)}
        />
      </CardBody>
    </Card>
  );
};
