type Callback<Payload> = (payload: Payload) => void;

export type Event<Payload = unknown> = { type: string; payload?: Payload };

export class EventEmitter<E extends Event> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private events: { [key: string]: Set<Callback<any>> } = {};

  subscribe(event: E["type"], callback: Callback<E["payload"]>) {
    if (!this.events[event]) {
      this.events[event] = new Set();
    }
    this.events[event].add(callback);
  }

  unsubscribe(event: E["type"], callback: Callback<E["payload"]>) {
    if (!this.events[event]) {
      return;
    }
    this.events[event].delete(callback);
  }

  publish(event: E["type"], payload: E["payload"]) {
    if (!this.events[event]) {
      return;
    }
    this.events[event].forEach((callback) => callback(payload));
  }
}
