import { Progress, Text, VStack } from "@chakra-ui/react";
import { t } from "i18next";

interface IProps {
  password: string;
}

export const PasswordStrength = ({ password }: IProps) => {
  if (!password) {
    return null;
  }

  const { power, label, color } = getPower(password);

  return (
    <VStack position="absolute" align="stretch" right="0" w="150px" mt={2}>
      <Progress value={power} size="xs" colorScheme={color} />
      <Text fontSize="sm" color={color}>
        {label}
      </Text>
    </VStack>
  );
};

const getPower = (password: string) => {
  const ruleSet = [/[A-Z]/, /[a-z]/, /\W|_/g, /\d/];

  const fulfilledRules = ruleSet.filter((rule) => rule.test(password));

  switch (true) {
    case new RegExp(`^.{12,}$`).test(password) && fulfilledRules.length > 2:
      return { power: 100, label: t("Silne hasło"), color: "green" };
    case new RegExp(`^.{8,}$`).test(password) && fulfilledRules.length > 0:
      return { power: 50, label: t("Średnie hasło"), color: "orange" };
    case new RegExp(`^.{5,}$`).test(password):
      return { power: 25, label: t("Słabe hasło"), color: "red" };
    default:
      return { power: 0, label: t("Bardzo słabe hasło"), color: "red" };
  }
};
