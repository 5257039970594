import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

interface MutationVariables {
  title: string;
  content: string;
  publishedAt: Date | null;
}

export const useEditChange = (changeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["edit-change"],
    mutationFn: (variables: MutationVariables) => {
      return client.put(`changelog/${changeId}`, {
        body: { ...variables },
      });
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["changelog"],
      });
    },
  });
};
