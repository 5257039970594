import { useMutation } from "@tanstack/react-query";

import { createFormData } from "utils/createFormData";
import { withHost } from "utils/http";

interface MutationVariables {
  name: string;
  surname: string;
  email: string;
  phone: string;
  message: string;
  files: File[];
}

export const useSendInterpretationRequest = (verificationId: string) => {
  return useMutation({
    mutationKey: ["send-interpretation-request"],
    mutationFn: ({ files, ...variables }: MutationVariables) => {
      const formData = createFormData({
        verificationId: verificationId,
        name: variables.name,
        surname: variables.surname,
        email: variables.email,
        phone: variables.phone,
        content: variables.message,
      });

      files.forEach((file, index) => formData.append(`files[${index}]`, file));

      // Oczywiście że ky nie działa ........ AAAAAAAAAAAaa
      return fetch(withHost("support/verificationInterpretation"), {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    },
  });
};
