import {
  Button,
  InputGroup,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { useIsMutating } from "@tanstack/react-query";
import { t } from "i18next";

import { Modal } from "components/Dialog";

import { useEditUserPassword } from "modules/organization/infrastructure/useEditUserPassword";

import { IUserFormData, UserPasswordModalForm } from "./UserPasswordModalForm";

interface IProps {
  id: string;
  contextId: string;
}

export const EditUserPasswordModal = ({ id, contextId }: IProps) => {
  const { mutateAsync: editUser } = useEditUserPassword(contextId);

  const isMutating = useIsMutating({
    mutationKey: ["edit-user-password"],
  });

  return (
    <Modal<IUserFormData> id={id}>
      {({ onClose, payload }) => {
        return (
          <>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{t("Zmień hasło")}</ModalHeader>
              <ModalBody as={VStack} align="stretch" spacing={6}>
                <UserPasswordModalForm
                  onClose={onClose}
                  onSubmit={async (data: IUserFormData) => {
                    await editUser(data);
                  }}
                  defaultData={payload}
                />
              </ModalBody>
              <ModalFooter>
                <InputGroup gap="2" justifyContent="end">
                  <Button onClick={onClose} isLoading={!!isMutating}>
                    {t("Anuluj")}
                  </Button>
                  <Button
                    colorScheme="purple"
                    type="submit"
                    form="user-form"
                    data-testid="edit-user-button"
                    isLoading={!!isMutating}
                  >
                    {t("Zapisz")}
                  </Button>
                </InputGroup>
              </ModalFooter>
            </ModalContent>
          </>
        );
      }}
    </Modal>
  );
};
