import { ComponentProps } from "react";

import { mdiMagnify } from "@mdi/js";

import { Icon } from "./Icon";

export const SearchIcon = (
  props: Omit<ComponentProps<typeof Icon>, "path">
) => {
  return <Icon {...props} path={mdiMagnify} />;
};
