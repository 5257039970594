import { Code, Text, VStack } from "@chakra-ui/react";
import { t } from "i18next";

import { AlertDialog } from "components/Dialog";
import { toastWithError } from "components/ErrorHandling/toastWithError";

import { useToggleApiKeyActivity } from "modules/apiKey/infrastructure/useToggleApiKeyActivity";

interface IConfirmProps {
  id: string;
  keyId: string;
  isActive: boolean;
  apiKey: string;
}

export const ToggleApiKeyConfirm = ({
  id,
  keyId,
  isActive,
  apiKey,
}: IConfirmProps) => {
  const { mutateAsync: toggle } = useToggleApiKeyActivity();

  return (
    <AlertDialog
      id={id}
      title={
        isActive ? t("Dezaktywacja klucza API") : t("Aktywacja klucza API")
      }
      body={
        <VStack align="stretch" spacing={4} fontSize="md">
          <Text>
            {t("Czy na pewno chcesz {{value}} klucza API?", {
              value: isActive ? t("dezaktywować") : t("aktywować"),
            })}
          </Text>
          <Code wordBreak="break-all">{apiKey}</Code>
        </VStack>
      }
      colorScheme="purple"
      size="xl"
      confirmLabel={isActive ? t("Dezaktywuj") : t("Aktywuj")}
      onConfirm={async () => {
        try {
          await toggle({
            isActive,
            keyId,
          });
        } catch (error) {
          toastWithError({
            error,
          });
        }
      }}
    />
  );
};
