import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { publicRouter, router } from "router";
import { theme } from "theme";

import { httpEmitter } from "utils/http/HttpEmitter";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      throwOnError: true,
      retryDelay: 1000,
      retry: 0,
      staleTime: 1000,
    },
  },
});

const ProvidersConnected = () => {
  const value = ContextMachineContext.useSelector((state) => state.value);
  const { userInfo } = ContextMachineContext.useSelector(
    (state) => state.context
  );
  const contextType = userInfo?.currentlySelectedContext?.type;
  const contextId = userInfo?.currentlySelectedContext?.id;

  if (value === "getToken") {
    return null;
  }

  const getRouter = () => {
    switch (value) {
      case "changeContext":
      case "loggedIn":
      case "sync":
        return createBrowserRouter(
          router(queryClient, contextType!, contextId!)
        );
      case "logIn":
      case "logOut":
      case "loggedOut":
        return createBrowserRouter(publicRouter());
      default:
        return createBrowserRouter(publicRouter());
    }
  };

  const appRouter = getRouter();

  return (
    <ChakraProvider resetCSS={true} theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <RouterProvider router={appRouter} />
      </QueryClientProvider>
    </ChakraProvider>
  );
};

const WithContext = () => {
  const { send } = ContextMachineContext.useActorRef();

  httpEmitter.subscribe("afterResponse", ({ response }) => {
    if (response.status === 403 || response.status === 401) {
      localStorage.removeItem("token");
      send({ type: "logout", onLogout: () => window.location.reload() });
    }
  });

  return <ProvidersConnected />;
};

export const Providers = () => {
  return (
    <ContextMachineContext.Provider>
      <WithContext />
    </ContextMachineContext.Provider>
  );
};
