import { QueryClient } from "@tanstack/react-query";
import { HttpResponse, http } from "msw";

import { client, withHost } from "utils/http";
import { useQuery } from "utils/useQuery";

import { IOrganization } from "../application/types/IOrganization";
import { organizationFixtures } from "./organizationFixtures";

const query = (id: string) => {
  return {
    queryKey: ["organizations", id, "details"],
    queryFn: () =>
      client.get<{ organization: IOrganization }>(`organizations/${id}`),
  };
};
export const getOrganizationByIdQuery =
  (queryClient: QueryClient) => (id: string) => {
    return queryClient.ensureQueryData(query(id));
  };

export const useOrganizationByIdQuery = (id: string) => {
  return useQuery(query(id));
};

export const getOrganizationByIdMock = (
  organization: IOrganization = organizationFixtures.withTariffAndApi
) => {
  return http.get(withHost("organizations/:organizationId"), () => {
    return HttpResponse.json<{ organization: IOrganization }>({
      organization: organization,
    });
  });
};
