import { Link, useLocation } from "react-router-dom";

import { Button, IconButton, useBreakpointValue } from "@chakra-ui/react";
import { t } from "i18next";

import { LogsIcon } from "components/icons/LogsIcon";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";

export const SystemEventsButton = () => {
  const type = useBreakpointValue({
    base: undefined,
    lg: IconButton,
  });
  const { pathname } = useLocation();

  const context = ContextMachineContext.useSelector((state) => state.context)
    .userInfo?.currentlySelectedContext?.type;

  const availableFor = ["system_admin", "system_manager"];

  if (!availableFor.includes(context!)) {
    return null;
  }

  return (
    <Link to={"/zdarzenia-systemowe"} style={{ width: "100%" }}>
      <Button
        w="100%"
        as={type}
        title={t("Zdarzenia systemowe")}
        variant={{ base: "link", lg: "ghost" }}
        aria-label="system-events"
        icon={<LogsIcon height="20px" />}
        leftIcon={<LogsIcon height="24px" />}
        background="transparent"
        isActive={pathname === `/zdarzenia-systemowe`}
      >
        {t("Zdarzenia systemowe")}
      </Button>
    </Link>
  );
};
