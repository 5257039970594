import { useMutation } from "@tanstack/react-query";

import { client } from "utils/http";

interface MutationVariables {
  keyId: string;
}

export const useTestIntegrationKey = () => {
  return useMutation({
    mutationKey: ["test-integration-key"],
    mutationFn: (variables: MutationVariables) => {
      return client.patch(
        `integrationKeys/${variables.keyId}/checkConfiguration`
      );
    },
  });
};
