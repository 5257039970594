import { Button, IconButton, useBreakpoint } from "@chakra-ui/react";
import { useIsMutating } from "@tanstack/react-query";
import { t } from "i18next";

import { toastWithError } from "components/ErrorHandling/toastWithError";
import { DeleteIcon } from "components/icons/DeleteIcon";

import { useDeleteNotificationProcess } from "modules/notifications/infrastructure/useDeleteNotificationProcess";

interface IProps {
  notificationProcessId: string;
  smallBreakpoints?: string[];
}

export const DeleteNotificationBtn = ({
  notificationProcessId,
  smallBreakpoints = ["base", "lg"],
}: IProps) => {
  const breakpoint = useBreakpoint();
  const { mutateAsync: deleteNotification } = useDeleteNotificationProcess(
    notificationProcessId
  );

  const isMutating = useIsMutating({
    mutationKey: ["delete-notification-process"],
  });

  const handleClick = async () => {
    try {
      await deleteNotification();
    } catch (error) {
      toastWithError({
        error,
      });
    }
  };

  if (smallBreakpoints.includes(breakpoint)) {
    return (
      <IconButton
        aria-label={t("Usuń")}
        icon={<DeleteIcon />}
        title={t("Usuń")}
        onClick={handleClick}
        isLoading={!!isMutating}
      />
    );
  }

  return (
    <Button onClick={handleClick} isLoading={!!isMutating}>
      {t("Usuń")}
    </Button>
  );
};
