import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";

import {
  Table as CTable,
  Grid,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  Column,
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  useReactTable,
} from "@tanstack/react-table";
import { t } from "i18next";

import { EmptyState } from "components/EmptyStates/EmptyState";
import { Pagination } from "components/Pagination";
import { SearchEngineIcon } from "components/icons/SearchEngineIcon";

import { SortingIndicator } from "./SortingIndicator";
import { globalFilterFn } from "./globalFilterFn";

interface IProps<T> {
  data: T[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<T, any>[];
  globalSearchParam?: string;
  columnVisibility?: Record<string, boolean>;
}

export const Table = <T,>({
  columns,
  data,
  globalSearchParam = "globalSearch",
  columnVisibility,
}: IProps<T>) => {
  const [searchParams] = useSearchParams();
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const table = useReactTable({
    data,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    filterFns: {
      global: globalFilterFn,
    },
    state: {
      pagination,
      globalFilter,
      columnVisibility,
      columnFilters,
    },
  });

  const searchedPhrase = searchParams.get(globalSearchParam) || "";

  useEffect(() => {
    setGlobalFilter(String(searchedPhrase));
  }, [searchedPhrase]);

  return (
    <>
      <TableContainer>
        <CTable variant="simple">
          <Thead bgColor="gray.50">
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Fragment key={header.id}>
                      <Th
                        cursor={
                          header.column.getCanSort() ? "pointer" : "default"
                        }
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getCanSort() && (
                          <SortingIndicator
                            currentSortType={header.column.getIsSorted()}
                          />
                        )}
                        {header.column.getCanFilter() ? (
                          <>
                            <Filter<T> column={header.column} />
                          </>
                        ) : null}
                      </Th>
                    </Fragment>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody
            fontSize="sm"
            sx={{
              tr: {
                _last: {
                  td: {
                    border: "none",
                  },
                },
              },
            }}
          >
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <Fragment key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Fragment>
                  );
                })}
              </Tr>
            ))}
          </Tbody>
        </CTable>
        {table.getRowModel().rows.length === 0 && (
          <Grid
            placeItems="center"
            border="2px dashed var(--chakra-colors-gray-300)"
            borderRadius="md"
            h="100%"
            w="100%"
            py={3}
          >
            <EmptyState
              title={
                <Text fontSize="md" fontWeight={700}>
                  {t("Brak wyników")}
                </Text>
              }
              icon={<SearchEngineIcon />}
              size="xs"
            />
          </Grid>
        )}
      </TableContainer>
      <Pagination
        mt={6}
        currentPage={table.getState().pagination.pageIndex + 1}
        totalItems={table.getRowCount()}
        itemsPerPage={table.getState().pagination.pageSize}
        onPageChange={(page) => {
          table.setPageIndex(page - 1);
        }}
      />
    </>
  );
};

const Filter = <T,>({ column }: { column: Column<T, unknown> }) => {
  const [searchParams] = useSearchParams();
  const value = searchParams.get(column.id);

  useEffect(() => {
    if (value) {
      column.setFilterValue(value.split(","));
    } else {
      column.setFilterValue(undefined);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return null;
};
