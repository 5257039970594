import {
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Td,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { t } from "i18next";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";

import { ActiveBadge } from "components/ActiveBadge";
import { FormatDate } from "components/FormatDate";
import { Table } from "components/Table";
import { TableEmptyState } from "components/Table/TableEmptyState";
import { TableLoadingState } from "components/Table/TableLoadingState";

import { HiddenKey } from "modules/apiKey/presentation/ApiKeysTable/HiddenKey";
import { IDefaultIntegrationKey } from "modules/integrationKey/application/types/IDefaultIntegrationKey";
import { useDefaultIntegrationKeysQuery } from "modules/integrationKey/infrastructure/useDefaultIntegrationKeysQuery";

import { TestKeyButton } from "../IntegrationKeysTable/TestKeyButton";
import { EditKeyButton } from "./EditKeyButton";

const columnHelper = createColumnHelper<IDefaultIntegrationKey>();

const columns = [
  columnHelper.accessor("name", {
    header: () => t("Adres instancji"),
    cell: (info) => (
      <Td whiteSpace="normal" data-testid="instance-address">
        {info.getValue()}
      </Td>
    ),
  }),
  columnHelper.accessor("key", {
    header: () => t("Klucz"),
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal">
        <HiddenKey apiKey={info.getValue()} />
      </Td>
    ),
  }),
  columnHelper.accessor("createdAt", {
    header: () => t("Data utworzenia"),
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal">
        <FormatDate date={info.getValue()} />
      </Td>
    ),
  }),
  columnHelper.accessor("active", {
    header: () => t("Aktywny"),
    cell: (info) => (
      <Td whiteSpace="normal" data-testid="active-badge">
        <ActiveBadge isActive={info.getValue()} />
      </Td>
    ),
  }),
  columnHelper.accessor("additionalInformation", {
    header: () => t("Komentarz"),
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal" data-testid="comment">
        {info.getValue()}
      </Td>
    ),
  }),
  columnHelper.accessor("id", {
    header: () => "",
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal" isNumeric>
        <ButtonGroup>
          <TestKeyButton
            hasIframeUrl={!!info.row.original.iframeUrl}
            keyId={info.getValue()}
          />
          <EditKeyButton integrationKey={info.row.original} />
        </ButtonGroup>
      </Td>
    ),
  }),
];

interface IProps {
  keys: IDefaultIntegrationKey[];
  isLoading: boolean;
}

const IntegrationKeysTableConnected = ({ keys, isLoading }: IProps) => {
  if (isLoading) {
    return <TableLoadingState />;
  }

  if (keys?.length === 0) {
    return <TableEmptyState title={t("Brak kluczy integracyjnych")} />;
  }

  const sortedRecords = reverse(
    sortBy(keys, function (data) {
      return new Date(data.createdAt);
    })
  );

  return (
    <Table<IDefaultIntegrationKey> data={sortedRecords} columns={columns} />
  );
};

export const DefaultIntegrationKeyTable = () => {
  const { data, isLoading } = useDefaultIntegrationKeysQuery();

  return (
    <Card id="default-keys" variant="sawpe">
      <CardHeader>
        <Heading fontSize="lg">{t("Domyślne klucze integracyjne")}</Heading>
      </CardHeader>
      <CardBody>
        <IntegrationKeysTableConnected
          keys={data?.defaultIntegrationKeys}
          isLoading={isLoading}
        />
      </CardBody>
    </Card>
  );
};
