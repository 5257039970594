import { useMutation } from "@tanstack/react-query";

import { client } from "utils/http";

import { Company } from "modules/organization/infrastructure/useAddCompany";

import { BaseRegistration } from "../application/types/BaseRegistration";

export type CompanyRegistration = Company & BaseRegistration;

export const useRegisterCompany = () => {
  return useMutation({
    mutationKey: ["auth", "register", "company"],
    mutationFn: (body: CompanyRegistration) =>
      client.post("auth/register/company", { body }),
  });
};
