import { GridItem, Skeleton } from "@chakra-ui/react";

import { countTariffs } from "modules/dashboard/application/countTariffs";
import { useOrganizationsQuery } from "modules/organization/infrastructure/useOrganizationsQuery";
import { TariffTypes } from "modules/tariffs/application/types/ITariff";

import { TariffTile } from "./TariffTile";

export const TariffsTiles = () => {
  const { data, isLoading } = useOrganizationsQuery();

  if (isLoading) {
    return (
      <>
        <GridItem>
          <Skeleton height="150px" />
        </GridItem>
        <GridItem>
          <Skeleton height="150px" />
        </GridItem>
        <GridItem>
          <Skeleton height="150px" />
        </GridItem>
        <GridItem>
          <Skeleton height="150px" />
        </GridItem>
      </>
    );
  }

  const countedTariffs = countTariffs(data?.organizations);

  return (
    <>
      {Object.entries(countedTariffs).map(([tariffType, count]) => {
        return (
          <TariffTile
            key={tariffType}
            tariffType={tariffType as TariffTypes}
            activeOrganizations={count}
          />
        );
      })}
    </>
  );
};
