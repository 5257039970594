import { UseFormRegisterReturn } from "react-hook-form";

import { Select } from "@chakra-ui/react";
import { t } from "i18next";

import { FormControl } from "components/Form/FormControl";

import { useOrganizationsQuery } from "modules/organization/infrastructure/useOrganizationsQuery";

interface IProps {
  register: UseFormRegisterReturn;
}

export const OrganizationSelect = (props: IProps) => {
  const { data, isLoading } = useOrganizationsQuery();

  if (isLoading) {
    return null;
  }

  return (
    <FormControl formLabel={t("Podmiot")} isRequired>
      <Select {...props.register} placeholder={t("Wybierz podmiot")}>
        {data.organizations
          .filter(({ tariffs }) => tariffs.current?.type === "E")
          .map(({ details, id }) => {
            return (
              <option key={id} value={id}>
                {details.displayName}
              </option>
            );
          })}
      </Select>
    </FormControl>
  );
};
