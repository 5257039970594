import {
  Button,
  InputGroup,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  chakra,
} from "@chakra-ui/react";
import { t } from "i18next";

import { Modal } from "components/Dialog";

interface IProps {
  id: string;
  payload: object;
}

export const SystemEventPayloadModal = ({ id, payload }: IProps) => {
  return (
    <Modal id={id}>
      {({ onClose }) => {
        return (
          <>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{t("Szczegóły zdarzenia")}</ModalHeader>
              <ModalBody as={VStack} align="stretch" spacing={6}>
                <chakra.pre fontSize="10px" whiteSpace="pre-wrap">
                  {JSON.stringify(payload, null, 2)}
                </chakra.pre>
              </ModalBody>
              <ModalFooter>
                <InputGroup gap="2" justifyContent="end">
                  <Button colorScheme="purple" onClick={onClose}>
                    {t("Zamknij")}
                  </Button>
                </InputGroup>
              </ModalFooter>
            </ModalContent>
          </>
        );
      }}
    </Modal>
  );
};
