import { ReactNode } from "react";

import { Flex, FlexProps, Text } from "@chakra-ui/react";

import { useDescriptionListConsumer } from "./DescriptionList";

interface IProps extends FlexProps {
  label: string;
  value: ReactNode;
}

export const DescriptionListItem = ({ label, value, ...props }: IProps) => {
  const { divider, padding } = useDescriptionListConsumer();

  return (
    <Flex
      flex={1}
      flexFlow={{ base: "column", md: "row" }}
      padding={padding}
      borderBottom={divider}
      {...props}
    >
      <Label>{label}</Label>
      <Value>{value ?? "-"}</Value>
    </Flex>
  );
};

interface IChildrenProp {
  children: ReactNode;
}

const Label = (props: IChildrenProp) => {
  return (
    <Text flex={1} fontSize="sm" fontWeight={600}>
      {props.children}
    </Text>
  );
};

const Value = (props: IChildrenProp) => {
  return (
    <Text mt={2} flex={2} fontSize="sm" fontWeight={400}>
      {props.children}
    </Text>
  );
};
