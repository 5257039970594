import { Text, VStack } from "@chakra-ui/react";
import { t } from "i18next";

import { AlertDialog } from "components/Dialog";
import { toastWithError } from "components/ErrorHandling/toastWithError";

import { useRemoveManager } from "modules/organization/infrastructure/useRemoveManager";

interface IProps {
  id: string;
  name: string;
  contextId: string;
}

export const RemoveManagerConfirm = ({ id, name, contextId }: IProps) => {
  const { mutateAsync: remove } = useRemoveManager();

  return (
    <AlertDialog
      id={id}
      title={t("Usuń konto")}
      body={
        <VStack align="stretch" spacing={4} fontSize="md">
          <Text>
            {t("Czy na pewno chcesz usnąć konto użytkownika {{accName}}?", {
              accName: name,
            })}
          </Text>
        </VStack>
      }
      confirmLabel={t("Usuń")}
      onConfirm={async () => {
        try {
          await remove({
            contextId,
          });
        } catch (error) {
          toastWithError({
            error,
          });
        }
      }}
      colorScheme="purple"
      size="xl"
    />
  );
};
