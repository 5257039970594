import { QueryClient } from "@tanstack/react-query";
import { http, HttpResponse } from "msw";

import { client, withHost } from "utils/http";
import { useQuery } from "utils/useQuery";

import { NotificationProcess } from "../application/NotificationProcess";

const query = (id: string) => {
  return {
    queryKey: ["notifications-process", id],
    queryFn: () =>
      client.get<{ notificationsProcess: NotificationProcess }>(
        `notificationsProcess/${id}`
      ),
  };
};

export const getNotificationProcessByIdQuery =
  (queryClient: QueryClient) => (processId: string) => {
    return queryClient.ensureQueryData(query(processId));
  };

export const useNotificationProcessByIdQuery = (processId: string) => {
  return useQuery(query(processId));
};

export const getNotificationProcessByIdMock = (
  process: NotificationProcess
) => {
  return http.get(withHost("notificationsProcess/:processId"), () => {
    return HttpResponse.json<{ notificationsProcess: NotificationProcess }>({
      notificationsProcess: { ...process },
    });
  });
};
