import { useParams } from "react-router-dom";

import { IconButton, Tooltip } from "@chakra-ui/react";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";
import { PowerIcon } from "components/icons/PowerIcon";

import { useOrganizationByIdQuery } from "modules/organization/infrastructure/useOrganizationByIdQuery";

import { ToggleUserConfirm } from "./ToggleUserConfirm";

interface IProps {
  contextId: string;
  isActive: boolean;
  name: string;
}

export const ToggleUserButton = ({ isActive, contextId, name }: IProps) => {
  const id = `toggle-${contextId}`;
  const onOpen = useOpenDialog(id);

  const { organizationId } = useParams<{ organizationId: string }>();
  const { data } = useOrganizationByIdQuery(organizationId!);

  const label = isActive ? t("Dezaktywuj") : t("Aktywuj");

  if (data?.organization?.status !== "ACTIVE") {
    return null;
  }

  return (
    <>
      <ToggleUserConfirm
        id={id}
        contextId={contextId}
        isActive={isActive}
        name={name}
      />
      <Tooltip label={label} aria-label={label}>
        <IconButton
          variant="link"
          colorScheme="purple"
          size="sm"
          data-testid="toggle-contextId"
          onClick={onOpen}
          aria-label={label}
          icon={<PowerIcon />}
        />
      </Tooltip>
    </>
  );
};
