import { useDialogStore } from "./useDialogStore";

export function useOpenDialog(): <P>(id: string, payload?: P) => void;
export function useOpenDialog<P>(id: string): (payload?: P) => void;
export function useOpenDialog<P>(id: string, payload?: P): () => void;
export function useOpenDialog(id1?: string, payload1?: unknown) {
  const onOpen = useDialogStore((state) => state.onOpen);
  return (id2?: string, payload2?: unknown) => {
    if (!payload1 && !payload2 && !!id1) {
      return onOpen(id1, id2);
    }
    onOpen(id1 ?? id2!, payload1 ?? payload2);
  };
}
