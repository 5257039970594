import {
  CircularProgress as CCircularProgress,
  CircularProgressLabel,
  CircularProgressProps,
} from "@chakra-ui/react";

interface IProps extends CircularProgressProps {
  percentage: number;
}

export const CircularProgress = ({ percentage, ...rest }: IProps) => {
  const rounded = parseFloat(percentage.toFixed(2));
  return (
    <CCircularProgress
      color="purple.500"
      value={rounded}
      thickness="4px"
      {...rest}
    >
      <CircularProgressLabel fontSize="sm">{rounded}%</CircularProgressLabel>
    </CCircularProgress>
  );
};
