import { useForm, useWatch } from "react-hook-form";

import { useToast, VStack } from "@chakra-ui/react";
import { t } from "i18next";
import { HTTPError } from "ky";

import { toastWithError } from "components/ErrorHandling/toastWithError";
import { FormControl } from "components/Form/FormControl";
import { PasswordInput } from "components/Form/PasswordInput";

import { PasswordStrength } from "../common/PasswordStrength";

export interface IChangePasswordFormData {
  oldPassword: string;
  newPassword: string;
  repeatPassword: string;
}

interface IProps {
  onClose: () => void;
  onSubmit: (payload: IChangePasswordFormData) => Promise<void>;
}

export const ChangeUserPasswordForm = ({ onClose, onSubmit }: IProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    getValues,
    control,
  } = useForm<IChangePasswordFormData>();

  const newPassword = useWatch({
    control,
    name: "newPassword",
  });

  const toast = useToast({
    variant: "subtle",
    status: "success",
    description: t("Hasło zostało zmienione."),
  });

  return (
    <form
      id="change-password-form"
      onSubmit={handleSubmit(async (data) => {
        try {
          await onSubmit(data);
          toast();
          onClose();
        } catch (error) {
          if (error instanceof HTTPError) {
            const errorJson = await error.response.json<{
              message: string;
            }>();

            if (errorJson.message === "context.error.invalidOldPassword") {
              setError("oldPassword", {
                type: "manual",
                message: t("Podano błędne hasło"),
              });
              return;
            }

            // context.error.changePasswordNotAllowed
            if (
              errorJson.message === "context.error.changePasswordNotAllowed"
            ) {
              toastWithError({
                description: t("Brak możliwości zmiany hasła."),
                error,
              });
              return;
            }
          }

          toastWithError({ error });
        }
      })}
    >
      <VStack align="stretch" spacing={6}>
        <FormControl
          formLabel={t("Stare hasło")}
          isRequired
          formErrorMessage={errors.oldPassword?.message}
        >
          <PasswordInput
            register={{
              ...register("oldPassword", {
                required: true,
              }),
            }}
          />
        </FormControl>
        <FormControl
          formLabel={t("Nowe hasło")}
          isRequired
          formErrorMessage={errors.newPassword?.message}
        >
          <PasswordInput
            register={{
              ...register("newPassword", {
                required: true,
                minLength: {
                  value: 5,
                  message: t("Hasło musi mieć co najmniej 5 znaków"),
                },
              }),
            }}
          />
          <PasswordStrength password={newPassword} />
        </FormControl>
        <FormControl
          formLabel={t("Powtórz nowe hasło")}
          isRequired
          formErrorMessage={errors.repeatPassword?.message}
        >
          <PasswordInput
            register={{
              ...register("repeatPassword", {
                required: true,
                validate: (value) => {
                  if (value !== getValues("newPassword")) {
                    return t("Hasła muszą być takie same");
                  }
                },
              }),
            }}
          />
        </FormControl>
      </VStack>
    </form>
  );
};
