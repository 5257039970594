import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

export const useDeleteNotificationProcess = (processId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["delete-notification-process"],
    mutationFn: () => {
      return client.delete(`notificationsProcess/${processId}`);
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["notifications-process"],
      });
    },
  });
};
