import { ReactNode } from "react";

import { Stack, StackProps } from "@chakra-ui/react";

interface StackedListProps extends StackProps {
  children: ReactNode;
  direction?: "row" | "column";
}

export const StackedList = ({
  children,
  direction = "column",
  ...props
}: StackedListProps) => {
  return (
    <Stack
      direction={{ base: "column", lg: direction }}
      spacing={4}
      overflowY={"auto"}
      p={1}
      {...props}
    >
      {children}
    </Stack>
  );
};
