import { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { Box, VStack } from "@chakra-ui/react";

import { SAWPEIcon } from "components/icons/SAWPEIcon";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";
import { Footer } from "modules/auth/presentation/Footer";
import { ResetPassword as ResetPasswordCard } from "modules/auth/presentation/ResetPassword";

export const ResetPassword = withTranslation()(() => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const { send } = ContextMachineContext.useActorRef();

  useEffect(() => {
    const authToken = localStorage.getItem("token");
    if (authToken) {
      localStorage.removeItem("token");
      send({ type: "logout" });
    }
  }, [send]);

  return (
    <VStack minH="100vh" justifyContent="center" spacing={8} pb="120px">
      <Box w={[330, 420]}>
        <SAWPEIcon height="71px" width="100%" />
      </Box>
      <ResetPasswordCard token={token!} />
      <Footer />
    </VStack>
  );
});
