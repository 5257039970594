import { Link, useLocation, useParams } from "react-router-dom";

import { Button } from "@chakra-ui/react";
import { t } from "i18next";

import { DrawIcon } from "components/icons/DrawIcon";

export const VerificationHistoryBtn = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { pathname } = useLocation();

  if (!organizationId) {
    return null;
  }

  return (
    <Link
      to={organizationId + "/historia-weryfikacji"}
      style={{ width: "100%" }}
    >
      <Button
        w="100%"
        variant={{ base: "link", lg: "ghost" }}
        leftIcon={<DrawIcon height="24px" width="24px" />}
        isActive={pathname === `/${organizationId}/historia-weryfikacji`}
      >
        {t("Wykonane weryfikacje")}
      </Button>
    </Link>
  );
};
