import { useRef } from "react";
import { FilePond, registerPlugin } from "react-filepond";

import { Box, useTheme } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FilePondFile } from "filepond";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import "filepond/dist/filepond.min.css";
import { t } from "i18next";

interface IProps {
  files: File[];
  onupdatefiles: (files: FilePondFile[], pond: FilePond) => void;
}

export const FileUploader = ({ files, onupdatefiles }: IProps) => {
  const { colors } = useTheme();
  const pond = useRef<FilePond | null>(null);

  registerPlugin(FilePondPluginFileValidateSize);

  const maxFileSize = "250MB";
  const maxTotalFileSize = "1000MB";

  return (
    <StylesWrapper
      w="100%"
      iconBackground={colors.gray[100]}
      iconColor={colors.gray[600]}
      dropzoneBackground={colors.gray[50]}
      loadInvalidBackground={colors.red[500]}
      idleBackground={colors.gray[500]}
      processingCompleteBackground={colors.green[500]}
      dropzoneBorderColor={colors.purple[200]}
    >
      <FilePond
        files={files}
        onupdatefiles={(files) => {
          if (onupdatefiles) onupdatefiles(files, pond.current!);
        }}
        instantUpload={false}
        allowProcess={false}
        allowMultiple={true}
        maxFiles={200}
        allowFileSizeValidation={true}
        maxFileSize={maxFileSize}
        maxTotalFileSize={maxTotalFileSize}
        ref={(ref) => {
          pond.current = ref;
        }}
        labelIdle={t("Upuść pliki lub kliknij tutaj.")}
        labelMaxFileSizeExceeded={t("Plik jest zbyt duży")}
        labelMaxFileSize={t("Maksymalny rozmiar pliku to {{maxFileSize}}", {
          maxFileSize,
        })}
        labelMaxTotalFileSizeExceeded={t(
          "Maksymalny rozmiar plików przekroczony"
        )}
        labelMaxTotalFileSize={t(
          "Maksymalny rozmiar plików to {{maxTotalFileSize}}",
          { maxTotalFileSize }
        )}
      />
    </StylesWrapper>
  );
};

const excludedProps = [
  "iconBackground",
  "iconColor",
  "dropzoneBackground",
  "loadInvalidBackground",
  "idleBackground",
  "processingCompleteBackground",
  "dropzoneBorderColor",
];

const StylesWrapper = styled(Box, {
  shouldForwardProp(prop: string) {
    return !excludedProps.includes(prop);
  },
})<{
  iconBackground: string;
  iconColor: string;
  dropzoneBackground: string;
  loadInvalidBackground: string;
  idleBackground: string;
  processingCompleteBackground: string;
  dropzoneBorderColor: string;
}>`
  .filepond--root {
    margin-bottom: 0;
  }

  .filepond--panel-root {
    background: ${(props) => props.dropzoneBackground};
    border: ${(props) => `2px dashed ${props.dropzoneBorderColor}`};
  }

  .icon-box {
    background-color: ${(props) => props.iconBackground};
    padding: 8px;
    border-radius: 50%;
  }

  .dropzone-container {
    color: ${(props) => props.iconColor};
  }

  .filepond--label-action {
    font-weight: 500;
  }

  [data-filepond-item-state="processing"] .filepond--item-panel,
  [data-filepond-item-state="busy processing"] .filepond--item-panel {
    background-color: ${(props) => props.idleBackground};
  }

  [data-filepond-item-state="processing-loading"] .filepond--item-panel {
    background-color: ${(props) => props.idleBackground};
  }

  [data-filepond-item-state="processing-complete"] .filepond--item-panel {
    background-color: ${(props) => props.processingCompleteBackground};
  }

  [data-filepond-item-state="load-invalid"] .filepond--item-panel,
  [data-filepond-item-state="invalid"] .filepond--item-panel,
  [data-filepond-item-state="error"] .filepond--item-panel {
    background-color: ${(props) => props.loadInvalidBackground};
  }

  [data-filepond-item-state="idle"] .filepond--item-panel {
    background-color: ${(props) => props.idleBackground};
  }
`;
