import { useNavigate } from "react-router-dom";

import { Grid, HStack, Skeleton, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import { t } from "i18next";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";

import { EmptyState } from "components/EmptyStates/EmptyState";
import { StackedList } from "components/StackedList";
import { StackedListItem } from "components/StackedList/StackedListItem";
import { CalendarIcon } from "components/icons/CalendarIcon";
import { NoDataIcon } from "components/icons/NoDataIcon";

import { NotificationProcess } from "modules/notifications/application/NotificationProcess";
import { useNotificationProcessQuery } from "modules/notifications/infrastructure/useNotificationProcessQuery";

import { CorrectionButton } from "../CorrectionModal/CorrectionButton";
import { CreateBasedOnNotificationBtn } from "./CreateBasedOnNotificationBtn";

interface IProps {
  isLoading: boolean;
  data: NotificationProcess[];
}

const SentNotificationsConnected = ({ isLoading, data }: IProps) => {
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <StackedList>
        <Skeleton h="140px" />
        <Skeleton h="140px" />
        <Skeleton h="140px" />
      </StackedList>
    );
  }

  if (data.length === 0) {
    return (
      <Grid placeItems="center" h="100%" w="100%">
        <EmptyState
          icon={<NoDataIcon />}
          size="xs"
          title={
            <Text fontWeight="500">{t("Brak wysłanych powiadomień")}</Text>
          }
        />
      </Grid>
    );
  }

  const sortedRecords = reverse(
    sortBy(data, function (data) {
      return new Date(data.completedAt!);
    })
  );

  return (
    <StackedList maxH="620px">
      {sortedRecords.map((notification) => {
        return (
          <StackedListItem
            key={notification.id}
            title={notification.subject}
            badges={notification.selectedTariffs}
            description={
              <HStack>
                <CalendarIcon size="16px" />
                <Text>
                  {t("Wysłano: {{sendAt}}", {
                    sendAt: dayjs(notification.completedAt).format(
                      "DD.MM.YYYY HH:mm"
                    ),
                  })}
                </Text>
              </HStack>
            }
            onClick={() => {
              navigate(`/powiadomienia/${notification.id}`);
            }}
            actions={
              <>
                <CreateBasedOnNotificationBtn
                  notificationProcessId={notification.id}
                />
                <CorrectionButton notificationProcess={notification} />
              </>
            }
          >
            <Text>
              {t("Wysłano: {{sentEmails}} z {{totalEmails}}", {
                sentEmails: notification.emailsSent,
                totalEmails: notification.emailsTotal,
              })}
            </Text>
          </StackedListItem>
        );
      })}
    </StackedList>
  );
};

export const SentNotifications = () => {
  const { data, isLoading } = useNotificationProcessQuery();

  return (
    <SentNotificationsConnected
      data={data?.notificationsProcesses.filter(
        ({ isLocked, isCompleted }) => isLocked && isCompleted
      )}
      isLoading={isLoading}
    />
  );
};
