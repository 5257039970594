import { QueryClient } from "@tanstack/react-query";
import { HttpResponse, http } from "msw";

import { client, withHost } from "utils/http";
import { useQuery } from "utils/useQuery";

import { IEvent } from "../application/types/IEvent";

const query = (id: string) => {
  return {
    queryKey: ["organizations", id, "events"],
    queryFn: () =>
      client.get<{ events: IEvent[] }>(`organizations/${id}/events`),
  };
};
export const getEventsQuery = (queryClient: QueryClient) => (id: string) => {
  return queryClient.ensureQueryData(query(id));
};

export const useEventsQuery = (id: string) => {
  return useQuery(query(id));
};

export const getEventsMock = (events: IEvent[] = []) => {
  return http.get(withHost("organizations/:id/events"), () => {
    return HttpResponse.json<{ events: IEvent[] }>({
      events: [...events],
    });
  });
};
