import {
  Button,
  FormControl,
  FormLabel,
  InputGroup,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useIsMutating } from "@tanstack/react-query";
import { t } from "i18next";

import { Modal } from "components/Dialog";
import { toastWithError } from "components/ErrorHandling/toastWithError";

import { useAllowUserPasswordChange } from "modules/organization/infrastructure/useAllowUserPasswordChange";
import { useOrganizationByIdQuery } from "modules/organization/infrastructure/useOrganizationByIdQuery";

interface IProps {
  id: string;
  organizationId: string;
}

export const OrganizationConfigurationModal = ({
  id,
  organizationId,
}: IProps) => {
  const { data, isLoading } = useOrganizationByIdQuery(organizationId);

  const { mutateAsync: toggle } = useAllowUserPasswordChange(organizationId);
  const isMutating = useIsMutating({
    mutationKey: ["allow-user-password-change"],
  });

  if (isLoading) {
    return null;
  }

  return (
    <Modal id={id}>
      {({ onClose }) => {
        return (
          <>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{t("Konfiguracja podmiotu")}</ModalHeader>
              <ModalBody as={VStack} align="stretch" spacing={6}>
                <VStack align="stretch">
                  <FormControl display="flex" alignItems="center" gap="2">
                    <Switch
                      id="allow-user-password-change"
                      name="allow-user-password-change"
                      colorScheme="purple"
                      isChecked={data.organization.allowUserPasswordChange}
                      isDisabled={!!isMutating}
                      onChange={async () => {
                        try {
                          await toggle({
                            isActive: data.organization.allowUserPasswordChange,
                          });
                        } catch (e) {
                          toastWithError({ error: e });
                        }
                      }}
                    />
                    <FormLabel
                      htmlFor="allow-user-password-change"
                      mb="0"
                      fontWeight="bold"
                    >
                      {t("Zmiana hasła przez użytkowników")}
                    </FormLabel>
                  </FormControl>
                  <Text fontSize="sm" color="gray.600">
                    {t(
                      "Jeśli włączysz tę funkcję, użytkownicy będą mogli samodzielnie zmieniać swoje hasła bez konieczności kontaktowania się z administratorem. W przypadku wyłączenia tej funkcji, użytkownicy stracą możliwość zmiany hasła, co oznacza, że każda prośba o aktualizację hasła będzie musiała być obsłużona przez administratora."
                    )}
                  </Text>
                </VStack>
              </ModalBody>
              <ModalFooter>
                <InputGroup gap="2" justifyContent="end">
                  <Button onClick={onClose}>{t("Zamknij")}</Button>
                </InputGroup>
              </ModalFooter>
            </ModalContent>
          </>
        );
      }}
    </Modal>
  );
};
