import { ComponentProps } from "react";

import { mdiCheckCircleOutline } from "@mdi/js";

import { Icon } from "./Icon";

export const CheckCircleOutlineIcon = (
  props: Omit<ComponentProps<typeof Icon>, "path">
) => {
  return <Icon {...props} path={mdiCheckCircleOutline} />;
};
