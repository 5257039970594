import { IconButton, Tooltip } from "@chakra-ui/react";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";
import { EditIcon } from "components/icons/EditIcon";

import { ManagerContext } from "modules/organization/application/types/IOrganizationContext";

import { IManagerFormData } from "../../common/ManagerModalForm";
import { EditManagerModal } from "./EditManagerModal";

interface IProps {
  manager: ManagerContext;
}

export const EditManagerBtn = ({ manager }: IProps) => {
  const id = `edit-manager-${manager.id}`;
  const onOpen = useOpenDialog<Omit<IManagerFormData, "email" | "surname">>(id);

  const label = t("Edytuj");

  return (
    <>
      <EditManagerModal id={id} contextId={manager.id} />
      <Tooltip label={label} aria-label={label}>
        <IconButton
          variant="link"
          colorScheme="purple"
          size="sm"
          data-testid="edit-manager"
          onClick={() =>
            onOpen({
              name: manager.name,
              phone: manager.details.phone,
            })
          }
          aria-label={label}
          icon={<EditIcon />}
        />
      </Tooltip>
    </>
  );
};
