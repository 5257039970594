import { Text, VStack } from "@chakra-ui/react";
import { t } from "i18next";

import { AlertDialog } from "components/Dialog";
import { toastWithError } from "components/ErrorHandling/toastWithError";

import { useToggleOrganizationActivity } from "modules/organization/infrastructure/useToggleOrganizationActivity";

interface IConfirmProps {
  id: string;
  organizationId: string;
  isActive: boolean;
  name: string;
}

export const ToggleOrganizationConfirm = ({
  id,
  organizationId,
  isActive,
  name,
}: IConfirmProps) => {
  const { mutateAsync: toggle } = useToggleOrganizationActivity(organizationId);

  return (
    <AlertDialog
      id={id}
      title={isActive ? t("Dezaktywacja podmiotu") : t("Aktywacja podmiotu")}
      body={
        <VStack align="stretch" spacing={4} fontSize="md">
          <Text>
            {t("Czy na pewno chcesz {{value}} podmiot {{name}}?", {
              value: isActive ? t("dezaktywować") : t("aktywować"),
              name,
            })}
          </Text>
        </VStack>
      }
      colorScheme="purple"
      size="xl"
      confirmLabel={isActive ? t("Dezaktywuj") : t("Aktywuj")}
      onConfirm={async () => {
        try {
          await toggle({
            isActive,
          });
        } catch (error) {
          toastWithError({
            error,
          });
        }
      }}
    />
  );
};
