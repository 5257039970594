import { useParams } from "react-router-dom";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";

interface MutationVariables {
  name: string;
  login: string;
  password: string;
}

export const useAddUser = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const queryClient = useQueryClient();
  const { send } = ContextMachineContext.useActorRef();

  return useMutation({
    mutationKey: ["add-user"],
    mutationFn: (variables: MutationVariables) => {
      return client.post(`organizations/${organizationId}/contexts/user`, {
        body: { ...variables },
      });
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["organizations", organizationId, "contexts"],
      });
      send({ type: "sync" });
    },
  });
};
