import { useForm } from "react-hook-form";
import { Link, useSearchParams } from "react-router-dom";

import { Button, Card, CardBody, Input, Text, VStack } from "@chakra-ui/react";
import { t } from "i18next";

import { toastWithError } from "components/ErrorHandling/toastWithError";
import { FormControl } from "components/Form/FormControl";
import { ArrowLeftIcon } from "components/icons/ArrowLeftIcon";

import { forgot } from "../infrastructure/forgot";

export const ForgotPasswordForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string }>();

  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Card w={[330, 420]}>
      <CardBody p={8}>
        <form
          id="login-form"
          noValidate
          onSubmit={handleSubmit(async (data) => {
            try {
              await forgot(data.email);
              setSearchParams(
                { ...searchParams, email: data.email },
                {
                  replace: true,
                }
              );
            } catch (e) {
              toastWithError({
                error: e,
              });
            }
          })}
        >
          <VStack align="stretch" spacing={6}>
            <div>
              <Button
                variant="link"
                as={Link}
                to="/login"
                size="xs"
                colorScheme="purple"
                leftIcon={<ArrowLeftIcon size="3" />}
              >
                {t("Powrót")}
              </Button>
            </div>
            <Text>
              {t(
                "Podaj swój adres e-mail do konta, pod który wyślemy link aktywacyjny."
              )}
            </Text>
            <FormControl
              formLabel={t("E-mail")}
              formErrorMessage={errors.email?.message}
            >
              <Input
                type="email"
                {...register("email", {
                  setValueAs: (value) => value.trim(),
                  required: t("Pole jest wymagane."),
                  pattern: {
                    value: /[^@]+@[^.]+\..+/,
                    message: t("Niepoprawny format"),
                  },
                })}
              />
            </FormControl>
            <Button type="submit" colorScheme="purple" w="100%" mt="4">
              {t("Wyślij link")}
            </Button>
          </VStack>
        </form>
      </CardBody>
    </Card>
  );
};
