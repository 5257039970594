import { QueryClient } from "@tanstack/react-query";
import { http, HttpResponse } from "msw";

import { client, withHost } from "utils/http";
import { useQuery } from "utils/useQuery";

import { IInterpretation } from "../application/types/IInterpretation";

const query = () => {
  return {
    queryKey: ["support", "verificationInterpretations"],
    queryFn: () =>
      client.get<{ interpretations: IInterpretation[] }>(
        `support/verificationInterpretations`
      ),
  };
};

export const getVerificationInterpretationsQuery = (
  queryClient: QueryClient
) => {
  return queryClient.ensureQueryData(query());
};

export const useVerificationInterpretationsQuery = () => {
  return useQuery(query());
};

export const getVerificationInterpretationsMock = (
  interpretations: IInterpretation[] = []
) => {
  return http.get(withHost("support/verificationInterpretations"), () => {
    return HttpResponse.json<{
      interpretations: IInterpretation[];
    }>({
      interpretations: [...interpretations],
    });
  });
};
