import { useParams } from "react-router-dom";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

interface MutationVariables {
  isActive: boolean;
  contextId: string;
}

export const useToggleUserActivity = () => {
  const queryClient = useQueryClient();
  const { organizationId } = useParams<{ organizationId: string }>();

  return useMutation({
    mutationKey: ["toggle-user"],
    mutationFn: (variables: MutationVariables) => {
      if (variables.isActive) {
        return client.post(
          `organizations/${organizationId}/contexts/${variables.contextId}/disable`
        );
      }
      return client.post(
        `organizations/${organizationId}/contexts/${variables.contextId}/activate`
      );
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["organizations", organizationId, "contexts"],
      });
    },
  });
};
