import { useMutation } from "@tanstack/react-query";

import { client } from "utils/http";

interface MutationVariables {
  password: string;
  token: string;
}

export const useResetPassword = () => {
  return useMutation({
    mutationKey: ["reset-password"],
    mutationFn: (variables: MutationVariables) => {
      return client.post(`auth/resetPassword?token=${variables.token}`, {
        body: { password: variables.password },
        headers: {
          Authorization: undefined,
        },
      });
    },
  });
};
