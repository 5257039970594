import { useParams } from "react-router-dom";

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  Link,
  useDisclosure,
} from "@chakra-ui/react";
import { t } from "i18next";

import { useOrganizationByIdQuery } from "modules/organization/infrastructure/useOrganizationByIdQuery";
import { useOrganizationContextsQuery } from "modules/organization/infrastructure/useOrganizationContextsQuery";
import { useOpenAddUserModal } from "modules/organization/presentation/AddUserButton/AddUserModal";

export const NoUsersAlert = () => {
  const { isOpen: isVisible, onClose } = useDisclosure({ defaultIsOpen: true });

  const { organizationId } = useParams<{ organizationId: string }>();
  const { data, isLoading } = useOrganizationContextsQuery(organizationId!);
  const { data: org, isLoading: isOrgLoading } = useOrganizationByIdQuery(
    organizationId!
  );
  const onOpen = useOpenAddUserModal();

  if (isLoading || isOrgLoading) {
    return null;
  }

  if (org.organization.status !== "ACTIVE") {
    return null;
  }

  const users = data?.contexts.filter(
    (context) => context.type === "context_user"
  );

  if (users?.length > 0) {
    return null;
  }

  return isVisible ? (
    <Alert
      status="warning"
      position="sticky"
      top="0"
      zIndex="1"
      borderRadius="md"
      boxShadow="md"
      id="no-user-alert"
    >
      <AlertIcon />
      <Box>
        <AlertTitle>{t("Uwaga!")}</AlertTitle>
        <AlertDescription>
          {t(
            "Nie utworzyłeś lub nie posiadasz przynajmniej jednego aktywnego użytkownika."
          )}{" "}
          <Link onClick={onOpen} fontWeight="bold" textDecoration="underline">
            {t("Stwórz")}
          </Link>{" "}
          {t(
            "przynajmniej 1 konto w celu korzystania z usług weryfikacji podpisów i pieczęci elektronicznych."
          )}
        </AlertDescription>
      </Box>
      <CloseButton
        alignSelf="flex-start"
        position="relative"
        right={-1}
        top={-1}
        onClick={onClose}
        display={{ base: "block", sm: "none" }}
      />
    </Alert>
  ) : null;
};
