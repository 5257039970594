import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

interface State {
  language: string;
  setLanguage: (language: string) => void;
}

export const useLanguageStore = create(
  persist<State>(
    (set, get) => ({
      language: navigator.language,
      setLanguage: (language: string) => set({ language }),
      getLanguage: () => get().language,
    }),
    {
      name: "language-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
