import { Fragment } from "react/jsx-runtime";

import {
  Button,
  Heading,
  InputGroup,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { t } from "i18next";

import { Modal } from "components/Dialog";

import { InterpretationCount } from "modules/verification/application/getInterpretationCount";

import { InterpretationChart } from "./InterpretationChart";

interface IProps {
  id: string;
  payload: InterpretationCount;
}

export const ShowInterpretationCountModal = ({ id, payload }: IProps) => {
  const yearlyInterpretationCount = getYearlyInterpretationCount(
    payload.monthlyData
  );

  return (
    <Modal id={id}>
      {({ onClose }) => {
        return (
          <>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{payload.organizationName}</ModalHeader>
              <ModalBody as={VStack} align="stretch" spacing={0}>
                {yearlyInterpretationCount.map((item, index) => (
                  <Fragment key={index}>
                    <Heading fontSize="md">{item.year}</Heading>
                    <InterpretationChart data={item.data} />
                  </Fragment>
                ))}
              </ModalBody>
              <ModalFooter>
                <InputGroup gap="2" justifyContent="end">
                  <Button onClick={onClose}>{t("Zamknij")}</Button>
                </InputGroup>
              </ModalFooter>
              s
            </ModalContent>
          </>
        );
      }}
    </Modal>
  );
};

const getYearlyInterpretationCount = (
  interpretations: InterpretationCount["monthlyData"]
) => {
  const interpretationsData: { year: number; data: number[] }[] = [];

  interpretations.forEach(({ month, year, interpretationsCount }) => {
    let yearEntry = interpretationsData.find((entry) => entry.year === year);

    if (!yearEntry) {
      yearEntry = { year, data: new Array(12).fill(0) };
      interpretationsData.push(yearEntry);
    }

    yearEntry.data[month - 1] = interpretationsCount;
  });

  return interpretationsData;
};
