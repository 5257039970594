import sortBy from "lodash/sortBy";

import { NotificationProcess } from "modules/notifications/application/NotificationProcess";

export const getTrackedNotification = (
  notificationsProcesses: NotificationProcess[]
): NotificationProcess | null => {
  const pendingNotifications = notificationsProcesses.filter(
    ({ isLocked, isCompleted }) => isLocked && !isCompleted
  );

  const sortedRecords = sortBy(pendingNotifications, function (notification) {
    return new Date(notification.plannedSendAt || notification.lockedAt!);
  });

  return sortedRecords[0] || null;
};
