import { HStack, VStack } from "@chakra-ui/react";

import { SystemEventsTable } from "modules/eventLog/presentation/EventHistoryTable/SystemEvents";

export const SystemEvents = () => {
  return (
    <VStack align="stretch" gap="8">
      <HStack gap="8" align="start">
        <VStack align="stretch" w="100%" gap="6">
          <SystemEventsTable />
        </VStack>
      </HStack>
    </VStack>
  );
};
