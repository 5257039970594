import { useNavigate } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  GridItem,
  HStack,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { t } from "i18next";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";

import { FormatDate } from "components/FormatDate";
import { CalendarIcon } from "components/icons/CalendarIcon";
import { HistoryIcon } from "components/icons/HistoryIcon";

import { useChangelogQuery } from "modules/changelog/infrastructure/useChangelogQuery";
import { getTrackedChange } from "modules/dashboard/application/getTrackedChange";

import { TrackedChange } from "./TrackedChange";

export const ChangelogTile = () => {
  const { data, isLoading } = useChangelogQuery();
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <>
        <GridItem colSpan={2}>
          <Skeleton height="100%" />
        </GridItem>
      </>
    );
  }

  const lastUpdated = reverse(
    sortBy(data.changelog, (change) => {
      return new Date(change.updatedAt || change.createdAt);
    })
  )[0];

  return (
    <GridItem colSpan={2}>
      <Card height="100%">
        <CardHeader
          fontSize="lg"
          fontWeight="semibold"
          roundedTop="md"
          color="white"
          bgColor="purple.500"
        >
          <HStack justifyContent="space-between">
            <Text>{t("Changelog")}</Text>
            <HistoryIcon />
          </HStack>
        </CardHeader>

        <CardBody>
          <VStack align="stretch" justifyContent="space-between" h="100%">
            <HStack>
              <CalendarIcon size="24px" color="purple.500" />
              <Text fontSize="lg" fontWeight="semibold">
                {t("Ostatnio zaktualizowano: ")}
                <FormatDate
                  date={lastUpdated?.updatedAt || lastUpdated?.createdAt}
                />
              </Text>
            </HStack>
            <Divider m={0} />
            <TrackedChange trackedChange={getTrackedChange(data?.changelog)} />
            <Button
              mt="auto"
              colorScheme="purple"
              onClick={() => navigate("/changelog")}
            >
              {t("Przygotuj changelog")}
            </Button>
          </VStack>
        </CardBody>
      </Card>
    </GridItem>
  );
};
