import { useSearchParams } from "react-router-dom";

import { Button } from "@chakra-ui/react";
import { t } from "i18next";

interface IProps {
  changeId: string;
}

export const EditChangeButon = ({ changeId }: IProps) => {
  const [, setSearchParams] = useSearchParams();

  const handleClick = () => {
    setSearchParams({ changeId });
    const element = document.getElementById("changelog-form");
    element?.scrollIntoView({ behavior: "smooth" });
  };

  return <Button onClick={handleClick}>{t("Edytuj")}</Button>;
};
