import { Button } from "@chakra-ui/react";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";
import { PencilIcon } from "components/icons/PencilIcon";

import { ITariff } from "modules/tariffs/application/types/ITariff";

import { ITariffFormData } from "../TariffModalForm";
import { EditTariffModal } from "./EditTariffModal";

interface IProps {
  tariff: ITariff | null;
}

export const EditTariffButton = ({ tariff }: IProps) => {
  const id = `edit-tariff-${tariff?.id}`;

  const onOpen = useOpenDialog<ITariffFormData>(id);

  if (!tariff || tariff.isActive) {
    return null;
  }

  return (
    <>
      <EditTariffModal id={id} tariffId={tariff?.id} />
      <Button
        leftIcon={<PencilIcon />}
        variant="outline"
        colorScheme="purple"
        size="sm"
        data-testid="edit-tariff-button"
        onClick={() =>
          onOpen({
            type: tariff.type,
            validFrom: tariff.validFrom,
            validTo: tariff.validTo,
            personalDataClause: tariff.personalDataClause,
            withApiAccess: tariff.apiAccess,
          })
        }
      >
        {t("Edytuj")}
      </Button>
    </>
  );
};
