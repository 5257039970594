import { useParams } from "react-router-dom";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

interface MutationVariables {
  isActive: boolean;
  keyId: string;
}

export const useToggleApiKeyActivity = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["toggle-api-key"],
    mutationFn: (variables: MutationVariables) => {
      if (variables.isActive) {
        return client.post(
          `organizations/${organizationId}/apiKeys/${variables.keyId}/disable`
        );
      }

      return client.post(
        `organizations/${organizationId}/apiKeys/${variables.keyId}/enable`
      );
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["organizations", organizationId, "apiKeys"],
      });
    },
  });
};
