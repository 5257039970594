import { QueryClient } from "@tanstack/react-query";
import { http, HttpResponse } from "msw";

import { client, withHost } from "utils/http";
import { useQuery } from "utils/useQuery";

import { NotificationProcess } from "../application/NotificationProcess";

const query = () => {
  return {
    queryKey: ["notifications-process"],
    queryFn: () =>
      client.get<{ notificationsProcesses: NotificationProcess[] }>(
        "notificationsProcess"
      ),
  };
};

export const getNotificationProcessQuery = (queryClient: QueryClient) => {
  return queryClient.ensureQueryData(query());
};

export const useNotificationProcessQuery = () => {
  return useQuery(query());
};

export const getNotificationProcessMock = (
  notificationsProcesses: NotificationProcess[] = []
) => {
  return http.get(withHost("notificationsProcess"), () => {
    return HttpResponse.json<{ notificationsProcesses: NotificationProcess[] }>(
      {
        notificationsProcesses: [...notificationsProcesses],
      }
    );
  });
};
