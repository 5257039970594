import { Text, VStack } from "@chakra-ui/react";
import { t } from "i18next";

import { AlertDialog } from "components/Dialog";
import { toastWithError } from "components/ErrorHandling/toastWithError";

import { useToggleIntegrationKeyActivity } from "modules/integrationKey/infrastructure/useToggleIntegrationKeyActivity";

interface IConfirmProps {
  id: string;
  keyId: string;
  isActive: boolean;
  organizationName: string;
}

export const ToggleKeyConfirm = ({
  id,
  keyId,
  isActive,
  organizationName,
}: IConfirmProps) => {
  const { mutateAsync: toggle } = useToggleIntegrationKeyActivity();

  return (
    <AlertDialog
      id={id}
      title={
        isActive
          ? t("Dezaktywacja klucza integracyjnego")
          : t("Aktywacja klucza integracyjnego")
      }
      body={
        <VStack align="stretch" spacing={4} fontSize="md">
          <Text>
            {t(
              "Czy na pewno chcesz {{value}} klucza podmiotu {{organizationName}}?",
              {
                value: isActive ? t("dezaktywować") : t("aktywować"),
                organizationName,
              }
            )}
          </Text>
        </VStack>
      }
      colorScheme="purple"
      size="xl"
      confirmLabel={isActive ? t("Dezaktywuj") : t("Aktywuj")}
      onConfirm={async () => {
        try {
          await toggle({
            isActive,
            keyId,
          });
        } catch (error) {
          toastWithError({
            error,
          });
        }
      }}
    />
  );
};
