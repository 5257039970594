import React from "react";
import { useParams } from "react-router-dom";

import {
  Box,
  Heading,
  Text,
  Code,
  UnorderedList,
  ListItem,
  VStack,
  Divider,
} from "@chakra-ui/react";
import { t } from "i18next";

import { useTariffsQuery } from "modules/tariffs/infrastructure/useTariffsQuery";

interface ApiDocumentation {
  introduction: Section;
  authorization: AuthorizationSection;
  verification: MethodSection;
  archivalDownloads: MethodSection;
  contact: Section;
}

interface Section {
  title: string;
  content?: string[];
}

interface AuthorizationSection extends Section {
  example: {
    code: string;
  };
}

interface MethodSection extends Section {
  methods: {
    [key: string]: {
      description: string[];
      httpRequest: string;
      parameters?: { name: string; description: string }[];
      example: {
        code: string;
      };
      notes?: string[];
      responses?: {
        [key: string]: { title: string; source: string };
      };
    };
  };
}

const SectionComponent: React.FC<{ section: Section }> = ({ section }) => (
  <Box mb={8}>
    <Heading as="h2" size="lg" mb={4}>
      {section.title}
    </Heading>
    {section.content?.map((paragraph, index) => (
      <Text key={index} mb={2}>
        {paragraph}
      </Text>
    ))}
  </Box>
);

const AuthorizationComponent: React.FC<{ section: AuthorizationSection }> = ({
  section,
}) => (
  <Box mb={8}>
    <SectionComponent section={section} />
    <Code p={2} borderRadius="md" bg="gray.100" wordBreak="break-all">
      {section.example.code}
    </Code>
  </Box>
);

const MethodSectionComponent: React.FC<{ section: MethodSection }> = ({
  section,
}) => (
  <Box mb={8}>
    <Heading as="h2" size="lg" mb={4}>
      {section.title}
    </Heading>
    {Object.entries(section.methods).map(([method, details]) => (
      <Box key={method} mb={6}>
        <Heading as="h3" size="md" mb={2}>
          {method}
        </Heading>
        {details.description.map((desc, index) => (
          <Text key={index} mb={2}>
            {desc}
          </Text>
        ))}
        <Text fontWeight="bold" mt={4}>
          HTTP Request:
        </Text>
        <Code p={2} borderRadius="md" bg="gray.100" wordBreak="break-all">
          {details.httpRequest}
        </Code>
        {details.parameters && (
          <>
            <Text fontWeight="bold" mt={4}>
              Parameters:
            </Text>
            <UnorderedList>
              {details.parameters.map((param, index) => (
                <ListItem key={index}>
                  <Text as="span" fontWeight="bold">
                    {param.name}
                  </Text>
                  : {param.description}
                </ListItem>
              ))}
            </UnorderedList>
          </>
        )}
        <Text fontWeight="bold" mt={4}>
          Example:
        </Text>
        <Code p={2} borderRadius="md" bg="gray.100" wordBreak="break-all">
          {details.example.code}
        </Code>
        {details.notes && (
          <>
            <Text fontWeight="bold" mt={4}>
              Notes:
            </Text>
            <UnorderedList>
              {details.notes.map((note, index) => (
                <ListItem key={index}>{note}</ListItem>
              ))}
            </UnorderedList>
          </>
        )}
        {details.responses && (
          <>
            <Text fontWeight="bold" mt={4}>
              Responses:
            </Text>
            {Object.entries(details.responses).map(([type, description]) => (
              <VStack align="stretch">
                <Text key={type}>
                  <Text as="span" fontWeight="bold">
                    {type}
                  </Text>
                  : {description.title}
                </Text>
                <Code as="pre" mb={4} p={4} borderRadius="md">
                  {description.source}
                </Code>
              </VStack>
            ))}
          </>
        )}
      </Box>
    ))}
  </Box>
);

const ApiDocumentation: React.FC<{ data: ApiDocumentation }> = ({ data }) => {
  return (
    <VStack spacing={8} align="stretch" p={8}>
      <Heading as="h1" size="2xl" mb={8}>
        Dokumentacja API
      </Heading>
      <SectionComponent section={data.introduction} />
      <Divider />
      <AuthorizationComponent section={data.authorization} />
      <Divider />
      <MethodSectionComponent section={data.verification} />
      <Divider />
      <MethodSectionComponent section={data.archivalDownloads} />
      <Divider />
      <SectionComponent section={data.contact} />
    </VStack>
  );
};

const docs = (customUrl: string | null) => {
  let customPart = "widget";

  if (customUrl) {
    const match = customUrl.match(/^https:\/\/([^\\.]+)\./);
    customPart = match ? match[1] : "widget";
  }

  const data = {
    introduction: {
      title: t("Wstęp"),
      content: [
        t(
          "Platforma weryfikacjapodpisu.pl umożliwia weryfikowanie plików poprzez API oraz pobieranie wyników archiwalnych w postaci HTML, PDF i JSON."
        ),
      ],
    },
    authorization: {
      title: t("Autoryzacja"),
      content: [
        t(
          'Autoryzacja odbywa się poprzez przekazanie klucza autoryzacyjnego w nagłówku api-auth. Klucz API możesz wygenerować w panelu administracyjnym w sekcji "Klucze API"'
        ),
      ],
      example: {
        code: t(
          `curl "https://${customPart}.weryfikacjapodpisu.pl/api/<PATH>" -H "api-auth: <API_KEY>"`
        ),
      },
    },
    verification: {
      title: t("Weryfikacja"),
      methods: {
        POST: {
          description: [
            t("Ten endpoint pozwala przesłać pliki do weryfikacji."),
            t(
              "Istnieje możliwość przesłania opcjonalnych tablic white_list i black_list z nazwami plików. Lista przesyłanych plików do weryfikatora będzie wtedy okrojona: dodane zostaną tylko pliki z white listy (jeżeli jest ustawiona) i usunięte pliki znajdujące się na black liście."
            ),
          ],
          httpRequest: `POST https://${customPart}.weryfikacjapodpisu.pl/api/verify`,
          parameters: [
            {
              name: "white_list",
              description: t(
                "tablica z nazwami plików, które powinny być zweryfikowane (parametr opcjonalny)"
              ),
            },
            {
              name: "black_list",
              description: t(
                "tablica z nazwami plików, które nie powinny być weryfikowane (parametr opcjonalny)"
              ),
            },
          ],
          example: {
            code: `curl -F "file[0]=@some_signed_file.xml" -F "file[1]=@signature.xml" -H "api-auth: <API_KEY>" https://${customPart}.weryfikacjapodpisu.pl/api/verify`,
          },
        },
      },
    },
    archivalDownloads: {
      title: t("Pobieranie Poświadczenia Weryfikacji"),
      methods: {
        GET: {
          description: [
            t(
              "Ten endpoint pozwala pobrać archiwalne wyniki przy pomocy identyfikatora weryfikacji."
            ),
            t(
              "Identyfikator weryfikacji można znaleźć w JSONie odpowiedzi serwera po weryfikacji w kluczu idRequest, wydruku PDF lub widoku HTML."
            ),
            t(
              "Istnieje możliwość pobrania wyniku weryfikacji w języku angielskim, należy w tym celu dodać parametr lang do zapytania, tak jak w przykładzie."
            ),
          ],
          httpRequest: `GET https://${customPart}.weryfikacjapodpisu.pl/api/v2/epw/<ID>.<TYPE>?lang=<LANG>`,
          parameters: [
            { name: "ID", description: t("Identyfikator weryfikacji") },
            {
              name: "TYPE",
              description: t("Typ pobieranego pliku (HTML, PDF lub JSON)"),
            },
            {
              name: "LANG",
              description: t(
                "Język wyniku weryfikacji (pl lub en), opcjonalny"
              ),
            },
          ],
          example: {
            code: `curl -H "api-auth: <API_KEY>" https://${customPart}.weryfikacjapodpisu.pl/api/v2/epw/6f19acdb9b0262b9074489d91da460a17fe0f93fb9462a5adf3193130a999dea.pdf?lang=pl`,
          },
        },
      },
    },
    contact: {
      title: t("Kontakt"),
      content: [
        t(
          "Jeśli napotkałeś problemy z naszym API, nie wahaj się skontaktować z nami! Jesteśmy tutaj, aby pomóc i zapewnić, że Twoje doświadczenie z naszym produktem jest jak najlepsze. Nasz zespół wsparcia technicznego jest dostępny, aby odpowiedzieć na Twoje pytania i rozwiązać wszelkie napotkane trudności. Skontaktuj się z nami przy użyciu formularza kontaktowego dostępnego na stronie weryfikacjapodpisu.pl, aby uzyskać szybką i profesjonalną pomoc!"
        ),
      ],
    },
  };

  return data;
};

export default () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { data, isLoading } = useTariffsQuery(organizationId!);

  if (isLoading || !data?.tariffs.current) {
    return null;
  }

  return (
    <ApiDocumentation data={docs(data?.tariffs.current?.iframeCustomUrl)} />
  );
};
