import { Link, useParams } from "react-router-dom";

import { Link as CLink } from "@chakra-ui/react";
import { settings } from "settings/getSettings";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";

interface IProps {
  verificationId: string;
}

export const VerificationLink = ({ verificationId }: IProps) => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const contextType = ContextMachineContext.useSelector(
    (state) => state.context.userInfo
  )?.currentlySelectedContext?.type;

  if (contextType === "context_user") {
    return (
      <CLink
        color="purple.500"
        as={Link}
        to={`/${organizationId}/weryfikacja?searchVerificationId=${verificationId}`}
      >
        {verificationId}
      </CLink>
    );
  }

  return (
    <CLink
      color="purple.500"
      href={`${settings.SAWPE_PUBLIC_WIDGET}?searchVerificationId=${verificationId}#dropzone`}
      isExternal
      target="_blank"
    >
      {verificationId}
    </CLink>
  );
};
