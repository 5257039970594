import { client } from "utils/http";

import { IUserInfo } from "../application/types/IUserInfo";

interface ILoginData {
  username: string;
  password: string;
}

export const login = async (loginData: ILoginData) => {
  return client.post<{ userInfo: IUserInfo }, ILoginData>(`auth/login`, {
    body: loginData,
  });
};
