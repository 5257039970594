import { http, HttpResponse } from "msw";

import { client, withHost } from "utils/http";

import { IUserInfo } from "../application/types/IUserInfo";
import { userInfoFixtures } from "./userInfoFixtures";

export const getUserInfoQuery = async () => {
  return client.get<{ userInfo: IUserInfo }>("auth/userInfo");
};

export const getUserInfoMock = (
  userInfo: IUserInfo = userInfoFixtures.systemAdmin
) => {
  return http.get(withHost("auth/userInfo"), () => {
    return HttpResponse.json<{ userInfo: IUserInfo }>({
      userInfo: userInfo,
    });
  });
};
