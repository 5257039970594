import { ReactNode, useState } from "react";

import {
  Box,
  BoxProps,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { t } from "i18next";

import { useChangelogQuery } from "modules/changelog/infrastructure/useChangelogQuery";
import { getTrackedChange } from "modules/dashboard/application/getTrackedChange";

interface IProps extends Omit<BoxProps, "children"> {
  children: (Function?: () => void) => ReactNode;
  save: () => void;
}

const NotReadDotConnected = ({ children, save, ...props }: IProps) => {
  const canShowPopover = useBreakpointValue({
    base: false,
    lg: true,
  });
  const [isVisible, setIsVisible] = useState(true);
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  return (
    <Box position="relative">
      {isVisible && (
        <Box
          position="absolute"
          top="10px"
          right="2"
          height="10px"
          width="10px"
          bg="red"
          rounded="full"
          zIndex="2"
          {...props}
        />
      )}
      <Popover isOpen={canShowPopover && isVisible && isOpen} onClose={onClose}>
        <PopoverTrigger>
          {children(() => {
            save();
            setIsVisible(false);
          })}
        </PopoverTrigger>
        <PopoverContent bgColor="purple.100">
          <PopoverArrow bgColor="purple.100" />
          <PopoverCloseButton />
          <PopoverHeader fontWeight="bold" border="0" fontSize="1rem">
            {t("Co nowego?")}
          </PopoverHeader>
          <PopoverBody>
            {t("Zobacz zmiany wprowadzone w najnowszej aktualizacji systemu.")}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export const NotReadDot = (props: Omit<IProps, "save">) => {
  const { data, isLoading } = useChangelogQuery();
  const latestSeenChange = localStorage.getItem("latestSeenChange");

  if (isLoading) {
    return props.children();
  }
  const publishedChanges = data.changelog.filter(
    ({ publishedAt }) => publishedAt && dayjs(publishedAt).isBefore(dayjs())
  );

  const latestUpdate = getTrackedChange(publishedChanges);

  if (!latestUpdate) {
    return props.children();
  }

  if (
    latestSeenChange &&
    !dayjs(latestSeenChange).isAfter(dayjs(latestUpdate.publishedAt!))
  ) {
    return props.children();
  }

  return (
    <NotReadDotConnected
      {...props}
      save={() => {
        localStorage.setItem("latestSeenChange", latestUpdate.publishedAt!);
      }}
    />
  );
};
