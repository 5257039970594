import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

export const useContinueNotificationProcess = (notificationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["continue-notification-process"],
    mutationFn: () => {
      return client.patch(`notificationsProcess/${notificationId}/continue`);
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["notifications-process"],
      });
    },
  });
};
