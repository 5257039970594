import { settings } from "settings/getSettings";

export const withHost = (path: string) => {
  return new URL(
    path,
    addTrailingSlash(settings.API_HOST ?? import.meta.env.VITE_APP_API_HOST)
  ).toString();
};

function addTrailingSlash(str: string): string {
  if (!str) {
    throw Error(
      "I couldn't locate the PREFIX_URL variable. Ensure it's configured through the .env or settings.json file, or is available as an environmental variable."
    );
  }
  if (!str.endsWith("/")) {
    return str + "/";
  }
  return str;
}
