import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

export const useStopNotificationProcess = (notificationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["stop-notification-process"],
    mutationFn: () => {
      return client.patch(`notificationsProcess/${notificationId}/stop`);
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["notifications-process"],
      });
    },
  });
};
