import { useNavigate } from "react-router-dom";

import { Button, Text } from "@chakra-ui/react";
import { t } from "i18next";

import { OpenInNewIcon } from "components/icons/OpenInNewIcon";

interface IProps {
  isActive: boolean;
  hasApiAccess: boolean;
  hasActiveTariff: boolean;
}

export const ApiDocsBtn = ({
  hasApiAccess,
  hasActiveTariff,
  isActive,
}: IProps) => {
  const navigate = useNavigate();

  if (!hasApiAccess || !hasActiveTariff || !isActive) {
    return null;
  }

  const handleRedirect = () => {
    navigate("api-doc");
  };

  return (
    <Button
      onClick={handleRedirect}
      variant="outline"
      size="sm"
      colorScheme="purple"
      rightIcon={<OpenInNewIcon size="18px" />}
    >
      <Text mt="1.5px">{t("Dokumentacja API")}</Text>
    </Button>
  );
};
