import { settings } from "settings/getSettings";

export const getToken = () => {
  // storybook
  if (!settings.API_HOST) {
    return "123";
  }

  const token = localStorage.getItem("token");

  return token;
};
