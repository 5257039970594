import { useForm, useWatch } from "react-hook-form";

import { VStack } from "@chakra-ui/react";
import { t } from "i18next";
import { HTTPError } from "ky";

import { toastWithError } from "components/ErrorHandling/toastWithError";
import { FormControl } from "components/Form/FormControl";
import { PasswordInput } from "components/Form/PasswordInput";

import { PasswordStrength } from "../../common/PasswordStrength";

export interface IUserFormData {
  password: string;
  repeatPassword: string;
}

interface IProps {
  onClose: () => void;
  onSubmit: (payload: IUserFormData) => Promise<void>;
  defaultData?: IUserFormData;
}

export const UserPasswordModalForm = ({
  onClose,
  onSubmit,
  defaultData,
}: IProps) => {
  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors },
    control,
  } = useForm<IUserFormData>({ defaultValues: { ...defaultData } });

  const password = useWatch({
    control,
    name: "password",
  });

  return (
    <form
      id="user-form"
      onSubmit={handleSubmit(async (data) => {
        try {
          await onSubmit(data);
          onClose();
        } catch (error) {
          if (error instanceof HTTPError) {
            const errorJson = await error.response.json<{
              message: string;
            }>();

            if (
              errorJson.message === "context.error.organizationDisabled" ||
              errorJson.message === "context.error.notAllowedForNewOrganization"
            ) {
              toastWithError({
                description: t("Podmiot jest nieaktywny."),
                error,
              });
              return;
            }
          }

          toastWithError({
            error,
          });
        }
      })}
    >
      <VStack align="stretch" spacing={6}>
        <FormControl
          formLabel={t("Hasło")}
          isRequired
          formErrorMessage={errors.password?.message}
        >
          <PasswordInput
            register={{
              ...register("password", {
                required: true,
                minLength: {
                  value: 5,
                  message: t("Hasło musi mieć co najmniej 5 znaków"),
                },
              }),
            }}
          />
          <PasswordStrength password={password} />
        </FormControl>
        <FormControl formLabel={t("Powtórz hasło")} isRequired>
          <PasswordInput
            register={{
              ...register("repeatPassword", {
                required: true,
                validate: (value) => {
                  if (value !== getValues("password")) {
                    return t("Hasła muszą być takie same");
                  }
                },
              }),
            }}
          />
        </FormControl>
      </VStack>
    </form>
  );
};
