import { IconButton, Tooltip } from "@chakra-ui/react";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";
import { PowerIcon } from "components/icons/PowerIcon";

import { ToggleOrganizationConfirm } from "./ToggleOrganizationConfirm";

interface IProps {
  isDisabled: boolean;
  isActive: boolean;
  organizationId: string;
  organizationName: string;
}

export const ToggleOrganizationButton = ({
  isDisabled,
  isActive,
  organizationId,
  organizationName,
}: IProps) => {
  const id = `toggle-organization-${organizationId}`;
  const onOpen = useOpenDialog(id);

  return (
    <>
      <ToggleOrganizationConfirm
        id={id}
        isActive={isActive}
        organizationId={organizationId}
        name={organizationName}
      />
      <Tooltip
        label={
          isDisabled
            ? t("Brak aktywnego pakietu")
            : isActive
              ? t("Dezaktywuj")
              : t("Aktywuj")
        }
      >
        <IconButton
          variant="link"
          colorScheme="purple"
          size="sm"
          data-testid="toggle-organization"
          isDisabled={isDisabled}
          onClick={onOpen}
          aria-label={isActive ? t("Dezaktywuj") : t("Aktywuj")}
          icon={<PowerIcon />}
        />
      </Tooltip>
    </>
  );
};
