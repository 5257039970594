import { useEffect } from "react";
import { useRouteError } from "react-router-dom";

import * as Sentry from "@sentry/react";
import { t } from "i18next";

import { EmptyState } from "./EmptyState";
import { NotFoundState } from "./NotFoundState";

export const ErrorState = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const error: any = useRouteError();

  useEffect(() => {
    if (error?.error) {
      Sentry.captureException(error.error);
    } else {
      Sentry.captureException(error);
    }
  }, [error]);

  if (error?.response?.status === 404 || error?.status === 404) {
    return <NotFoundState />;
  }

  return (
    <EmptyState
      title={t("Wystąpił błąd")}
      description={t("Skontaktuj się z administratorem")}
      mt={16}
    />
  );
};
