import { ComponentProps } from "react";

import { mdiAttachment } from "@mdi/js";

import { Icon } from "./Icon";

export const AttachmentIcon = (
  props: Omit<ComponentProps<typeof Icon>, "path">
) => {
  return <Icon {...props} path={mdiAttachment} />;
};
