import { Link, Text } from "@chakra-ui/react";
import { t } from "i18next";

export const Footnotes = () => {
  return (
    <Text mt={8} fontSize="sm">
      <sup>1</sup>{" "}
      {t(
        "RODO - Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego. przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) (Tekst mający znaczenie dla EOG)"
      )}{" "}
      <Link
        href="https://eur-lex.europa.eu/legal-content/PL/TXT/?uri=CELEX%3A32016R0679"
        isExternal
        target="_blank"
        color="purple.500"
      >
        eur-lex.europa.eu
      </Link>
    </Text>
  );
};
