import {
  Box,
  Card,
  CardBody,
  HStack,
  Skeleton,
  Tag,
  VStack,
} from "@chakra-ui/react";
import { t } from "i18next";

import {
  DescriptionList,
  DescriptionListItem,
  DescriptionListItemsInColumn,
} from "components/DescriptionList";
import { FormatDateTime } from "components/FormatDateTime";

import { NotificationProcess } from "modules/notifications/application/NotificationProcess";

interface IProps {
  isLoading: boolean;
  notificationProcess: NotificationProcess;
}

export const NotificationDetailsCard = ({
  isLoading,
  notificationProcess,
}: IProps) => {
  return (
    <Card variant="sawpe">
      <CardBody>
        {isLoading ? (
          <VStack align="stretch" gap="4">
            <Skeleton height="50px" />
            <Skeleton height="150px" />
          </VStack>
        ) : (
          <NotificationDetailsCardConnected
            selectedTariffs={notificationProcess.selectedTariffs}
            body={notificationProcess.body}
            completedAt={notificationProcess.completedAt}
            planedAt={notificationProcess.plannedSendAt}
            lockedAt={notificationProcess.lockedAt}
          />
        )}
      </CardBody>
    </Card>
  );
};

interface CartProps {
  selectedTariffs: string[];
  body: string;
  completedAt: string | null;
  planedAt: string | null;
  lockedAt: string | null;
}

const NotificationDetailsCardConnected = ({
  selectedTariffs,
  body,
  completedAt,
  planedAt,
  lockedAt,
}: CartProps) => {
  return (
    <DescriptionList>
      <DescriptionListItemsInColumn>
        <DescriptionListItem
          label={t("Adresaci ( według pakietów)")}
          value={
            <HStack>
              {selectedTariffs.map((tariff) => (
                <Tag key={tariff} colorScheme="purple">
                  {tariff}
                </Tag>
              ))}
            </HStack>
          }
        />
        {completedAt ? (
          <DescriptionListItem
            label={t("Termin wysyłki")}
            value={completedAt ? <FormatDateTime date={completedAt} /> : "---"}
          />
        ) : (
          <DescriptionListItem
            label={t("Planowana wysyłka")}
            value={
              planedAt ? (
                <FormatDateTime date={planedAt} />
              ) : (
                <FormatDateTime date={lockedAt!} />
              )
            }
          />
        )}
      </DescriptionListItemsInColumn>
      <DescriptionListItemsInColumn>
        <DescriptionListItem
          label={t("Treść")}
          value={
            <Box
              dangerouslySetInnerHTML={{ __html: body }}
              pl={4}
              sx={{
                ul: {
                  paddingLeft: "1.5rem",
                },
                ol: {
                  paddingLeft: "1.5rem",
                },
                a: {
                  textDecoration: "underline",
                  color: "blue.600",
                },
              }}
            />
          }
        />
      </DescriptionListItemsInColumn>
    </DescriptionList>
  );
};
