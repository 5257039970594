import { IAllTariffs } from "modules/tariffs/application/types/IAllTariffs";

import { OrganizationStatus } from "./types/IOrganization";

export const canToggleOrganization = (
  tariffs: IAllTariffs,
  organizationStatus: OrganizationStatus
) => {
  const currentTariff = tariffs.current;
  const nextTariff = tariffs.next;

  if (organizationStatus === "ACTIVE") {
    return true;
  }

  if (!currentTariff?.isActive && !nextTariff?.isActive) {
    return false;
  }

  return true;
};
