import dayjs from "dayjs";

interface IProps {
  date: string;
  seconds?: boolean;
}

export const FormatDateTime = ({ date, seconds = true }: IProps) => {
  if (!dayjs(date).isValid()) {
    return <>---</>;
  }

  if (!seconds) {
    return <>{dayjs(date).format("DD.MM.YYYY HH:mm")}</>;
  }

  return <>{dayjs(date).format("DD.MM.YYYY HH:mm:ss")}</>;
};
