import { useParams } from "react-router-dom";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

interface MutationVariables {
  name: string;
  surname: string;
  email: string;
  phone: string;
}

export const useAddManager = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["add-manager"],
    mutationFn: (variables: MutationVariables) => {
      return client.post(`organizations/${organizationId}/contexts/manager`, {
        body: { ...variables },
      });
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["organizations", organizationId, "contexts"],
      });
    },
  });
};
