import {
  createStandaloneToast,
  ToastId,
  UseToastOptions,
} from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import { t } from "i18next";

export async function toastWithError(
  options?: UseToastOptions & { error?: unknown }
): Promise<ToastId> {
  const handler = createStandaloneToast({
    defaultOptions: { variant: "subtle", status: "error" },
  });

  if (options?.error) {
    Sentry.captureException(options.error);

    return handler.toast({
      description: getUserErrorMessage(),
      ...options,
    });
  }

  return handler.toast({
    description: getUserErrorMessage(),
    ...options,
  });
}

const getUserErrorMessage = () => {
  return t("Wystąpił błąd. Skontaktuj się z administratorem.");
};
