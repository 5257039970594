import { IconButton, Tooltip } from "@chakra-ui/react";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";
import { EditIcon } from "components/icons/EditIcon";

import { IDefaultIntegrationKey } from "modules/integrationKey/application/types/IDefaultIntegrationKey";

import { KeyModal } from "./KeyModal";

interface IProps {
  integrationKey: IDefaultIntegrationKey;
}

export const EditKeyButton = ({ integrationKey }: IProps) => {
  const id = `edit-key-${integrationKey.id}`;

  const onOpen = useOpenDialog(id);

  const label = t("Edytuj");

  return (
    <>
      <KeyModal id={id} keyId={integrationKey.id} />
      <Tooltip label={label} aria-label={label}>
        <IconButton
          variant="link"
          colorScheme="purple"
          size="sm"
          data-testid="edit-default-integration-key"
          onClick={() =>
            onOpen({
              name: integrationKey.name,
              iframeUrl: integrationKey.iframeUrl,
            })
          }
          aria-label={label}
          icon={<EditIcon />}
        />
      </Tooltip>
    </>
  );
};
