import { ReactNode } from "react";

import { ChakraProps, Text } from "@chakra-ui/react";

import { NoDataIcon } from "components/icons/NoDataIcon";

import { EmptyState } from "./EmptyState";

interface Props extends ChakraProps {
  title: string;
  description?: string | ReactNode;
  variant?: "simple" | "dashed-area";
}

export const NoData = ({
  title = "Brak danych",
  description,
  variant,
}: Props) => {
  const variantProps =
    variant === "dashed-area"
      ? {
          border: "2px dashed var(--chakra-colors-gray-300)",
          py: 5,
          borderRadius: "md",
        }
      : {};

  return (
    <EmptyState
      title={
        <Text fontSize="md" fontWeight={700} textAlign={"center"}>
          {title}
        </Text>
      }
      description={description}
      icon={<NoDataIcon width="86px" />}
      size="xs"
      {...variantProps}
    />
  );
};
