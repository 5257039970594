import { useParams } from "react-router-dom";

import {
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Heading,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { t } from "i18next";

import { FormatDate } from "components/FormatDate";
import { DraftIcon } from "components/icons/DraftIcon";

import { ITariff } from "modules/tariffs/application/types/ITariff";
import { useAllTariffsQuery } from "modules/tariffs/infrastructure/useAllTariffsQuery";

import { TrueFalseIcons } from "../common/TrueFalseIcons";
import { PrepareTariffBtn } from "./PrepareTariffBtn";

interface IProps {
  tariff: ITariff;
}

const TariffDraftConnected = ({ tariff }: IProps) => {
  const diff = dayjs(tariff.validTo).diff(dayjs(), "day");

  return (
    <HStack spacing={4}>
      <DraftIcon h="78px" w="78px" p={3} color="purple.500" />
      <VStack spacing="0" align="stretch" fontSize="sm">
        <HStack>
          <Text fontWeight="600" fontSize="lg">
            {t("Pakiet {{size}}", { size: tariff.type })}
          </Text>
        </HStack>
        <HStack data-testid="api-access">
          <Text textColor="gray.600">{t("Dostęp do API")}</Text>
          <TrueFalseIcons isSelected={tariff.apiAccess} />
        </HStack>
        <HStack data-testid="ppdo">
          <Text textColor="gray.600">{t("PPDO")}</Text>
          <TrueFalseIcons isSelected={tariff.personalDataClause} />
        </HStack>
        <Text textColor="gray.600">
          {t("Preferowana data rozpoczęcia:")}{" "}
          <FormatDate date={tariff.validFrom} /> {t("do")}{" "}
          <FormatDate date={tariff.validTo} />{" "}
          {diff < 0
            ? t("(zakończono {{value, relativetime}})", {
                value: diff,
              })
            : t("(upływa {{value, relativetime}})", {
                value: diff,
              })}
        </Text>
      </VStack>
    </HStack>
  );
};

export const TariffDraft = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { data } = useAllTariffsQuery(organizationId!);

  if (!data?.tariffs.draft) {
    return null;
  }

  return (
    <Card id="tariff-draft" variant="sawpe">
      <CardHeader>
        <Heading fontSize="lg">{t("Propozycja klienta")}</Heading>
        <ButtonGroup spacing={3}>
          <PrepareTariffBtn tariff={data?.tariffs.draft} />
        </ButtonGroup>
      </CardHeader>
      <CardBody>
        <TariffDraftConnected tariff={data?.tariffs.draft} />
      </CardBody>
    </Card>
  );
};
