import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  InputGroup,
  MenuItem,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  VStack,
} from "@chakra-ui/react";
import { t } from "i18next";

import { Modal, useOpenDialog } from "components/Dialog";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";
import { IContext } from "modules/auth/application/types/IContext";

export const ChangeContextButton = () => {
  const id = "change-context-modal";
  const onOpen = useOpenDialog(id);
  const { userInfo } = ContextMachineContext.useSelector(
    (state) => state.context
  );

  // dostępny jest też aktualny kontekst, dlatego < 2
  if (!userInfo?.availableContexts || userInfo.availableContexts.length < 2) {
    return null;
  }

  return (
    <>
      <ChangeContextModal
        id={id}
        availableContexts={userInfo.availableContexts}
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        currentContextId={userInfo.currentlySelectedContext?.id!}
      />
      <MenuItem onClick={onOpen}>{t("Przełącz użytkownika")}</MenuItem>
    </>
  );
};

interface IProps {
  id: string;
  availableContexts: IContext[];
  currentContextId: string;
}

const ChangeContextModal = ({
  id,
  availableContexts,
  currentContextId,
}: IProps) => {
  const [selectedContext, setSelectedContext] =
    useState<string>(currentContextId);

  const navigate = useNavigate();
  const { send } = ContextMachineContext.useActorRef();
  const value = ContextMachineContext.useSelector((state) => state.value);

  const isLoading = value === "changeContext";

  return (
    <Modal id={id}>
      {({ onClose }) => {
        return (
          <>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{t("Przełącz użytkownika")}</ModalHeader>
              <ModalBody as={VStack} align="stretch" spacing={6}>
                <Select
                  value={selectedContext}
                  onChange={(e) => {
                    setSelectedContext(e.target.value);
                  }}
                >
                  {availableContexts.map((context) => (
                    <option key={context.id} value={context.id}>
                      {context.name}
                    </option>
                  ))}
                </Select>
              </ModalBody>
              <ModalFooter>
                <InputGroup gap="2" justifyContent="end">
                  <Button onClick={onClose} isLoading={isLoading}>
                    {t("Anuluj")}
                  </Button>
                  <Button
                    colorScheme="purple"
                    isLoading={isLoading}
                    onClick={() => {
                      send({
                        type: "relog",
                        contextId: selectedContext,
                      });
                      onClose();
                      navigate("/");
                    }}
                  >
                    {t("Przełącz")}
                  </Button>
                </InputGroup>
              </ModalFooter>
            </ModalContent>
          </>
        );
      }}
    </Modal>
  );
};
