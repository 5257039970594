import {
  Button,
  InputGroup,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { useIsMutating } from "@tanstack/react-query";
import { t } from "i18next";

import { Modal } from "components/Dialog";

import { useChangeUserPassword } from "modules/organization/infrastructure/useChangeUserPassword";

import {
  ChangeUserPasswordForm,
  IChangePasswordFormData,
} from "./ChangeUserPasswordForm";

interface IProps {
  id: string;
  contextId: string;
}

export const ChangeUserPasswordModal = ({ id, contextId }: IProps) => {
  const { mutateAsync: changePassword } = useChangeUserPassword(contextId);
  const isMutating = useIsMutating({
    mutationKey: ["change-user-password"],
  });

  return (
    <Modal id={id}>
      {({ onClose }) => {
        return (
          <>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{t("Zmień hasło")}</ModalHeader>
              <ModalBody as={VStack} align="stretch" spacing={6}>
                <ChangeUserPasswordForm
                  onClose={onClose}
                  onSubmit={async (data: IChangePasswordFormData) => {
                    await changePassword(data);
                  }}
                />
              </ModalBody>
              <ModalFooter>
                <InputGroup gap="2" justifyContent="end">
                  <Button onClick={onClose} isLoading={!!isMutating}>
                    {t("Anuluj")}
                  </Button>
                  <Button
                    colorScheme="purple"
                    type="submit"
                    data-testid="change-password-button"
                    form="change-password-form"
                    isLoading={!!isMutating}
                  >
                    {t("Zmień")}
                  </Button>
                </InputGroup>
              </ModalFooter>
            </ModalContent>
          </>
        );
      }}
    </Modal>
  );
};
