// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createFormData = (obj: any, data = new FormData()) => {
  const formData = data;

  const fillFormData = (obj: Record<string, unknown>, subKeyStr = "") => {
    for (const i in obj) {
      const value = obj[i];
      const subKeyStrTrans = subKeyStr ? subKeyStr + "[" + i + "]" : i;

      if (typeof value === "string" || typeof value === "number") {
        formData.append(subKeyStrTrans, value as string);
      } else if (typeof value === "object" && value !== null) {
        fillFormData(value as Record<string, unknown>, subKeyStrTrans);
      }
    }
  };

  fillFormData(obj);

  return formData;
};
