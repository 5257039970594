import { useForm } from "react-hook-form";

import { Input, VStack } from "@chakra-ui/react";
import { t } from "i18next";
import { HTTPError } from "ky";

import { toastWithError } from "components/ErrorHandling/toastWithError";
import { FormControl } from "components/Form/FormControl";

export interface IUserFormData {
  accountName: string;
  login: string;
}

interface IProps {
  onClose: () => void;
  onSubmit: (payload: IUserFormData) => Promise<void>;
  defaultData: IUserFormData;
}

export const EditUserModalForm = ({
  onClose,
  onSubmit,
  defaultData,
}: IProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
  } = useForm<IUserFormData>({
    defaultValues: defaultData,
  });

  return (
    <form
      id="user-form"
      onSubmit={handleSubmit(async (data) => {
        try {
          await onSubmit(data);
          onClose();
        } catch (error) {
          if (error instanceof HTTPError) {
            const errorJson = await error.response.json<{
              message: string;
            }>();

            if (errorJson.message === "context.error.nonUniqueLogin") {
              setError("login", {
                type: "manual",
                message: t("Podany login jest już zajęty"),
              });
              return;
            }

            if (
              errorJson.message === "context.error.organizationDisabled" ||
              errorJson.message === "context.error.notAllowedForNewOrganization"
            ) {
              toastWithError({
                description: t("Podmiot jest nieaktywny."),
                error,
              });
              return;
            }
          }

          toastWithError({
            error,
          });
        }
      })}
    >
      <VStack align="stretch" spacing={6}>
        <FormControl
          formLabel={t("Nazwa konta")}
          isRequired
          formErrorMessage={errors.accountName?.message}
          formHelperText={t(
            "Nazwa konta jest widoczna tylko dla Ciebie oraz prezentowana będzie na liście zdarzeń. Może to być imię i nazwisko użytkownik, nazwa oddziału organizacji czy działu np. Księgowość Północ, Jan Kowalski, Oddział w Niemczech."
          )}
        >
          <Input {...register("accountName", { required: true })} />
        </FormControl>
        <FormControl
          formLabel={t("Login")}
          isRequired
          formErrorMessage={errors.login?.message}
          formHelperText={t(
            "Login (unikalny w systemie) wraz z hasłem pozwala zalogować się w systemie weryfikacjapodpisu.pl w celu korzystania z usług serwisu. Przekaż te dane w sposób bezpieczny każdemu użytkownikowi."
          )}
        >
          <Input {...register("login", { required: true })} />
        </FormControl>
      </VStack>
    </form>
  );
};
