import { NotFoundIcon } from "components/icons/NotFoundIcon";

import { EmptyState } from "./EmptyState";

export const NotFoundState = () => {
  return (
    <EmptyState
      title="Nie znaleziono strony"
      description="Skontaktuj się z administratorem"
      icon={<NotFoundIcon />}
      mt={16}
    />
  );
};
