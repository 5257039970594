import { QueryClient } from "@tanstack/react-query";
import { HttpResponse, http } from "msw";

import { client, withHost } from "utils/http";
import { useQuery } from "utils/useQuery";

import { ISystemEvent } from "../application/types/ISystemEvent";

const query = () => {
  return {
    queryKey: ["system", "events"],
    queryFn: () => client.get<{ events: ISystemEvent[] }>(`system/events`),
  };
};
export const getSystemEventsQuery = (queryClient: QueryClient) => {
  return queryClient.ensureQueryData(query());
};

export const useSystemEventsQuery = () => {
  return useQuery(query());
};

export const getSystemEventsMock = (events: ISystemEvent[] = []) => {
  return http.get(withHost("system/events"), () => {
    return HttpResponse.json<{ events: ISystemEvent[] }>({
      events: [...events],
    });
  });
};
