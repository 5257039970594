import { HStack, Spacer, Text, VStack } from "@chakra-ui/react";
import { t } from "i18next";

import { FormatDateTime } from "components/FormatDateTime";
import { CalendarIcon } from "components/icons/CalendarIcon";
import { HistoryIcon } from "components/icons/HistoryIcon";

import { Change } from "modules/changelog/application/types/Change";

interface IProps {
  trackedChange: Change | null;
}

export const TrackedChange = ({ trackedChange }: IProps) => {
  if (!trackedChange) {
    return (
      <VStack py={6}>
        <HistoryIcon size={"32px"} />
        <Text color="gray.600" textAlign="center">
          {t("Brak opublikowanych zmian")}
        </Text>
      </VStack>
    );
  }

  return (
    <VStack py={3} align="stretch">
      <Text mt={-2} fontSize="sm" color="gray.500">
        {t("Ostatnio opublikowana zmiana")}
      </Text>
      <Text fontWeight="semibold" fontSize="lg" isTruncated>
        {trackedChange.title}
      </Text>
      <HStack>
        <CalendarIcon size="18px" color="purple.500" />
        <Text color="gray.600">{t("Data publikacji")}</Text>
        <Spacer />
        <Text fontWeight="semibold">
          <FormatDateTime date={trackedChange.publishedAt!} seconds={false} />
        </Text>
      </HStack>
    </VStack>
  );
};
