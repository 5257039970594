import { withTranslation } from "react-i18next";

import { Card, CardBody, Heading, Stack, VStack } from "@chakra-ui/react";
import { t } from "i18next";

import { ChangelogDrafts } from "modules/changelog/presentation/ChangelogDrafts";
import { ChangelogForm } from "modules/changelog/presentation/ChangelogForm";
import { ChangelogList } from "modules/changelog/presentation/ChangelogList";

export const Changelog = withTranslation()(() => {
  return (
    <VStack align="stretch" gap="8">
      <Card>
        <CardBody px={4} py={6}>
          <Heading fontSize="lg">{t("Changelog")}</Heading>
        </CardBody>
      </Card>
      <VStack gap="6" align="stretch">
        <Stack direction={{ base: "column", lg: "row" }} gap="6">
          <ChangelogForm flexBasis={{ base: "100%", lg: "70%" }} />
          <ChangelogDrafts flexBasis={{ base: "100%", lg: "30%" }} />
        </Stack>
        <ChangelogList />
      </VStack>
    </VStack>
  );
});
