import { client } from "utils/http";

import { IContext } from "../application/types/IContext";

interface ILoginData {
  contextId: string;
}

export const relogin = async (loginData: ILoginData) => {
  return client.put<{ currentlySelectedContext: IContext }, ILoginData>(
    `auth/changeContext`,
    {
      body: loginData,
    }
  );
};
