import { Control, useController } from "react-hook-form";

import { Button, HStack, Tag, TagLabel, VStack } from "@chakra-ui/react";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";
import { FormControl } from "components/Form/FormControl";

import { TariffTypes } from "modules/tariffs/application/types/ITariff";

import { NotificationFormData } from "./NotificationFormData";
import { RecipientPreviewModal } from "./RecipientPreviewModal";

interface IProps {
  control: Control<NotificationFormData>;
}

const id = "notification-preview-dialog";

export const TariffsTags = ({ control }: IProps) => {
  const tags: TariffTypes[] = ["S", "M", "L", "E"];
  const {
    field: { value, onChange },
    formState: { errors },
  } = useController({
    name: "tariffs",
    control,
    defaultValue: [],
    rules: { required: t("Pole jest wymagane.") },
  });

  const onOpen = useOpenDialog<TariffTypes[]>(id);

  return (
    <>
      <RecipientPreviewModal id={id} />
      <VStack align="start">
        <FormControl
          formLabel={t("Adresaci (według pakietów)")}
          isRequired
          formErrorMessage={errors.tariffs?.message}
        >
          <HStack>
            <Tag
              variant={value.length === tags.length ? "solid" : "outline"}
              colorScheme="purple"
              cursor="pointer"
              onClick={() => {
                if (value.length === tags.length) {
                  onChange([]);
                  return;
                }
                onChange(tags);
              }}
            >
              {t("Wszystkie")}
            </Tag>
            {tags.map((tag) => (
              <Tag
                key={tag}
                variant={value.includes(tag) ? "solid" : "outline"}
                colorScheme="purple"
                cursor="pointer"
                onClick={() => {
                  if (value.includes(tag)) {
                    onChange(value.filter((t) => t !== tag));
                    return;
                  }

                  onChange([...value, tag]);
                }}
              >
                <TagLabel>{tag}</TagLabel>
              </Tag>
            ))}
          </HStack>
        </FormControl>
        {value.length > 0 && (
          <Button
            colorScheme="purple"
            variant="link"
            size="sm"
            onClick={() => {
              onOpen(value);
            }}
          >
            {t("Podgląd")}
          </Button>
        )}
      </VStack>
    </>
  );
};
