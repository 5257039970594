import { ReactNode } from "react";

import {
  FormControl as CFormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/react";

interface Props extends FormControlProps {
  formLabel: ReactNode;
  formHelperText?: ReactNode | undefined;
  formErrorMessage?: ReactNode | undefined;
}

export const FormControl = ({
  children,
  formLabel,
  formHelperText,
  formErrorMessage,
  ...props
}: Props) => {
  return (
    <CFormControl {...props} isInvalid={!!formErrorMessage}>
      <FormLabel>{formLabel}</FormLabel>
      {children}
      {formHelperText && <FormHelperText>{formHelperText}</FormHelperText>}
      {formErrorMessage && (
        <FormErrorMessage>{formErrorMessage}</FormErrorMessage>
      )}
    </CFormControl>
  );
};
