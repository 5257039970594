import { Button } from "@chakra-ui/react";
import { t } from "i18next";

import { toastWithError } from "components/ErrorHandling/toastWithError";

import { useRemoveChange } from "../infrastructure/useRemoveChange";

interface IProps {
  changeId: string;
}

export const DeleteChangeButon = ({ changeId }: IProps) => {
  const { mutateAsync: deleteChange } = useRemoveChange(changeId);

  const handleClick = async () => {
    try {
      await deleteChange();
    } catch (error) {
      toastWithError({
        error,
      });
    }
  };

  return <Button onClick={handleClick}>{t("Usuń")}</Button>;
};
