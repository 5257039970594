import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

interface MutationVariables {
  isActive: boolean;
}

export const useToggleOrganizationActivity = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["toggle-organization"],
    mutationFn: (variables: MutationVariables) => {
      if (variables.isActive) {
        return client.post(`organizations/${organizationId}/disable`);
      }

      return client.post(`organizations/${organizationId}/activate`);
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["organizations"],
      });
    },
  });
};
