import { Tag } from "@chakra-ui/react";
import { t } from "i18next";

interface IProps {
  sent: boolean;
  failed: boolean;
}

export const EmailStatusBadge = ({ sent, failed }: IProps) => {
  if (sent) {
    return (
      <Tag size="sm" colorScheme="green">
        {t("Wysłane")}
      </Tag>
    );
  }

  if (failed) {
    return (
      <Tag size="sm" colorScheme="red" minW="max-content">
        {t("Błąd wysyłki")}
      </Tag>
    );
  }

  return (
    <Tag size="sm" colorScheme="orange">
      {t("Niewysłane")}
    </Tag>
  );
};
