import { useEffect, useRef } from "react";

import { useToken } from "@chakra-ui/react";
import { Chart, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { t } from "i18next";

Chart.register(...registerables);
Chart.register(ChartDataLabels);

interface IProps {
  data: number[];
}

export const InterpretationChart = ({ data }: IProps) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartId = useRef<string | null>(null);
  const [purple500] = useToken("colors", ["purple.500"]);

  useEffect(() => {
    if (chartId.current !== null || !chartRef.current) {
      return;
    }

    const chart = new Chart(chartRef.current, {
      type: "bar",
      data: {
        labels: [
          t("Styczeń"),
          t("Luty"),
          t("Marzec"),
          t("Kwiecień"),
          t("Maj"),
          t("Czerwiec"),
          t("Lipiec"),
          t("Sierpień"),
          t("Wrzesień"),
          t("Październik"),
          t("Listopad"),
          t("Grudzień"),
        ],
        datasets: [
          {
            label: t("Wykonane zapytania"),
            backgroundColor: purple500,
            data,
            borderWidth: 1,
            datalabels: {
              align: "end",
              anchor: "start",
            },
          },
        ],
      },
      plugins: [ChartDataLabels],
      options: {
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              stepSize: 1,
            },
          },
        },
        plugins: {
          datalabels: {
            color: "Black",
            font: {
              weight: "bold",
            },
            display: function (context) {
              const data = context.dataset.data[context.dataIndex] as number;
              return data > 0;
            },
            formatter: Math.round,
          },
        },
      },
    });

    chartId.current = chart.id;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <canvas id="11" ref={chartRef}></canvas>;
};
