import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

interface MutationVariables {
  name: string;
  organizationId: string;
  additionalInformation: string;
  iframeUrl: string;
}

export const useAddIntegrationKey = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["add-integration-key"],
    mutationFn: (variables: MutationVariables) => {
      return client.post(`integrationKeys`, {
        body: { ...variables },
      });
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["integration-keys"],
      });
    },
  });
};
