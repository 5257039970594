import { ReactNode } from "react";

import { Modal as CModal, ModalProps } from "@chakra-ui/react";

import { useDialogPayload } from "./useDialogPayload";
import { useDialogStore } from "./useDialogStore";

type ChildrenFn<P> = (args: { payload: P; onClose(): void }) => ReactNode;

interface Props<P> extends Partial<Omit<ModalProps, "children">> {
  id: string;
  children: ReactNode | ChildrenFn<P>;
}

export const Modal = <P,>({
  children,
  size = "2xl",
  isOpen: isOpenProp,
  onClose: onCloseProp,
  ...props
}: Props<P>) => {
  const isOpen = useDialogStore(
    (state) => isOpenProp ?? state.isOpen(props.id)
  );
  const onClose = useDialogStore(
    (state) => () => (onCloseProp ? onCloseProp() : state.onClose(props.id))
  );
  const payload = useDialogPayload<P>(props.id);

  return (
    <CModal
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size={size}
      {...props}
    >
      {typeof children === "function"
        ? children({ payload, onClose })
        : children}
    </CModal>
  );
};
