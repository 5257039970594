import { withTranslation } from "react-i18next";

import { Box, VStack } from "@chakra-ui/react";

import { SAWPEIcon } from "components/icons/SAWPEIcon";

import { Footer } from "modules/auth/presentation/Footer";
import { LoginForm } from "modules/auth/presentation/LoginForm";

export const Login = withTranslation()(() => {
  return (
    <VStack minH="100vh" justifyContent="center" spacing={8} pb="120px">
      <Box w={[330, 420]}>
        <SAWPEIcon height="71px" width="100%" />
      </Box>
      <LoginForm />
      <Footer />
    </VStack>
  );
});
