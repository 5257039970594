import { QueryClient } from "@tanstack/react-query";
import { http, HttpResponse } from "msw";

import { client, withHost } from "utils/http";
import { useQuery } from "utils/useQuery";

import { IInterpretation } from "../application/types/IInterpretation";

const query = (organizationId: string) => {
  return {
    queryKey: ["support", organizationId, "verificationInterpretations"],
    queryFn: () =>
      client.get<{ interpretations: IInterpretation[] }>(
        `support/verificationInterpretations/${organizationId}`
      ),
  };
};

export const getOrganizationVerificationInterpretationsQuery =
  (queryClient: QueryClient) => (organizationId: string) => {
    return queryClient.ensureQueryData(query(organizationId));
  };

export const useOrganizationVerificationInterpretationsQuery = (
  organizationId: string
) => {
  return useQuery(query(organizationId));
};

export const getOrganizationVerificationInterpretationsMock = (
  interpretations: IInterpretation[] = []
) => {
  return http.get(
    withHost("support/verificationInterpretations/:organizationId"),
    () => {
      return HttpResponse.json<{
        interpretations: IInterpretation[];
      }>({
        interpretations: [...interpretations],
      });
    }
  );
};
