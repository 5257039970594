import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

export const useRemoveChange = (changeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["remove-change"],
    mutationFn: () => {
      return client.delete(`changelog/${changeId}`);
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["changelog"],
      });
    },
  });
};
