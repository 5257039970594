import { useParams } from "react-router-dom";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

interface MutationVariables {
  contextId: string;
}

export const useRemoveManager = () => {
  const queryClient = useQueryClient();
  const { organizationId } = useParams<{ organizationId: string }>();

  return useMutation({
    mutationKey: ["remove-user"],
    mutationFn: (variables: MutationVariables) => {
      return client.delete(
        `organizations/${organizationId}/contexts/${variables.contextId}`
      );
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["organizations", organizationId, "contexts"],
      });
    },
  });
};
