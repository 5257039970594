import {
  Button,
  InputGroup,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { useIsMutating } from "@tanstack/react-query";
import { t } from "i18next";

import { Modal, useOpenDialog } from "components/Dialog";
import { toastWithError } from "components/ErrorHandling/toastWithError";

import { useAddTariff } from "modules/tariffs/infrastructure/useAddTariff";

import { ITariffFormData, TariffModalForm } from "../common/TariffModalForm";

const id = "prepare-tariff";

export const useOpenPrepareTariffModal = () => {
  return useOpenDialog<ITariffFormData>(id);
};

export const PrepareTariffModal = () => {
  const { mutateAsync: addTariff } = useAddTariff();

  const isMutating = useIsMutating({
    mutationKey: ["edit-tariff"],
  });

  return (
    <Modal<ITariffFormData> id={id}>
      {({ onClose, payload }) => {
        return (
          <>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{t("Przygotuj pakiet")}</ModalHeader>
              <ModalBody as={VStack} align="stretch" spacing={6}>
                <TariffModalForm
                  onClose={onClose}
                  onSubmit={async (data: ITariffFormData) => {
                    try {
                      await addTariff(data);
                    } catch (error) {
                      toastWithError({
                        description: t(
                          "Nie udało się przygotować pakietu. Skontaktuj się z administratorem."
                        ),
                        error,
                      });
                    }
                  }}
                  defaultData={payload}
                />
              </ModalBody>
              <ModalFooter>
                <InputGroup gap="2" justifyContent="end">
                  <Button onClick={onClose} isLoading={!!isMutating}>
                    {t("Anuluj")}
                  </Button>
                  <Button
                    colorScheme="purple"
                    type="submit"
                    form="tariff-form"
                    data-testid="save-tariff-changes-button"
                    isLoading={!!isMutating}
                  >
                    {t("Zapisz")}
                  </Button>
                </InputGroup>
              </ModalFooter>
            </ModalContent>
          </>
        );
      }}
    </Modal>
  );
};
