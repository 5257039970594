import {
  Button,
  InputGroup,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { useIsMutating } from "@tanstack/react-query";
import { t } from "i18next";

import { Modal } from "components/Dialog";

import { useEditManager } from "modules/organization/infrastructure/useEditManager";

import {
  IManagerFormData,
  ManagerModalForm,
} from "../../common/ManagerModalForm";

interface IProps {
  id: string;
  contextId: string;
}

type FormData = Omit<IManagerFormData, "email">;

export const EditManagerModal = ({ id, contextId }: IProps) => {
  const { mutateAsync: editManager } = useEditManager(contextId);

  const isMutating = useIsMutating({
    mutationKey: ["edit-manager"],
  });

  return (
    <Modal<FormData> id={id}>
      {({ onClose, payload }) => {
        return (
          <>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{t("Edytuj administratora")}</ModalHeader>
              <ModalBody as={VStack} align="stretch" spacing={6}>
                <ManagerModalForm
                  onClose={onClose}
                  onSubmit={async (data: IManagerFormData) => {
                    await editManager(data);
                  }}
                  defaultData={payload}
                />
              </ModalBody>
              <ModalFooter>
                <InputGroup gap="2" justifyContent="end">
                  <Button onClick={onClose} isLoading={!!isMutating}>
                    {t("Anuluj")}
                  </Button>
                  <Button
                    colorScheme="purple"
                    type="submit"
                    form="manager-form"
                    data-testid="add-manager-button"
                    isLoading={!!isMutating}
                  >
                    {t("Zapisz")}
                  </Button>
                </InputGroup>
              </ModalFooter>
            </ModalContent>
          </>
        );
      }}
    </Modal>
  );
};
