import { useForm } from "react-hook-form";

import { Heading, Input, Textarea, VStack } from "@chakra-ui/react";
import { t } from "i18next";
import { HTTPError } from "ky";

import { toastWithError } from "components/ErrorHandling/toastWithError";
import { FormControl } from "components/Form/FormControl";

import { OrganizationSelect } from "./OrganizationSelect";

export interface IKeyFormData {
  name: string;
  organizationId: string;
  additionalInformation: string;
  iframeUrl: string;
}

interface IProps {
  onClose: () => void;
  onSubmit: (payload: IKeyFormData) => Promise<void>;
  organizationName?: string;
  defaultData?: IKeyFormData;
  editMode?: boolean;
}

export const IntegrationKeyForm = ({
  onSubmit,
  onClose,
  organizationName,
  defaultData,
  editMode,
}: IProps) => {
  const { handleSubmit, register } = useForm<IKeyFormData>({
    defaultValues: defaultData,
  });

  return (
    <form
      id="key-form"
      onSubmit={handleSubmit(async (data) => {
        try {
          await onSubmit(data);
          onClose();
        } catch (error) {
          if (error instanceof HTTPError) {
            const errorJson = await error.response.json<{
              message: string;
            }>();

            if (errorJson.message === "integrationKey.error.keyAlreadyExists") {
              toastWithError({
                description: t("Klucz dla tej organizacji już istnieje."),
                error,
              });
              return;
            }
          }

          toastWithError({
            error,
          });
        }
      })}
    >
      <VStack align="stretch" spacing={6}>
        {organizationName && (
          <Heading
            textAlign="center"
            size="md"
            color="gray.600"
            fontWeight="500"
          >
            {organizationName}
          </Heading>
        )}
        <FormControl formLabel={t("Adres instancji")} isRequired>
          <Input {...register("name", { required: true })} />
        </FormControl>
        {!editMode && (
          <OrganizationSelect
            register={{
              ...register("organizationId", { required: true }),
            }}
          />
        )}
        <FormControl
          formLabel={t("Iframe URL")}
          formHelperText={t(
            "Bez podania tego adresu aktywacja klucza integracyjnego nie będzie możliwa."
          )}
        >
          <Input {...register("iframeUrl")} />
        </FormControl>
        <FormControl formLabel={t("Komentarz")}>
          <Textarea {...register("additionalInformation")} />
        </FormControl>
      </VStack>
    </form>
  );
};
