import {
  Box,
  Card,
  CardBody,
  CardHeader,
  CardProps,
  Grid,
  Heading,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";

import { EmptyState } from "components/EmptyStates/EmptyState";
import { StackedList } from "components/StackedList";
import { StackedListItem } from "components/StackedList/StackedListItem";
import { NoDataIcon } from "components/icons/NoDataIcon";

import { Change } from "../application/types/Change";
import { useChangelogQuery } from "../infrastructure/useChangelogQuery";
import { DeleteChangeButon } from "./DeleteChangeButon";
import { EditChangeButon } from "./EditChangeButon";

interface IProps {
  isLoading: boolean;
  changes: Change[];
}

const ChangelogDraftList = ({ isLoading, changes }: IProps) => {
  if (isLoading) {
    return (
      <StackedList>
        <Skeleton h="140px" />
        <Skeleton h="140px" />
        <Skeleton h="140px" />
      </StackedList>
    );
  }

  if (changes.length === 0) {
    return (
      <Grid placeItems="center" h="100%" w="100%">
        <EmptyState
          icon={<NoDataIcon />}
          size="xs"
          title={<Text fontWeight="500">{t("Brak wersji roboczych")}</Text>}
        />
      </Grid>
    );
  }

  const sortedRecords = reverse(
    sortBy(changes, (change) => {
      return new Date(change.updatedAt || change.createdAt);
    })
  );

  return (
    <StackedList maxH="620px">
      {sortedRecords.map((change) => {
        return (
          <StackedListItem
            key={change.id}
            title={change.title}
            actions={
              <>
                <EditChangeButon changeId={change.id} />
                <DeleteChangeButon changeId={change.id} />
              </>
            }
          >
            <Box
              dangerouslySetInnerHTML={{ __html: change.content }}
              sx={{
                // https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-line-clamp
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: "2",
                overflow: "hidden",

                ul: {
                  paddingLeft: "1.5rem",
                },
                ol: {
                  paddingLeft: "1.5rem",
                },
              }}
            />
          </StackedListItem>
        );
      })}
    </StackedList>
  );
};

export const ChangelogDrafts = (props: CardProps) => {
  const { data, isLoading } = useChangelogQuery();

  return (
    <Card variant="sawpe" {...props} minW="315px">
      <CardHeader>
        <Heading fontSize="lg">{t("Wersje robocze")}</Heading>
      </CardHeader>
      <CardBody>
        <ChangelogDraftList
          isLoading={isLoading}
          changes={data?.changelog.filter(({ publishedAt }) => !publishedAt)}
        />
      </CardBody>
    </Card>
  );
};
