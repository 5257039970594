import { Link, Text, VStack } from "@chakra-ui/react";
import { t } from "i18next";

export const Footer = () => {
  return (
    <VStack color="gray.500" spacing="1" position="absolute" bottom="6" px="4">
      <Text textAlign="center">
        {t("Madkom SA 2009 - {{currentYear}} Wszelkie prawa zastrzeżone.", {
          currentYear: new Date().getFullYear(),
        })}
      </Text>
      <Link href="www.madkom.pl">www.madkom.pl</Link>
    </VStack>
  );
};
