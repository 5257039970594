import { Tooltip } from "@chakra-ui/react";
import { t } from "i18next";

import { KeyIcon } from "components/icons/KeyIcon";

import { ITariff } from "modules/tariffs/application/types/ITariff";

interface IProps {
  tariff: ITariff | null;
}

export const IntegrationStatus = ({ tariff }: IProps) => {
  if (tariff?.type !== "E") {
    return null;
  }

  if (!tariff?.iframeCustomUrl) {
    return (
      <Tooltip label={t("Ten podmiot nie ma aktywnego klucza integracyjnego")}>
        <span>
          <KeyIcon pl="1" height="14px" color="red.600" />
        </span>
      </Tooltip>
    );
  }

  return (
    <Tooltip label={t("Klucz integracyjny jest aktywny")}>
      <span>
        <KeyIcon pl="1" height="14px" color="green.600" />
      </span>
    </Tooltip>
  );
};
