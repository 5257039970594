import { QueryClient } from "@tanstack/react-query";
import { http, HttpResponse } from "msw";

import { client, withHost } from "utils/http";
import { useQuery } from "utils/useQuery";

import { IIntegrationKey } from "../application/types/IIntegrationKey";

const query = () => {
  return {
    queryKey: ["integration-keys"],
    queryFn: () =>
      client.get<{ integrationKeys: IIntegrationKey[] }>(`integrationKeys`),
  };
};

export const getIntegrationKeysQuery = (queryClient: QueryClient) => () => {
  return queryClient.ensureQueryData(query());
};

export const useIntegrationKeysQuery = () => {
  return useQuery(query());
};

export const getIntegrationKeysMock = (
  integrationKeys: IIntegrationKey[] = []
) => {
  return http.get(withHost("integrationKeys"), () => {
    return HttpResponse.json<{ integrationKeys: IIntegrationKey[] }>({
      integrationKeys: [...integrationKeys],
    });
  });
};
