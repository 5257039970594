import { Button } from "@chakra-ui/react";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";
import { PlusIcon } from "components/icons/PlusIcon";

import { AddSystemContextModal } from "./AddSystemContextModal";

interface IProps {
  type: "system_admin" | "system_manager";
}

export const AddSystemContextButton = ({ type }: IProps) => {
  const id = `add-system-context-${type}`;
  const onOpen = useOpenDialog(id);

  return (
    <>
      <AddSystemContextModal id={id} type={type} />
      <Button
        size="sm"
        leftIcon={<PlusIcon />}
        colorScheme="purple"
        onClick={onOpen}
      >
        {type === "system_manager"
          ? t("Dodaj zarządcę")
          : t("Dodaj administratora")}
      </Button>
    </>
  );
};
