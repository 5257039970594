import { useController, useForm, useWatch } from "react-hook-form";

import {
  Alert,
  Button,
  Input,
  InputGroup,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useIsMutating } from "@tanstack/react-query";
import { t } from "i18next";

import { maxLength } from "utils/form/maxLength";
import { minLength } from "utils/form/minLength";

import { Modal } from "components/Dialog";
import { toastWithError } from "components/ErrorHandling/toastWithError";
import { FileUploader } from "components/Form/FileUploader";
import { FormControl } from "components/Form/FormControl";

import { useSendInterpretationRequest } from "modules/verification/infrastructure/useSendInterpretationRequest";

interface FormValues {
  name: string;
  surname: string;
  email: string;
  phone: string;
  message: string;
  files: File[];
}

interface IProps {
  id: string;
  verificationId: string;
}

export const InterpretationRequestModal = ({ verificationId, id }: IProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<FormValues>();
  const toast = useToast({ variant: "subtle" });

  const { mutateAsync: sendInterpretationRequest } =
    useSendInterpretationRequest(verificationId);
  const isMutating = useIsMutating({
    mutationKey: ["send-interpretation-request"],
  });

  const files: File[] = useWatch({ name: "files", control });
  const {
    field: { onChange },
  } = useController({
    name: "files",
    control,
  });

  return (
    <Modal id={id}>
      {({ onClose }) => {
        return (
          <>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {t("Zadaj pytanie dotyczące wyniku weryfikacji")}
              </ModalHeader>
              <ModalBody as={VStack} align="stretch" spacing={6}>
                <Alert status="info" variant="subtle">
                  {t("Id weryfikacji: {{verificationId}}", {
                    verificationId,
                  })}
                </Alert>
                <form
                  id="interpretation-request-form"
                  noValidate
                  onSubmit={handleSubmit(async (data) => {
                    try {
                      const res = await sendInterpretationRequest(data);
                      if (res.ok) {
                        reset();
                        onClose();
                        toast({
                          description: t("Zapytanie zostało wysłane."),
                          status: "success",
                        });
                      } else {
                        const json = await res.json();
                        throw new Error(json);
                      }
                    } catch (error) {
                      toastWithError({
                        error,
                      });
                    }
                  })}
                >
                  <VStack spacing="6">
                    <FormControl
                      formLabel={t("Imię")}
                      formErrorMessage={errors.name?.message}
                      isRequired
                    >
                      <Input
                        {...register("name", {
                          required: t("Pole jest wymagane."),
                          minLength: minLength(),
                          maxLength: maxLength(),
                        })}
                      />
                    </FormControl>
                    <FormControl
                      formLabel={t("Nazwisko")}
                      formErrorMessage={errors.surname?.message}
                      isRequired
                    >
                      <Input
                        {...register("surname", {
                          required: t("Pole jest wymagane."),
                          minLength: minLength(),
                          maxLength: maxLength(),
                        })}
                      />
                    </FormControl>
                    <FormControl
                      formLabel={t("E-mail")}
                      formErrorMessage={errors.email?.message}
                      isRequired
                    >
                      <Input
                        type="email"
                        {...register("email", {
                          setValueAs: (value) => value.trim(),
                          required: t("Pole jest wymagane."),
                          pattern: {
                            value: /[^@]+@[^.]+\..+/,
                            message: t("Niepoprawny format"),
                          },
                        })}
                      />
                    </FormControl>
                    <FormControl
                      formLabel={t("Telefon")}
                      formErrorMessage={errors.phone?.message}
                    >
                      <Input
                        type="tel"
                        {...register("phone", {
                          minLength: minLength(6),
                          maxLength: maxLength(15),
                        })}
                      />
                    </FormControl>
                    <FormControl
                      formLabel={t("Treść zapytania")}
                      formErrorMessage={errors.message?.message}
                      isRequired
                    >
                      <Textarea
                        {...register("message", {
                          required: t("Pole jest wymagane."),
                          minLength: minLength(),
                          maxLength: maxLength(4000),
                        })}
                      />
                    </FormControl>
                    <FormControl
                      formLabel={t("Weryfikowane dokumenty")}
                      formErrorMessage={errors.files?.message}
                      isRequired
                    >
                      <FileUploader
                        files={files}
                        onupdatefiles={(files) => {
                          onChange(files.map((file) => file.file));
                        }}
                      />
                    </FormControl>
                  </VStack>
                </form>
              </ModalBody>
              <ModalFooter>
                <InputGroup gap="2" justifyContent="end">
                  <Button onClick={onClose} isLoading={!!isMutating}>
                    {t("Anuluj")}
                  </Button>
                  <Button
                    colorScheme="purple"
                    type="submit"
                    form="interpretation-request-form"
                    isLoading={!!isMutating}
                  >
                    {t("Wyślij")}
                  </Button>
                </InputGroup>
              </ModalFooter>
            </ModalContent>
          </>
        );
      }}
    </Modal>
  );
};
