import { Skeleton, Stack } from "@chakra-ui/react";

export const TableLoadingState = () => {
  return (
    <Stack>
      <Skeleton height="52px" />
      <Skeleton height="52px" />
      <Skeleton height="52px" />
      <Skeleton height="52px" />
    </Stack>
  );
};
