import { useSearchParams } from "react-router-dom";

import { Button, IconButton, useBreakpoint } from "@chakra-ui/react";
import { t } from "i18next";

import { EmailOpen } from "components/icons/EmailOpen";

interface IProps {
  notificationProcessId: string;
  smallBreakpoints?: string[];
}

export const CreateBasedOnNotificationBtn = ({
  notificationProcessId,
  smallBreakpoints = ["base", "sm"],
}: IProps) => {
  const breakpoint = useBreakpoint();
  const [, setSearchParams] = useSearchParams();

  const handleClick = () => {
    setSearchParams({ notificationProcessId, createBasedOn: "true" });
    const element = document.getElementById("notification-form");
    element?.scrollIntoView({ behavior: "smooth" });
  };

  if (smallBreakpoints.includes(breakpoint)) {
    return (
      <IconButton
        aria-label={t("Utwórz na podstawie")}
        icon={<EmailOpen />}
        title={t("Utwórz na podstawie")}
        onClick={handleClick}
      />
    );
  }

  return <Button onClick={handleClick}>{t("Utwórz na podstawie")}</Button>;
};
