import { IInterpretation } from "./types/IInterpretation";

export type InterpretationCount = ReturnType<typeof getInterpretationCount>[0];

interface Result {
  [key: string]: {
    organizationName: string;
    organizationId: string;
    totalInterpretations: number;
    monthlyInterpretations: {
      [key: string]: number;
    };
  };
}

export const getInterpretationCount = (interpretations: IInterpretation[]) => {
  const groupedInterpretations = interpretations.reduce(
    (acc, interpretation) => {
      const key = interpretation.organization.id;
      if (!acc[key]) {
        acc[key] = {
          organizationName: interpretation.organization.details.displayName,
          organizationId: interpretation.organization.id,
          totalInterpretations: 0,
          monthlyInterpretations: {},
        };
      }

      // Oblicz miesiąc z createdAt
      const createdAtDate = new Date(interpretation.createdAt);
      const monthYearKey = `${createdAtDate.getMonth() + 1}_${createdAtDate.getFullYear()}`;

      // Zwiększ licznik wykonanych interpretacji dla danego miesiąca
      if (!acc[key].monthlyInterpretations[monthYearKey]) {
        acc[key].monthlyInterpretations[monthYearKey] = 0;
      }
      acc[key].monthlyInterpretations[monthYearKey]++;

      // Zwiększ licznik całkowitych interpretacji
      acc[key].totalInterpretations++;

      return acc;
    },
    {} as Result
  );

  type Keys = (keyof typeof groupedInterpretations)[] & {};

  // Przekształć obiekt map na tablicę wynikową
  const result = (Object.keys(groupedInterpretations) as Keys).map((key) => {
    const {
      organizationId,
      organizationName,
      totalInterpretations,
      monthlyInterpretations,
    } = groupedInterpretations[key];

    // Przekształć obiekty miesięczne na tablicę punktów danych do wykresu
    const monthlyData = Object.keys(monthlyInterpretations).map(
      (monthYearKey) => {
        const [month, year] = monthYearKey.split("_");
        return {
          month: parseInt(month),
          year: parseInt(year),
          interpretationsCount: monthlyInterpretations[monthYearKey],
        };
      }
    );

    return {
      organizationId,
      organizationName,
      totalInterpretations,
      monthlyData,
    };
  });

  return result;
};
