import { IOrganization } from "modules/organization/application/types/IOrganization";
import { TariffTypes } from "modules/tariffs/application/types/ITariff";

export const countTariffs = (organizations: IOrganization[]) => {
  const countByType: Record<TariffTypes, number> = {
    S: 0,
    M: 0,
    L: 0,
    E: 0,
  };

  return organizations.reduce((countByType, organization) => {
    const tariff = organization.tariffs.current;

    if (tariff) {
      countByType[tariff.type]++;
    }

    return countByType;
  }, countByType);
};
