import { UseFormRegisterReturn } from "react-hook-form";

import { Select } from "@chakra-ui/react";
import { t } from "i18next";

import { FormControl } from "./FormControl";

interface Props {
  register: UseFormRegisterReturn;
}

export const CountriesField = (props: Props) => {
  return (
    <FormControl formLabel={t("Kraj")} isRequired>
      <Select defaultValue="PL" {...props.register}>
        {countries.map((country) => {
          return (
            <option key={country.name} value={country.name}>
              {country.value}
            </option>
          );
        })}
      </Select>
    </FormControl>
  );
};

const countries = [
  { name: "AT", value: "Austria" },
  { name: "BE", value: "Belgia" },
  { name: "BG", value: "Bułgaria" },
  { name: "CY", value: "Cypr" },
  { name: "CZ", value: "Republika Czeska" },
  { name: "DE", value: "Niemcy" },
  { name: "DK", value: "Dania" },
  { name: "EE", value: "Estonia" },
  { name: "EL", value: "Grecja" },
  { name: "ES", value: "Hiszpania" },
  { name: "FI", value: "Finlandia" },
  { name: "FR", value: "Francja" },
  { name: "HR", value: "Chorwacja" },
  { name: "HU", value: "Węgry" },
  { name: "IE", value: "Irlandia" },
  { name: "IT", value: "Włochy" },
  { name: "LT", value: "Litwa" },
  { name: "LU", value: "Luksemburg" },
  { name: "LV", value: "Łotwa" },
  { name: "MT", value: "Malta" },
  { name: "NL", value: "Niderlandy" },
  { name: "PL", value: "Polska" },
  { name: "PT", value: "Portugalia" },
  { name: "RO", value: "Rumunia" },
  { name: "SE", value: "Szwecja" },
  { name: "SI", value: "Słowenia" },
  { name: "SK", value: "Słowacja" },
  { name: "UK", value: "Zjednoczone Królestwo" },
  { name: "AD", value: "Andora" },
  { name: "AE", value: "Zjednoczone Emiraty Arabskie" },
  { name: "AF", value: "Afganistan" },
  { name: "AG", value: "Antigua i Barbuda" },
  { name: "AI", value: "Anguilla" },
  { name: "AL", value: "Albania" },
  { name: "AM", value: "Armenia" },
  { name: "AO", value: "Angola" },
  { name: "AQ", value: "Antarktyda" },
  { name: "AR", value: "Argentyna" },
  { name: "AS", value: "Samoa Amerykańskie" },
  { name: "AU", value: "Australia" },
  { name: "AW", value: "Aruba" },
  { name: "AX", value: "Wyspy Alandzkie" },
  { name: "AZ", value: "Azerbejdżan" },
  { name: "BA", value: "Bośnia i Hercegowina" },
  { name: "BB", value: "Barbados" },
  { name: "BD", value: "Bangladesz" },
  { name: "BF", value: "Burkina Faso" },
  { name: "BH", value: "Bahrajn" },
  { name: "BI", value: "Burundi" },
  { name: "BJ", value: "Benin" },
  { name: "BL", value: "Saint-Barthélemy" },
  { name: "BM", value: "Bermudy" },
  { name: "BN", value: "Brunei" },
  { name: "BO", value: "Boliwia" },
  { name: "BR", value: "Brazylia" },
  { name: "BS", value: "Bahamy" },
  { name: "BT", value: "Bhutan" },
  { name: "BV", value: "Wyspa Bouveta" },
  { name: "BW", value: "Botswana" },
  { name: "BY", value: "Białoruś" },
  { name: "BZ", value: "Belize" },
  { name: "CA", value: "Kanada" },
  { name: "CC", value: "Wyspy Kokosowe" },
  { name: "CD", value: "Demokratyczna Republika Konga" },
  { name: "CF", value: "Republika Środkowoafrykańska" },
  { name: "CG", value: "Kongo" },
  { name: "CH", value: "Szwajcaria" },
  { name: "CI", value: "Wybrzeże Kości Słoniowej" },
  { name: "CK", value: "Wyspy Cooka" },
  { name: "CL", value: "Chile" },
  { name: "CM", value: "Kamerun" },
  { name: "CN", value: "Chiny" },
  { name: "CO", value: "Kolumbia" },
  { name: "CP", value: "Clipperton" },
  { name: "CR", value: "Kostaryka" },
  { name: "CU", value: "Kuba" },
  { name: "CV", value: "Republika Zielonego Przylądka" },
  { name: "CX", value: "Wyspa Bożego Narodzenia" },
  { name: "CW", value: "Curaçao" },
  { name: "DJ", value: "Dżibuti" },
  { name: "DM", value: "Dominika" },
  { name: "DO", value: "Republika Dominikańska" },
  { name: "DZ", value: "Algieria" },
  { name: "EC", value: "Ekwador" },
  { name: "EG", value: "Egipt" },
  { name: "EH", value: "Sahara Zachodnia" },
  { name: "ER", value: "Erytrea" },
  { name: "ET", value: "Etiopia" },
  { name: "FJ", value: "Fidżi" },
  { name: "FK", value: "Falklandy" },
  { name: "FM", value: "Mikronezja" },
  { name: "FO", value: "Wyspy Owcze" },
  { name: "GA", value: "Gabon" },
  { name: "GD", value: "Grenada" },
  { name: "GE", value: "Gruzja" },
  { name: "GF", value: "Gujana Francuska" },
  { name: "GG", value: "Guernsey" },
  { name: "GH", value: "Ghana" },
  { name: "GI", value: "Gibraltar" },
  { name: "GL", value: "Grenlandia" },
  { name: "GM", value: "Gambia" },
  { name: "GN", value: "Gwinea" },
  { name: "GP", value: "Gwadelupa" },
  { name: "GQ", value: "Gwinea Równikowa" },
  { name: "GS", value: "Georgia Południowa i Sandwich Południowy" },
  { name: "GT", value: "Gwatemala" },
  { name: "GU", value: "Guam" },
  { name: "GW", value: "Gwinea Bissau" },
  { name: "GY", value: "Gujana" },
  { name: "HK", value: "Hongkong" },
  { name: "HM", value: "Wyspy Heard i McDonalda" },
  { name: "HN", value: "Honduras" },
  { name: "HT", value: "Haiti" },
  { name: "ID", value: "Indonezja" },
  { name: "IL", value: "Izrael" },
  { name: "IN", value: "Indie" },
  { name: "IM", value: "Wyspa Man" },
  { name: "IO", value: "Brytyjskie Terytorium Oceanu Indyjskiego" },
  { name: "IQ", value: "Irak" },
  { name: "IR", value: "Iran" },
  { name: "IS", value: "Islandia" },
  { name: "JE", value: "Jersey" },
  { name: "JM", value: "Jamajka" },
  { name: "JO", value: "Jordania" },
  { name: "JP", value: "Japonia" },
  { name: "KE", value: "Kenia" },
  { name: "KG", value: "Kirgistan" },
  { name: "KH", value: "Kambodża" },
  { name: "KI", value: "Kiribati" },
  { name: "KM", value: "Komory" },
  { name: "KN", value: "Saint Kitts i Nevis" },
  { name: "KP", value: "Korea Północna" },
  { name: "KR", value: "Korea Południowa" },
  { name: "KW", value: "Kuwejt" },
  { name: "KY", value: "Kajmany" },
  { name: "KZ", value: "Kazachstan" },
  { name: "LA", value: "Laos" },
  { name: "LB", value: "Liban" },
  { name: "LC", value: "Saint Lucia" },
  { name: "LI", value: "Liechtenstein" },
  { name: "LK", value: "Sri Lanka" },
  { name: "LR", value: "Liberia" },
  { name: "LS", value: "Lesotho" },
  { name: "LY", value: "Libia" },
  { name: "MA", value: "Maroko" },
  { name: "MC", value: "Monako" },
  { name: "MD", value: "Mołdawia" },
  { name: "ME", value: "Czarnogóra" },
  { name: "MF", value: "Saint Martin" },
  { name: "MG", value: "Madagaskar" },
  { name: "MH", value: "Wyspy Marshalla" },
  { name: "ML", value: "Mali" },
  { name: "MM", value: "Mjanma/Birma" },
  { name: "MN", value: "Mongolia" },
  { name: "MO", value: "Makau" },
  { name: "MP", value: "Mariany Północne" },
  { name: "MQ", value: "Martynika" },
  { name: "MR", value: "Mauretania" },
  { name: "MS", value: "Montserrat" },
  { name: "MU", value: "Mauritius" },
  { name: "MV", value: "Malediwy" },
  { name: "MW", value: "Malawi" },
  { name: "MX", value: "Meksyk" },
  { name: "MY", value: "Malezja" },
  { name: "MZ", value: "Mozambik" },
  { name: "NA", value: "Namibia" },
  { name: "NC", value: "Nowa Kaledonia" },
  { name: "NE", value: "Niger" },
  { name: "NF", value: "Wyspa Norfolk" },
  { name: "NG", value: "Nigeria" },
  { name: "NI", value: "Nikaragua" },
  { name: "NO", value: "Norwegia" },
  { name: "NP", value: "Nepal" },
  { name: "NR", value: "Nauru" },
  { name: "NU", value: "Niue" },
  { name: "NZ", value: "Nowa Zelandia" },
  { name: "OM", value: "Oman" },
  { name: "PA", value: "Panama" },
  { name: "PE", value: "Peru" },
  { name: "PF", value: "Polinezja Francuska" },
  { name: "PG", value: "Papua-Nowa Gwinea" },
  { name: "PH", value: "Filipiny" },
  { name: "PK", value: "Pakistan" },
  { name: "PM", value: "Saint-Pierre i Miquelon" },
  { name: "PN", value: "Pitcairn" },
  { name: "PR", value: "Portoryko" },
  { name: "PW", value: "Palau" },
  { name: "PY", value: "Paragwaj" },
  { name: "QA", value: "Katar" },
  { name: "RE", value: "Reunion" },
  { name: "RS", value: "Serbia" },
  { name: "RU", value: "Rosja" },
  { name: "RW", value: "Rwanda" },
  { name: "SA", value: "Arabia Saudyjska" },
  { name: "SB", value: "Wyspy Salomona" },
  { name: "SC", value: "Seszele" },
  { name: "SD", value: "Sudan" },
  { name: "SG", value: "Singapur" },
  {
    name: "SH",
    value: "Święta Helena, Wyspa Wniebowstąpienia i Tristan da Cunha",
  },
  { name: "SJ", value: "Svalbard i Jan Mayen" },
  { name: "SL", value: "Sierra Leone" },
  { name: "SM", value: "San Marino" },
  { name: "SN", value: "Senegal" },
  { name: "SO", value: "Somalia" },
  { name: "SR", value: "Surinam" },
  { name: "SS", value: "Sudan Południowy" },
  { name: "ST", value: "Wyspy Świętego Tomasza i Książęca" },
  { name: "SV", value: "Salwador" },
  { name: "SX", value: "Sint Maarten" },
  { name: "SY", value: "Syria" },
  { name: "SZ", value: "Suazi" },
  { name: "TC", value: "Turks i Caicos" },
  { name: "TD", value: "Czad" },
  { name: "TF", value: "Francuskie Terytoria Południowe i Antarktyczne" },
  { name: "TG", value: "Togo" },
  { name: "TH", value: "Tajlandia" },
  { name: "TJ", value: "Tadżykistan" },
  { name: "TK", value: "Tokelau" },
  { name: "TL", value: "Timor Wschodni" },
  { name: "TM", value: "Turkmenistan" },
  { name: "TN", value: "Tunezja" },
  { name: "TO", value: "Tonga" },
  { name: "TR", value: "Turcja" },
  { name: "TT", value: "Trynidad i Tobago" },
  { name: "TV", value: "Tuvalu" },
  { name: "TW", value: "Tajwan" },
  { name: "TZ", value: "Tanzania" },
  { name: "UA", value: "Ukraina" },
  { name: "UG", value: "Uganda" },
  { name: "UM", value: "Małe Oddalone Wyspy Stanów Zjednoczonych" },
  { name: "US", value: "Stany Zjednoczone" },
  { name: "UY", value: "Urugwaj" },
  { name: "UZ", value: "Uzbekistan" },
  { name: "VA", value: "Stolica Apostolska" },
  { name: "VC", value: "Saint Vincent i Grenadyny" },
  { name: "VE", value: "Wenezuela" },
  { name: "VG", value: "Brytyjskie Wyspy Dziewicze" },
  { name: "VI", value: "Wyspy Dziewicze Stanów Zjednoczonych" },
  { name: "VN", value: "Wietnam" },
  { name: "VU", value: "Vanuatu" },
  { name: "WF", value: "Wallis i Futuna" },
  { name: "WS", value: "Samoa" },
  { name: "YE", value: "Jemen" },
  { name: "YT", value: "Majotta" },
  { name: "ZA", value: "Republika Południowej Afryki" },
  { name: "ZM", value: "Zambia" },
  { name: "ZW", value: "Zimbabwe" },
];
