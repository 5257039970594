import { ReactNode, useRef } from "react";
import { useSearchParams } from "react-router-dom";

import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { t } from "i18next";

import { FilterIcon } from "components/icons/FilterIcon";

interface IProps {
  drawerTitle: string;
  children: ReactNode;
  trackedParams: string[];
}

export const FilterDrawer = ({
  drawerTitle,
  children,
  trackedParams,
}: IProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef<HTMLButtonElement>(null);

  const appliedFilters = trackedParams.filter((param) =>
    searchParams.get(param)
  ).length;

  return (
    <>
      <Box position="relative">
        <IconButton
          aria-label={t("Filtry")}
          ref={btnRef}
          colorScheme="purple"
          variant="ghost"
          onClick={onOpen}
          icon={<FilterIcon />}
        />
        {appliedFilters > 0 && (
          <Box
            position="absolute"
            top="2"
            right="2"
            height="10px"
            width="10px"
            bg="red"
            rounded="full"
            zIndex="2"
          />
        )}
      </Box>

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{drawerTitle}</DrawerHeader>

          <DrawerBody>
            <VStack align="stretch" spacing={6}>
              {children}
            </VStack>
          </DrawerBody>

          <DrawerFooter flexDir="row-reverse" gap={2}>
            <Button
              variant="outline"
              mr={3}
              onClick={() => {
                trackedParams.forEach((param) => {
                  searchParams.delete(param);
                });

                setSearchParams(searchParams, {
                  replace: true,
                });
              }}
            >
              {t("Resetuj")}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
