import { useParams } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";

import { client } from "utils/http";

interface MutationVariables {
  oldPassword: string;
  newPassword: string;
}

export const useChangeUserPassword = (contextId: string) => {
  const { organizationId } = useParams<{ organizationId: string }>();

  return useMutation({
    mutationKey: ["change-user-password"],
    mutationFn: (variables: MutationVariables) => {
      return client.put(
        `organizations/${organizationId}/contexts/${contextId}/myPassword`,
        {
          body: { ...variables },
        }
      );
    },
  });
};
