import { useParams } from "react-router-dom";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

interface MutationVariables {
  isActive: boolean;
  tariffId: string;
}

export const useToggleTariffActivity = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["toggle-tariff"],
    mutationFn: (variables: MutationVariables) => {
      if (variables.isActive) {
        return client.post(
          `organizations/${organizationId}/tariffs/${variables.tariffId}/disable`
        );
      }

      return client.post(
        `organizations/${organizationId}/tariffs/${variables.tariffId}/activate`
      );
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["organizations", organizationId, "tariffs"],
      });

      queryClient.invalidateQueries({
        queryKey: ["organizations", organizationId, "apiKeys"],
      });

      queryClient.invalidateQueries({
        queryKey: ["organizations", organizationId, "details"],
      });

      queryClient.invalidateQueries({
        queryKey: ["organizations", "all"],
      });
    },
  });
};
