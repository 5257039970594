import { Card, CardBody, CardHeader, Heading, Td } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { t } from "i18next";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";

import { ActiveBadge } from "components/ActiveBadge";
import { FormatDate } from "components/FormatDate";
import { Table } from "components/Table";
import { TableEmptyState } from "components/Table/TableEmptyState";
import { TableLoadingState } from "components/Table/TableLoadingState";

import { ISystemContext } from "modules/system/application/types/SystemContext";
import { useSystemContextsQuery } from "modules/system/infrastructure/useSystemContextsQuery";

import { AddSystemContextButton } from "../AddSystemContextButton";

const columnHelper = createColumnHelper<ISystemContext>();

const columns = [
  columnHelper.accessor("name", {
    header: () => t("Nazwa konta"),
    cell: (info) => <Td whiteSpace="normal">{info.getValue()}</Td>,
  }),
  columnHelper.accessor("details.email", {
    header: () => t("Login"),
    cell: (info) => <Td whiteSpace="normal">{info.getValue()}</Td>,
  }),
  columnHelper.accessor("active", {
    header: () => t("Status"),
    cell: (info) => (
      <Td whiteSpace="normal">
        <ActiveBadge isActive={info.getValue()} />
      </Td>
    ),
  }),
  columnHelper.accessor("createdAt", {
    header: () => t("Data utworzenia"),
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal">
        <FormatDate date={info.getValue()} />
      </Td>
    ),
  }),
];

interface IProps {
  contexts: ISystemContext[];
  isLoading: boolean;
}

const GlobalAdminTableConnected = ({ contexts, isLoading }: IProps) => {
  if (isLoading) {
    return <TableLoadingState />;
  }

  if (contexts.length === 0) {
    return <TableEmptyState title={t("Lista administratorów jest pusta")} />;
  }

  const sortedRecords = reverse(
    sortBy(contexts, function (data) {
      return new Date(data.createdAt);
    })
  );

  return <Table<ISystemContext> data={sortedRecords} columns={columns} />;
};

export const GlobalAdminTable = () => {
  const { data, isLoading } = useSystemContextsQuery();

  return (
    <Card id="admins" variant="sawpe">
      <CardHeader>
        <Heading fontSize="lg">{t("Administratorzy")}</Heading>
        <AddSystemContextButton type="system_admin" />
      </CardHeader>
      <CardBody>
        <GlobalAdminTableConnected
          contexts={
            data?.contexts.filter(({ type }) => type === "system_admin") ?? []
          }
          isLoading={isLoading}
        />
      </CardBody>
    </Card>
  );
};
