import { QueryClient } from "@tanstack/react-query";
import { HttpResponse, http } from "msw";

import { client, withHost } from "utils/http";
import { useQuery } from "utils/useQuery";

import { IVerificationHistoryRecord } from "../application/types/IVerificationHistoryRecord";

const query = (organizationId: string) => {
  return {
    queryKey: ["organizations", organizationId, "verifications"],
    queryFn: () =>
      client.get<{ verifications: IVerificationHistoryRecord[] }>(
        `organizations/${organizationId}/verifications`
      ),
  };
};

export const getVerificationsHistoryQuery =
  (queryClient: QueryClient) => (organizationId: string) => {
    return queryClient.ensureQueryData(query(organizationId));
  };

export const useVerificationsHistoryQuery = (organizationId: string) => {
  return useQuery(query(organizationId));
};

export const getVerificationsHistoryMock = (
  verifications: IVerificationHistoryRecord[] = []
) => {
  return http.get(
    withHost("organizations/:organizationId/verifications"),
    () => {
      return HttpResponse.json<{ verifications: IVerificationHistoryRecord[] }>(
        {
          verifications: [...verifications],
        }
      );
    }
  );
};
