import { t } from "i18next";

import { EmailType } from "modules/eventLog/application/types/EmailType";
import { IEvent } from "modules/eventLog/application/types/IEvent";

export const toReadableEventName = (event: IEvent) => {
  const name = event.name;

  switch (name) {
    case "user.loggedIn":
      return t("Zalogowano użytkownika {{login}}", {
        login: event.payload.login,
      });
    case "tariff.created":
      return t("Utworzono pakiet");
    case "tariff.disabled":
      return t("Wyłączono pakiet");
    case "tariff.enabled":
      return t("Włączono pakiet");
    case "tariff.changed":
      return t("Zmieniono pakiet");
    case "tariff.removed":
      return t("Usunięto pakiet");
    case "tariff.limitExceeded":
      return t("Przekroczono limit pakietu");
    case "context.contextManagerCreated":
      return t("Utworzono administratora kontekstu {{name}}", {
        name: event.payload.name,
      });
    case "context.contextUserCreated":
      return t("Utworzono użytkownika kontekstu {{name}}", {
        name: event.payload.name,
      });
    case "context.systemManagerCreated":
      return t("Utworzono zarządcę / admina");
    case "context.passwordChanged":
      return t("Zmieniono hasło dla {{name}}", { name: event.payload.name });
    case "context.contextDataChanged":
      return t("Zmieniono dane dla {{name}}", { name: event.payload.name });
    case "context.disabled":
      return t("Wyłączono kontekst {{name}}", { name: event.payload.name });
    case "context.removed":
      return t("Usunięto kontekst");
    case "file.created":
      return t("Utworzono plik");
    case "integrationKey.created":
      return t("Utworzono klucz integracyjny");
    case "integrationKey.enabled":
      return t("Włączono klucz integracyjny");
    case "integrationKey.disabled":
      return t("Wyłączono klucz integracyjny");
    case "integrationKey.removed":
      return t("Usunięto klucz integracyjny");
    case "integrationKey.changed":
      return t("Zmieniono klucz integracyjny");
    case "email.created":
      return t("Utworzono email - {{email}}", {
        email: getReadableEmailType(event.payload.type),
      });
    case "email.sent":
      return t("Wysłano email");
    case "apiKey.created":
      return t("Utworzono klucz API");
    case "apiKey.enabled":
      return t("Włączono klucz API");
    case "apiKey.disabled":
      return t("Wyłączono klucz API");
    case "organization.created":
      return t("Utworzono podmiot");
    case "organization.enabled":
      return t("Włączono podmiot");
    case "organization.disabled":
      return t("Wyłączono podmiot");
    case "organization.changed":
      return t("Zmieniono podmiot");
    case "organization.commentChanged":
      return t("Zmieniono komentarz podmiotu");
    case "organization.removed":
      return t("Usunięto podmiot {{organization}}", {
        organization: event.payload.organizationName,
      });
    case "organizationConfiguration.changed":
      return t("Zmieniono konfigurację podmiotu {{organization}}", {
        organization: event.payload.organizationName,
      });
    case "notificationsProcess.created":
      return t("Utworzono powiadomienie");
    case "notificationsProcess.locked":
      return t("Rozpoczęto wysyłkę powiadomienia");
    case "notificationsProcess.changed":
      return t("Zmieniono powiadomienie");
    case "notificationsProcess.removed":
      return t("Usunięto powiadomienie");
    case "notificationsProcess.completed":
      return t("Wysłano powiadomienie");
    case "verificationInterpretation.created":
      return t("Wysłano zapytanie o interpretację");
    case "complaint.created":
      return t("Utworzono zgłoszenie reklamacyjne");
    case "changelog.created":
      return t("Utworzono changelog");
    case "changelog.changed":
      return t("Zmieniono changelog");
    case "changelog.removed":
      return t("Usunięto changelog");
    case "context.contextManagerRemoved":
      return t("Usunięto administratora kontekstu");
    case "tariff.expirationReminderCreated":
      return t("Utworzono przypomnienie o wygaśnięciu pakietu");
    default:
      // eslint-disable-next-line no-case-declarations, @typescript-eslint/no-unused-vars
      const eventName: never = name;
      return t("Nieznany typ aktywności");
  }
};

const getReadableEmailType = (type: EmailType) => {
  switch (type) {
    case "FORGOT":
      return t("Zapomniane hasło");
    case "RESET_PASSWORD":
      return t("Reset hasła");
    case "VERIFICATION":
      return t("Weryfikacja");
    case "ORGANIZATION_FIRST_ACTIVATION":
      return t("Pierwsze aktywowanie podmiotu");
    case "ORGANIZATION_REACTIVATION":
      return t("Reaktywacja podmiotu");
    case "CONTEXT_MANAGER_CREATED":
      return t("Utworzono administratora");
    case "SYSTEM_CONTEXT_CREATED":
      return t("Utworzono kontekst systemowy");
    case "TARIFF_E_CREATED_ADMIN_NOTIFICATION":
      return t("Utworzono pakiet E - powiadomienie dla admina");
    case "ORGANIZATION_REGISTRATION_CONFIRMATION":
      return t("Potwierdzenie rejestracji podmiotu");
    case "ORGANIZATION_REGISTRATION":
      return t("Rejestracja podmiotu");
    case "COMPLAINT":
      return t("Zgłoszenie reklamacyjne");
    case "CONTEXT_MANAGER_REMOVED":
      return t("Usunięto administratora kontekstu");
    case "TARIFF_EXPIRATION_REMINDER":
      return t("Przypomnienie o wygaśnięciu pakietu");
    case "TARIFF_LIMIT_EXCEED_REMINDER":
      return t("Przypomnienie o przekroczeniu limitu pakietu");
    case "TARIFF_LIMIT_EXCEED":
      return t("Przekroczono limit pakietu");
    case "TARIFF_EXPIRED":
      return t("Wygasł pakiet");
    default:
      // eslint-disable-next-line no-case-declarations, @typescript-eslint/no-unused-vars
      const emailType: never = type;
      return t("Nieznany typ emaila");
  }
};
