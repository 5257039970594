import { QueryClient } from "@tanstack/react-query";
import { HttpResponse, http } from "msw";

import { client, withHost } from "utils/http";
import { useQuery } from "utils/useQuery";

import { IAllTariffs } from "../application/types/IAllTariffs";

const query = (organizationId: string) => {
  return {
    queryKey: ["organizations", organizationId, "tariffs", "all"],
    queryFn: () =>
      client.get<{ tariffs: IAllTariffs }>(
        `organizations/${organizationId}/tariffs/all`
      ),
  };
};

export const getAllTariffsQuery =
  (queryClient: QueryClient) => (organizationId: string) => {
    return queryClient.ensureQueryData(query(organizationId));
  };

/**
 * @description return inactive tariffs as well, available for system_manager and system_admin
 */
export const useAllTariffsQuery = (organizationId: string) => {
  return useQuery(query(organizationId));
};

export const getAllTariffsMock = ({
  current,
  next,
  draft,
}: Partial<IAllTariffs>) => {
  return http.get(withHost("organizations/:organizationId/tariffs/all"), () => {
    return HttpResponse.json<{ tariffs: IAllTariffs }>({
      tariffs: {
        draft: draft || null,
        current: current || null,
        next: next || null,
      },
    });
  });
};
