import { ChangeEvent, useCallback, useState } from "react";

import { chakra } from "@chakra-ui/react";
import { useIsMutating } from "@tanstack/react-query";
import { t } from "i18next";

import { useEditOrganizationComment } from "modules/organization/infrastructure/useEditOrganizationComment";

interface IProps {
  organizationId: string;
  value: string;
}

export const EditableComment = ({ value, organizationId }: IProps) => {
  const [inputValue, setInputValue] = useState(value);
  const onContentBlur = useCallback(async (evt: ChangeEvent) => {
    const comment = evt.currentTarget.innerHTML;
    if (comment !== inputValue) {
      await editComment({ comment });
      setInputValue(comment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { mutateAsync: editComment } =
    useEditOrganizationComment(organizationId);
  const isMutating = useIsMutating({
    mutationKey: ["edit-organization-comment", organizationId],
  });

  return (
    <chakra.div
      contentEditable={!isMutating}
      suppressContentEditableWarning
      color={isMutating ? "gray.500" : "inherit"}
      cursor={isMutating ? "not-allowed" : "inherit"}
      _focus={{
        outline: "2px solid var(--chakra-colors-blue-300)",
        outlineOffset: "3px",
        borderRadius: "sm",
      }}
      dangerouslySetInnerHTML={{ __html: inputValue }}
      onBlur={onContentBlur}
      position="relative"
      maxW="260px"
      sx={{
        "&:empty:after": {
          content: t("'Kliknij aby dodać'"),
          position: "absolute",
          color: "gray.400",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          pointerEvents: "none",
        },
      }}
    />
  );
};
