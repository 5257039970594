import { useForm } from "react-hook-form";

import { Input, VStack } from "@chakra-ui/react";
import { t } from "i18next";
import { HTTPError } from "ky";

import { maxLength } from "utils/form/maxLength";
import { minLength } from "utils/form/minLength";

import { toastWithError } from "components/ErrorHandling/toastWithError";
import { FormControl } from "components/Form/FormControl";

export interface IManagerFormData {
  name: string;
  surname: string;
  email: string;
  phone: string;
}

interface IProps {
  onClose: () => void;
  onSubmit: (payload: IManagerFormData) => Promise<void>;
  defaultData?: Partial<IManagerFormData>;
}

export const ManagerModalForm = ({
  onClose,
  onSubmit,
  defaultData,
}: IProps) => {
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm<IManagerFormData>({
    defaultValues: defaultData,
  });

  return (
    <form
      id="manager-form"
      onSubmit={handleSubmit(async (data) => {
        try {
          await onSubmit(data);
          onClose();
        } catch (error) {
          if (error instanceof HTTPError) {
            const errorJson = await error.response.json<{
              message: string;
            }>();

            if (errorJson.message === "context.error.nonUniqueLogin") {
              setError("email", {
                type: "manual",
                message: t("Podany email jest już zajęty"),
              });
              return;
            }

            if (
              errorJson.message === "context.error.organizationDisabled" ||
              errorJson.message === "context.error.notAllowedForNewOrganization"
            ) {
              toastWithError({
                description: t("Podmiot jest nieaktywny."),
                error,
              });
              return;
            }
          }

          toastWithError({
            error,
          });
        }
      })}
    >
      <VStack align="stretch" spacing={6}>
        <FormControl
          formLabel={t("Imię")}
          isRequired
          formErrorMessage={errors.name?.message}
        >
          <Input
            {...register("name", {
              required: true,
              minLength: minLength(),
              maxLength: maxLength(),
            })}
          />
        </FormControl>
        {!defaultData && (
          <>
            <FormControl
              formLabel={t("Nazwisko")}
              isRequired
              formErrorMessage={errors.surname?.message}
            >
              <Input
                {...register("surname", {
                  required: true,
                  minLength: minLength(),
                  maxLength: maxLength(),
                })}
              />
            </FormControl>
            <FormControl
              formLabel={t("E-mail")}
              formHelperText={t(
                "Na podany adres e-mail zostanie wysłana wiadomość z linkiem do ustawienia hasła."
              )}
              formErrorMessage={errors.email?.message}
              isRequired
            >
              <Input
                {...register("email", {
                  required: true,
                  minLength: minLength(),
                  maxLength: maxLength(),
                })}
              />
            </FormControl>
          </>
        )}
        <FormControl
          formLabel={t("Telefon")}
          formErrorMessage={errors.phone?.message}
        >
          <Input
            {...register("phone", {
              minLength: minLength(6),
              maxLength: maxLength(15),
            })}
          />
        </FormControl>
      </VStack>
    </form>
  );
};
