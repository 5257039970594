import { forwardRef } from "react";

import { Box, chakra, Skeleton, Stack } from "@chakra-ui/react";
import { t } from "i18next";
import { settings } from "settings/getSettings";

import { TableEmptyState } from "components/Table/TableEmptyState";

import { ITariff } from "modules/tariffs/application/types/ITariff";
import { IVerificationProcess } from "modules/verification/application/types/IVerificationProcess";

interface IProps {
  isLoading: boolean;
  tariff: ITariff | null;
  verificationProcess: IVerificationProcess;
  isWidgetReady: "ready" | "dead" | "pending";
  height?: number;
}

export const SawpeWidget = forwardRef<HTMLIFrameElement, IProps>(
  (
    { isLoading, tariff, verificationProcess, isWidgetReady, height = 830 },
    ref
  ) => {
    if (isWidgetReady === "dead") {
      throw new Error("Widget not responding");
    }

    if (isLoading || !verificationProcess?.id) {
      return (
        <Stack>
          <Skeleton height="600px" />
        </Stack>
      );
    }

    if (tariff === null) {
      return <TableEmptyState title={t("Brak aktywnego pakietu")} />;
    }

    return (
      <>
        <Stack display={isWidgetReady === "pending" ? "flex" : "none"}>
          <Skeleton height="830px" />
        </Stack>
        <Box visibility={isWidgetReady !== "ready" ? "hidden" : "visible"}>
          <chakra.iframe
            ref={ref}
            id="sawpe_widget"
            height={isWidgetReady === "ready" ? `${height}px` : "0px"}
            width="100%"
            name="sawpe_widget"
            src={`${tariff.iframeCustomUrl ?? settings.SAWPE_VERIFICATION_IFRAME_URL}`}
          />
        </Box>
      </>
    );
  }
);
