import { useForm, useWatch } from "react-hook-form";

import { Checkbox, HStack, Input, Select, VStack } from "@chakra-ui/react";
import dayjs from "dayjs";
import { t } from "i18next";

import { FormControl } from "components/Form/FormControl";

import { TariffTypes } from "modules/tariffs/application/types/ITariff";

export interface ITariffFormData {
  type: TariffTypes;
  validFrom: string;
  validTo: string;
  withApiAccess: boolean | null;
  personalDataClause: boolean | null;
}

interface IProps {
  onClose: () => void;
  onSubmit: (payload: ITariffFormData) => Promise<void>;
  defaultData?: ITariffFormData;
}

export const TariffModalForm = ({ onClose, onSubmit, defaultData }: IProps) => {
  const { handleSubmit, register, getValues, setValue, formState, control } =
    useForm<ITariffFormData>({
      defaultValues: {
        ...defaultData,
        validFrom: defaultData?.validFrom
          ? dayjs(defaultData?.validFrom).format("YYYY-MM-DD")
          : undefined,
        validTo: defaultData?.validTo
          ? dayjs(defaultData?.validTo).format("YYYY-MM-DD")
          : undefined,
      },
    });

  const type = useWatch({ control, name: "type" });
  const isPPDOAvailable = ["M", "L", "E"].includes(type);

  return (
    <form
      id="tariff-form"
      onSubmit={handleSubmit(async (data) => {
        await onSubmit({
          ...data,
          personalDataClause: isPPDOAvailable ? data.personalDataClause : null,
        });
        onClose();
      })}
    >
      <VStack align="stretch" spacing={6}>
        <FormControl formLabel={t("Pakiety")} isRequired>
          <Select
            {...register("type")}
            placeholder={t("Wybierz pakiet")}
            name="type"
          >
            <option value="S">S</option>
            <option value="M">M</option>
            <option value="L">L</option>
            <option value="E">Enterprise</option>
          </Select>
        </FormControl>
        <HStack w="100%">
          <FormControl formLabel={t("Data początkowa")} isRequired>
            <Input
              type="date"
              {...register("validFrom")}
              onChange={(e) => {
                const endDate = getValues("validTo");
                if (!endDate) {
                  setValue(
                    "validTo",
                    dayjs(e.target.value)
                      .add(1, "year")
                      .subtract(1, "day")
                      .format("YYYY-MM-DD")
                  );
                }
              }}
            />
          </FormControl>
          <FormControl
            formLabel={t("Data końcowa")}
            isRequired
            formErrorMessage={formState.errors?.validTo?.message}
          >
            <Input
              type="date"
              {...register("validTo", {
                validate: (endDate) => {
                  if (!dayjs(endDate).isAfter(getValues("validFrom"))) {
                    return t(
                      "Data końcowa nie może być wcześniejsza od daty początkowej"
                    );
                  }

                  return true;
                },
              })}
            />
          </FormControl>
        </HStack>
        <FormControl formLabel={t("Dostęp do API")} as={HStack}>
          <Checkbox
            colorScheme="purple"
            {...register("withApiAccess")}
            mb={2}
          />
        </FormControl>
        {isPPDOAvailable && (
          <FormControl formLabel={t("Umowa PPDO")} as={HStack}>
            <Checkbox
              colorScheme="purple"
              {...register("personalDataClause", {})}
              mb={2}
            />
          </FormControl>
        )}
      </VStack>
    </form>
  );
};
