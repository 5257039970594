import { mdiMenuDown, mdiMenuSwap, mdiMenuUp } from "@mdi/js";

import { Icon } from "components/icons/Icon";

interface IProps {
  currentSortType: "asc" | "desc" | false;
}

export const SortingIndicator = ({ currentSortType }: IProps) => {
  const getIconPath = () => {
    switch (currentSortType) {
      case "asc":
        return mdiMenuUp;
      case "desc":
        return mdiMenuDown;
      default:
        return mdiMenuSwap;
    }
  };

  return <Icon path={getIconPath()} />;
};
