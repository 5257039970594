import { withTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { Box, VStack } from "@chakra-ui/react";
import { settings } from "settings/getSettings";

import { SAWPEIcon } from "components/icons/SAWPEIcon";

import { Footer } from "modules/auth/presentation/Footer";
import { RegisterForm } from "modules/auth/presentation/RegisterForm";

import { RegisteredCard } from "./RegisteredCard";

export const Register = withTranslation()(() => {
  const [searchParams] = useSearchParams();
  const isRegistrationEnabled = settings.IS_REGISTRATION_ENABLED;

  if (!isRegistrationEnabled) {
    return null;
  }

  const name = searchParams.get("name");
  return (
    <VStack minH="100vh" justifyContent="center" spacing={8} p={8} pb="120px">
      <Box w={[330, 420]}>
        <SAWPEIcon height="71px" width="100%" />
      </Box>
      {name ? <RegisteredCard /> : <RegisterForm />}
      <Footer />
    </VStack>
  );
});
