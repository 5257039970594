import { ComponentProps } from "react";

import { mdiAccountClockOutline } from "@mdi/js";

import { Icon } from "./Icon";

export const UserPendingIcon = (
  props: Omit<ComponentProps<typeof Icon>, "path">
) => {
  return <Icon {...props} path={mdiAccountClockOutline} />;
};
