import { t } from "i18next";

import { CheckboxGroup } from "components/Filters/CheckboxGroup";
import { FilterDrawer } from "components/Filters/FilterDrawer";

import { OrganizationStatus } from "modules/organization/application/types/IOrganization";
import { TariffTypes } from "modules/tariffs/application/types/ITariff";

export const OrganizationFiltersDrawer = () => {
  return (
    <FilterDrawer
      drawerTitle={t("Filtry listy podmiotów")}
      trackedParams={["tariff.type", "status"]}
    >
      <CheckboxGroup<TariffTypes>
        filterId="tariff.type"
        label={t("Pakiety")}
        options={[
          ["S", "S"],
          ["M", "M"],
          ["L", "L"],
          ["E", "Enterprise"],
        ]}
      />
      <CheckboxGroup<OrganizationStatus>
        filterId="status"
        label={t("Statusy")}
        options={[
          ["NEW", "Nowy"],
          ["ACTIVE", "Aktywny"],
          ["BLOCKED", "Nieaktywny"],
        ]}
      />
    </FilterDrawer>
  );
};
