import { Text, VStack } from "@chakra-ui/react";
import { t } from "i18next";

import { AlertDialog } from "components/Dialog";
import { toastWithError } from "components/ErrorHandling/toastWithError";

import { useRemoveOrganization } from "modules/organization/infrastructure/useRemoveOrganization";

interface IProps {
  organizationId: string;
  organizationName: string;
  id: string;
}

export const RemoveOrganizationConfirm = ({
  id,
  organizationId,
  organizationName,
}: IProps) => {
  const { mutateAsync: remove } = useRemoveOrganization(organizationId);

  return (
    <AlertDialog
      id={id}
      title={t("Usuń podmiot")}
      body={
        <VStack align="stretch" spacing={4} fontSize="md">
          <Text>
            {t("Czy na pewno chcesz usunąć podmiot {{name}}?", {
              name: organizationName,
            })}
          </Text>
        </VStack>
      }
      colorScheme="purple"
      size="xl"
      confirmLabel={t("Usuń")}
      onConfirm={async () => {
        try {
          await remove();
        } catch (error) {
          toastWithError({
            error,
          });
        }
      }}
    />
  );
};
