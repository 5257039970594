import { useSearchParams } from "react-router-dom";

import { InputGroup, InputLeftElement } from "@chakra-ui/react";
import { t } from "i18next";

import { DebouncedInput } from "components/DebouncedInput";
import { SearchIcon } from "components/icons/SearchIcon";

export const RecipientFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <InputGroup maxW="322px">
      <InputLeftElement pointerEvents="none">
        <SearchIcon />
      </InputLeftElement>
      <DebouncedInput
        value={searchParams.get("recipients") || ""}
        onChange={(e) => {
          if (typeof e === "string") {
            searchParams.set("recipients", e);
            setSearchParams(searchParams, {
              replace: true,
            });
          }
        }}
        placeholder={t("Szukaj")}
        paddingLeft="36px"
      />
    </InputGroup>
  );
};
