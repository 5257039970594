import { Button, IconButton, useBreakpoint } from "@chakra-ui/react";
import { useIsMutating } from "@tanstack/react-query";
import { t } from "i18next";

import { toastWithError } from "components/ErrorHandling/toastWithError";
import { PlayIcon } from "components/icons/PlayIcon";
import { StopIcon } from "components/icons/StopIcon";

import { NotificationProcess } from "modules/notifications/application/NotificationProcess";
import { useContinueNotificationProcess } from "modules/notifications/infrastructure/useContinueNotificationProcess";
import { useStopNotificationProcess } from "modules/notifications/infrastructure/useStopNotificationProcess";

interface IProps {
  notification: NotificationProcess;
  smallBreakpoints?: string[];
}

export const StartStopNotificationBtn = ({
  notification,
  smallBreakpoints = ["base"],
}: IProps) => {
  const breakpoint = useBreakpoint();
  const { mutateAsync: stopNotification } = useStopNotificationProcess(
    notification.id
  );
  const { mutateAsync: continueNotification } = useContinueNotificationProcess(
    notification.id
  );

  const isMutating = useIsMutating({
    mutationKey: ["delete-notification-process"],
  });

  const handleClick = async () => {
    try {
      if (notification.isStopped) {
        await continueNotification();
      } else {
        await stopNotification();
      }
    } catch (error) {
      toastWithError({
        error,
      });
    }
  };

  const label = notification.isStopped ? t("Wznów") : t("Zatrzymaj");

  if (smallBreakpoints.includes(breakpoint)) {
    return (
      <IconButton
        aria-label={label}
        icon={notification.isStopped ? <PlayIcon /> : <StopIcon />}
        title={label}
        onClick={handleClick}
        isLoading={!!isMutating}
      />
    );
  }

  return (
    <Button onClick={handleClick} isLoading={!!isMutating}>
      {label}
    </Button>
  );
};
