import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

/**
 * @description Służy do usuwania podmiotów OCZEKUJĄCYCH
 */
export const useRemoveOrganization = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["remove-organization"],
    mutationFn: () => client.delete(`organizations/${organizationId}`),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["organizations", "all"],
      });
    },
  });
};
