import { HStack, Progress, Spacer, Text, VStack } from "@chakra-ui/react";
import { t } from "i18next";

import { FormatDateTime } from "components/FormatDateTime";
import { CalendarIcon } from "components/icons/CalendarIcon";
import { EmailCheckIcon } from "components/icons/EmailCheckIcon";
import { EmailIcon } from "components/icons/EmailIcon";

import { NotificationProcess } from "modules/notifications/application/NotificationProcess";

interface IProps {
  trackedNotification: NotificationProcess | null;
}

export const TrackedNotification = ({ trackedNotification }: IProps) => {
  if (!trackedNotification) {
    return (
      <VStack py={6}>
        <EmailCheckIcon size={"32px"} />
        <Text color="gray.600" textAlign="center">
          {t("Brak powiadomień oczekujących na wysyłkę")}
        </Text>
      </VStack>
    );
  }

  if (trackedNotification.plannedSendAt) {
    return (
      <VStack py={3} align="stretch">
        <Text mt={-2} fontSize="sm" color="gray.500">
          {t("Najbliższa zaplanowana wysyłka")}
        </Text>
        <Text fontWeight="semibold" fontSize="lg" isTruncated>
          {trackedNotification.subject}
        </Text>
        <HStack>
          <CalendarIcon size="18px" color="purple.500" />
          <Text color="gray.600">{t("Planowana wysyłka")}</Text>
          <Spacer />
          <Text fontWeight="semibold">
            <FormatDateTime
              date={trackedNotification.plannedSendAt}
              seconds={false}
            />
          </Text>
        </HStack>
        <HStack>
          <EmailIcon size="18px" color="purple.500" />
          <Text color="gray.600">{t("Liczba wiadomości")}</Text>
          <Spacer />
          <Text fontWeight="semibold">{trackedNotification.emailsTotal}</Text>
        </HStack>
      </VStack>
    );
  }

  return (
    <VStack py={3} align="stretch">
      <Text mt={-2} fontSize="sm" color="gray.500">
        {t("Trwająca wysyłka")}
      </Text>
      <Text fontWeight="semibold" fontSize="lg" isTruncated>
        {trackedNotification.subject}
      </Text>
      <HStack>
        <EmailIcon size="18px" color="purple.500" />
        <Text color="gray.600">{t("Wysłane wiadomości")}</Text>
        <Spacer />
        <Text fontWeight="semibold">
          {t("{{sentEmails}} / {{totalEmails}}", {
            sentEmails: trackedNotification.emailsSent,
            totalEmails: trackedNotification.emailsTotal,
          })}
        </Text>
      </HStack>
      <Progress
        rounded="md"
        colorScheme="gray"
        sx={{
          "div[role=progressbar]": {
            bgColor: "gray.900",
          },
        }}
        value={
          (trackedNotification.emailsSent / trackedNotification.emailsTotal) *
          100
        }
      />
      <HStack>
        <CalendarIcon size="18px" color="purple.500" />
        <Text color="gray.600">{t("Szacowany czas")}</Text>
        <Spacer />
        <Text fontWeight="semibold">
          {t("{{amount}} min", {
            amount: Math.round((trackedNotification.emailsTotal / 20) * 5),
          })}
        </Text>
      </HStack>
    </VStack>
  );
};
