import { useParams } from "react-router-dom";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

interface MutationVariables {
  accountName: string;
  login: string;
}

export const useEditUser = (contextId: string) => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["edit-user"],
    mutationFn: (variables: MutationVariables) => {
      return client.put(
        `organizations/${organizationId}/contexts/${contextId}/userData`,
        {
          body: {
            name: variables.accountName,
            login: variables.login,
          },
        }
      );
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["organizations", organizationId, "contexts"],
      });
    },
  });
};
