import { ReactNode } from "react";

import { Box, Flex } from "@chakra-ui/react";

import { LanguageMenu } from "../LanguageMenu";

interface IProps {
  children: ReactNode;
}

export const PublicLayout = ({ children }: IProps) => {
  return (
    <>
      <Flex position="fixed" top="4" right="4">
        <LanguageMenu />
      </Flex>
      <Box>{children}</Box>
    </>
  );
};
