import { Button, Tooltip } from "@chakra-ui/react";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";
import { PowerIcon } from "components/icons/PowerIcon";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";

import { ToggleOrganizationConfirm } from "../OrganizationsTable/ToggleOrganizationConfirm";

interface IProps {
  isDisabled: boolean;
  organizationId: string;
  isActive: boolean;
  organizationName: string;
}

export const ToggleOrganizationBtn = ({
  isDisabled,
  organizationId,
  isActive,
  organizationName,
}: IProps) => {
  const id = `toggle-organization-${organizationId}`;
  const onOpen = useOpenDialog(id);

  const context = ContextMachineContext.useSelector((state) => state.context)
    .userInfo?.currentlySelectedContext?.type;

  if (context !== "system_manager") {
    return null;
  }

  return (
    <>
      <ToggleOrganizationConfirm
        id={`toggle-organization-${organizationId}`}
        isActive={isActive}
        organizationId={organizationId}
        name={organizationName}
      />
      <Tooltip label={isDisabled ? t("Brak aktywnego pakietu") : undefined}>
        <Button
          leftIcon={<PowerIcon />}
          variant="outline"
          colorScheme="purple"
          size="sm"
          data-testid="toggle-organization"
          isDisabled={isDisabled}
          onClick={onOpen}
          flexShrink={0}
        >
          {isActive ? t("Dezaktywuj") : t("Aktywuj")}
        </Button>
      </Tooltip>
    </>
  );
};
