import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

interface MutationVariables {
  name: string;
  surname: string;
  email: string;
  type: "system_admin" | "system_manager";
}

export const useAddSystemContext = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["add-system-context"],
    mutationFn: (variables: MutationVariables) => {
      return client.post(`system/contexts`, {
        body: { ...variables },
      });
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["system", "contexts"],
      });
    },
  });
};
