import { Button } from "@chakra-ui/react";
import { t } from "i18next";

import { PlusIcon } from "components/icons/PlusIcon";

import { useOpenTariffModal } from "./AddTariffModal";

export const AddTariffButton = () => {
  const onOpen = useOpenTariffModal();

  return (
    <>
      <Button
        size="sm"
        leftIcon={<PlusIcon />}
        colorScheme="purple"
        onClick={() => onOpen()}
      >
        {t("Dodaj pakiet")}
      </Button>
    </>
  );
};
