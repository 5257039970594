import { useSearchParams } from "react-router-dom";

import { Button, IconButton, useBreakpoint } from "@chakra-ui/react";
import { t } from "i18next";

import { EditIcon } from "components/icons/EditIcon";

export const EditNotificationBtn = ({
  notificationProcessId,
}: {
  notificationProcessId: string;
}) => {
  const breakpoint = useBreakpoint();
  const [, setSearchParams] = useSearchParams();

  const handleClick = () => {
    setSearchParams({ notificationProcessId });
    const element = document.getElementById("notification-form");
    element?.scrollIntoView({ behavior: "smooth" });
  };

  if (breakpoint === "base" || breakpoint === "lg") {
    return (
      <IconButton
        aria-label={t("Edytuj")}
        icon={<EditIcon />}
        title={t("Edytuj")}
        onClick={handleClick}
        data-testid="edit-notification-button"
      />
    );
  }

  return <Button onClick={handleClick}>{t("Edytuj")}</Button>;
};
