import {
  Button,
  InputGroup,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { useIsMutating } from "@tanstack/react-query";
import { t } from "i18next";
import { HTTPError } from "ky";

import { Modal } from "components/Dialog";
import { toastWithError } from "components/ErrorHandling/toastWithError";

import { useEditTariff } from "modules/tariffs/infrastructure/useEditTariff";

import { ITariffFormData, TariffModalForm } from "../TariffModalForm";

interface IProps {
  id: string;
  tariffId: string;
}

export const EditTariffModal = ({ id, tariffId }: IProps) => {
  const { mutateAsync: editTariff } = useEditTariff(tariffId);

  const isMutating = useIsMutating({
    mutationKey: ["edit-tariff"],
  });

  return (
    <Modal<ITariffFormData> id={id}>
      {({ onClose, payload }) => {
        return (
          <>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{t("Edytuj pakiet")}</ModalHeader>
              <ModalBody as={VStack} align="stretch" spacing={6}>
                <TariffModalForm
                  onClose={onClose}
                  onSubmit={async (data: ITariffFormData) => {
                    try {
                      await editTariff(data);
                    } catch (error) {
                      if (error instanceof HTTPError) {
                        const errorJson = await error.response.json<{
                          message: string;
                        }>();

                        if (
                          errorJson.message ===
                          "tariff.error.nextTariffStartsBeforeCurrent"
                        ) {
                          toastWithError({
                            description: t(
                              "Oczekujący pakiet nie może zaczynać się przed aktualnym."
                            ),
                            error,
                          });
                          return;
                        }
                      }
                      toastWithError({
                        description: t(
                          "Nie udało się edytować pakietu. Skontaktuj się z administratorem."
                        ),
                        error,
                      });
                    }
                  }}
                  defaultData={payload}
                />
              </ModalBody>
              <ModalFooter>
                <InputGroup gap="2" justifyContent="end">
                  <Button onClick={onClose} isLoading={!!isMutating}>
                    {t("Anuluj")}
                  </Button>
                  <Button
                    colorScheme="purple"
                    type="submit"
                    form="tariff-form"
                    data-testid="save-tariff-changes-button"
                    isLoading={!!isMutating}
                  >
                    {t("Zapisz")}
                  </Button>
                </InputGroup>
              </ModalFooter>
            </ModalContent>
          </>
        );
      }}
    </Modal>
  );
};
