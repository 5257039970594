import { IconButton, Tooltip } from "@chakra-ui/react";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";
import { DeleteIcon } from "components/icons/DeleteIcon";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";
import { ManagerContext } from "modules/organization/application/types/IOrganizationContext";

import { RemoveManagerConfirm } from "./RemoveManagerConfirm";

interface IProps {
  manager: ManagerContext;
  onlyOneActive: boolean;
}

export const RemoveManagerBtn = ({ manager, onlyOneActive }: IProps) => {
  const id = `remove-${manager.id}`;
  const onOpen = useOpenDialog(id);

  const { userInfo } = ContextMachineContext.useSelector(
    (state) => state.context
  );

  if (onlyOneActive) {
    return null;
  }

  if (
    userInfo?.currentlySelectedContext?.type === "context_manager" &&
    manager.id === userInfo?.currentlySelectedContext?.id
  ) {
    return null;
  }

  return (
    <>
      <RemoveManagerConfirm
        id={id}
        contextId={manager.id}
        name={manager.name}
      />
      <Tooltip label={t("Usuń")} aria-label={t("Usuń")}>
        <IconButton
          variant="link"
          colorScheme="purple"
          size="sm"
          data-testid="remove-contextId"
          onClick={onOpen}
          aria-label={t("Usuń")}
          icon={<DeleteIcon />}
        />
      </Tooltip>
    </>
  );
};
