import React, { Children, ReactElement, ReactNode } from "react";

import { Flex } from "@chakra-ui/react";

interface IProps {
  children: ReactNode;
}

export const DescriptionListItemsInColumn = (props: IProps) => {
  return (
    <Flex flexFlow={{ base: "column", md: "row" }}>
      {Children.map(props.children, (element) =>
        React.cloneElement(element! as unknown as ReactElement, {
          flexFlow: "column",
        })
      )}
    </Flex>
  );
};
