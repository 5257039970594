import { useNavigate } from "react-router-dom";

import { IconButton, Tooltip } from "@chakra-ui/react";

import { ArrowRightBold } from "components/icons/ArrowRightBold";

interface ILinkCellProps {
  path: string;
  label: string;
}

export const LinkCell = ({ path, label }: ILinkCellProps) => {
  const navigate = useNavigate();

  return (
    <Tooltip label={label} aria-label={label}>
      <IconButton
        variant="link"
        colorScheme="purple"
        size="sm"
        data-testid="go-to-path"
        onClick={() => {
          navigate(path);
        }}
        aria-label={label}
        icon={<ArrowRightBold />}
      />
    </Tooltip>
  );
};
