import { useParams } from "react-router-dom";

import {
  Card,
  CardBody,
  HStack,
  Heading,
  Skeleton,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";

import { IOrganization } from "modules/organization/application/types/IOrganization";
import { useOrganizationByIdQuery } from "modules/organization/infrastructure/useOrganizationByIdQuery";

interface IProps {
  isLoading: boolean;
  organization: IOrganization;
}

const OrganizationPreviewWidgetConnected = ({
  isLoading,
  organization,
}: IProps) => {
  if (isLoading) {
    return (
      <Stack>
        <Skeleton height="78px" />
      </Stack>
    );
  }

  return (
    <HStack justifyContent="space-between" height="100%">
      <VStack align="stretch" spacing="0">
        <Heading
          size={
            organization.details.displayName.length >= 150
              ? "sm"
              : { base: "sm", md: "md" }
          }
        >
          {organization.details.displayName}
        </Heading>
        <Text>{organization.details.address.street}</Text>
      </VStack>
    </HStack>
  );
};

export const OrganizationPreviewWidget = () => {
  const { organizationId } = useParams<{ organizationId: string }>();

  const { data, isLoading } = useOrganizationByIdQuery(organizationId!);

  return (
    <Card>
      <CardBody py="3" px="6">
        <OrganizationPreviewWidgetConnected
          isLoading={isLoading}
          organization={data?.organization}
        />
      </CardBody>
    </Card>
  );
};
