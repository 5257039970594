import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

interface MutationVariables {
  isActive: boolean;
  keyId: string;
}

export const useToggleIntegrationKeyActivity = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["toggle-integration-key"],
    mutationFn: (variables: MutationVariables) => {
      if (variables.isActive) {
        return client.post(`integrationKeys/${variables.keyId}/disable`);
      }
      return client.post(`integrationKeys/${variables.keyId}/enable`);
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["integration-keys"],
      });
    },
  });
};
