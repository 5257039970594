import { ComponentProps } from "react";

import { IconButton, Tooltip } from "@chakra-ui/react";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";
import { DeleteIcon } from "components/icons/DeleteIcon";

import { RemoveOrganizationConfirm } from "./RemoveOrganizationConfirm";

type IProps = Omit<ComponentProps<typeof RemoveOrganizationConfirm>, "id">;

export const RemoveOrganizationButon = (props: IProps) => {
  const id = `remove-organization-${props.organizationId}`;
  const onOpen = useOpenDialog(id);

  return (
    <>
      <RemoveOrganizationConfirm id={id} {...props} />
      <Tooltip label={t("Usuń podmiot")}>
        <IconButton
          variant="link"
          colorScheme="purple"
          size="sm"
          data-testid="remove-organization-btn"
          onClick={onOpen}
          aria-label={t("Usuń podmiot")}
          icon={<DeleteIcon />}
        />
      </Tooltip>
    </>
  );
};
