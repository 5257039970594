import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import {
  AbsoluteCenter,
  Box,
  Button,
  Card,
  CardBody,
  Divider,
  Flex,
  Input,
  VStack,
  chakra,
} from "@chakra-ui/react";
import { t } from "i18next";
import { settings } from "settings/getSettings";

import { FormControl } from "components/Form/FormControl";

import { ContextMachineContext } from "../application/ContextMachineContext";

interface FormValues {
  login: string;
  password: string;
}

export const LoginForm = () => {
  const { send } = ContextMachineContext.useActorRef();
  const value = ContextMachineContext.useSelector((state) => state.value);
  const navigate = useNavigate();
  const isRegistrationEnabled = settings.IS_REGISTRATION_ENABLED;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  return (
    <Card w={[330, 420]}>
      <CardBody p={8}>
        <form
          id="login-form"
          noValidate
          onSubmit={handleSubmit(async (data) => {
            send({
              type: "login",
              username: data.login,
              password: data.password,
            });
          })}
        >
          <VStack align="stretch" spacing={6}>
            <FormControl
              formLabel={t("Login lub e-mail")}
              formErrorMessage={errors.login?.message}
            >
              <Input
                {...register("login", {
                  required: t("Pole jest wymagane."),
                  setValueAs: (value) => value.trim(),
                })}
              />
            </FormControl>
            <FormControl
              formLabel={t("Hasło")}
              formErrorMessage={errors.password?.message}
            >
              <Input
                type="password"
                {...register("password", {
                  required: t("Pole jest wymagane."),
                })}
              />
            </FormControl>
            <Flex justifyContent="flex-end">
              <Link to="/zapomnialem-hasla">
                <chakra.a
                  color="purple.500"
                  fontSize="sm"
                  fontWeight="600"
                  _hover={{ textDecoration: "underline" }}
                >
                  {t("Nie pamiętam hasła")}
                </chakra.a>
              </Link>
            </Flex>
            <Button
              isLoading={value === "logIn"}
              type="submit"
              colorScheme="purple"
              w="100%"
              mt="4"
            >
              {t("Zaloguj się")}
            </Button>
          </VStack>
        </form>
        {isRegistrationEnabled && (
          <>
            <Box position="relative" py={8} w="100%">
              <Divider />
              <AbsoluteCenter
                px="4"
                bgColor="white"
                fontSize="sm"
                minW="max-content"
              >
                {t("Nie masz konta?")}
              </AbsoluteCenter>
            </Box>
            <Button
              variant="outline"
              colorScheme="purple"
              w="100%"
              as={Link}
              onClick={() => navigate("/rejestracja")}
            >
              {t("Zarejestruj się")}
            </Button>
          </>
        )}
      </CardBody>
    </Card>
  );
};
