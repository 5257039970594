import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

interface MutationVariables {
  name: string;
  additionalInformation: string;
  iframeUrl: string;
}

export const useEditIntegrationKey = (keyId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["edit-integration-key"],
    mutationFn: (variables: MutationVariables) => {
      return client.put(`integrationKeys/${keyId}`, {
        body: {
          ...variables,
        },
      });
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["integration-keys"],
      });
    },
  });
};
