import dayjs from "dayjs";

interface IProps {
  date: string;
}

export const FormatDate = ({ date }: IProps) => {
  if (!date || !dayjs(date).isValid()) {
    return <>---</>;
  }
  return <>{dayjs(date).format("DD.MM.YYYY")}</>;
};
