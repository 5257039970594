import { Grid } from "@chakra-ui/react";

import { NoData } from "components/EmptyStates/NoData";

interface IProps {
  title: string;
  children?: React.ReactNode;
}

export const TableEmptyState = ({ title, children }: IProps) => {
  return (
    <Grid
      placeItems="center"
      border="2px dashed var(--chakra-colors-gray-300)"
      borderRadius="md"
      h="100%"
      w="100%"
      py={3}
    >
      <NoData title={title} />
      {children}
    </Grid>
  );
};
