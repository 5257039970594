import { Link, useParams } from "react-router-dom";

import {
  Card,
  CardBody,
  Heading,
  Skeleton,
  VStack,
  Link as ChakraLink,
  Text,
  HStack,
  Spacer,
  ButtonGroup,
} from "@chakra-ui/react";
import { t } from "i18next";

import { ArrowLeftIcon } from "components/icons/ArrowLeftIcon";

import { useNotificationProcessByIdQuery } from "modules/notifications/infrastructure/useNotificationProcessByIdQuery";
import { CorrectionButton } from "modules/notifications/presentation/CorrectionModal/CorrectionButton";
import { CorrectionModal } from "modules/notifications/presentation/CorrectionModal/CorrectionModal";
import { NotificationDetailsCard } from "modules/notifications/presentation/NotificationDetailsCard";
import { NotificationEmailsTable } from "modules/notifications/presentation/NotificationEmailsTable";
import { CreateBasedOnNotificationBtn } from "modules/notifications/presentation/SentNotifications/CreateBasedOnNotificationBtn";

export const Notification = () => {
  const { notificationProcessId } = useParams<{
    notificationProcessId: string;
  }>();
  const { data, isLoading } = useNotificationProcessByIdQuery(
    notificationProcessId!
  );

  return (
    <>
      <CorrectionModal />
      <VStack align="stretch" gap="8">
        <GoBackLink />
        <Card>
          <CardBody px={4} py={6}>
            {isLoading ? (
              <Skeleton h="35px" />
            ) : (
              <HStack>
                <Heading
                  fontSize="lg"
                  isTruncated
                  title={data?.notificationsProcess.subject}
                >
                  {data?.notificationsProcess.subject}
                </Heading>
                <Spacer />
                <ButtonGroup size="sm" colorScheme="purple" variant="outline">
                  <CreateBasedOnNotificationBtn
                    notificationProcessId={notificationProcessId!}
                    smallBreakpoints={["base"]}
                  />
                  <CorrectionButton
                    smallBreakpoints={["base"]}
                    notificationProcess={data?.notificationsProcess}
                  />
                </ButtonGroup>
              </HStack>
            )}
          </CardBody>
        </Card>
        <VStack gap="6" align="stretch">
          <NotificationDetailsCard
            isLoading={isLoading}
            notificationProcess={data?.notificationsProcess}
          />
          <NotificationEmailsTable />
        </VStack>
      </VStack>
    </>
  );
};

const GoBackLink = () => {
  return (
    <ChakraLink
      as={Link}
      to="/powiadomienia"
      color="purple.500"
      w="max-content"
      mb={-4}
    >
      <HStack w="max-content" spacing="1">
        <ArrowLeftIcon size="16px" />
        <Text fontSize="sm">{t("Powrót")}</Text>
      </HStack>
    </ChakraLink>
  );
};
