import { useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Link, VStack } from "@chakra-ui/react";
import { t } from "i18next";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";
import { useAllTariffsQuery } from "modules/tariffs/infrastructure/useAllTariffsQuery";

export const OrganizationNavigation = () => {
  const context = ContextMachineContext.useSelector((state) => state.context)
    .userInfo?.currentlySelectedContext?.type;
  const [offsetHeight, setOffsetHeight] = useState(0);

  const managerContexts = ["system_admin", "system_manager"];

  useLayoutEffect(() => {
    const alert: HTMLElement | null = document.querySelector("#no-user-alert");

    const offsetHeight = alert ? alert.offsetHeight + 8 : 0;
    setOffsetHeight(offsetHeight);
  }, []);

  return (
    <VStack
      align="stretch"
      pl={3}
      minW="160px"
      spacing="2"
      color="gray.600"
      position="sticky"
      top={`${offsetHeight}px`}
      display={{ base: "none", lg: "flex" }}
    >
      {context === "system_admin" && <Link href="#tariffs">{t("Pakiet")}</Link>}
      {managerContexts.includes(context!) && <TariffsTabs />}
      <Link href="#organization-description">{t("Dane podmiotu")}</Link>
      <Link href="#api-keys">{t("Klucz API")}</Link>
      <Link href="#managers">{t("Administratorzy")}</Link>
      <Link href="#users">{t("Użytkownicy")}</Link>
      <Link href="#interpretation-request-history-table">
        {t("Interpretacje")}
      </Link>
    </VStack>
  );
};

const TariffsTabs = () => {
  const { organizationId } = useParams<{ organizationId: string }>();

  const { data } = useAllTariffsQuery(organizationId!);
  const isDraft = !!data?.tariffs.draft;
  return (
    <>
      {isDraft ? (
        <Link href="#tariff-draft">{t("Propozycja klienta")}</Link>
      ) : (
        <>
          <Link href="#current-tariff">{t("Aktualny pakiet")}</Link>
          <Link href="#next-tariff">{t("Oczekujący pakiet")}</Link>
        </>
      )}
    </>
  );
};
