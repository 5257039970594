import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

import { BaseOrganization } from "../application/types/BaseOrganization";

export interface Person extends BaseOrganization {
  surname: string;
}

export const useAddPerson = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["organizations", "person"],
    mutationFn: (body: Person) => client.post("organizations/person", { body }),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ["organizations"] });
    },
  });
};
