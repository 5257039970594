import { Button } from "@chakra-ui/react";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";
import { PowerIcon } from "components/icons/PowerIcon";

import { ITariff } from "modules/tariffs/application/types/ITariff";

import { ToggleTariffConfirm } from "./ToggleTariffConfirm";

interface IProps {
  tariff: ITariff | null;
}

export const ToggleTariffButton = ({ tariff }: IProps) => {
  const id = `toggle-tariff-${tariff?.id}`;
  const onOpen = useOpenDialog(id);

  if (!tariff) {
    return null;
  }

  return (
    <>
      <ToggleTariffConfirm
        id={id}
        tariffId={tariff.id}
        isActive={tariff.isActive}
      />
      <Button
        leftIcon={<PowerIcon />}
        variant="outline"
        colorScheme="purple"
        size="sm"
        data-testid="toggle-tariff"
        onClick={onOpen}
      >
        {tariff.isActive ? t("Dezaktywuj") : t("Aktywuj")}
      </Button>
    </>
  );
};
