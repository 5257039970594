import { createContext, ReactNode, useContext } from "react";

import {
  ChakraProps,
  Heading,
  HeadingProps,
  HStack,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";

interface Props {
  title?: string | ReactNode;
  children: ReactNode;
  actions?: ReactNode;
  description?: string | ReactNode;
  variant?: "default" | "in-card";
  size?: HeadingProps["size"];
  isDivided?: boolean;
}

interface ContextValue {
  padding: ChakraProps["padding"];
  divider: ChakraProps["border"];
}

const context = createContext<ContextValue>({
  padding: "20px 24px",
  divider: "1px solid var(--chakra-colors-gray-200)",
});

interface ProviderProps {
  value: ContextValue;
  children: ReactNode;
}

const DescriptionListProvider = (props: ProviderProps) => {
  return (
    <context.Provider value={props.value}>{props.children}</context.Provider>
  );
};

export const useDescriptionListConsumer = () => {
  return useContext(context);
};

export const DescriptionList = ({
  title,
  description,
  variant = "default",
  isDivided = true,
  size = "md",
  children,
  actions,
}: Props) => {
  const bg = useColorModeValue("#FFF", "gray.700");
  const padding = variant === "in-card" ? "20px 24px" : "24px 0px";
  const divider = isDivided
    ? {
        base: "none",
        md: "1px solid var(--chakra-colors-gray-200)",
      }
    : "none";

  return (
    <DescriptionListProvider value={{ padding, divider }}>
      <VStack
        spacing={0}
        align="stretch"
        bg={variant === "default" ? "none" : bg}
        borderRadius="md"
      >
        {(title || description) && (
          <HStack
            justify="space-between"
            padding={padding}
            borderBottom="1px solid var(--chakra-colors-gray-200)"
            flex={1}
          >
            <div>
              {title && (
                <Heading m={0} size={size}>
                  {title}
                </Heading>
              )}
              {description && (
                <Text fontSize="sm" color="gray.500" mt={1}>
                  {description}
                </Text>
              )}
            </div>
            {actions}
          </HStack>
        )}
        {children}
      </VStack>
    </DescriptionListProvider>
  );
};
