import { useNavigate } from "react-router-dom";

import { Grid, HStack, Skeleton, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import { t } from "i18next";
import sortBy from "lodash/sortBy";

import { EmptyState } from "components/EmptyStates/EmptyState";
import { StackedList } from "components/StackedList";
import { StackedListItem } from "components/StackedList/StackedListItem";
import { CalendarIcon } from "components/icons/CalendarIcon";
import { NoDataIcon } from "components/icons/NoDataIcon";

import { NotificationProcess } from "modules/notifications/application/NotificationProcess";
import { useNotificationProcessQuery } from "modules/notifications/infrastructure/useNotificationProcessQuery";

import { DeleteNotificationBtn } from "../NotificationDraftCard/DeleteNotificationBtn";
import { StartStopNotificationBtn } from "./StartStopNotificationBtn";

interface IProps {
  isLoading: boolean;
  data: NotificationProcess[];
}

const PendingNotificationsConnected = ({ isLoading, data }: IProps) => {
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <StackedList>
        <Skeleton h="140px" />
        <Skeleton h="140px" />
        <Skeleton h="140px" />
      </StackedList>
    );
  }

  if (data.length === 0) {
    return (
      <Grid placeItems="center" h="100%" w="100%">
        <EmptyState
          icon={<NoDataIcon />}
          size="xs"
          title={
            <Text fontWeight="500">
              {t("Brak powiadomień oczekujących na wysyłkę")}
            </Text>
          }
        />
      </Grid>
    );
  }

  const sortedRecords = sortBy(data, function (data) {
    return new Date(data.plannedSendAt || data.lockedAt!);
  });

  return (
    <StackedList maxH="620px">
      {sortedRecords.map((notification) => {
        return (
          <StackedListItem
            key={notification.id}
            title={notification.subject}
            badges={notification.selectedTariffs}
            description={
              <HStack>
                <CalendarIcon size="16px" />
                <Text>
                  {t("Planowana wysyłka: {{sendAt}}", {
                    sendAt: notification.plannedSendAt
                      ? dayjs(notification.plannedSendAt).format(
                          "DD.MM.YYYY HH:mm"
                        )
                      : t("natychmiast"),
                  })}
                </Text>
              </HStack>
            }
            onClick={() => {
              navigate(`/powiadomienia/${notification.id}`);
            }}
            actions={
              <>
                <StartStopNotificationBtn notification={notification} />
                <RemovePendingNotificationBtn notification={notification} />
              </>
            }
          >
            <Text>
              {t("Wysłano: {{sentEmails}} z {{totalEmails}}", {
                sentEmails: notification.emailsSent,
                totalEmails: notification.emailsTotal,
              })}
            </Text>
          </StackedListItem>
        );
      })}
    </StackedList>
  );
};

export const PendingNotifications = () => {
  const { data, isLoading } = useNotificationProcessQuery();

  return (
    <PendingNotificationsConnected
      data={data?.notificationsProcesses.filter(
        ({ isLocked, isCompleted }) => isLocked && !isCompleted
      )}
      isLoading={isLoading}
    />
  );
};

const RemovePendingNotificationBtn = ({
  notification,
}: {
  notification: NotificationProcess;
}) => {
  if (!notification.plannedSendAt) return null;
  if (dayjs(notification.plannedSendAt).isBefore(dayjs())) return null;

  return (
    <DeleteNotificationBtn
      notificationProcessId={notification.id}
      smallBreakpoints={["base"]}
    />
  );
};
