import { QueryClient } from "@tanstack/react-query";
import { http, HttpResponse } from "msw";

import { client, withHost } from "utils/http";
import { useQuery } from "utils/useQuery";

import { Change } from "../application/types/Change";

const query = () => {
  return {
    queryKey: ["changelog"],
    queryFn: async () => client.get<{ changelog: Change[] }>(`changelog`),
  };
};

export const getChangelogQuery = (queryClient: QueryClient) => {
  return queryClient.ensureQueryData(query());
};

export const useChangelogQuery = () => {
  return useQuery(query());
};

export const getChangelogMock = (changelog: Change[] = []) => {
  return http.get(withHost("changelog"), () => {
    return HttpResponse.json<{ changelog: Change[] }>({
      changelog: [...changelog],
    });
  });
};
