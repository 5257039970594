import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

import { Person } from "./useAddPerson";

export const useEditPerson = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["organizations", "person"],
    mutationFn: (body: Person) =>
      client.put(`organizations/${organizationId}/person`, { body }),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ["organizations"] });
    },
  });
};
