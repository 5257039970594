import { Tag } from "@chakra-ui/react";
import { t } from "i18next";

interface IProps {
  isActive: boolean;
}

export const ActiveBadge = ({ isActive }: IProps) => {
  if (isActive) {
    return (
      <Tag size="sm" variant="subtle" colorScheme="green">
        {t("Aktywny")}
      </Tag>
    );
  }

  return (
    <Tag size="sm" variant="subtle" colorScheme="orange">
      {t("Nieaktywny")}
    </Tag>
  );
};
