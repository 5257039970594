import { IconButton, Tooltip } from "@chakra-ui/react";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";
import { DetailsIcon } from "components/icons/DetailsIcon";

import { IInterpretation } from "modules/verification/application/types/IInterpretation";

import { ShowInterpretationDetailsModal } from "./ShowInterpretationDetailsModal";

export const ShowInterpretationDetailsBtn = (props: IInterpretation) => {
  const id = `interpretation-details-modal-${props.id}`;
  const onOpen = useOpenDialog(id);

  const label = t("Szczegóły");

  return (
    <>
      <ShowInterpretationDetailsModal id={id} payload={props} />
      <Tooltip label={label} aria-label={label}>
        <IconButton
          variant="link"
          colorScheme="purple"
          size="sm"
          data-testid="open-interpretation-details-modal"
          onClick={onOpen}
          aria-label={label}
          icon={<DetailsIcon />}
        />
      </Tooltip>
    </>
  );
};
