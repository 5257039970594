import React from "react";
import ReactDOM from "react-dom/client";
import {
  createRoutesFromChildren,
  useLocation,
  useNavigationType,
  matchRoutes,
} from "react-router-dom";

import * as Sentry from "@sentry/react";
import { Providers } from "Providers";
import { changeLanguage } from "i18next";
import { getSettings } from "settings/getSettings";

import { useLanguageStore } from "utils/i18n/languageProvider";

import { NoSettingsFileState } from "components/EmptyStates/NoSettingsFileState";

import "./polyfills";
import "./utils/i18n";

getSettings()
  .then((settings) => {
    Sentry.init({
      dsn: "https://ed8870c0e605972b0851721753a8664f@sentry.madkom.pl/17",
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
          enableInp: true,
        }),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: 1.0,
      // tracePropagationTargets: [""],
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
      allowUrls: ["portal.weryfikacjapodpisu.pl"],
      denyUrls: ["localhost", "test-portal-sawpe.madkom.pl"],
      enabled: import.meta.env.PROD && import.meta.env.STORYBOOK !== "true",
      release: import.meta.env.CI_COMMIT_TAG,
    });

    if (settings?.INTERNATIONALIZATION)
      changeLanguage(useLanguageStore.getState().language);

    ReactDOM.createRoot(document.getElementById("root")!).render(
      <React.StrictMode>
        <Providers />
      </React.StrictMode>
    );
  })
  .catch(() => {
    ReactDOM.createRoot(document.getElementById("root")!).render(
      <React.StrictMode>
        <NoSettingsFileState />
      </React.StrictMode>
    );
  });
