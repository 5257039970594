import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Button, Card, CardBody, Flex, Text, VStack } from "@chakra-ui/react";
import { t } from "i18next";
import { HTTPError } from "ky";

import { toastWithError } from "components/ErrorHandling/toastWithError";
import { FormControl } from "components/Form/FormControl";
import { PasswordInput } from "components/Form/PasswordInput";

import { useResetPassword } from "modules/auth/infrastructure/useResetPassword";

import { PasswordValidationList } from "./PasswordValidationList";

interface FormValues {
  password: string;
  repeatPassword: string;
}

export const ResetPassword = ({ token }: { token: string }) => {
  const { mutateAsync: resetPassword } = useResetPassword();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
  } = useForm<FormValues>();

  const password = useWatch({
    control,
    name: "password",
  });

  const navigate = useNavigate();

  return (
    <Card w={[330, 420, 750]}>
      <CardBody p={8}>
        <form
          id="reset-password-form"
          noValidate
          onSubmit={handleSubmit(async (data) => {
            try {
              await resetPassword({
                password: data.password,
                token,
              });
              navigate("/");
            } catch (error) {
              if (error instanceof HTTPError) {
                const errorJson = await error.response.json<{
                  message: string;
                }>();

                if (errorJson.message === "context.error.tokenNotFound") {
                  return toastWithError({
                    description: t(
                      "Link jest niepoprawny lub stracił ważność."
                    ),
                    error,
                  });
                }
              }
              toastWithError({
                error,
              });
            }
          })}
        >
          <Flex
            gap="16"
            flexDirection={["column", "column", "row"]}
            alignItems="center"
          >
            <VStack align="stretch" spacing={6} w="100%" flex="1">
              <FormControl
                formLabel={t("Nowe hasło")}
                formErrorMessage={errors.password?.message}
                isRequired
              >
                <PasswordInput
                  register={{
                    ...register("password", {
                      required: t("Pole jest wymagane."),
                      validate: (value) => {
                        if (value.length < 8) {
                          return t(
                            "Hasło musi zawierać co najmniej {{minLength}} znaków.",
                            { minLength: 8 }
                          );
                        }

                        if (!/[A-Z]/.test(value)) {
                          return t(
                            "Hasło musi zawierać co najmniej jedną dużą literę"
                          );
                        }

                        if (!/[a-z]/.test(value)) {
                          return t(
                            "Hasło musi zawierać co najmniej jedną małą literę"
                          );
                        }

                        if (!/\d/.test(value)) {
                          return t(
                            "Hasło musi zawierać co najmniej jedną cyfrę"
                          );
                        }

                        if (!/\W|_/g.test(value)) {
                          return t(
                            "Hasło musi zawierać co najmniej jeden znak specjalny"
                          );
                        }

                        return true;
                      },
                    }),
                  }}
                />
              </FormControl>
              <FormControl
                formLabel={t("Powtórz nowe hasło")}
                formErrorMessage={errors.repeatPassword?.message}
                isRequired
              >
                <PasswordInput
                  register={{
                    ...register("repeatPassword", {
                      required: t("Pole jest wymagane."),
                      validate: (value) =>
                        value === password || t("Hasła nie są identyczne."),
                    }),
                  }}
                />
              </FormControl>
              <Button
                type="submit"
                colorScheme="purple"
                mt={4}
                isLoading={isSubmitting}
              >
                {t("Zapisz i przejdź do panelu")}
              </Button>
            </VStack>
            <VStack
              color="gray.600"
              align="stretch"
              flex="1"
              w="100%"
              spacing="4"
            >
              <Text>{t("Twoje hasło musi zawierać:")}</Text>
              <PasswordValidationList
                password={password || ""}
                rules={{
                  minLength: 8,
                  strength: "strong",
                }}
              />
            </VStack>
          </Flex>
        </form>
      </CardBody>
    </Card>
  );
};
