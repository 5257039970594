import { CheckIcon } from "components/icons/CheckIcon";
import { CloseIcon } from "components/icons/CloseIcon";

interface IProps {
  isSelected: boolean | null;
}

export const TrueFalseIcons = ({ isSelected }: IProps) => {
  if (isSelected === null) {
    return "---";
  }

  return isSelected ? (
    <CheckIcon height="24px" width="24px" data-testid="check-icon" />
  ) : (
    <CloseIcon height="24px" width="24px" data-testid="close-icon" />
  );
};
