import { ComponentProps } from "react";

import { mdiAccountCheckOutline } from "@mdi/js";

import { Icon } from "./Icon";

export const UserCheckIcon = (
  props: Omit<ComponentProps<typeof Icon>, "path">
) => {
  return <Icon {...props} path={mdiAccountCheckOutline} />;
};
