import {
  Button,
  InputGroup,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { useIsMutating } from "@tanstack/react-query";
import { t } from "i18next";

import { Modal, useOpenDialog } from "components/Dialog";

import { useAddUser } from "modules/organization/infrastructure/useAddUser";

import { IUserFormData, UserModalForm } from "./UserModalForm";

const id = `add-user-modal`;

export const useOpenAddUserModal = () => {
  const onOpen = useOpenDialog(id);

  return onOpen;
};

export const AddUserModal = () => {
  const { mutateAsync: addUser } = useAddUser();
  const isMutating = useIsMutating({
    mutationKey: ["add-user"],
  });

  return (
    <Modal id={id}>
      {({ onClose }) => {
        return (
          <>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{t("Dodaj użytkownika")}</ModalHeader>
              <ModalBody as={VStack} align="stretch" spacing={6}>
                <UserModalForm
                  onClose={onClose}
                  onSubmit={async (data: IUserFormData) => {
                    await addUser({
                      name: data.accountName,
                      login: data.login,
                      password: data.password,
                    });
                  }}
                />
              </ModalBody>
              <ModalFooter>
                <InputGroup gap="2" justifyContent="end">
                  <Button onClick={onClose} isLoading={!!isMutating}>
                    {t("Anuluj")}
                  </Button>
                  <Button
                    colorScheme="purple"
                    type="submit"
                    form="user-form"
                    data-testid="add-user-button"
                    isLoading={!!isMutating}
                  >
                    {t("Dodaj użytkownika")}
                  </Button>
                </InputGroup>
              </ModalFooter>
            </ModalContent>
          </>
        );
      }}
    </Modal>
  );
};
