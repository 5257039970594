import { useParams } from "react-router-dom";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

export const useAddApiKey = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["add-api-key"],
    mutationFn: () => {
      return client.post(`organizations/${organizationId}/apiKeys`, {});
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["organizations", organizationId, "apiKeys"],
      });
    },
  });
};
