import { QueryClient } from "@tanstack/react-query";
import { HttpResponse, http } from "msw";

import { client, withHost } from "utils/http";
import { useQuery } from "utils/useQuery";

import { IOrganization } from "../application/types/IOrganization";

const query = () => {
  return {
    queryKey: ["organizations", "all"],
    queryFn: () =>
      client.get<{ organizations: IOrganization[] }>(`organizations`),
  };
};

export const getOrganizationsQuery = (queryClient: QueryClient) => () => {
  return queryClient.ensureQueryData(query());
};

export const useOrganizationsQuery = () => {
  return useQuery(query());
};

export const getOrganizationsMock = (organizations: IOrganization[] = []) => {
  return http.get(withHost("organizations"), () => {
    return HttpResponse.json<{ organizations: IOrganization[] }>({
      organizations: [...organizations],
    });
  });
};
