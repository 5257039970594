import { QueryClient } from "@tanstack/react-query";
import { HttpResponse, http } from "msw";

import { client, withHost } from "utils/http";
import { useQuery } from "utils/useQuery";

import { IVerificationHistoryRecord } from "../application/types/IVerificationHistoryRecord";

const query = (contextId: string) => {
  return {
    queryKey: ["organizations", contextId, "verifications"],
    queryFn: () =>
      client.get<{ verifications: IVerificationHistoryRecord[] }>(
        `contexts/${contextId}/verifications`
      ),
  };
};
export const getContextVerificationsHistoryQuery =
  (queryClient: QueryClient) => (organizationId: string) => {
    return queryClient.ensureQueryData(query(organizationId));
  };

export const useContextVerificationsHistoryQuery = (organizationId: string) => {
  return useQuery(query(organizationId));
};

export const getContextVerificationsHistoryMock = (
  verifications: IVerificationHistoryRecord[] = []
) => {
  return http.get(withHost("contexts/:contextId/verifications"), () => {
    return HttpResponse.json<{ verifications: IVerificationHistoryRecord[] }>({
      verifications: [...verifications],
    });
  });
};
