import { IconButton, Tooltip } from "@chakra-ui/react";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";
import { EditIcon } from "components/icons/EditIcon";

import { UserContext } from "modules/organization/application/types/IOrganizationContext";

import { EditUserModal } from "./EditUserModal";
import { IUserFormData } from "./EditUserModalForm";

interface IProps {
  user: UserContext;
}

export const EditUserButton = ({ user }: IProps) => {
  const id = `edit-user-${user.id}`;

  const onOpen = useOpenDialog<IUserFormData>(id);

  const label = t("Edytuj");

  return (
    <>
      <EditUserModal id={id} contextId={user.id} />
      <Tooltip label={label} aria-label={label}>
        <IconButton
          variant="link"
          colorScheme="purple"
          size="sm"
          data-testid="edit-user"
          onClick={() =>
            onOpen({
              accountName: user.name,
              login: user.details.login,
            })
          }
          aria-label={label}
          icon={<EditIcon />}
        />
      </Tooltip>
    </>
  );
};
