import { useState } from "react";
import { Control, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardProps,
  Checkbox,
  Heading,
  HStack,
  Input,
  Skeleton,
  Spacer,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { t } from "i18next";
import { HTTPError } from "ky";

import { toastWithError } from "components/ErrorHandling/toastWithError";
import { FormControl } from "components/Form/FormControl";
import { HourSelect } from "components/Form/HourSelect";
import { TextEditor } from "components/Form/TextEditor";

import { useCompleteNotificationProcess } from "modules/notifications/infrastructure/useCompleteNotificationProcess";
import { useCreateNotificationProcess } from "modules/notifications/infrastructure/useCreateNotificationProcess";
import { useEditNotificationProcess } from "modules/notifications/infrastructure/useEditNotificationProcess";
import { useNotificationProcessByIdQuery } from "modules/notifications/infrastructure/useNotificationProcessByIdQuery";

import { NotificationFormData } from "./NotificationFormData";
import { TariffsTags } from "./TariffsTags";

interface IProps {
  defaultData?: Partial<NotificationFormData>;
}

const NotificationFormCardConnected = (props: IProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isIntermediate, setIsIntermediate] = useState(
    props.defaultData?.plannedDeliveryDate ? false : true
  );
  const {
    register,
    unregister,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    reset,
    setError,
    clearErrors,
  } = useForm<NotificationFormData>({
    defaultValues: {
      ...props.defaultData,
    },
  });

  const { mutateAsync: create } = useCreateNotificationProcess();
  const { mutateAsync: edit } = useEditNotificationProcess();
  const { mutateAsync: complete } = useCompleteNotificationProcess();

  const resetForm = () => {
    reset({
      content: "",
      title: "",
      tariffs: [],
      plannedDeliveryDate: null,
      plannedDeliveryHour: null,
    });
    setSearchParams({});
    clearErrors();
  };

  const createOrEdit = async (data: NotificationFormData) => {
    const notificationProcessId = searchParams.get("notificationProcessId");
    const createBasedOn = searchParams.get("createBasedOn");

    if (notificationProcessId && !createBasedOn) {
      return await edit({
        id: notificationProcessId,
        tariffTypes: data.tariffs,
        subject: data.title,
        body: data.content,
        sendAtDate: data.plannedDeliveryDate || null,
        sendAtTime: data.plannedDeliveryHour || null,
      });
    } else {
      return await create({
        tariffTypes: data.tariffs,
        subject: data.title,
        body: data.content,
        sendAtDate: data.plannedDeliveryDate || null,
        sendAtTime: data.plannedDeliveryHour || null,
      });
    }
  };

  return (
    <form id="notification-form" noValidate>
      <VStack align="stretch" spacing={6}>
        <TariffsTags control={control} />
        <FormControl
          formLabel={t("Tytuł wiadomości")}
          isRequired
          formErrorMessage={errors.title?.message}
        >
          <Input
            {...register("title", { required: t("Pole jest wymagane.") })}
          />
        </FormControl>
        <TextEditor
          control={control as unknown as Control}
          label={t("Treść wiadomości")}
          name="content"
        />
        <VStack align="start" spacing={2}>
          <Text fontWeight="500">{t("Planowany termin wysyłki")}</Text>
          <HStack as={"label"}>
            <Checkbox
              isChecked={isIntermediate}
              name="intermediateDelivery"
              onChange={() => {
                setIsIntermediate((prev) => !prev);
                unregister("plannedDeliveryDate");
                unregister("plannedDeliveryHour");
              }}
            />
            <Text>{t("wyślij natychmiast")}</Text>
          </HStack>
        </VStack>
        {!isIntermediate && (
          <FormControl
            formLabel={t("Inny termin wysyłki")}
            isRequired
            formErrorMessage={errors.plannedDeliveryDate?.message}
          >
            <HStack>
              <Input
                type="date"
                placeholder="dd.mm.rrrr"
                min={dayjs().format("YYYY-MM-DD")}
                maxW="60"
                {...register("plannedDeliveryDate", {
                  required: t("Pole jest wymagane."),
                })}
              />
              <HourSelect register={register("plannedDeliveryHour")} />
            </HStack>
          </FormControl>
        )}
        <Wrap mt="6">
          <Button
            type="submit"
            colorScheme="purple"
            variant="outline"
            isLoading={isSubmitting}
            onClick={handleSubmit(async (data) => {
              try {
                await createOrEdit(data);
                resetForm();
              } catch (error) {
                if (error instanceof HTTPError) {
                  const errorJson = await error.response.json<{
                    message: string;
                  }>();

                  if (
                    errorJson.message ===
                    "notificationProcess.error.noPastDates"
                  ) {
                    setError("plannedDeliveryDate", {
                      type: "manual",
                      message: t("Data nie może być z przeszłości."),
                    });
                    return;
                  }
                }

                toastWithError({
                  error,
                });
              }
            })}
          >
            {t("Zapisz wersję roboczą")}
          </Button>
          <Spacer />
          <WrapItem gap="2">
            <Button type="reset" colorScheme="gray" onClick={() => resetForm()}>
              {t("Resetuj")}
            </Button>
            <Button
              type="submit"
              colorScheme="purple"
              isLoading={isSubmitting}
              onClick={handleSubmit(async (data) => {
                try {
                  const { notificationsProcess } = await createOrEdit(data);
                  await complete(notificationsProcess.id);
                  resetForm();
                } catch (error) {
                  if (error instanceof HTTPError) {
                    const errorJson = await error.response.json<{
                      message: string;
                    }>();

                    if (
                      errorJson.message ===
                      "notificationProcess.error.noPastDates"
                    ) {
                      setError("plannedDeliveryDate", {
                        type: "manual",
                        message: t("Data nie może być z przeszłości."),
                      });
                      return;
                    }
                  }

                  toastWithError({
                    error,
                  });
                }
              })}
            >
              {t("Zaplanuj wysyłkę")}
            </Button>
          </WrapItem>
        </Wrap>
      </VStack>
    </form>
  );
};

const NotificationFormCardWithData = ({
  notificationProcessId,
}: {
  notificationProcessId: string;
}) => {
  const { data, isLoading } = useNotificationProcessByIdQuery(
    notificationProcessId
  );

  if (isLoading) {
    return (
      <VStack align="stretch" spacing={6}>
        <Skeleton h="55px" />;
        <Skeleton h="75px" />;
        <Skeleton h="275px" />;
        <Skeleton h="55px" />;
        <Skeleton h="40px" />;
      </VStack>
    );
  }

  return (
    <NotificationFormCardConnected
      defaultData={{
        content: data?.notificationsProcess.body,
        title: data?.notificationsProcess.subject,
        tariffs: data?.notificationsProcess.selectedTariffs,
        plannedDeliveryDate: data?.notificationsProcess.plannedSendAt
          ? dayjs(data?.notificationsProcess.plannedSendAt).format("YYYY-MM-DD")
          : null,
        plannedDeliveryHour: data?.notificationsProcess.plannedSendAt
          ? dayjs(data?.notificationsProcess.plannedSendAt).format("HH:mm")
          : null,
      }}
    />
  );
};

export const NotificationFormCard = (props: CardProps) => {
  const [searchParams] = useSearchParams();
  const notificationProcessId = searchParams.get("notificationProcessId");

  return (
    <Card variant="sawpe" {...props} id="notification-form">
      <CardHeader>
        <Heading fontSize="lg">{t("Przygotuj powiadomienie")}</Heading>
      </CardHeader>
      <CardBody>
        {notificationProcessId ? (
          <div key={notificationProcessId}>
            <NotificationFormCardWithData
              notificationProcessId={notificationProcessId}
            />
          </div>
        ) : (
          <NotificationFormCardConnected />
        )}
      </CardBody>
    </Card>
  );
};
