import { useState } from "react";

import {
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from "@chakra-ui/react";
import { t, changeLanguage } from "i18next";
import { settings } from "settings/getSettings";

import { useLanguageStore } from "utils/i18n/languageProvider";

import { GBFlagIcon } from "components/icons/GBFlagIcon";
import { PLFlagIcon } from "components/icons/PLFlagIcon";

export const LanguageMenu = () => {
  const isEnabled = settings.INTERNATIONALIZATION;
  const { setLanguage, language } = useLanguageStore();
  const [selectedLanguage, setSelectedLanguage] = useState(language);

  if (!isEnabled) {
    return null;
  }

  return (
    <Menu>
      <MenuButton as={Button} variant="ghost" minW="55px">
        {selectedLanguage.includes("pl") ? (
          <PLFlagIcon height="20px" />
        ) : (
          <GBFlagIcon height="20px" />
        )}
      </MenuButton>
      <MenuList>
        <MenuOptionGroup
          defaultValue={selectedLanguage}
          type="radio"
          onChange={(e) => {
            setLanguage(e as string);
            setSelectedLanguage(e as string);
            changeLanguage(e as string);
          }}
        >
          <MenuItemOption value="pl-PL">
            <HStack>
              <PLFlagIcon height="20px" />
              <span>{t("Polski")}</span>
            </HStack>
          </MenuItemOption>
          <MenuItemOption value="en-EN">
            <HStack alignItems="flex-start" justify="start">
              <GBFlagIcon height="20px" />
              <span>{t("Angielski")}</span>
            </HStack>
          </MenuItemOption>
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};
