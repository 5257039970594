import { useState } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

import {
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from "@chakra-ui/react";

import { HideIcon } from "components/icons/HideIcon";
import { ShowIcon } from "components/icons/ShowIcon";

interface Props extends InputProps {
  register: UseFormRegisterReturn;
}

export const PasswordInput = ({ register, ...rest }: Props) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <InputGroup>
      <Input
        type={showPassword ? "text" : "password"}
        {...register}
        {...rest}
      />
      <InputRightElement width="4.5rem">
        <IconButton
          aria-label="show password"
          h="1.75rem"
          size="sm"
          variant="ghost"
          colorScheme="purple"
          icon={showPassword ? <HideIcon /> : <ShowIcon />}
          onClick={() => setShowPassword(!showPassword)}
        />
      </InputRightElement>
    </InputGroup>
  );
};
