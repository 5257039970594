import { Link } from "react-router-dom";

import {
  chakra,
  Card,
  CardBody,
  CardHeader,
  GridItem,
  HStack,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";

import { OrganizationIcon } from "components/icons/OrganizationIcon";

import { TariffTypes } from "modules/tariffs/application/types/ITariff";

interface IProps {
  tariffType: TariffTypes;
  activeOrganizations: number;
}

export const TariffTile = ({ tariffType, activeOrganizations }: IProps) => {
  return (
    <GridItem>
      <Card>
        <CardHeader
          bgColor={getTariffColor(tariffType).bg}
          color={getTariffColor(tariffType).text}
          fontSize="lg"
          fontWeight="semibold"
          roundedTop="md"
        >
          <Link to={`/organizacje?tariff.type=${tariffType}`}>
            <HStack
              justifyContent="space-between"
              cursor="pointer"
              _hover={{ textDecoration: "underline" }}
            >
              <Text>{t("Pakiet {{tariffType}}", { tariffType })}</Text>
              <OrganizationIcon />
            </HStack>
          </Link>
        </CardHeader>

        <CardBody>
          <chakra.p fontSize="xl" fontWeight="semibold">
            {activeOrganizations}
          </chakra.p>
          <chakra.p fontSize="sm" color="gray.600">
            {t("Aktywne podmioty")}
          </chakra.p>
        </CardBody>
      </Card>
    </GridItem>
  );
};

const getTariffColor = (tariffType: TariffTypes) => {
  switch (tariffType) {
    case "S":
      return { text: "purple.700", bg: "purple.100" };
    case "M":
      return { text: "purple.800", bg: "purple.200" };
    case "L":
      return { text: "purple.900", bg: "purple.300" };
    case "E":
      return { text: "purple.900", bg: "purple.400" };
    default:
      // eslint-disable-next-line no-case-declarations, @typescript-eslint/no-unused-vars
      const type: never = tariffType;
      return { text: "gray.700", bg: "gray.100" };
  }
};
