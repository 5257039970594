import {
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Td,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { t } from "i18next";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";

import { ActiveBadge } from "components/ActiveBadge";
import { FormatDate } from "components/FormatDate";
import { Table } from "components/Table";
import { TableEmptyState } from "components/Table/TableEmptyState";
import { TableLoadingState } from "components/Table/TableLoadingState";

import { HiddenKey } from "modules/apiKey/presentation/ApiKeysTable/HiddenKey";
import { IIntegrationKey } from "modules/integrationKey/application/types/IIntegrationKey";
import { useIntegrationKeysQuery } from "modules/integrationKey/infrastructure/useIntegrationKeysQuery";

import { AddKeyButton } from "./AddKeyButton";
import { EditKeyButton } from "./EditKeyButton";
import { TestKeyButton } from "./TestKeyButton";
import { ToggleKeyButton } from "./ToggleKeyButton";

const columnHelper = createColumnHelper<IIntegrationKey>();

const columns = [
  columnHelper.accessor("name", {
    header: () => t("Adres instancji"),
    cell: (info) => (
      <Td whiteSpace="normal" data-testid="instance-address">
        {info.getValue()}
      </Td>
    ),
  }),
  columnHelper.accessor("organizationName", {
    header: () => t("Podmiot"),
    enableSorting: false,
    cell: (info) => <Td whiteSpace="normal">{info.getValue()}</Td>,
  }),
  columnHelper.accessor("key", {
    header: () => t("Klucz"),
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal">
        <HiddenKey apiKey={info.getValue()} />
      </Td>
    ),
  }),
  columnHelper.accessor("createdAt", {
    header: () => t("Data utworzenia"),
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal">
        <FormatDate date={info.getValue()} />
      </Td>
    ),
  }),
  columnHelper.accessor("active", {
    header: () => t("Aktywny"),
    cell: (info) => (
      <Td whiteSpace="normal" data-testid="active-badge">
        <ActiveBadge isActive={info.getValue()} />
      </Td>
    ),
  }),
  columnHelper.accessor("additionalInformation", {
    header: () => t("Komentarz"),
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal" data-testid="comment">
        {info.getValue()}
      </Td>
    ),
  }),
  columnHelper.accessor("id", {
    header: () => "",
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal" isNumeric>
        <ButtonGroup>
          <TestKeyButton
            hasIframeUrl={!!info.row.original.iframeUrl}
            keyId={info.getValue()}
          />
          <EditKeyButton integrationKey={info.row.original} />
          <ToggleKeyButton
            isActive={info.row.original.active}
            hasIframeUrl={!!info.row.original.iframeUrl}
            keyId={info.getValue()}
            organizationName={info.row.original.organizationName}
          />
        </ButtonGroup>
      </Td>
    ),
  }),
];

interface IProps {
  keys: IIntegrationKey[];
  isLoading: boolean;
}

const IntegrationKeysTableConnected = ({ keys, isLoading }: IProps) => {
  if (isLoading) {
    return <TableLoadingState />;
  }

  if (keys.length === 0) {
    return (
      <TableEmptyState title={t("Brak kluczy integracyjnych")}>
        <AddKeyButton />
      </TableEmptyState>
    );
  }

  const sortedRecords = reverse(
    sortBy(keys, function (data) {
      return new Date(data.createdAt);
    })
  );

  return <Table<IIntegrationKey> data={sortedRecords} columns={columns} />;
};

export const IntegrationKeysTable = () => {
  const { data, isLoading } = useIntegrationKeysQuery();

  return (
    <Card id="keys" variant="sawpe">
      <CardHeader>
        <Heading fontSize="lg">{t("Klucze integracyjne")}</Heading>
        <AddKeyButton />
      </CardHeader>
      <CardBody>
        <IntegrationKeysTableConnected
          keys={data?.integrationKeys}
          isLoading={isLoading}
        />
      </CardBody>
    </Card>
  );
};
