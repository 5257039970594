import { ReactNode } from "react";

import { Button, VStack } from "@chakra-ui/react";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { t } from "i18next";

import { EmptyState } from "components/EmptyStates/EmptyState";

interface IProps {
  children: ReactNode;
}

export const ErrorBoundary = ({ children }: IProps) => {
  return (
    <SentryErrorBoundary
      fallback={
        <VStack spacing={4} p={6}>
          <EmptyState
            title={t("Wystąpił błąd")}
            description={
              <VStack spacing="0">
                <span>{t("Spróbuj ponownie")}</span>
                <span>
                  {t(
                    "Jeśli błąd będzie się powtarzał, skontaktuj się z administratorem"
                  )}
                </span>
              </VStack>
            }
            size="sm"
          />
          <Button
            variant="outline"
            colorScheme="primary"
            onClick={() => window.location.reload()}
          >
            {t("Odśwież")}
          </Button>
        </VStack>
      }
    >
      {children}
    </SentryErrorBoundary>
  );
};
