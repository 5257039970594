import { useState } from "react";
import { FieldErrors, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import {
  Alert,
  AlertDescription,
  AlertIcon,
  Divider,
  GridItem,
  Heading,
  Input,
  Select,
  SimpleGrid,
} from "@chakra-ui/react";
import { t } from "i18next";
import { HTTPError } from "ky";

import { maxLength } from "utils/form/maxLength";
import { minLength } from "utils/form/minLength";

import { toastWithError } from "components/ErrorHandling/toastWithError";
import { CountriesField } from "components/Form/CountriesField";
import { FormControl } from "components/Form/FormControl";

import {
  Company,
  useAddCompany,
} from "modules/organization/infrastructure/useAddCompany";
import {
  Person,
  useAddPerson,
} from "modules/organization/infrastructure/useAddPerson";
import { useEditCompany } from "modules/organization/infrastructure/useEditCompany";
import { useEditPerson } from "modules/organization/infrastructure/useEditPerson";

import { FormValues } from "./FormValues";

interface Props {
  defaultValues?: FormValues;
  type: "company" | "person";
  isEdit?: boolean;
  onSuccess?(): void;
}

interface AdditionalFields {
  repeatEmail: string;
  contextManager: {
    repeatEmail: string;
  };
}

export const OrganizationForm = (props: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
  } = useForm<FormValues & AdditionalFields>({
    defaultValues: props.defaultValues,
  });
  const { organizationId } = useParams<{ organizationId: string }>();

  const { mutateAsync: addPerson } = useAddPerson();
  const { mutateAsync: addCompany } = useAddCompany();

  const { mutateAsync: editPerson } = useEditPerson(organizationId!);
  const { mutateAsync: editCompany } = useEditCompany(organizationId!);

  const [formType, setFormType] = useState<"company" | "person">(
    props.type ? props.type : "company"
  );
  const isCompany = formType === "company";

  return (
    <form
      id="organization-form"
      noValidate
      onSubmit={handleSubmit(async (values) => {
        try {
          if (props.isEdit && isCompany) {
            await editCompany(values as Company);
            props.onSuccess?.();
            return;
          }

          if (props.isEdit) {
            await editPerson(values as Person);
            props.onSuccess?.();
            return;
          }

          if (isCompany) {
            await addCompany(values as Company);
            props.onSuccess?.();
            return;
          }
          await addPerson(values as Person);
          props.onSuccess?.();
        } catch (error) {
          if (error instanceof HTTPError) {
            const errorJson = await error.response.json<{
              message: string;
            }>();

            if (errorJson.message === "context.error.nonUniqueLogin") {
              setError("contextManager.email", {
                type: "manual",
                message: t("Podany e-mail jest już zajęty"),
              });
              return;
            }
          }

          toastWithError({
            error,
          });
        }
      })}
    >
      <SimpleGrid columns={6} rowGap={6} columnGap={4}>
        <GridItem colSpan={4}>
          <FormControl formLabel={t("Typ podmiotu")} isRequired>
            <Select
              value={formType}
              onChange={(e) => setFormType(e.target.value as typeof formType)}
            >
              <option value="person">{t("Osoba fizyczna")}</option>
              <option value="company">{t("Osoba niefizyczna")}</option>
            </Select>
          </FormControl>
        </GridItem>
        {isCompany && (
          <GridItem colSpan={4}>
            <FormControl formLabel={t("Rodzaj podmiotu")} isRequired>
              <Select
                defaultValue="public_institution"
                {...register("type", { required: t("Pole jest wymagane.") })}
              >
                <option value="company">{t("Firma")}</option>
                <option value="public_institution">
                  {t("Instytucja publiczna")}
                </option>
                <option value="other">{t("Inne")}</option>
              </Select>
            </FormControl>
          </GridItem>
        )}
        <GridItem colSpan={3}>
          <FormControl
            formLabel={isCompany ? t("Nazwa") : t("Imię")}
            isRequired
            formErrorMessage={errors.name?.message}
          >
            <Input
              autoFocus
              {...register("name", {
                required: t("Pole jest wymagane."),
                minLength: minLength(),
                maxLength: maxLength(),
              })}
            />
          </FormControl>
        </GridItem>
        {!isCompany && (
          <GridItem colSpan={3}>
            <FormControl
              formLabel={t("Nazwisko")}
              isRequired
              formErrorMessage={
                (errors as FieldErrors<Person>).surname?.message
              }
            >
              <Input
                {...register("surname", {
                  required: t("Pole jest wymagane."),
                  minLength: minLength(),
                  maxLength: maxLength(),
                })}
              />
            </FormControl>
          </GridItem>
        )}
        <GridItem colSpan={4}>
          <CountriesField register={register("address.countryCode")} />
        </GridItem>
        <GridItem colSpan={2} colStart={1}>
          <FormControl
            formLabel={t("Kod pocztowy")}
            isRequired
            formErrorMessage={errors.address?.postCode?.message}
          >
            <Input
              {...register("address.postCode", {
                required: t("Pole jest wymagane."),
                minLength: minLength(5),
                maxLength: maxLength(6),
                validate: (value) => {
                  if (new RegExp(/^\d{2}-\d{3}$/).test(value) === false) {
                    return t("Kod pocztowy jest nie prawidłowy.");
                  }
                },
              })}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={4}>
          <FormControl
            formLabel={t("Miejscowość")}
            isRequired
            formErrorMessage={errors.address?.city?.message}
          >
            <Input
              {...register("address.city", {
                required: t("Pole jest wymagane."),
                minLength: minLength(),
                maxLength: maxLength(),
              })}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={4}>
          <FormControl
            formLabel={t("Ulica")}
            formErrorMessage={errors.address?.street?.message}
          >
            <Input
              {...register("address.street", {
                minLength: minLength(),
                maxLength: maxLength(),
              })}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={1}>
          <FormControl
            formLabel={t("Nr domu")}
            isRequired
            formErrorMessage={errors.address?.houseNumber?.message}
          >
            <Input
              {...register("address.houseNumber", {
                required: t("Pole jest wymagane."),
                maxLength: maxLength(15),
              })}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={1}>
          <FormControl formLabel={t("Nr lokalu")}>
            <Input {...register("address.flatNumber")} />
          </FormControl>
        </GridItem>
        <GridItem colSpan={4}>
          <FormControl
            formLabel={t("E-mail")}
            isRequired
            formErrorMessage={errors.email?.message}
          >
            <Input
              type="email"
              {...register("email", {
                setValueAs: (value) => value.trim(),
                required: t("Pole jest wymagane."),
                minLength: minLength(),
                maxLength: maxLength(),
                pattern: {
                  value: /[^@]+@[^.]+\..+/,
                  message: t("Niepoprawny format"),
                },
              })}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={4}>
          <FormControl
            formLabel={t("Powtórz e-mail")}
            isRequired
            formErrorMessage={errors.repeatEmail?.message}
          >
            <Input
              type="email"
              {...register("repeatEmail", {
                setValueAs: (value) => value.trim(),
                required: t("Pole jest wymagane."),
                validate: (value) =>
                  value === getValues("email") ||
                  t("Adresy e-mail nie są identyczne."),
              })}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={4}>
          <FormControl
            formLabel={t("Telefon")}
            formErrorMessage={errors.phone?.message}
          >
            <Input
              {...register("phone", {
                minLength: minLength(6),
                maxLength: maxLength(15),
              })}
            />
          </FormControl>
        </GridItem>
      </SimpleGrid>
      {!props.isEdit && (
        <>
          <Divider my={6} />
          <Heading fontSize="lg" mb={4}>
            {t("Dane administratora")}
          </Heading>
          <Alert status="info" variant="subtle" mb={4}>
            <AlertIcon />
            <AlertDescription>
              {t(
                "E-mail aktywacyjny zostanie wysłany do administratora po aktywacji podmiotu."
              )}
            </AlertDescription>
          </Alert>
          <SimpleGrid columns={6} rowGap={6} columnGap={4}>
            <GridItem colSpan={4}>
              <FormControl
                formLabel={t("Imię")}
                isRequired
                formErrorMessage={errors.contextManager?.name?.message}
              >
                <Input
                  {...register("contextManager.name", {
                    required: t("Pole jest wymagane."),
                    minLength: minLength(),
                    maxLength: maxLength(),
                  })}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={4}>
              <FormControl
                formLabel={t("Nazwisko")}
                isRequired
                formErrorMessage={errors.contextManager?.surname?.message}
              >
                <Input
                  {...register("contextManager.surname", {
                    required: t("Pole jest wymagane."),
                    minLength: minLength(),
                    maxLength: maxLength(),
                  })}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={4}>
              <FormControl
                formLabel={t("E-mail")}
                formHelperText={t(
                  "Na podany adres e-mail zostanie wysłana wiadomość z linkiem do ustawienia hasła."
                )}
                formErrorMessage={errors.contextManager?.email?.message}
                isRequired
              >
                <Input
                  type="email"
                  {...register("contextManager.email", {
                    setValueAs: (value) => value.trim(),
                    required: t("Pole jest wymagane."),
                    minLength: minLength(),
                    maxLength: maxLength(),
                    pattern: {
                      value: /[^@]+@[^.]+\..+/,
                      message: t("Niepoprawny format"),
                    },
                  })}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={4}>
              <FormControl
                formLabel={t("Powtórz e-mail")}
                formErrorMessage={errors.contextManager?.repeatEmail?.message}
                isRequired
              >
                <Input
                  type="email"
                  {...register("contextManager.repeatEmail", {
                    setValueAs: (value) => value.trim(),
                    required: t("Pole jest wymagane."),
                    validate: (value) =>
                      value === getValues("contextManager.email") ||
                      t("Adresy e-mail nie są identyczne."),
                  })}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={4}>
              <FormControl
                formLabel={t("Telefon")}
                formErrorMessage={errors.contextManager?.phone?.message}
              >
                <Input
                  {...register("contextManager.phone", {
                    minLength: minLength(6),
                    maxLength: maxLength(15),
                  })}
                />
              </FormControl>
            </GridItem>
          </SimpleGrid>
        </>
      )}
    </form>
  );
};
