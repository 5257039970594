import { useParams } from "react-router-dom";

import { MenuItem } from "@chakra-ui/react";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";
import { useOrganizationByIdQuery } from "modules/organization/infrastructure/useOrganizationByIdQuery";
import { ChangeUserPasswordModal } from "modules/organization/presentation/ChangeUserPasswordModal";

interface IProps {
  contextId: string;
  organizationId: string;
}

const ChangePasswordMenuItemConnected = ({
  contextId,
  organizationId,
}: IProps) => {
  const { data, isLoading } = useOrganizationByIdQuery(organizationId);
  const id = `change-password-${contextId}`;
  const onOpen = useOpenDialog(id);

  if (isLoading) {
    return null;
  }

  if (!data.organization.allowUserPasswordChange) {
    return null;
  }

  return (
    <>
      <ChangeUserPasswordModal id={id} contextId={contextId!} />
      <MenuItem onClick={onOpen}>{t("Zmiana hasła")}</MenuItem>
    </>
  );
};

export const ChangePasswordMenuItem = () => {
  const userInfo = ContextMachineContext.useSelector(
    (state) => state.context.userInfo
  )!;
  const contextId = userInfo?.currentlySelectedContext?.id;
  const { organizationId } = useParams<{ organizationId: string }>();

  const isContextOwner =
    userInfo &&
    userInfo.login === userInfo.currentlySelectedContext?.details.login;

  if (!isContextOwner || !organizationId) {
    return null;
  }
  return (
    <ChangePasswordMenuItemConnected
      contextId={contextId!}
      organizationId={organizationId}
    />
  );
};
