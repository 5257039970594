import { useForm } from "react-hook-form";

import { Input, VStack } from "@chakra-ui/react";
import { t } from "i18next";

import { maxLength } from "utils/form/maxLength";
import { minLength } from "utils/form/minLength";

import { toastWithError } from "components/ErrorHandling/toastWithError";
import { FormControl } from "components/Form/FormControl";

export interface ISystemContextFormData {
  name: string;
  surname: string;
  email: string;
  type: "system_admin" | "system_manager";
}

interface IProps {
  onClose: () => void;
  onSubmit: (payload: ISystemContextFormData) => Promise<void>;
  defaultData?: ISystemContextFormData;
  type: "system_admin" | "system_manager";
}

export const SystemContextModalForm = ({
  onClose,
  onSubmit,
  defaultData,
  type,
}: IProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ISystemContextFormData>({
    defaultValues: { ...defaultData, type },
  });

  return (
    <form
      id="system-context-form"
      onSubmit={handleSubmit(async (data) => {
        try {
          await onSubmit(data);
          onClose();
        } catch (error) {
          toastWithError({
            error,
          });
        }
      })}
    >
      <VStack align="stretch" spacing={6}>
        <FormControl
          formLabel={t("Imię")}
          formErrorMessage={errors.name?.message}
          isRequired
        >
          <Input
            {...register("name", {
              required: true,
              minLength: minLength(),
              maxLength: maxLength(),
            })}
          />
        </FormControl>
        <FormControl
          formLabel={t("Nazwisko")}
          formErrorMessage={errors.name?.message}
          isRequired
        >
          <Input
            {...register("surname", {
              required: true,
              minLength: minLength(),
              maxLength: maxLength(),
            })}
          />
        </FormControl>
        <FormControl
          formLabel={t("E-mail")}
          formErrorMessage={errors.name?.message}
          isRequired
        >
          <Input
            {...register("email", {
              required: true,
              minLength: minLength(),
              maxLength: maxLength(),
            })}
          />
        </FormControl>
      </VStack>
    </form>
  );
};
