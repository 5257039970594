import {
  Button,
  InputGroup,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { useIsMutating } from "@tanstack/react-query";
import { t } from "i18next";

import { Modal } from "components/Dialog";

import { useAddSystemContext } from "modules/system/infrastructure/useAddSystemContext";

import {
  ISystemContextFormData,
  SystemContextModalForm,
} from "./SystemContextModalForm";

interface IProps {
  id: string;
  type: "system_admin" | "system_manager";
}

export const AddSystemContextModal = ({ id, type }: IProps) => {
  const { mutateAsync: addSystemContext } = useAddSystemContext();
  const isMutating = useIsMutating({
    mutationKey: ["add-system-context"],
  });

  return (
    <Modal id={id}>
      {({ onClose }) => {
        return (
          <>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {type === "system_manager"
                  ? t("Dodaj zarządcę")
                  : t("Dodaj administratora")}
              </ModalHeader>
              <ModalBody as={VStack} align="stretch" spacing={6}>
                <SystemContextModalForm
                  onClose={onClose}
                  type={type}
                  onSubmit={async (e: ISystemContextFormData) => {
                    await addSystemContext(e);
                  }}
                />
              </ModalBody>
              <ModalFooter>
                <InputGroup gap="2" justifyContent="end">
                  <Button onClick={onClose} isLoading={!!isMutating}>
                    {t("Anuluj")}
                  </Button>
                  <Button
                    colorScheme="purple"
                    type="submit"
                    form="system-context-form"
                    data-testid="add-system-context-button"
                    isLoading={!!isMutating}
                  >
                    {type === "system_manager"
                      ? t("Dodaj zarządcę")
                      : t("Dodaj administratora")}
                  </Button>
                </InputGroup>
              </ModalFooter>
            </ModalContent>
          </>
        );
      }}
    </Modal>
  );
};
