import { withTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";

import { AdminDashboard } from "./AdminDashboard";
import { ManagerDashboard } from "./ManagerDashboard";

export const Start = withTranslation()(() => {
  const { userInfo } = ContextMachineContext.useSelector(
    (state) => state.context
  );

  switch (userInfo?.currentlySelectedContext?.type) {
    case "system_admin":
      return <AdminDashboard />;
    case "system_manager":
      return <ManagerDashboard />;
    case "context_manager":
      return (
        <Navigate
          to={`${userInfo.currentlySelectedContext.organizationId}`}
          replace={true}
        />
      );
    case "context_user":
      return (
        <Navigate
          to={`${userInfo.currentlySelectedContext.organizationId}/weryfikacja`}
          replace={true}
        />
      );
    default:
      return null;
  }
});
