import { QueryClient } from "@tanstack/react-query";
import { HttpResponse, http } from "msw";

import { client, withHost } from "utils/http";
import { useQuery } from "utils/useQuery";

import { IOrganizationContext } from "../application/types/IOrganizationContext";

const query = (id: string) => {
  return {
    queryKey: ["organizations", id, "contexts"],
    queryFn: () =>
      client.get<{ contexts: IOrganizationContext[] }>(
        `organizations/${id}/contexts`
      ),
  };
};

export const getOrganizationContextsQuery =
  (queryClient: QueryClient) => (id: string) => {
    return queryClient.ensureQueryData(query(id));
  };

export const useOrganizationContextsQuery = (id: string) => {
  return useQuery(query(id));
};

export const getOrganizationContextsMock = (
  contexts: IOrganizationContext[] = []
) => {
  return http.get(withHost("organizations/:organizationId/contexts"), () => {
    return HttpResponse.json<{ contexts: IOrganizationContext[] }>({
      contexts: [...contexts],
    });
  });
};
