import { QueryClient } from "@tanstack/react-query";
import { http, HttpResponse } from "msw";

import { client, withHost } from "utils/http";
import { useQuery } from "utils/useQuery";

import { ITariffsExpirations } from "../application/types/ITariffsExpirations";

const query = (organizationId: string) => {
  return {
    queryKey: ["tariffs-expirations", organizationId],
    queryFn: () =>
      client.get<{ tariffsReminders: ITariffsExpirations[] }>(
        `organizations/${organizationId}/tariffsExpirations`
      ),
  };
};

export const getTariffsExpirationsQuery =
  (queryClient: QueryClient) => (organizationId: string) => {
    return queryClient.ensureQueryData(query(organizationId));
  };

export const useTariffsExpirationsQuery = (organizationId: string) => {
  return useQuery(query(organizationId));
};

export const getTariffsExpirationsMock = (
  tariffsReminders: ITariffsExpirations[] = []
) => {
  return http.get(
    withHost("organizations/:organizationId/tariffsExpirations"),
    () => {
      return HttpResponse.json<{ tariffsReminders: ITariffsExpirations[] }>({
        tariffsReminders,
      });
    }
  );
};
