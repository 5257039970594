import {
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Spacer,
  Td,
  Text,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { t } from "i18next";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";
import { settings } from "settings/getSettings";

import { Table } from "components/Table";
import { LinkCell } from "components/Table/LinkCell";
import { TableEmptyState } from "components/Table/TableEmptyState";
import { TableLoadingState } from "components/Table/TableLoadingState";

import { canToggleOrganization } from "modules/organization/application/canToggleOrganization";
import { IOrganization } from "modules/organization/application/types/IOrganization";
import { useOrganizationsQuery } from "modules/organization/infrastructure/useOrganizationsQuery";
import { useAllTariffsQuery } from "modules/tariffs/infrastructure/useAllTariffsQuery";

import { EditableComment } from "../OrganizationsTable/EditableComment";
import { ToggleOrganizationButton } from "../OrganizationsTable/ToggleOrganizationButton";
import { PendingOrganizationFilter } from "./PendingOrganizationFilter";
import { RemoveOrganizationButon } from "./RemoveOrganizationButon";

const columnHelper = createColumnHelper<IOrganization>();

const columns = [
  columnHelper.accessor("details.displayName", {
    id: "name",
    header: () => t("Nazwa"),
    cell: (info) => (
      <Td
        data-testid="pending-organization-name"
        whiteSpace="nowrap"
        w="50%"
        maxW={0}
      >
        <Text isTruncated>{info.getValue()}</Text>
      </Td>
    ),
  }),
  columnHelper.accessor("details.email", {
    id: "email",
    header: () => t("E-mail"),
    cell: (info) => (
      <Td whiteSpace="nowrap" w="25%" maxW={0}>
        <Text isTruncated>{info.getValue()}</Text>
      </Td>
    ),
  }),
  columnHelper.accessor("id", {
    id: "tariff.type",
    header: () => t("Pakiet"),
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal">
        <TariffType organizationId={info.getValue()} />
      </Td>
    ),
  }),
  columnHelper.accessor("comment", {
    id: "comment",
    header: () => t("Komentarz"),
    enableSorting: false,
    cell: (info) => (
      <Td>
        <EditableComment
          value={info.getValue() || ""}
          organizationId={info.row.original.id}
        />
      </Td>
    ),
  }),
  columnHelper.accessor("id", {
    header: () => "",
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal" isNumeric>
        <ButtonGroup>
          <RemoveOrganizationButon
            organizationId={info.getValue()}
            organizationName={info.row.original.details.displayName}
          />
          <ToggleOrganizationButton
            isDisabled={
              !canToggleOrganization(
                info.row.original.tariffs,
                info.row.original.status
              )
            }
            isActive={info.row.original.status === "ACTIVE"}
            organizationId={info.getValue()}
            organizationName={info.row.original.details.displayName}
          />
          <LinkCell path={`/${info.row.original.id}`} label={t("Przejdź")} />
        </ButtonGroup>
      </Td>
    ),
  }),
];

interface IProps {
  organizations: IOrganization[];
  isLoading: boolean;
}

const OrganizationsTableConnected = ({ organizations, isLoading }: IProps) => {
  if (isLoading) {
    return <TableLoadingState />;
  }

  if (organizations?.length === 0) {
    return <TableEmptyState title={t("Brak oczekujących podmiotów")} />;
  }

  const sortedRecords = reverse(
    sortBy(organizations, function (data) {
      return new Date(data.createdAt);
    })
  );

  return (
    <Table<IOrganization>
      data={sortedRecords}
      columns={columns}
      globalSearchParam="pendingOrgSearch"
    />
  );
};

export const PendingOrganizationTable = () => {
  const { data, isLoading } = useOrganizationsQuery();
  const isRegistrationEnabled = settings.IS_REGISTRATION_ENABLED;

  if (!isRegistrationEnabled) {
    return null;
  }

  return (
    <Card variant="sawpe">
      <CardHeader>
        <Heading fontSize="lg">{t("Podmioty oczekujące")}</Heading>
        <Spacer />
        <PendingOrganizationFilter />
      </CardHeader>
      <CardBody>
        <OrganizationsTableConnected
          organizations={data?.organizations.filter(
            (org) => org.status === "PENDING"
          )}
          isLoading={isLoading}
        />
      </CardBody>
    </Card>
  );
};

const TariffType = ({ organizationId }: { organizationId: string }) => {
  const { data, isLoading } = useAllTariffsQuery(organizationId);

  if (isLoading) {
    return null;
  }

  return <>{data.tariffs.current?.type || data.tariffs.draft?.type || "---"}</>;
};
