import { Link } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  Flex,
  Link as CLink,
  Text,
  VStack,
} from "@chakra-ui/react";
import { mdiCheckCircleOutline } from "@mdi/js";
import { t } from "i18next";

import { Icon } from "components/icons/Icon";

export const RegisteredCard = () => {
  return (
    <Card w={[330, 420]}>
      <CardBody p={8}>
        <VStack align="stretch" spacing="6">
          <Flex justifyContent="center">
            <Icon path={mdiCheckCircleOutline} size="33px" color="green.500" />
          </Flex>
          <Text textAlign={"center"}>
            {t("Dziękujemy za rejestrację w serwisie ")}
            <CLink
              href="https://weryfikacjapodpisu.pl/"
              color="purple.500"
              target="_blank"
            >
              {t("weryfikacjapodpisu.pl")}
            </CLink>
            {t(
              ". Na wskazany adres email wysłaliśmy Ci potwierdzenie zgłoszenia. Skontaktujemy się z Tobą niezwłocznie, w czasie nie dłuższym niż 1 dzień roboczy."
            )}
          </Text>
          <Button as={Link} to="/login" variant="outline" colorScheme="purple">
            {t("Wróć na stronę główną")}
          </Button>
        </VStack>
      </CardBody>
    </Card>
  );
};
