import {
  Button,
  InputGroup,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { useIsMutating } from "@tanstack/react-query";
import { t } from "i18next";

import { Modal } from "components/Dialog";

import { useAddManager } from "modules/organization/infrastructure/useAddManager";

import {
  IManagerFormData,
  ManagerModalForm,
} from "../../common/ManagerModalForm";

interface IProps {
  id: string;
}

export const AddManagerModal = ({ id }: IProps) => {
  const { mutateAsync: addManager } = useAddManager();
  const isMutating = useIsMutating({
    mutationKey: ["add-manager"],
  });

  return (
    <Modal id={id}>
      {({ onClose }) => {
        return (
          <>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{t("Dodaj administratora")}</ModalHeader>
              <ModalBody as={VStack} align="stretch" spacing={6}>
                <ManagerModalForm
                  onClose={onClose}
                  onSubmit={async (data: IManagerFormData) => {
                    await addManager(data);
                  }}
                />
              </ModalBody>
              <ModalFooter>
                <InputGroup gap="2" justifyContent="end">
                  <Button onClick={onClose} isLoading={!!isMutating}>
                    {t("Anuluj")}
                  </Button>
                  <Button
                    colorScheme="purple"
                    type="submit"
                    form="manager-form"
                    data-testid="add-manager-button"
                    isLoading={!!isMutating}
                  >
                    {t("Dodaj administratora")}
                  </Button>
                </InputGroup>
              </ModalFooter>
            </ModalContent>
          </>
        );
      }}
    </Modal>
  );
};
