import { ComponentProps } from "react";

import { mdiInformationOutline } from "@mdi/js";

import { Icon } from "./Icon";

export const DetailsIcon = (
  props: Omit<ComponentProps<typeof Icon>, "path">
) => {
  return <Icon {...props} path={mdiInformationOutline} />;
};
