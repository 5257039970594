import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

import { TariffTypes } from "modules/tariffs/application/types/ITariff";

import { NotificationProcess } from "../application/NotificationProcess";

interface MutationVariables {
  id: string;
  subject: string;
  body: string;
  tariffTypes: TariffTypes[];
  sendAtDate: string | null;
  sendAtTime: string | null;
}

export const useEditNotificationProcess = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["edit-notification-process"],
    mutationFn: ({ id, ...variables }: MutationVariables) => {
      return client.put<
        { notificationsProcess: NotificationProcess },
        Omit<MutationVariables, "id">
      >(`notificationsProcess/${id}`, {
        body: { ...variables },
      });
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["notifications-process"],
      });
    },
  });
};
