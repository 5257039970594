import { Text, VStack } from "@chakra-ui/react";
import { t } from "i18next";
import { HTTPError } from "ky";

import { AlertDialog } from "components/Dialog";
import { toastWithError } from "components/ErrorHandling/toastWithError";

import { useAddApiKey } from "modules/apiKey/infrastructure/useAddApiKey";

interface IProps {
  id: string;
}

export const GenerateApiKeyConfirm = ({ id }: IProps) => {
  const { mutateAsync: addApiKey } = useAddApiKey();

  return (
    <AlertDialog
      id={id}
      title={t("Wygeneruj nowy klucz API")}
      body={
        <VStack align="stretch" spacing={4} fontSize="md">
          <Text>
            {t(
              "Wygenerowanie nowego klucza spowoduje dezaktywację obecnie aktywnego klucza oraz dodanie nowego aktywnego klucza. "
            )}
          </Text>
          <Text>{t("Czy chcesz kontynuować?")}</Text>
        </VStack>
      }
      confirmLabel={t("Wygeneruj nowy klucz API")}
      onConfirm={async () => {
        try {
          await addApiKey();
        } catch (error) {
          if (error instanceof HTTPError) {
            const errorJson = await error.response.json<{
              message: string;
            }>();

            if (errorJson.message === "apiKey.error.organizationDisabled") {
              return toastWithError({
                description: t("Podmiot jest nie aktywny."),
                error,
              });
            }
          }

          toastWithError({
            error,
          });
        }
      }}
      colorScheme="purple"
      size="xl"
    />
  );
};
