import { Button } from "@chakra-ui/react";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";
import { PlusIcon } from "components/icons/PlusIcon";

import { KeyModal } from "./KeyModal";

export const AddKeyButton = () => {
  const id = `add-key`;
  const onOpen = useOpenDialog(id);

  return (
    <>
      <KeyModal id={id} />
      <Button
        size="sm"
        leftIcon={<PlusIcon />}
        colorScheme="purple"
        onClick={() => onOpen()}
      >
        {t("Dodaj klucz")}
      </Button>
    </>
  );
};
