import { IconButton, Tooltip } from "@chakra-ui/react";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";
import { DetailsIcon } from "components/icons/DetailsIcon";

import { SystemEventPayloadModal } from "./SystemEventPayloadModal";

interface IProps {
  sysEventId: string;
  payload?: object;
}

export const SystemEventPayloadBtn = ({ sysEventId, payload }: IProps) => {
  const id = `system-event-payload-${sysEventId}`;
  const onOpen = useOpenDialog(id);

  if (!payload || Object.keys(payload).length === 0) {
    return null;
  }

  const label = t("Szczegóły");

  return (
    <>
      <SystemEventPayloadModal id={id} payload={payload} />
      <Tooltip label={label} aria-label={label}>
        <IconButton
          variant="link"
          colorScheme="purple"
          size="sm"
          data-testid="system-event-payload"
          onClick={() => onOpen()}
          aria-label={label}
          icon={<DetailsIcon />}
        />
      </Tooltip>
    </>
  );
};
