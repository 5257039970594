import {
  Avatar,
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { t } from "i18next";

import { ChevronRightIcon } from "components/icons/ChevronRightIcon";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";

import { ChangeContextButton } from "./ChangeContextButton";
import { ChangePasswordMenuItem } from "./ChangePasswordMenuItem";

export const ProfileMenu = () => {
  const { userInfo } = ContextMachineContext.useSelector(
    (state) => state.context
  );
  const fullName = userInfo?.currentlySelectedContext?.name;

  return (
    <Menu>
      <MenuButton
        as={Button}
        leftIcon={<Avatar name={fullName} size="sm" />}
        rightIcon={
          <ChevronRightIcon
            size="16px"
            color="gray.400"
            transform={"rotate(90deg)"}
            display={{ base: "block", lg: "none", xl: "block" }}
          />
        }
        data-testid="profile-menu-button"
        variant="ghost"
        fontWeight={500}
      >
        <Box display={{ base: "block", lg: "none", xl: "block" }}>
          {fullName}
        </Box>
      </MenuButton>
      <MenuList>
        <ChangeContextButton />
        <ChangePasswordMenuItem />
        <LogoutMenuItem />
      </MenuList>
    </Menu>
  );
};

const LogoutMenuItem = () => {
  const { send } = ContextMachineContext.useActorRef();

  return (
    <MenuItem
      onClick={() => {
        send({ type: "logout" });
      }}
    >
      {t("Wyloguj")}
    </MenuItem>
  );
};
