import { ReactNode } from "react";

import { Divider, Heading, Text, VStack } from "@chakra-ui/react";

interface IProps {
  title: string;
  divided?: boolean;
  description?: ReactNode;
}

export const SectionHeading = ({
  description,
  title,
  divided = false,
}: IProps) => {
  return (
    <VStack align="stretch" spacing={0}>
      {divided && <Divider my={6} />}
      <Heading fontSize="lg" mb={description ? 2 : undefined}>
        {title}
      </Heading>
      {description && (
        <Text fontSize="sm" color="gray.600">
          {description}
        </Text>
      )}
    </VStack>
  );
};
