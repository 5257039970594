import { ComponentProps } from "react";

import { mdiAccountArrowUpOutline } from "@mdi/js";

import { Icon } from "./Icon";

export const UserNewIcon = (
  props: Omit<ComponentProps<typeof Icon>, "path">
) => {
  return <Icon {...props} path={mdiAccountArrowUpOutline} />;
};
