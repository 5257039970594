import { useNavigate } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  GridItem,
  HStack,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { t } from "i18next";

import { DraftIcon } from "components/icons/DraftIcon";
import { EmailOpen } from "components/icons/EmailOpen";

import { getTrackedNotification } from "modules/dashboard/application/getTrackedNotification";
import { useNotificationProcessQuery } from "modules/notifications/infrastructure/useNotificationProcessQuery";

import { TrackedNotification } from "./TrackedNotification";

export const MenageNotificationTile = () => {
  const { data, isLoading } = useNotificationProcessQuery();
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <>
        <GridItem colSpan={2}>
          <Skeleton height="100%" />
        </GridItem>
      </>
    );
  }

  const drafts = data?.notificationsProcesses.filter(
    ({ isLocked, isCompleted }) => !isLocked && !isCompleted
  );

  return (
    <GridItem colSpan={2}>
      <Card h="100%">
        <CardHeader
          fontSize="lg"
          fontWeight="semibold"
          roundedTop="md"
          color="white"
          bgColor="purple.500"
        >
          <HStack justifyContent="space-between">
            <Text>{t("Powiadomienia")}</Text>
            <EmailOpen />
          </HStack>
        </CardHeader>

        <CardBody>
          <VStack align="stretch" justifyContent="space-between" h="100%">
            <VStack align="stretch">
              <HStack>
                <DraftIcon size="24px" color="purple.500" />
                <Text fontSize="lg" fontWeight="semibold">
                  {t("Wersje robocze: {{drafts}}", {
                    drafts: drafts.length,
                  })}
                </Text>
              </HStack>
              <Divider m={0} />
              <TrackedNotification
                trackedNotification={getTrackedNotification(
                  data?.notificationsProcesses
                )}
              />
            </VStack>
            <Button
              mt="auto"
              colorScheme="purple"
              onClick={() => navigate("/powiadomienia")}
            >
              {t("Przejdź do powiadomień")}
            </Button>
          </VStack>
        </CardBody>
      </Card>
    </GridItem>
  );
};
