import { Text, VStack } from "@chakra-ui/react";
import { t } from "i18next";
import { HTTPError } from "ky";

import { AlertDialog } from "components/Dialog";
import { toastWithError } from "components/ErrorHandling/toastWithError";

import { useToggleUserActivity } from "modules/organization/infrastructure/useToggleUserActivity";

interface IConfirmProps {
  id: string;
  contextId: string;
  isActive: boolean;
  name: string;
}

export const ToggleUserConfirm = ({
  id,
  contextId,
  isActive,
  name,
}: IConfirmProps) => {
  const { mutateAsync: toggle } = useToggleUserActivity();

  return (
    <AlertDialog
      id={id}
      title={isActive ? t("Dezaktywacja konta") : t("Aktywacja konta")}
      body={
        <VStack align="stretch" spacing={4} fontSize="md">
          <Text>
            {t("Czy na pewno chcesz {{value}} konto {{accName}}?", {
              value: isActive ? t("dezaktywować") : t("aktywować"),
              accName: name,
            })}
          </Text>
        </VStack>
      }
      confirmLabel={isActive ? t("Dezaktywuj") : t("Aktywuj")}
      onConfirm={async () => {
        try {
          await toggle({
            isActive,
            contextId,
          });
        } catch (error) {
          if (error instanceof HTTPError) {
            const errorJson = await error.response.json<{
              message: string;
            }>();

            if (
              errorJson.message ===
              "context.error.canNotDisableLastContextManager"
            ) {
              return toastWithError({
                description: t("Musi pozostać jeden aktywny administrator."),
                error,
              });
            }
          }

          toastWithError({
            error,
          });
        }
      }}
      colorScheme="purple"
      size="xl"
    />
  );
};
