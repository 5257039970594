import { withTranslation } from "react-i18next";

import { HStack, VStack } from "@chakra-ui/react";

import { DefaultIntegrationKeyTable } from "modules/integrationKey/presentation/DefaultIntegrationKeyTable";
import { IntegrationKeysTable } from "modules/integrationKey/presentation/IntegrationKeysTable";
import { GlobalAdminTable } from "modules/system/presentation/GlobalAdminTable";
import { SystemManagerTable } from "modules/system/presentation/SystemManagerTable";

import { DashboardNavigation } from "./DashboardNavigation";

export const AdminDashboard = withTranslation()(() => {
  return (
    <VStack align="stretch" gap="8">
      <HStack gap="8" align="start">
        <DashboardNavigation />
        <VStack align="stretch" w="100%" gap="6" p="2px" overflow="auto">
          <SystemManagerTable />
          <GlobalAdminTable />
          <DefaultIntegrationKeyTable />
          <IntegrationKeysTable />
        </VStack>
      </HStack>
    </VStack>
  );
});
