import { withTranslation } from "react-i18next";

import {
  Card,
  CardBody,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from "@chakra-ui/react";
import { t } from "i18next";

import { CorrectionModal } from "modules/notifications/presentation/CorrectionModal/CorrectionModal";
import { NotificationDraftCard } from "modules/notifications/presentation/NotificationDraftCard";
import { NotificationFormCard } from "modules/notifications/presentation/NotificationFormCard";
import { PendingNotifications } from "modules/notifications/presentation/PendingNotifications";
import { RefreshButton } from "modules/notifications/presentation/PendingNotifications/RefreshButton";
import { SentNotifications } from "modules/notifications/presentation/SentNotifications";

export const Notifications = withTranslation()(() => {
  return (
    <>
      <CorrectionModal />
      <VStack align="stretch" gap="8">
        <Card>
          <CardBody px={4} py={6}>
            <Heading fontSize="lg">{t("Powiadomienia")}</Heading>
          </CardBody>
        </Card>
        <VStack gap="6" align="stretch">
          <Stack direction={{ base: "column", lg: "row" }} gap="6">
            <NotificationFormCard flexBasis={{ base: "100%", lg: "70%" }} />
            <NotificationDraftCard flexBasis={{ base: "100%", lg: "30%" }} />
          </Stack>
          <Card variant="sawpe">
            <CardBody>
              <Tabs variant="enclosed" colorScheme="purple">
                <TabList>
                  <Tab>{t("Oczekujące")}</Tab>
                  <Tab>{t("Wysłane")}</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel px={0} data-testid="pending-notifications">
                    <RefreshButton />
                    <PendingNotifications />
                  </TabPanel>
                  <TabPanel px={0} data-testid="sent-notifications">
                    <SentNotifications />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </CardBody>
          </Card>
        </VStack>
      </VStack>
    </>
  );
});
