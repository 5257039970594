import { SVGProps } from "react";

export function SAWPEIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="203"
      height="35"
      viewBox="0 0 203 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.1479 34.2988C16.9057 34.2985 16.666 34.2506 16.4423 34.1577C16.2187 34.0648 16.0155 33.9288 15.8445 33.7574L0.540128 18.453C0.194284 18.1071 0 17.6379 0 17.1488C0 16.6596 0.194284 16.1904 0.540128 15.8445L15.8445 0.540128C16.1904 0.194284 16.6596 0 17.1488 0C17.6379 0 18.1071 0.194284 18.453 0.540128L28.5097 10.5985C28.681 10.7697 28.817 10.9731 28.9097 11.1968C29.0024 11.4206 29.0502 11.6605 29.0502 11.9028C29.0502 12.145 29.0024 12.3849 28.9097 12.6087C28.817 12.8325 28.681 13.0358 28.5097 13.207L24.1098 17.607C23.7641 17.953 23.2951 18.1476 22.8059 18.1478C22.3168 18.148 21.8476 17.954 21.5016 17.6083C21.1556 17.2626 20.961 16.7936 20.9608 16.3045C20.9606 15.8153 21.1546 15.3462 21.5003 15.0001L24.5892 11.9036L17.1479 4.45213L4.45213 17.1581L17.1581 29.8674L17.8938 29.1037C18.2418 28.768 18.7077 28.5824 19.1912 28.5868C19.6747 28.5911 20.1372 28.7851 20.4791 29.127C20.821 29.4689 21.015 29.9314 21.0193 30.4149C21.0237 30.8984 20.8381 31.3643 20.5024 31.7123L18.453 33.7616C18.2817 33.9332 18.0783 34.0693 17.8544 34.1622C17.6304 34.2551 17.3904 34.303 17.1479 34.303V34.2988Z"
        fill="#302F46"
      />
      <path
        d="M62.8546 20.883C63.116 20.5111 63.2589 20.0689 63.2645 19.6144H61.3339C61.3291 19.737 61.2952 19.8567 61.235 19.9637C61.1748 20.0707 61.09 20.1618 60.9877 20.2296C60.7255 20.4038 60.4146 20.49 60.1001 20.4757H58.9121C58.5813 20.4927 58.2572 20.3787 58.01 20.1583C57.8972 20.0493 57.8087 19.9178 57.7502 19.7723C57.6918 19.6268 57.6646 19.4706 57.6706 19.314V18.9032H63.2883V17.3452C63.3051 16.9465 63.2368 16.5487 63.088 16.1784C62.9392 15.8081 62.7133 15.4737 62.4252 15.1974C61.7909 14.6407 60.9652 14.3519 60.1221 14.3921H58.9087C58.3283 14.3752 57.7524 14.4989 57.2302 14.7528C56.7635 14.9855 56.3731 15.3468 56.1049 15.794C55.8279 16.2643 55.6861 16.802 55.6951 17.3478V19.2995C55.683 19.8104 55.8214 20.3135 56.093 20.7464C56.3645 21.1792 56.7573 21.5227 57.2225 21.7341C57.7453 21.9767 58.3163 22.0972 58.8925 22.0863H60.0466C60.6199 22.0964 61.1891 21.989 61.7192 21.7706C62.1747 21.5892 62.5686 21.2812 62.8546 20.883ZM57.6366 17.3987C57.6196 17.0216 57.744 16.6518 57.9854 16.3617C58.0999 16.2316 58.2417 16.1285 58.4007 16.0597C58.5598 15.9908 58.7321 15.9581 58.9053 15.9637H60.1349C60.3015 15.9574 60.4675 15.9877 60.6212 16.0525C60.7749 16.1173 60.9124 16.215 61.0242 16.3388C61.2537 16.6137 61.3721 16.9645 61.356 17.3223V17.4963H57.6366V17.3987Z"
        fill="#302F46"
      />
      <path
        d="M68.6524 16.1577H69.6317V14.469H68.6524C68.267 14.473 67.8883 14.5708 67.5492 14.7541C67.2233 14.9139 66.9448 15.1561 66.7414 15.4568L66.6429 15.6044V14.5012H64.8354V21.9731H66.7948V17.9397C66.7873 17.7016 66.8295 17.4644 66.9188 17.2435C67.0081 17.0226 67.1424 16.8227 67.3133 16.6567C67.4921 16.4886 67.7026 16.3579 67.9326 16.2722C68.1625 16.1865 68.4072 16.1476 68.6524 16.1577Z"
        fill="#302F46"
      />
      <path
        d="M77.5243 23.5658C77.7884 23.0605 77.9196 22.4963 77.9053 21.9263V14.4978H75.9459V18.6822C75.8554 19.1083 75.65 19.5016 75.3519 19.8193C75.2318 19.9526 75.0856 20.0597 74.9224 20.1342C74.7592 20.2086 74.5825 20.2487 74.4031 20.2521H73.524C73.3893 20.2556 73.2556 20.2276 73.1336 20.1703C73.0116 20.113 72.9047 20.0279 72.8214 19.922C72.6384 19.678 72.5457 19.378 72.5591 19.0734V14.491H70.6015V19.0386C70.5656 19.7985 70.8215 20.5435 71.3168 21.121C71.5568 21.3799 71.85 21.5837 72.1762 21.7184C72.5024 21.8532 72.8539 21.9157 73.2066 21.9017H74.2088C74.5134 21.8966 74.8109 21.8086 75.0693 21.6471C75.3757 21.4604 75.6419 21.2146 75.8525 20.9241L75.9467 20.7968V21.9425C75.9637 22.3146 75.8555 22.6818 75.6395 22.9854C75.5482 23.1141 75.4269 23.2186 75.2862 23.29C75.1455 23.3614 74.9895 23.3975 74.8317 23.3952H73.586C73.3526 23.4024 73.1247 23.3246 72.9444 23.1763C72.7856 23.0446 72.6835 22.8568 72.6593 22.6519H70.7287C70.7788 23.3174 71.0895 23.9362 71.5934 24.3737C72.1512 24.8373 72.8603 25.079 73.5851 25.0525H74.8308C75.3925 25.0638 75.9478 24.9306 76.4432 24.6656C76.9029 24.4131 77.2785 24.0313 77.5234 23.5675L77.5243 23.5658Z"
        fill="#302F46"
      />
      <path
        d="M86.9691 21.9694V14.4976H82.4716V13.9749C82.4611 13.8475 82.4765 13.7193 82.5171 13.598C82.5577 13.4768 82.6224 13.3651 82.7075 13.2697C82.916 13.1008 83.1818 13.0193 83.4492 13.0423H85.7039V11.3867H83.4212C82.6473 11.3406 81.8838 11.5824 81.2776 12.0655C81.0207 12.3043 80.8192 12.5965 80.6872 12.9215C80.5553 13.2466 80.4961 13.5965 80.5139 13.9469V14.5001H79.2104V16.1557H80.5156V21.972H82.475V16.1532H85.0097V21.9694H86.9691Z"
        fill="#302F46"
      />
      <path
        d="M94.0906 21.9697H96.2969L92.9645 17.6597L96.1552 14.4978H93.9073L90.8413 17.5943V11.4463H88.8794V21.9688H90.8405V19.7684L91.7009 18.9054L94.0906 21.9697Z"
        fill="#302F46"
      />
      <path
        d="M111.135 19.1428C111.143 19.4722 111.034 19.7937 110.828 20.0508C110.729 20.1706 110.603 20.2661 110.461 20.3301C110.319 20.394 110.164 20.4248 110.009 20.42H108.841C108.669 20.4239 108.498 20.3891 108.341 20.3182C108.185 20.2472 108.046 20.1419 107.935 20.0101C107.696 19.7156 107.573 19.3437 107.589 18.9646V17.4974C107.573 17.1202 107.693 16.7498 107.929 16.4545C108.037 16.3222 108.174 16.2165 108.33 16.1454C108.486 16.0744 108.655 16.0399 108.826 16.0446H109.997C110.142 16.0396 110.287 16.0665 110.421 16.1236C110.555 16.1806 110.674 16.2663 110.771 16.3747C110.967 16.6022 111.072 16.8944 111.064 17.1945H113.009C113.01 16.69 112.882 16.1936 112.638 15.7519C112.39 15.3225 112.023 14.9739 111.581 14.748C111.09 14.5019 110.546 14.3787 109.997 14.389H108.796C108.221 14.3784 107.651 14.5065 107.136 14.7624C106.674 14.9976 106.289 15.3593 106.025 15.8053C105.756 16.2782 105.62 16.8151 105.632 17.3591V19.1335C105.62 19.6775 105.756 20.2144 106.025 20.6873C106.291 21.1331 106.678 21.4935 107.142 21.726C107.66 21.9826 108.232 22.1107 108.81 22.0993H110.009C110.417 22.1176 110.825 22.0545 111.208 21.9137C111.591 21.773 111.942 21.5574 112.241 21.2796C112.519 21.0012 112.738 20.6689 112.882 20.303C113.027 19.9371 113.096 19.5455 113.084 19.1522L111.135 19.1428Z"
        fill="#302F46"
      />
      <path
        d="M115 13.3513C115.256 13.5627 115.579 13.6782 115.911 13.6782C116.244 13.6782 116.566 13.5627 116.823 13.3513C117.041 13.116 117.162 12.8072 117.162 12.4866C117.162 12.166 117.041 11.8571 116.823 11.6219C116.566 11.4105 116.244 11.2949 115.911 11.2949C115.579 11.2949 115.256 11.4105 115 11.6219C114.78 11.8562 114.658 12.1654 114.658 12.4866C114.658 12.8078 114.78 13.1169 115 13.3513Z"
        fill="#302F46"
      />
      <path
        d="M116.892 14.4976H114.933V21.8243C114.961 22.2199 114.867 22.6146 114.664 22.9555C114.566 23.084 114.436 23.186 114.288 23.2522C114.14 23.3184 113.978 23.3468 113.816 23.3348H112.928V25.0006H113.819C114.24 25.037 114.665 24.9865 115.065 24.8522C115.466 24.7179 115.835 24.5027 116.149 24.2199C116.699 23.5228 116.966 22.6439 116.895 21.759L116.892 14.4976Z"
        fill="#302F46"
      />
      <path
        d="M124.265 21.0785L124.36 20.958V21.9704H126.167V16.906C126.181 16.5581 126.116 16.2115 125.977 15.8921C125.838 15.5728 125.63 15.2887 125.366 15.0612C124.767 14.5797 124.012 14.3335 123.245 14.3687H121.935C121.178 14.3334 120.434 14.5686 119.835 15.0323C119.585 15.2369 119.384 15.495 119.246 15.7875C119.109 16.08 119.039 16.3996 119.041 16.7227H120.941C120.946 16.6207 120.974 16.5211 121.022 16.4311C121.07 16.3411 121.138 16.263 121.22 16.2025C121.429 16.0564 121.681 15.9844 121.935 15.998H123.24C123.496 15.9873 123.746 16.0772 123.937 16.2483C124.025 16.3323 124.094 16.434 124.14 16.5466C124.186 16.6592 124.208 16.7802 124.203 16.9018V17.775L124.128 17.7385C123.866 17.6141 123.591 17.5185 123.308 17.4533C123.034 17.3869 122.752 17.3519 122.47 17.349H121.544C120.841 17.3142 120.147 17.5162 119.573 17.9226C119.338 18.0948 119.148 18.3212 119.02 18.5824C118.892 18.8436 118.828 19.1319 118.835 19.4229V19.9847C118.827 20.2759 118.886 20.565 119.009 20.8293C119.132 21.0935 119.314 21.3256 119.542 21.5071C120.083 21.9132 120.748 22.1182 121.423 22.0866H122.365C122.724 22.0807 123.076 21.9863 123.39 21.8117C123.731 21.633 124.03 21.3829 124.265 21.0785ZM123.546 20.3199C123.241 20.5177 122.884 20.621 122.521 20.6169H121.701C121.456 20.6277 121.214 20.5602 121.01 20.4243C120.927 20.3681 120.858 20.2924 120.811 20.2036C120.763 20.1148 120.738 20.0158 120.738 19.9151V19.5197C120.734 19.4184 120.755 19.3179 120.798 19.2262C120.841 19.1346 120.905 19.0545 120.986 18.9927C121.161 18.8558 121.378 18.7833 121.6 18.7873H122.555C122.869 18.7859 123.182 18.8224 123.488 18.8959C123.74 18.9508 123.978 19.0552 124.189 19.2031L124.209 19.2193V19.5222C124.077 19.8515 123.845 20.131 123.546 20.3216V20.3199Z"
        fill="#302F46"
      />
      <path
        d="M102.206 21.0784L102.3 20.958V21.9703H104.106V16.9059C104.12 16.5579 104.055 16.2112 103.916 15.8917C103.778 15.5723 103.569 15.2883 103.305 15.0611C102.706 14.5794 101.951 14.3331 101.183 14.3686H99.878C99.1214 14.3337 98.3769 14.5689 97.7777 15.0322C97.5277 15.2368 97.3269 15.4949 97.19 15.7874C97.0531 16.08 96.9837 16.3996 96.9869 16.7226H98.8869C98.8909 16.6207 98.9179 16.521 98.9658 16.431C99.0137 16.3409 99.0813 16.2628 99.1635 16.2024C99.3723 16.056 99.6242 15.9837 99.8789 15.9971H101.184C101.44 15.9864 101.69 16.0763 101.881 16.2474C101.969 16.3314 102.038 16.433 102.084 16.5456C102.13 16.6583 102.151 16.7793 102.147 16.9008V17.774L102.072 17.7375C101.81 17.6132 101.535 17.5176 101.252 17.4524C100.977 17.3859 100.696 17.3509 100.413 17.348H99.4868C98.784 17.313 98.0898 17.515 97.5155 17.9217C97.2809 18.0939 97.0913 18.3203 96.963 18.5815C96.8346 18.8427 96.7712 19.131 96.7781 19.422V19.9838C96.7697 20.2752 96.8295 20.5646 96.9526 20.8289C97.0758 21.0932 97.2589 21.3251 97.4875 21.5061C98.0274 21.9124 98.6922 22.1174 99.3672 22.0857H100.31C100.669 22.0797 101.021 21.9852 101.334 21.8108C101.674 21.6323 101.971 21.3825 102.206 21.0784ZM101.486 20.3198C101.181 20.5175 100.825 20.6208 100.462 20.6168H99.6421C99.3973 20.6273 99.1554 20.5599 98.9514 20.4242C98.8678 20.3681 98.7992 20.2923 98.7517 20.2036C98.7042 20.1148 98.6792 20.0157 98.679 19.915V19.5196C98.6759 19.4185 98.6968 19.318 98.7398 19.2265C98.7829 19.1349 98.8469 19.0548 98.9267 18.9926C99.1049 18.8533 99.3261 18.7806 99.5522 18.7873H100.509C100.824 18.7856 101.137 18.8221 101.443 18.8959C101.692 18.9484 101.928 19.0493 102.138 19.1929L102.158 19.209V19.5119C102.025 19.8454 101.79 20.1283 101.486 20.3198Z"
        fill="#302F46"
      />
      <path
        d="M144.256 20.6964C144.533 20.2266 144.674 19.6901 144.666 19.1452V17.3632C144.675 16.8156 144.533 16.2763 144.256 15.8035C143.989 15.354 143.597 14.9906 143.13 14.7563C142.612 14.4993 142.039 14.3706 141.461 14.3812H140.097C139.518 14.3707 138.945 14.4993 138.427 14.7563C137.961 14.9922 137.573 15.3553 137.306 15.8035C137.031 16.2759 136.889 16.814 136.896 17.3606V19.1376C136.888 19.6851 137.03 20.2243 137.306 20.6973C137.567 21.1385 137.95 21.4945 138.409 21.7224C138.928 21.9749 139.5 22.1005 140.077 22.089H141.444C142.027 22.101 142.604 21.9754 143.129 21.7224C143.595 21.4951 143.986 21.1391 144.256 20.6964ZM142.699 18.9772C142.715 19.3562 142.592 19.7279 142.353 20.0227C142.238 20.156 142.094 20.2619 141.933 20.3328C141.771 20.4037 141.596 20.4378 141.42 20.4325H140.115C139.942 20.4366 139.772 20.4019 139.615 20.3309C139.458 20.26 139.319 20.1546 139.208 20.0227C138.969 19.7281 138.846 19.3563 138.862 18.9772V17.4973C138.846 17.1182 138.969 16.7463 139.208 16.4518C139.321 16.319 139.462 16.2132 139.622 16.1422C139.781 16.0713 139.954 16.037 140.128 16.0419H141.433C141.608 16.0369 141.781 16.0712 141.941 16.1421C142.101 16.213 142.242 16.3189 142.356 16.4518C142.594 16.7465 142.718 17.1182 142.702 17.4973L142.699 18.9772Z"
        fill="#302F46"
      />
      <path
        d="M166.929 14.4976H164.969V21.9677H166.929V14.4976Z"
        fill="#302F46"
      />
      <path
        d="M165.037 13.3513C165.294 13.5627 165.616 13.6782 165.949 13.6782C166.282 13.6782 166.604 13.5627 166.861 13.3513C167.08 13.1169 167.203 12.8078 167.203 12.4866C167.203 12.1654 167.08 11.8562 166.861 11.6219C166.604 11.4105 166.282 11.2949 165.949 11.2949C165.616 11.2949 165.294 11.4105 165.037 11.6219C164.819 11.8571 164.698 12.166 164.698 12.4866C164.698 12.8072 164.819 13.116 165.037 13.3513Z"
        fill="#302F46"
      />
      <path
        d="M176.038 20.0024V19.4551C176.034 19.2493 175.987 19.0467 175.898 18.8611C175.809 18.6754 175.681 18.511 175.524 18.3791C175.138 18.04 174.663 17.8201 174.155 17.746L171.39 17.2462C171.245 17.2257 171.108 17.1671 170.993 17.0765C170.946 17.0373 170.909 16.9883 170.884 16.933C170.859 16.8776 170.846 16.8174 170.847 16.7566V16.5105C170.847 16.171 171.196 16.0013 171.886 16.0013H173.04C173.263 15.9911 173.482 16.0643 173.654 16.2067C173.727 16.2724 173.785 16.3524 173.826 16.4415C173.866 16.5307 173.888 16.6272 173.89 16.7252H175.832C175.837 16.4047 175.771 16.0872 175.639 15.7951C175.507 15.503 175.312 15.2434 175.069 15.0348C174.498 14.5735 173.778 14.3374 173.046 14.3712H171.858C171.089 14.3276 170.325 14.5147 169.663 14.9083C169.415 15.0668 169.212 15.2871 169.075 15.5476C168.938 15.8081 168.87 16.0999 168.88 16.3942V16.894C168.881 17.1124 168.927 17.3282 169.018 17.5271C169.108 17.726 169.239 17.9036 169.403 18.0481C169.805 18.4027 170.305 18.6282 170.837 18.6956L173.467 19.1504C173.626 19.1733 173.776 19.2377 173.902 19.3371C173.952 19.3748 173.994 19.4232 174.023 19.4788C174.052 19.5345 174.069 19.5959 174.072 19.6587V19.9133C174.072 20.2799 173.747 20.4666 173.108 20.4666H171.741C171.46 20.4791 171.185 20.3845 170.971 20.2018C170.879 20.1206 170.805 20.021 170.754 19.9095C170.703 19.798 170.675 19.677 170.674 19.5544H168.743C168.739 19.8994 168.811 20.241 168.952 20.5557C169.094 20.8703 169.302 21.1503 169.563 21.3763C170.178 21.8745 170.955 22.1292 171.746 22.0917H173.113C173.866 22.1286 174.611 21.9302 175.246 21.5239C175.495 21.3574 175.699 21.1307 175.837 20.8647C175.975 20.5987 176.044 20.3022 176.038 20.0024Z"
        fill="#302F46"
      />
      <path
        d="M184.708 21.9694V14.4976H182.748V18.8339C182.658 19.26 182.452 19.6532 182.154 19.971C182.034 20.1042 181.888 20.2114 181.725 20.2859C181.562 20.3603 181.385 20.4004 181.205 20.4038H180.326C180.192 20.4084 180.059 20.3818 179.937 20.326C179.815 20.2702 179.708 20.1867 179.624 20.0821C179.441 19.8382 179.348 19.5382 179.361 19.2335V14.4976H177.402V19.1971C177.368 19.9617 177.623 20.7112 178.117 21.2956C178.355 21.5579 178.648 21.765 178.974 21.9024C179.301 22.0397 179.653 22.104 180.007 22.0908H181.011C181.35 22.0795 181.679 21.9769 181.964 21.7937C182.295 21.5965 182.581 21.3326 182.804 21.019L182.9 20.8858V21.9703L184.708 21.9694Z"
        fill="#302F46"
      />
      <path
        d="M188.373 20.061C188.17 19.8293 187.892 19.6762 187.588 19.6283C187.284 19.5804 186.972 19.6408 186.708 19.799C186.444 19.9572 186.243 20.2032 186.142 20.4939C186.04 20.7847 186.044 21.1019 186.152 21.3903C186.261 21.6786 186.467 21.9198 186.735 22.0717C187.003 22.2237 187.315 22.2768 187.618 22.2218C187.922 22.1668 188.196 22.0072 188.393 21.7709C188.591 21.5345 188.699 21.2363 188.699 20.9283C188.706 20.7692 188.681 20.6104 188.625 20.4613C188.569 20.3123 188.483 20.1761 188.373 20.061Z"
        fill="#302F46"
      />
      <path
        d="M202.081 21.9697H203.003V20.3141H202.239C202.137 20.3308 202.032 20.323 201.933 20.2911C201.834 20.2592 201.744 20.2042 201.671 20.1307C201.598 20.0573 201.543 19.9674 201.511 19.8685C201.48 19.7696 201.472 19.6646 201.489 19.5622V11.4463H199.524V19.6844C199.497 19.9996 199.538 20.3169 199.644 20.6149C199.75 20.9129 199.919 21.1848 200.139 21.4121C200.697 21.8279 201.388 22.0261 202.081 21.9697Z"
        fill="#302F46"
      />
      <path
        d="M47.1201 22.0899L49.2696 17.8469L51.403 22.0899H52.591L54.9212 14.3745H52.9814L51.7212 18.6226L50.1293 15.4828C50.0537 15.3299 49.9369 15.2012 49.7921 15.1112C49.6472 15.0212 49.48 14.9736 49.3095 14.9736H49.2501C49.0779 14.9721 48.9088 15.0191 48.7621 15.1091C48.6154 15.1992 48.4969 15.3286 48.4202 15.4828L46.824 18.6344L45.5511 14.3762H43.6299L45.9474 22.0916L47.1201 22.0899Z"
        fill="#302F46"
      />
      <path
        d="M197.542 15.762C197.296 15.3255 196.929 14.9695 196.485 14.7369C195.992 14.486 195.444 14.3626 194.89 14.3779L193.814 14.3618C193.458 14.3565 193.107 14.4539 192.804 14.6424C192.501 14.8309 192.258 15.1026 192.105 15.4251V14.4975H190.298V25.0006H192.26V21.5163C192.432 21.7136 192.648 21.868 192.89 21.9673C193.132 22.0666 193.395 22.108 193.656 22.0882H194.939C195.482 22.0988 196.018 21.9652 196.493 21.7012C196.937 21.4431 197.299 21.0634 197.535 20.6066C197.792 20.0987 197.92 19.5359 197.909 18.9671V17.2699C197.921 16.7439 197.794 16.2239 197.542 15.762ZM195.956 18.8169C195.975 19.2303 195.853 19.6379 195.61 19.9727C195.501 20.1157 195.359 20.2307 195.197 20.3084C195.035 20.386 194.856 20.4239 194.677 20.419H193.736C193.551 20.4221 193.367 20.3858 193.197 20.3127C193.027 20.2396 192.874 20.1312 192.749 19.9947C192.472 19.6876 192.304 19.2978 192.271 18.8856V17.3582C192.382 16.9969 192.587 16.6715 192.865 16.4154C193.098 16.186 193.409 16.0535 193.735 16.0445H194.662C194.837 16.0375 195.011 16.0678 195.173 16.1335C195.336 16.1993 195.482 16.2988 195.602 16.4256C195.846 16.6963 195.975 17.0511 195.961 17.415L195.956 18.8169Z"
        fill="#302F46"
      />
      <path
        d="M162.717 15.7623C162.472 15.3258 162.105 14.9697 161.662 14.7372C161.171 14.4857 160.626 14.3597 160.075 14.3706L158.999 14.3545C158.643 14.349 158.292 14.4462 157.989 14.6344C157.686 14.8226 157.444 15.0939 157.29 15.4161V14.4979H155.483V25.0051H157.442V21.5174C157.615 21.7145 157.831 21.8688 158.073 21.968C158.315 22.0673 158.577 22.1088 158.838 22.0894H160.123C160.666 22.0998 161.203 21.966 161.678 21.7016C162.122 21.4435 162.484 21.0637 162.721 20.6069C162.977 20.0991 163.105 19.5362 163.094 18.9674V17.2702C163.103 16.7433 162.973 16.2233 162.717 15.7623ZM161.131 18.8172C161.149 19.2302 161.028 19.6372 160.787 19.973C160.677 20.1163 160.534 20.2314 160.371 20.309C160.208 20.3866 160.029 20.4244 159.848 20.4193H158.905C158.72 20.4222 158.536 20.3858 158.366 20.3127C158.196 20.2396 158.043 20.1313 157.917 19.995C157.641 19.6877 157.474 19.2978 157.442 18.8859V17.3678C157.552 17.0061 157.758 16.6805 158.036 16.425C158.269 16.1956 158.58 16.0631 158.907 16.0542H159.834C160.008 16.0472 160.183 16.0775 160.345 16.1432C160.507 16.2089 160.653 16.3085 160.774 16.4352C161.017 16.7039 161.146 17.0567 161.133 17.4187L161.131 18.8172Z"
        fill="#302F46"
      />
      <path
        d="M135.221 15.762C134.975 15.3254 134.607 14.9694 134.163 14.7369C133.676 14.4897 133.136 14.3664 132.59 14.3779L131.514 14.3618C131.157 14.3565 130.806 14.4539 130.503 14.6424C130.2 14.8309 129.958 15.1026 129.805 15.4251V14.4975H127.998V25.0006H129.96V21.5163C130.132 21.7136 130.347 21.868 130.59 21.9673C130.832 22.0666 131.094 22.108 131.355 22.0882H132.638C133.182 22.0988 133.718 21.9652 134.192 21.7012C134.637 21.4431 134.998 21.0633 135.233 20.6066C135.49 20.0988 135.618 19.5359 135.607 18.9671V17.2699C135.612 16.7422 135.479 16.2224 135.221 15.762ZM133.632 18.8169C133.652 19.2301 133.53 19.6377 133.288 19.9727C133.179 20.1157 133.037 20.2307 132.875 20.3084C132.712 20.386 132.534 20.4239 132.354 20.419H131.411C131.226 20.4221 131.043 20.3859 130.873 20.3127C130.703 20.2396 130.55 20.1312 130.425 19.9947C130.148 19.6882 129.98 19.298 129.948 18.8856V17.3582C130.058 16.9965 130.263 16.6709 130.542 16.4154C130.774 16.1857 131.084 16.0531 131.411 16.0445H132.336C132.511 16.038 132.685 16.0685 132.848 16.1342C133.01 16.1998 133.157 16.2991 133.278 16.4256C133.521 16.6966 133.649 17.0514 133.635 17.415L133.632 18.8169Z"
        fill="#302F46"
      />
      <path
        d="M151.61 11.4463V15.164C151.149 14.7128 150.545 14.4371 149.903 14.385H149.068C148.499 14.3723 147.935 14.5033 147.429 14.766C146.972 15.0117 146.594 15.3837 146.343 15.8377C146.076 16.3307 145.942 16.884 145.953 17.4441V19.1761C145.939 19.5662 146.004 19.9552 146.145 20.3193C146.286 20.6833 146.499 21.0148 146.773 21.2933C147.057 21.5651 147.393 21.7766 147.761 21.915C148.13 22.0534 148.522 22.1159 148.915 22.0987H149.807C150.217 22.0894 150.616 21.9667 150.961 21.7443C151.305 21.5218 151.581 21.2083 151.758 20.8385V21.9782H153.566V11.4463H151.61ZM148.27 20.0306C148.029 19.7451 147.905 19.3787 147.924 19.0055V17.5714C147.905 17.165 148.043 16.767 148.309 16.4589C148.439 16.3178 148.597 16.2067 148.774 16.1334C148.952 16.0601 149.143 16.0264 149.334 16.0346H150.258C150.423 16.034 150.585 16.0668 150.737 16.131C150.888 16.1952 151.025 16.2894 151.139 16.408C151.395 16.6771 151.562 17.0184 151.617 17.3856V18.7153C151.599 19.1696 151.425 19.6038 151.124 19.9449C150.988 20.0947 150.822 20.2136 150.636 20.2938C150.45 20.374 150.249 20.4136 150.047 20.41H149.18C149.009 20.4171 148.84 20.3868 148.683 20.3213C148.525 20.2558 148.384 20.1565 148.27 20.0306Z"
        fill="#302F46"
      />
      <path
        d="M22.7471 28.7158C22.5048 28.7161 22.2649 28.6686 22.0409 28.5762C21.817 28.4837 21.6135 28.348 21.4419 28.1769L14.789 21.5333C14.4533 21.1853 14.2677 20.7194 14.272 20.2359C14.2764 19.7524 14.4704 19.29 14.8123 18.9481C15.1542 18.6062 15.6167 18.4122 16.1002 18.4078C16.5837 18.4034 17.0496 18.5891 17.3976 18.9247L24.0412 25.5684C24.298 25.8259 24.4729 26.1536 24.544 26.5102C24.6152 26.8669 24.5793 27.2366 24.4409 27.5729C24.3025 27.9092 24.0678 28.1972 23.7663 28.4005C23.4648 28.6038 23.1107 28.7135 22.7471 28.7158Z"
        fill="#95C11F"
      />
      <path
        d="M22.7469 28.7156C22.3823 28.7153 22.0261 28.6071 21.723 28.4045C21.4199 28.2019 21.1837 27.9141 21.044 27.5774C20.9044 27.2406 20.8676 26.8701 20.9384 26.5125C21.0092 26.1549 21.1844 25.8263 21.4418 25.5681L31.2523 15.7499C31.6003 15.4143 32.0662 15.2286 32.5497 15.233C33.0332 15.2373 33.4957 15.4314 33.8376 15.7733C34.1795 16.1152 34.3735 16.5776 34.3778 17.0611C34.3822 17.5446 34.1966 18.0105 33.8609 18.3585L24.0435 28.1725C23.7 28.5176 23.2339 28.7128 22.7469 28.7156Z"
        fill="#3AAB49"
      />
      <path
        d="M23.9952 25.5089L22.7452 24.2649L21.4435 25.5064C21.1729 25.7623 20.9853 26.0936 20.905 26.4573C20.8248 26.821 20.8555 27.2005 20.9933 27.5465C21.131 27.8926 21.3694 28.1894 21.6777 28.3984C21.9859 28.6075 22.3498 28.7193 22.7223 28.7193C23.0948 28.7193 23.4587 28.6075 23.7669 28.3984C24.0752 28.1894 24.3136 27.8926 24.4513 27.5465C24.5891 27.2005 24.6198 26.821 24.5395 26.4573C24.4593 26.0936 24.2717 25.7623 24.0011 25.5064L23.9952 25.5089Z"
        fill="#009939"
      />
    </svg>
  );
}
