import { Button } from "@chakra-ui/react";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";

import { InterpretationRequestModal } from "./InterpretationRequestModal";

interface IProps {
  verificationId: string;
}

export const InterpretationRequestButton = ({ verificationId }: IProps) => {
  const id = `verification-request`;
  const onOpen = useOpenDialog(id);

  return (
    <>
      <InterpretationRequestModal id={id} verificationId={verificationId} />
      <Button
        variant="outline"
        colorScheme="purple"
        onClick={onOpen}
        whiteSpace="normal"
      >
        {t("Zadaj pytanie dotyczące wyniku weryfikacji")}
      </Button>
    </>
  );
};
