import { Button, IconButton, useBreakpoint } from "@chakra-ui/react";
import { t } from "i18next";

import { EmailEditIcon } from "components/icons/EmailEditIcon";

import { NotificationProcess } from "modules/notifications/application/NotificationProcess";

import { useOpenCorrectionModal } from "./CorrectionModal";

interface IProps {
  smallBreakpoints?: string[];
  notificationProcess: NotificationProcess;
}

export const CorrectionButton = ({
  smallBreakpoints = ["base", "sm"],
  notificationProcess,
}: IProps) => {
  const breakpoint = useBreakpoint();
  const onOpen = useOpenCorrectionModal();

  if (smallBreakpoints.includes(breakpoint)) {
    return (
      <IconButton
        aria-label={t("Korekta")}
        icon={<EmailEditIcon />}
        title={t("Korekta")}
        onClick={() => {
          onOpen(notificationProcess);
        }}
      />
    );
  }

  return (
    <Button
      onClick={() => {
        onOpen(notificationProcess);
      }}
    >
      {t("Korekta")}
    </Button>
  );
};
