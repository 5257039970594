import { useMutation } from "@tanstack/react-query";

import { client } from "utils/http";

interface MutationVariables {
  comment: string;
}

export const useEditOrganizationComment = (organizationId: string) => {
  return useMutation({
    mutationKey: ["edit-organization-comment", organizationId],
    mutationFn: (variables: MutationVariables) => {
      return client.put(`organizations/${organizationId}/comment`, {
        body: { ...variables },
      });
    },
    onSuccess() {},
  });
};
