import { Control, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  CardProps,
  Heading,
  HStack,
  Input,
  Skeleton,
  VStack,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { t } from "i18next";

import { toastWithError } from "components/ErrorHandling/toastWithError";
import { FormControl } from "components/Form/FormControl";
import { TextEditor } from "components/Form/TextEditor";

import { useAddNewChange } from "../infrastructure/useAddNewChange";
import { useChangelogQuery } from "../infrastructure/useChangelogQuery";
import { useEditChange } from "../infrastructure/useEditChange";

interface ChangelogFormData {
  title: string;
  content: string;
  publishedAtDate: string | null;
  publishedAtTime: string | null;
}

interface IProps {
  defaultData?: Partial<ChangelogFormData>;
}

const ChangelogFormConnected = ({ defaultData }: IProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    reset,
    setValue,
  } = useForm<ChangelogFormData>({ defaultValues: defaultData });

  const { mutateAsync: addNew } = useAddNewChange();
  const { mutateAsync: edit } = useEditChange(searchParams.get("changeId")!);

  return (
    <form id="changelog-form" noValidate>
      <VStack align="stretch" spacing={6}>
        <FormControl
          formLabel={t("Tytuł wiadomości")}
          isRequired
          formErrorMessage={errors.title?.message}
        >
          <Input
            {...register("title", { required: t("Pole jest wymagane.") })}
          />
        </FormControl>
        <TextEditor
          control={control as unknown as Control}
          label={t("Treść")}
          name="content"
        />
        <FormControl
          formLabel={t("Termin publikacji")}
          formHelperText={t(
            "Nie podanie daty publikacji spowoduje zapisanie zmian jako wersja robocza."
          )}
          formErrorMessage={
            errors.publishedAtDate?.message || errors.publishedAtTime?.message
          }
        >
          <HStack>
            <Input
              type="date"
              placeholder="dd.mm.rrrr"
              maxW="60"
              {...register("publishedAtDate")}
              onChange={(e) => {
                if (!e.target.value) {
                  setValue("publishedAtTime", null);
                }
              }}
            />
            <Input type="time" maxW="32" {...register("publishedAtTime")} />
          </HStack>
        </FormControl>
        <ButtonGroup pt="6" pb="2">
          <Button
            type="reset"
            isLoading={isSubmitting}
            onClick={() => {
              setSearchParams({});
              reset();
            }}
          >
            {t("Resetuj")}
          </Button>
          <Button
            type="submit"
            colorScheme="purple"
            isLoading={isSubmitting}
            onClick={handleSubmit(async (data) => {
              try {
                const publishedAt = dayjs(
                  `${data.publishedAtDate} ${data.publishedAtTime}`
                ).toDate();
                if (searchParams.get("changeId")) {
                  await edit({
                    title: data.title,
                    content: data.content,
                    publishedAt,
                  });
                } else {
                  await addNew({
                    title: data.title,
                    content: data.content,
                    publishedAt,
                  });
                }

                setSearchParams({});
                reset();
              } catch (error) {
                toastWithError({
                  error,
                });
              }
            })}
          >
            {searchParams.get("changeId")
              ? t("Zapisz zmianę")
              : t("Dodaj zmianę")}
          </Button>
        </ButtonGroup>
      </VStack>
    </form>
  );
};

const ChangelogFormConnectedWithData = ({ changeId }: { changeId: string }) => {
  const { data, isLoading } = useChangelogQuery();

  if (isLoading) {
    return (
      <VStack align="stretch" spacing={6}>
        <Skeleton h="75px" />;
        <Skeleton h="275px" />;
        <Skeleton h="55px" />;
        <Skeleton h="40px" />;
      </VStack>
    );
  }

  const change = data?.changelog.find((change) => change.id === changeId);

  return (
    <ChangelogFormConnected
      defaultData={
        change
          ? {
              title: change.title,
              content: change.content,
              publishedAtDate: dayjs(change.publishedAt).format("YYYY-MM-DD"),
              publishedAtTime: dayjs(change.publishedAt).format("HH:mm"),
            }
          : undefined
      }
    />
  );
};

export const ChangelogForm = (props: CardProps) => {
  const [searchParams] = useSearchParams();
  const changeId = searchParams.get("changeId");

  return (
    <Card variant="sawpe" {...props} id="notification-form">
      <CardHeader>
        <Heading fontSize="lg">{t("Dodaj nowy wpis")}</Heading>
      </CardHeader>
      <CardBody>
        {changeId ? (
          <div key={changeId}>
            <ChangelogFormConnectedWithData changeId={changeId} />
          </div>
        ) : (
          <ChangelogFormConnected />
        )}
      </CardBody>
    </Card>
  );
};
