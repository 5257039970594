import { ReactNode } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  Box,
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  IconButton,
  Spacer,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { t } from "i18next";

import { MenuIcon } from "components/icons/MenuIcon";
import { SAWPEIcon } from "components/icons/SAWPEIcon";

import { ChangelogModal } from "modules/changelog/presentation/ChangelogModal";

import { EventHistoryBtn } from "./EventHistoryBtn";
import { LanguageMenu } from "./LanguageMenu";
import { NotReadDot } from "./NotReadDot";
import { NotificationButton } from "./NotificationButton";
import { ProfileMenu } from "./ProfileMenu";
import { StartBtn } from "./StartBtn";
import { SystemEventsButton } from "./SystemEventsButton";
import { VerificationHistoryBtn } from "./VerificationHistoryBtn";

interface IProps {
  children: ReactNode;
}

export const Layout = withTranslation()(({ children }: IProps) => {
  return (
    <>
      <HStack
        as="header"
        position="sticky"
        top="0"
        w="full"
        background="white"
        boxShadow="md"
        px={3}
        py={2}
        spacing={6}
        zIndex={2}
      >
        <Box>
          <Link to="/">
            <SAWPEIcon height={50} />
          </Link>
        </Box>
        <ResponsiveMenu>
          <StartBtn />
          <EventHistoryBtn />
          <VerificationHistoryBtn />
        </ResponsiveMenu>
      </HStack>
      <Box
        mt={8}
        px={{ base: 4, md: 8 }}
        pb={8}
        maxH="calc(100vh - 100px)"
        overflowY="auto"
        data-testid="layout"
      >
        {children}
      </Box>
    </>
  );
});

const ResponsiveMenu = ({ children }: { children: ReactNode }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <ChangelogModal />
      <HStack w="full" spacing={3} display={{ base: "none", lg: "flex" }}>
        <Spacer />
        <ButtonGroup>{children}</ButtonGroup>
        <ButtonGroup>
          <LanguageMenu />
          <SystemEventsButton />
          <NotificationButton />
        </ButtonGroup>
        <ProfileMenu />
      </HStack>
      <VStack w="full" display={{ base: "flex", lg: "none" }} align="flex-end">
        <HStack w="full" spacing={4} justifyContent="flex-end">
          <LanguageMenu />
          <NotReadDot>
            {() => {
              return (
                <IconButton
                  variant="ghost"
                  aria-label={t("Menu")}
                  icon={<MenuIcon />}
                  onClick={() => {
                    onOpen();
                  }}
                />
              );
            }}
          </NotReadDot>
        </HStack>
        <Drawer onClose={onClose} isOpen={isOpen} placement="top">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>
              <Link to="/">
                <SAWPEIcon height={50} />
              </Link>
            </DrawerHeader>
            <DrawerBody>
              <VStack w="full" maxW="500px" spacing="6" pb={8} margin="auto">
                <ProfileMenu />
                {children}
                <SystemEventsButton />
                <NotificationButton />
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </VStack>
    </>
  );
};
