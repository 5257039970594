import { Link, useLocation, useParams } from "react-router-dom";

import { Button } from "@chakra-ui/react";
import { t } from "i18next";

import { HomeIcon } from "components/icons/HomeIcon";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";

export const StartBtn = () => {
  const { userInfo } = ContextMachineContext.useSelector(
    (state) => state.context
  );
  const { organizationId } = useParams<{ organizationId: string }>();
  const { pathname } = useLocation();

  const getPath = () => {
    switch (userInfo?.currentlySelectedContext?.type) {
      case "system_admin":
        return "/";
      case "system_manager":
        return "/";
      case "context_manager":
        return `/${organizationId}`;
      case "context_user":
        return `/${organizationId}/weryfikacja`;
      default:
        return "/";
    }
  };

  return (
    <Link to={getPath()} style={{ width: "100%" }}>
      <Button
        w="100%"
        variant={{ base: "link", lg: "ghost" }}
        leftIcon={<HomeIcon height="24px" width="24px" />}
        isActive={pathname === getPath()}
      >
        {t("Start")}
      </Button>
    </Link>
  );
};
