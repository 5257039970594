import { chakra } from "@chakra-ui/react";

import { EmptyState } from "./EmptyState";

export const NoSettingsFileState = () => {
  return (
    <EmptyState
      mt={24}
      title={"Wystąpił błąd"}
      description={
        <chakra.div textAlign="center">
          <p>{"Nie odnaleziono pliku konfiguracyjnego"}</p>
          <p>{"Skontaktuj się z administratorem"}</p>
        </chakra.div>
      }
    />
  );
};
