import { useState } from "react";
import { Control, useForm } from "react-hook-form";

import { Checkbox, HStack, Input, Text, VStack } from "@chakra-ui/react";
import dayjs from "dayjs";
import { t } from "i18next";
import { HTTPError } from "ky";

import { toastWithError } from "components/ErrorHandling/toastWithError";
import { FormControl } from "components/Form/FormControl";
import { HourSelect } from "components/Form/HourSelect";
import { TextEditor } from "components/Form/TextEditor";

import { useCompleteNotificationProcess } from "modules/notifications/infrastructure/useCompleteNotificationProcess";
import { useCreateNotificationProcess } from "modules/notifications/infrastructure/useCreateNotificationProcess";

import { NotificationFormData } from "../NotificationFormCard/NotificationFormData";

type FormData = NotificationFormData & {
  recipientsFrom: string;
};

interface IProps {
  onClose: () => void;
  defaultData: Partial<FormData>;
}

export const CorrectionForm = ({ defaultData, onClose }: IProps) => {
  const [isIntermediate, setIsIntermediate] = useState(true);
  const {
    register,
    unregister,
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useForm<FormData>({
    defaultValues: defaultData,
  });

  const { mutateAsync: create } = useCreateNotificationProcess();
  const { mutateAsync: complete } = useCompleteNotificationProcess();

  return (
    <form
      id="correction-form"
      noValidate
      onSubmit={handleSubmit(async (data) => {
        try {
          const { notificationsProcess } = await create({
            tariffTypes: data.tariffs,
            subject: data.title,
            body: data.content,
            sendAtDate: data.plannedDeliveryDate || null,
            sendAtTime: data.plannedDeliveryHour || null,
            recipientsFrom: data.recipientsFrom,
          });
          await complete(notificationsProcess.id);
          onClose();
        } catch (error) {
          if (error instanceof HTTPError) {
            const errorJson = await error.response.json<{
              message: string;
            }>();

            if (errorJson.message === "notificationProcess.error.noPastDates") {
              setError("plannedDeliveryDate", {
                type: "manual",
                message: t("Data nie może być z przeszłości."),
              });
              return;
            }
          }

          toastWithError({
            error,
          });
        }
      })}
    >
      <VStack align="stretch" spacing={6}>
        <FormControl
          formLabel={t("Tytuł wiadomości")}
          isRequired
          formErrorMessage={errors.title?.message}
        >
          <Input
            {...register("title", { required: t("Pole jest wymagane.") })}
          />
        </FormControl>
        <TextEditor
          control={control as unknown as Control}
          label={t("Treść wiadomości")}
          name="content"
        />
        <VStack align="start" spacing={2}>
          <Text fontWeight="500">{t("Planowany termin wysyłki")}</Text>
          <HStack as={"label"}>
            <Checkbox
              isChecked={isIntermediate}
              name="intermediateDelivery"
              onChange={() => {
                setIsIntermediate((prev) => !prev);
                unregister("plannedDeliveryDate");
                unregister("plannedDeliveryHour");
              }}
            />
            <Text>{t("wyślij natychmiast")}</Text>
          </HStack>
        </VStack>
        {!isIntermediate && (
          <FormControl
            formLabel={t("Inny termin wysyłki")}
            isRequired
            formErrorMessage={errors.plannedDeliveryDate?.message}
          >
            <HStack>
              <Input
                type="date"
                placeholder="dd.mm.rrrr"
                min={dayjs().format("YYYY-MM-DD")}
                maxW="60"
                {...register("plannedDeliveryDate", {
                  required: t("Pole jest wymagane."),
                })}
              />
              <HourSelect register={register("plannedDeliveryHour")} />
            </HStack>
          </FormControl>
        )}
      </VStack>
    </form>
  );
};
