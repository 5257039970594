import { Button, HStack, Text, VStack } from "@chakra-ui/react";
import { t } from "i18next";

import { AttachmentIcon } from "components/icons/AttachmentIcon";

import { IFile } from "./IFile";

interface IProps {
  files: IFile[];
}

export const FileList = ({ files }: IProps) => {
  return (
    <VStack
      align="stretch"
      spacing={0}
      border="1px solid"
      borderColor="gray.300"
      maxW="max-content"
      borderRadius="6px"
    >
      {files.map(({ fileId, fileName, url }, index) => (
        <HStack
          key={fileId}
          justifyContent="space-between"
          px={3}
          py={2}
          minW="300px"
          borderBottom={
            files.length > 1 && index + 1 !== files.length
              ? "1px solid"
              : "none"
          }
          borderColor="gray.300"
        >
          <HStack spacing={2}>
            <AttachmentIcon color="gray.500" />
            <Text maxW="200px" fontSize="sm" isTruncated>
              {fileName}
            </Text>
          </HStack>
          <Button
            variant="link"
            colorScheme="purple"
            size="sm"
            onClick={() => {
              window.open(url);
            }}
          >
            {t("Pobierz")}
          </Button>
        </HStack>
      ))}
    </VStack>
  );
};
