import { Link, useNavigate } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  GridItem,
  HStack,
  SimpleGrid,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { t } from "i18next";

import { UserBlockedIcon } from "components/icons/UserBlockedIcon";
import { UserCheckIcon } from "components/icons/UserCheckIcon";
import { UserNewIcon } from "components/icons/UserNewIcon";
import { UserPendingIcon } from "components/icons/UserPendingIcon";
import { UsersIcon } from "components/icons/UsersIcon";

import { getOrganizationsStatistics } from "modules/dashboard/application/getOrganizationsStatistics";
import { OrganizationStatus } from "modules/organization/application/types/IOrganization";
import { useOrganizationsQuery } from "modules/organization/infrastructure/useOrganizationsQuery";

export const MenageOrganizationsTile = () => {
  const { data, isLoading } = useOrganizationsQuery();
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <>
        <GridItem colSpan={2}>
          <Skeleton height="100%" />
        </GridItem>
      </>
    );
  }

  const organizationStatistics = getOrganizationsStatistics(
    data?.organizations
  );

  return (
    <GridItem colSpan={2}>
      <Card height="100%">
        <CardHeader
          fontSize="lg"
          fontWeight="semibold"
          roundedTop="md"
          color="white"
          bgColor="purple.500"
        >
          <HStack justifyContent="space-between">
            <Text>{t("Podmioty")}</Text>
            <UsersIcon />
          </HStack>
        </CardHeader>

        <CardBody>
          <VStack align="stretch" gap="8" h="100%">
            <SimpleGrid columns={{ base: 2, md: 3, lg: 5 }}>
              {Object.entries(organizationStatistics).map(([status, count]) => {
                const [icon, text] = getMeta(status as OrganizationStatus);
                return (
                  <Link
                    to={
                      status === "PENDING"
                        ? `/organizacje`
                        : `/organizacje?status=${status}`
                    }
                    key={status}
                  >
                    <Flex
                      flexDir="column"
                      alignItems="center"
                      cursor="pointer"
                      py={2}
                      _hover={{ bgColor: "gray.100" }}
                      rounded="md"
                    >
                      {icon}
                      <Text fontSize="lg" fontWeight="semibold">
                        {count}
                      </Text>
                      <Text fontSize="sm" color="gray.600">
                        {text}
                      </Text>
                    </Flex>
                  </Link>
                );
              })}
              <Link to={`/organizacje`}>
                <Flex
                  flexDir="column"
                  alignItems="center"
                  cursor="pointer"
                  py={2}
                  _hover={{ bgColor: "gray.100" }}
                  rounded="md"
                >
                  <UsersIcon size="24px" mb="2" color="purple.500" />
                  <Text fontSize="lg" fontWeight="semibold">
                    {data?.organizations.length}
                  </Text>
                  <Text fontSize="sm" color="gray.600">
                    {t("Wszystkie")}
                  </Text>
                </Flex>
              </Link>
            </SimpleGrid>
            <Button
              mt="auto"
              colorScheme="purple"
              onClick={() => navigate("/organizacje")}
            >
              {t("Przeglądaj listę podmiotów")}
            </Button>
          </VStack>
        </CardBody>
      </Card>
    </GridItem>
  );
};

const getMeta = (status: OrganizationStatus) => {
  switch (status) {
    case "ACTIVE":
      return [
        <UserCheckIcon size="24px" mb="2" color="green.500" />,
        t("Aktywne"),
      ];
    case "BLOCKED":
      return [
        <UserBlockedIcon size="24px" mb="2" color="red.500" />,
        t("Nieaktywne"),
      ];
    case "PENDING":
      return [
        <UserPendingIcon size="24px" mb="2" color="yellow.500" />,
        t("Oczekujące"),
      ];
    case "NEW":
      return [<UserNewIcon size="24px" mb="2" color="blue.500" />, t("Nowe")];
    default:
      // eslint-disable-next-line no-case-declarations, @typescript-eslint/no-unused-vars
      const type: never = status;
      return [
        <UsersIcon size="24px" mb="2" color="purple.500" />,
        t("Wszystkie"),
      ];
  }
};
