import { useParams } from "react-router-dom";

import { IconButton, Tooltip } from "@chakra-ui/react";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";
import { PowerIcon } from "components/icons/PowerIcon";

import { useApiKeysQuery } from "modules/apiKey/infrastructure/useApiKeysQuery";
import { useTariffsQuery } from "modules/tariffs/infrastructure/useTariffsQuery";

import { ToggleApiKeyConfirm } from "./ToggleApiKeyConfirm";

interface IProps {
  keyId: string;
  isActive: boolean;
  apiKey: string;
}

export const ToggleApiKeyButton = ({ isActive, keyId, apiKey }: IProps) => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { data: keysData } = useApiKeysQuery(organizationId!);
  const { data: tariffsData } = useTariffsQuery(organizationId!);

  const id = `toggle-api-key-${keyId}`;
  const onOpen = useOpenDialog(id);

  const activeKey = keysData?.apiKeys.find(({ active }) => active);
  if (activeKey && activeKey?.id !== keyId) {
    return null;
  }

  if (!tariffsData?.tariffs.current?.isActive) {
    return null;
  }

  const label = isActive ? t("Dezaktywuj") : t("Aktywuj");

  return (
    <>
      <ToggleApiKeyConfirm
        id={id}
        keyId={keyId}
        isActive={isActive}
        apiKey={apiKey}
      />
      <Tooltip label={label} aria-label={label}>
        <IconButton
          variant="link"
          colorScheme="purple"
          size="sm"
          data-testid="toggle-api-key"
          onClick={onOpen}
          aria-label={label}
          icon={<PowerIcon />}
        />
      </Tooltip>
    </>
  );
};
