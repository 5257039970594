import { withTranslation } from "react-i18next";

import { HStack, SimpleGrid, VStack } from "@chakra-ui/react";

import { OrganizationPreviewWidget } from "modules/organization/presentation/OrganizationPreviewWidget";
import { TariffUsageWidget } from "modules/tariffs/presentation/TariffUsageWidget";
import { StartVerification } from "modules/verification/presentation/StartVerification";

export const Verification = withTranslation()(() => {
  return (
    <VStack align="stretch" gap="8">
      <SimpleGrid columns={{ base: 1, md: 2 }} gap="8">
        <OrganizationPreviewWidget />
        <TariffUsageWidget />
      </SimpleGrid>
      <HStack gap="8" align="start">
        <VStack align="stretch" w="100%" gap="6">
          <StartVerification />
        </VStack>
      </HStack>
    </VStack>
  );
});
