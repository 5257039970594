import { QueryClient } from "@tanstack/react-query";
import { http, HttpResponse } from "msw";

import { client, withHost } from "utils/http";
import { useQuery } from "utils/useQuery";

import { IDefaultIntegrationKey } from "../application/types/IDefaultIntegrationKey";

const query = () => {
  return {
    queryKey: ["default-integration-keys"],
    queryFn: () =>
      client.get<{ defaultIntegrationKeys: IDefaultIntegrationKey[] }>(
        `integrationKeys/default`
      ),
  };
};

export const getDefaultIntegrationKeysQuery =
  (queryClient: QueryClient) => () => {
    return queryClient.ensureQueryData(query());
  };

export const useDefaultIntegrationKeysQuery = () => {
  return useQuery(query());
};

export const getDefaultIntegrationMock = (
  integrationKeys: IDefaultIntegrationKey[] = []
) => {
  return http.get(withHost("integrationKeys/default"), () => {
    return HttpResponse.json<{
      defaultIntegrationKeys: IDefaultIntegrationKey[];
    }>({
      defaultIntegrationKeys: [...integrationKeys],
    });
  });
};
