import { QueryClient } from "@tanstack/react-query";
import { http, HttpResponse } from "msw";

import { client, withHost } from "utils/http";
import { useQuery } from "utils/useQuery";

import { NotificationsEmails } from "../application/NotificationsEmails";

const query = (processId: string) => {
  return {
    queryKey: ["notifications-process", "emails"],
    queryFn: () =>
      client.get<{ emails: NotificationsEmails[] }>(
        `notificationsProcess/${processId}/emails`
      ),
  };
};

export const getNotificationProcessEmailsQuery =
  (queryClient: QueryClient) => (processId: string) => {
    return queryClient.ensureQueryData(query(processId));
  };

export const useNotificationProcessEmailsQuery = (processId: string) => {
  return useQuery(query(processId));
};

export const getNotificationProcessEmailsMock = (
  emails: NotificationsEmails[] = []
) => {
  return http.get(withHost("notificationsProcess/:processId/emails"), () => {
    return HttpResponse.json<{ emails: NotificationsEmails[] }>({
      emails: [...emails],
    });
  });
};
