import { QueryClient } from "@tanstack/react-query";
import { http, HttpResponse } from "msw";

import { client, withHost } from "utils/http";
import { useQuery } from "utils/useQuery";

import { ISystemContext } from "../application/types/SystemContext";

const query = () => {
  return {
    queryKey: ["system", "contexts"],
    queryFn: () =>
      client.get<{ contexts: ISystemContext[] }>(`system/contexts`),
  };
};

export const getSystemContextsQuery = (queryClient: QueryClient) => () => {
  return queryClient.ensureQueryData(query());
};

export const useSystemContextsQuery = () => {
  return useQuery(query());
};

export const getSystemContextsMock = (contexts: ISystemContext[] = []) => {
  return http.get(withHost("system/contexts"), () => {
    return HttpResponse.json<{ contexts: ISystemContext[] }>({
      contexts: [...contexts],
    });
  });
};
