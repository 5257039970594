import { Box, HStack, Text, chakra } from "@chakra-ui/react";
import { t } from "i18next";

import { CheckCircleOutlineIcon } from "components/icons/CheckCircleOutlineIcon";
import { CircleOutlineIcon } from "components/icons/CircleOutlineIcon";

interface Props {
  password: string;
  rules: {
    minLength: number;
    strength: "weak" | "medium" | "strong";
  };
}

export const PasswordValidationList = (props: Props) => {
  const { minLength, strength } = props.rules;

  const rulesSets = {
    weak: [
      {
        title: t(`co najmniej {{minLength}} znaków`, { minLength }),
        regex: new RegExp(`^.{${minLength},}$`),
      },
    ],
    medium: [
      {
        title: t("co najmniej jedną dużą literę"),
        regex: /[A-Z]/,
      },
      {
        title: t("co najmniej jedną małą literę"),
        regex: /[a-z]/,
      },
    ],
    strong: [
      {
        title: t("co najmniej jedną cyfrę"),
        regex: /\d/,
      },
      {
        title: t("co najmniej jeden znak specjalny"),
        regex: /\W|_/g,
      },
    ],
  };

  const rulesToApply = [
    ...rulesSets.weak,
    ...(strength === "medium" ? rulesSets.medium : []),
    ...(strength === "strong"
      ? [...rulesSets.medium, ...rulesSets.strong]
      : []),
  ];

  return (
    <Box as="ul" listStyleType="none">
      {rulesToApply.map((element, index) => (
        <chakra.li key={index} mb={2}>
          <HStack>
            {element.regex.test(props.password) ? (
              <CheckCircleOutlineIcon color="green.500" />
            ) : (
              <CircleOutlineIcon color="gray.300" />
            )}
            <Text>{element.title as string}</Text>
          </HStack>
        </chakra.li>
      ))}
    </Box>
  );
};
