import { ComponentProps } from "react";

import { mdiChevronDoubleLeft } from "@mdi/js";

import { Icon } from "./Icon";

export const ChevronDoubleLeftIcon = (
  props: Omit<ComponentProps<typeof Icon>, "path">
) => {
  return <Icon {...props} path={mdiChevronDoubleLeft} />;
};
