import { cardAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, extendTheme } from "@chakra-ui/react";
// czcionka zgodna z weryfikacjapodpisu.pl
import "@fontsource/onest/400.css";
import "@fontsource/onest/500.css";
import "@fontsource/onest/600.css";
import "@fontsource/onest/700.css";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys);

const cardTheme = defineMultiStyleConfig({
  variants: {
    sawpe: definePartsStyle({
      container: {
        boxShadow: "base",
      },
      header: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: 6,
        p: 4,
      },
      body: {
        mt: 2,
        px: 4,
        py: 3,
      },
    }),
  },
});

export const theme = extendTheme({
  fonts: {
    heading: `'Onest', sans-serif`,
    body: `'Onest', sans-serif`,
  },
  styles: {
    global: {
      body: {
        bg: "gray.50",
        scrollBehavior: "smooth",
      },
    },
  },
  components: {
    Card: cardTheme,
  },
});
