import { useParams } from "react-router-dom";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

interface MutationVariables {
  password: string;
}

export const useEditUserPassword = (contextId: string) => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["edit-user-password"],
    mutationFn: (variables: MutationVariables) => {
      return client.put(
        `organizations/${organizationId}/contexts/${contextId}/password`,
        {
          body: { password: variables.password },
        }
      );
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["organizations", organizationId, "contexts"],
      });
    },
  });
};
