import { Button } from "@chakra-ui/react";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";
import { PlusIcon } from "components/icons/PlusIcon";

import { useOrganizationByIdQuery } from "modules/organization/infrastructure/useOrganizationByIdQuery";

import { AddManagerModal } from "./AddManagerModal";

interface IProps {
  organizationId: string;
}

export const AddManagerBtn = ({ organizationId }: IProps) => {
  const id = `add-manager`;
  const onOpen = useOpenDialog(id);
  const { data, isLoading } = useOrganizationByIdQuery(organizationId!);

  if (isLoading) {
    return null;
  }

  if (data?.organization.status !== "ACTIVE") {
    return null;
  }

  return (
    <>
      <AddManagerModal id={id} />
      <Button
        size="sm"
        leftIcon={<PlusIcon />}
        colorScheme="purple"
        onClick={onOpen}
      >
        {t("Dodaj administratora")}
      </Button>
    </>
  );
};
