import { QueryClient } from "@tanstack/react-query";
import { HttpResponse, http } from "msw";

import { client, withHost } from "utils/http";
import { useQuery } from "utils/useQuery";

import { IApiKey } from "../application/types/IApiKey";

const query = (organizationId: string) => {
  return {
    queryKey: ["organizations", organizationId, "apiKeys"],
    queryFn: () =>
      client.get<{ apiKeys: IApiKey[] }>(
        `organizations/${organizationId}/apiKeys`
      ),
  };
};

export const getApiKeysQuery =
  (queryClient: QueryClient) => (organizationId: string) => {
    return queryClient.ensureQueryData(query(organizationId));
  };

export const useApiKeysQuery = (organizationId: string) => {
  return useQuery(query(organizationId));
};

export const getApiKeysMock = (keys: IApiKey[] = []) => {
  return http.get(withHost("organizations/:organizationId/apiKeys"), () => {
    return HttpResponse.json<{ apiKeys: IApiKey[] }>({
      apiKeys: [...keys],
    });
  });
};
