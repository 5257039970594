import { withTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { Box, VStack } from "@chakra-ui/react";

import { SAWPEIcon } from "components/icons/SAWPEIcon";

import { Footer } from "modules/auth/presentation/Footer";
import { ForgotPasswordForm } from "modules/auth/presentation/ForgotPasswordForm";
import { LinkSendCard } from "modules/auth/presentation/LinkSendCard";

export const ForgotPassword = withTranslation()(() => {
  const [searchParams] = useSearchParams();

  const email = searchParams.get("email");

  return (
    <VStack minH="100vh" justifyContent="center" spacing={8} pb="120px">
      <Box w={[330, 420]}>
        <SAWPEIcon height="71px" width="100%" />
      </Box>
      {email ? <LinkSendCard /> : <ForgotPasswordForm />}
      <Footer />
    </VStack>
  );
});
