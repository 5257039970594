import { withTranslation } from "react-i18next";

import { HStack, VStack } from "@chakra-ui/react";

import { OrganizationHeader } from "modules/organization/presentation/OrganizationHeader";
import { VerificationsHistoryTable } from "modules/verification/presentation/VerificationsHistoryTable";

export const VerificationsHistory = withTranslation()(() => {
  return (
    <VStack align="stretch" gap="8">
      <OrganizationHeader />
      <HStack gap="8" align="start">
        <VStack align="stretch" w="100%" gap="6">
          <VerificationsHistoryTable />
        </VStack>
      </HStack>
    </VStack>
  );
});
