import { create } from "zustand";

type UseDialog = {
  isOpen(id: string): boolean;
  onOpen(id: string, payload?: unknown): void;
  onClose(id: string): void;
  getPayload(id: string): unknown;
};

export const useDialogStore = create<UseDialog>((set, get) => {
  type Store = UseDialog & {
    [id: string]: { isOpen: boolean; payload: unknown };
  };
  return {
    isOpen(id) {
      const store = get() as Store;
      return store[id]?.isOpen ?? false;
    },
    onClose(id) {
      set({ [id]: { isOpen: false, payload: undefined } } as Store);
    },
    onOpen(id, payload) {
      set({ [id]: { isOpen: true, payload } } as Store);
    },
    getPayload(id) {
      const store = get() as Store;
      return store[id]?.payload;
    },
  };
});
