import { Card, CardBody, CardHeader, Heading, Spacer } from "@chakra-ui/react";
import { t } from "i18next";

import { IEvent } from "modules/eventLog/application/types/IEvent";
import { useSystemEventsQuery } from "modules/eventLog/infrastructure/useSystemEventsQuery";

import { EventTableConnected } from ".";
import { EventHistoryFilter } from "./EventHistoryFilter";

export const SystemEventsTable = () => {
  const { data, isLoading } = useSystemEventsQuery();

  return (
    <Card variant="sawpe">
      <CardHeader>
        <Heading fontSize="lg">{t("Zdarzenia systemowe")}</Heading>
        <Spacer />
        <EventHistoryFilter />
      </CardHeader>
      <CardBody>
        <EventTableConnected
          events={data?.events as IEvent[]}
          isLoading={isLoading}
        />
      </CardBody>
    </Card>
  );
};
