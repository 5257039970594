import { IconButton, Tooltip, useToast } from "@chakra-ui/react";
import { useIsMutating } from "@tanstack/react-query";
import { t } from "i18next";
import { HTTPError } from "ky";

import { toastWithError } from "components/ErrorHandling/toastWithError";
import { TestIcon } from "components/icons/TestIcon";

import { useTestIntegrationKey } from "modules/integrationKey/infrastructure/useTestIntegrationKey";

interface IProps {
  hasIframeUrl: boolean;
  keyId: string;
}

export const TestKeyButton = ({ keyId, hasIframeUrl }: IProps) => {
  const { mutateAsync: testKey } = useTestIntegrationKey();
  const isMutating = useIsMutating({
    mutationKey: ["test-api-key"],
  });

  const toast = useToast({ variant: "subtle" });

  if (!hasIframeUrl) {
    return null;
  }

  const handleClick = async () => {
    try {
      await testKey({ keyId });
      toast({
        description: t("Klucz skonfigurowany poprawnie"),
        status: "success",
      });
    } catch (error) {
      if (error instanceof HTTPError) {
        const errorJson = await error.response.json<{
          message: string;
        }>();

        if (errorJson.message) {
          return toast({
            description: errorJson.message,
            status: "error",
          });
        }
      }

      toastWithError({
        error,
      });
    }
  };

  const label = t("Testuj konfigurację klucza");

  return (
    <Tooltip label={label} aria-label={label}>
      <IconButton
        variant="link"
        colorScheme="purple"
        size="sm"
        data-testid="test-integration-key"
        isLoading={!!isMutating}
        onClick={handleClick}
        aria-label={label}
        icon={<TestIcon />}
      />
    </Tooltip>
  );
};
