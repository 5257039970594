import {
  Button,
  InputGroup,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { useIsMutating } from "@tanstack/react-query";
import { t } from "i18next";

import { Modal } from "components/Dialog";

import { useAddIntegrationKey } from "modules/integrationKey/infrastructure/useAddIntegrationKey";
import { useEditIntegrationKey } from "modules/integrationKey/infrastructure/useEditIntegrationKey";

import { IKeyFormData, IntegrationKeyForm } from "./IntegrationKeyForm";

interface IProps {
  id: string;
  organizationName?: string;
  keyId?: string; // niezbędny do edycji klucza
}

export const KeyModal = ({ id, keyId, organizationName }: IProps) => {
  const { mutateAsync: addKey } = useAddIntegrationKey();
  const { mutateAsync: editKey } = useEditIntegrationKey(keyId!);
  const isAdding = useIsMutating({
    mutationKey: ["add-integration-key"],
  });

  const isEditing = useIsMutating({
    mutationKey: ["edit-integration-key"],
  });

  const isMutating = isAdding || isEditing;

  return (
    <Modal<IKeyFormData> id={id}>
      {({ payload, onClose }) => {
        return (
          <>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {keyId ? t("Edytuj klucz") : t("Dodaj klucz")}
              </ModalHeader>
              <ModalBody as={VStack} align="stretch" spacing={6}>
                <IntegrationKeyForm
                  onClose={onClose}
                  onSubmit={async (data: IKeyFormData) => {
                    if (keyId) {
                      await editKey(data);
                    } else {
                      await addKey(data);
                    }
                  }}
                  editMode={!!keyId}
                  defaultData={payload}
                  organizationName={organizationName}
                />
              </ModalBody>
              <ModalFooter>
                <InputGroup gap="2" justifyContent="end">
                  <Button onClick={onClose} isLoading={!!isMutating}>
                    {t("Anuluj")}
                  </Button>
                  <Button
                    colorScheme="purple"
                    type="submit"
                    data-testid="add-key-button"
                    form="key-form"
                    isLoading={!!isMutating}
                  >
                    {keyId ? t("Zapisz") : t("Dodaj")}
                  </Button>
                </InputGroup>
              </ModalFooter>
            </ModalContent>
          </>
        );
      }}
    </Modal>
  );
};
