import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

export const useCompleteNotificationProcess = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["complete-notification-process"],
    mutationFn: (processId: string) => {
      return client.patch(`notificationsProcess/${processId}/complete`);
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["notifications-process"],
      });
    },
  });
};
