import { useParams } from "react-router-dom";

import { Button } from "@chakra-ui/react";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";
import { ToolsIcon } from "components/icons/ToolsIcon";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";

import { OrganizationConfigurationModal } from "./OrganizationConfigurationModal";

export const OrganizationConfigurationBtn = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const id = `organization-configuration-${organizationId}`;
  const onOpen = useOpenDialog(id);

  const context = ContextMachineContext.useSelector((state) => state.context)
    .userInfo?.currentlySelectedContext?.type;

  const withAccess = ["system_admin", "system_manager", "context_manager"];

  if (!withAccess.includes(context!)) {
    return null;
  }

  return (
    <>
      <OrganizationConfigurationModal
        id={id}
        organizationId={organizationId!}
      />
      <Button
        leftIcon={<ToolsIcon />}
        variant="outline"
        colorScheme="purple"
        size="sm"
        data-testid="organization-configuration"
        onClick={onOpen}
        flexShrink={0}
      >
        {t("Konfiguracja")}
      </Button>
    </>
  );
};
